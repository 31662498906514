import React, { useState, useEffect } from "react";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import Input from "../Input/Input";
import "../../css/loginSignup.css";
import { Button } from "../../JSS/Button";
import { useDispatch } from "react-redux";
import { logIn } from "../../redux/actions/UserAction";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { KEY } from "../../utils/Constants/systemSetting";
import toast from "react-hot-toast";

export default function LoginAndSignup() {
  const [user, setUser] = useState();
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeClass, setActiveClass] = useState("");
  // previously: location.pathname === "/signup" ? "right-panel-active" : ""

  useEffect(() => {
    if (localStorage.getItem(KEY) === "expired") {
      toast.error("Session has expired. Please log in to your account again.");
    }
  }, []);

  return (
    <div className="login login-signup">
      <div className={`container ${activeClass}`}>
        <div className="form-container sign-up-container">
          <button
            onClick={() => {
              setActiveClass("");
            }}
            className="d-block d-sm-none"
          >
            <i className="fa fa-arrow-right"></i> Log In
          </button>
          <SignUpForm active={activeClass} setUser={setUser} />
        </div>
        <div className="form-container sign-in-container">
          <SignInForm active={!activeClass} setUser={setUser} />
        </div>
        <div className="overlay-container ">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1>Welcome to</h1>
              <h1>C-Suite Comp!</h1>
              <p></p>
              <p>Already have an account?</p>
              <Button
                style={{ marginTop: -15 }}
                onClick={() => {
                  setActiveClass("");
                }}
              >
                Log in
              </Button>
            </div>
            <div className="overlay-panel overlay-right">
              <h2>Don't have an account?</h2>
              <Link to="/subscribe">
                <Button
                  style={{ marginTop: -15 }}
                  onClick={() => {
                    setActiveClass("right-panel-active");
                  }}
                >
                  Join
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
