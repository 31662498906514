import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../JSS/Button";
import Input from "../Input/Input";
import { Tooltip } from "antd";
import { resetNewPassword } from "../../redux/actions/UserAction";
import { Link, useHistory } from "react-router-dom";

export default function NewPassword({ email }) {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  const handleChange = (e) => {
    let { value } = e.target;
    let valid = true;
    // if (!PASSWORD_REGEX.test(value)) {
    //   valid = false;
    // }
    if (value.length < 6 || value.length > 21) {
      valid = false;
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
    if (value.length > 20) {
      value = value.substring(0, 20);
    }
    setPassword(value);
    setDisabled(!valid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPassword = {
      password,
      email,
    };
    dispatch(resetNewPassword(newPassword));
    setPassword("");
  };

  const resetPage = () => {
    history.go(0);
  };

  return (
    <div className="login-signup forgot-password">
      <div className="container">
        <div className="background new-password">
          <div className="overlay-color"></div>
        </div>
        <form onSubmit={handleSubmit} className="content">
          <h1>New Password</h1>
          <p>Enter a new password for your account.</p>
          <Tooltip
            overlayStyle={{ maxWidth: "345px" }}
            visible={passwordInvalid && password !== ""}
            overlayInnerStyle={{
              borderRadius: "5px",
              color: "var(--white)",
              backgroundColor: "var(--dark-red)",
            }}
            title={passwordInvalid ? "Password is too short." : ""}
            placement="right"
          >
            <Input
              name="password"
              onChange={handleChange}
              value={password}
              required
              type="password"
              label="New Password"
              id="password"
            />
          </Tooltip>
          <div className="btn-group">
            <Link onClick={resetPage}>Go Back</Link>
            <Button disabled={disabled} primary>
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
