import React, { useState, useEffect, useMemo } from "react";
import { Table, Tooltip } from "antd";
import {
  toDollar,
  dataKeyMap,
  getExecCompensationBreakdown,
} from "../../utils/Constants/systemSetting";
import { useSelector } from "react-redux";
import "../../css/overview.css";
import { posGroupMatch } from "../../utils/ExecutiveData/getExecPosition.mjs";
import question from "../../assets/question-mark.svg";

export default function PercentileTable({
  executive,
  year,
  execPositionGroup,
}) {
  const { companies } = useSelector((state) => state.CompaniesReducer);
  const { csuite: allExecutives } = useSelector(
    (state) => state.ExecutivesReducer
  );

  const hasHoverText = (element) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 25,
        }}
      >
        {element}
        &nbsp;
        <img
          src={question}
          alt="question_mark"
          style={{
            width: 25,
            paddingBottom: 2,
            filter: "invert(0.5) saturate(100) hue-rotate(0deg)",
          }}
        />
      </div>
    );
  };

  const compensationTableKeys = useMemo(() => {
    if (!executive?.compensation?.[year]) return [];
    const execTableKeys = [];

    for (const key of Object.keys(executive.compensation[year])) {
      if (
        !execTableKeys.includes(key) &&
        key !== "rank" &&
        key !== "undefined" &&
        key !== "originalTotal" &&
        key !== "manuallyEdited" &&
        key !== "sumOfCompensationComponents" &&
        executive.compensation[year][key] > 0
      ) {
        execTableKeys.push(key);
      }
    }
    return execTableKeys;
  }, [executive, year]);

  const sectorExecutives = useMemo(() => {
    return allExecutives.filter(
      (exec) =>
        companies[exec?.Ticker]?.Sector ===
          companies[executive?.Ticker]?.Sector &&
        posGroupMatch(year, exec, execPositionGroup, true)
    );
  }, [allExecutives, executive, companies, execPositionGroup, year]);

  const allExecutivesWithPosition = useMemo(() => {
    return allExecutives.filter((exec) =>
      posGroupMatch(year, exec, execPositionGroup, true)
    );
  }, [allExecutives, execPositionGroup, year]);

  const allCompensationBreakdown = useMemo(() => {
    return getExecCompensationBreakdown(allExecutivesWithPosition, year);
  }, [allExecutivesWithPosition, year]);
  const sectorCompensationBreakdown = useMemo(() => {
    return getExecCompensationBreakdown(sectorExecutives, year);
  }, [sectorExecutives, year]);

  const compensationTableColumns = [
    {
      title: `Compensation`,
      dataIndex: "compensation",
      key: "compensation",
      align: "left",
    },
    {
      title: `Compensation Amount in ${year}`,
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
    {
      title: (
        <Tooltip title="Percentile for the compensation component listed among all executives in this executive's position group and sector.">
          {hasHoverText(<span>Percentile in Sector</span>)}
        </Tooltip>
      ),
      dataIndex: "sectorPercentile",
      key: "sectorPercentile",
      align: "center",
    },
    {
      title: (
        <Tooltip title="Rank for the compensation component listed among all executives in this executive's position group and sector.">
          {hasHoverText(
            <div style={{ minWidth: 100 }}>
              <p>Rank in Sector</p>
              <p>
                (Max:{" "}
                {sectorCompensationBreakdown?.["total"]
                  ? sectorCompensationBreakdown["total"].length.toLocaleString()
                  : null}
                )
              </p>
            </div>
          )}
        </Tooltip>
      ),
      dataIndex: "sectorRank",
      key: "sectorRank",
      align: "center",
      responsive: ["md"],
    },
    {
      title: (
        <Tooltip title="Percentile for the compensation component listed among all executives in this executive's position group.">
          {hasHoverText(<span>Percentile All</span>)}
          {/* <span>
            Percentile All <Image src={question} alt="question mark" />
          </span> */}
        </Tooltip>
      ),
      dataIndex: "overallPercentile",
      key: "overallPercentile",
      align: "center",
    },
    {
      title: (
        <Tooltip title="Rank for the compensation component listed among all executives in this executive's position group.">
          {hasHoverText(
            <div style={{ minWidth: 100 }}>
              <p>Overall Rank</p>
              <p>
                (Max:{" "}
                {allCompensationBreakdown["total"].length.toLocaleString()})
              </p>
            </div>
          )}
        </Tooltip>
      ),
      dataIndex: "overallRank",
      key: "overallRank",
      align: "center",
      responsive: ["md"],
    },
  ];

  const compensationTableData = useMemo(() => {
    return compensationTableKeys
      .map((key, index) => {
        const compensationValue = executive?.compensation?.[year]?.[key];
        const overallRank = allCompensationBreakdown?.[key]
          ? allCompensationBreakdown[key].indexOf(compensationValue) + 1
          : null;
        const sectorRank = sectorCompensationBreakdown?.[key]
          ? sectorCompensationBreakdown[key].indexOf(compensationValue) + 1
          : null;
        return {
          key: key + index,
          compensation: dataKeyMap[key].title,
          order: dataKeyMap[key].order,
          amount: toDollar(compensationValue),
          overallPercentile: parseFloat(
            100 -
              ((overallRank - 1) / allCompensationBreakdown[key].length) * 100
          ).toFixed(2),
          overallRank: overallRank.toLocaleString(),
          sectorPercentile: parseFloat(
            100 -
              ((sectorRank - 1) / sectorCompensationBreakdown[key].length) * 100
          ).toFixed(2),
          sectorRank: sectorRank.toLocaleString(),
        };
      })
      .sort((a, b) => a.order - b.order);
  }, [
    year,
    executive,
    compensationTableKeys,
    allCompensationBreakdown,
    sectorCompensationBreakdown,
  ]);

  const getRowStyle = (record, index) => {
    if (record.key?.includes("total")) {
      return "bold-column";
    }
    return "";
  };

  return (
    <div
      className="compensation-table"
      id="percentile-table"
      style={{ marginBottom: 10 }}
    >
      <h3>{executive.name} Executive Summary</h3>
      <Table
        dataSource={compensationTableData}
        columns={compensationTableColumns}
        pagination={{ hideOnSinglePage: true }}
        bordered={true}
        rowClassName={getRowStyle}
      />
    </div>
  );
}
