import {
  CHECK_TABLE_USE_LIMIT_SAGA,
  RESET_NEW_PASSWORD_SAGA,
  RESET_PASSWORD_REQUEST_SAGA,
  SET_TABLE_USE_LIMIT_SAGA,
  SIGN_IN_SAGA,
  USER_LOG_IN_SAGA,
  SET_USER_DETAIL,
  SIGN_OUT,
  UPDATE_USER_DETAILS,
  UPDATE_USER_PASSWORD,
  SIGN_UP_SAGA,
  VERIFY_SECRET_CODE_SAGA,
  RESEND_ACTIVATION_LINK_SAGA,
} from "../constants/UserConstant";
import jwt_decode from "jwt-decode";
import { ACCESS_TOKEN, USER_INFO } from "../../utils/Constants/systemSetting";

export const logIn = (user) => ({
  type: USER_LOG_IN_SAGA,
  user,
});

export const setUser = () => {
  const user = localStorage.getItem(USER_INFO);
  const decoded = jwt_decode(user);
  return {
    type: SET_USER_DETAIL,
    user: decoded,
  };
};

export const checkTableUseLimit = () => ({
  type: CHECK_TABLE_USE_LIMIT_SAGA,
});

export const setTableUseLimit = () => ({
  type: SET_TABLE_USE_LIMIT_SAGA,
});

export const signUp = (newUser) => ({
  type: SIGN_UP_SAGA,
  newUser,
});

export const updateUser = (newUserDetails) => ({
  type: UPDATE_USER_DETAILS,
  newUserDetails,
});

export const updateUserPassword = (newUserDetails) => ({
  type: UPDATE_USER_PASSWORD,
  newUserDetails,
});

export const signIn = (user) => ({
  type: SIGN_IN_SAGA,
  user,
});

export const signOut = () => ({
  type: SIGN_OUT,
});

export const resetPasswordRequest = (email) => ({
  type: RESET_PASSWORD_REQUEST_SAGA,
  email,
});

export const resendActivationLink = (email) => ({
  type: RESEND_ACTIVATION_LINK_SAGA,
  email,
});

export const verifySecretCode = (code) => ({
  type: VERIFY_SECRET_CODE_SAGA,
  code,
});

export const resetNewPassword = (newPassword) => ({
  type: RESET_NEW_PASSWORD_SAGA,
  newPassword,
});
