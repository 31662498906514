import React, { useState, useEffect, useMemo } from "react";
import BarGraphOverview from "../../Graphs/BarGraphOverview";
import {
  fromDollar,
  getPercentile,
} from "../../../utils/Constants/systemSetting";
import { Checkbox } from "antd";

export default function MeasureBarGraph({
  year,
  executive,
  measure,
  cohortTableSelector,
  sortedTableKeys,
  tablePagination,
}) {
  const [cohortExecutives, setCohortExecutives] = useState([]);

  useEffect(() => {
    const { current, pageSize } = tablePagination;
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    setCohortExecutives(
      sortedTableKeys
        .slice(sortedTableKeys?.length > 40 ? startIndex : 0)
        .filter((key) => {
          return (
            key !== null &&
            !["N/A", "N", "P"].some((excludeTerm) =>
              key.measureValue.toString().includes(excludeTerm)
            )
          );
        })
        .map((key) => {
          return {
            name: key.name.props.children,
            company: key.company.props.children,
            value: key.toString().includes("%")
              ? parseFloat(key.measureValue)
              : fromDollar(key.measureValue),
            isExecPage:
              key.name.props.children &&
              key.ticker &&
              executive.name &&
              executive.Ticker &&
              key.name.props.children.replace("*", "") ===
                executive.name.replace("*", "") &&
              key.ticker === executive.Ticker,
            percentile: key?.measurePercentile,
          };
        })
    );
  }, [sortedTableKeys, tablePagination, executive]);

  const percentileLines = useMemo(() => {
    const percentileLineValues = [25, 50, 75, 90];
    return percentileLineValues.map((value) => ({
      percentile: value,
      amount: getPercentile(
        sortedTableKeys
          .filter((key) => {
            // console.log(key);
            return (
              key !== null &&
              !["N/A", "N", "P"].some((excludeTerm) =>
                key.measureValue.toString().includes(excludeTerm)
              )
            );
          })
          .map((key) => fromDollar(key.measureValue))
          .sort((a, b) => a - b),
        value / 100
      ),
    }));
  }, [sortedTableKeys]);

  const [showPercenileLines, setShowPercentileLines] = useState(true);

  return (
    <div
      className="c-table"
      style={{
        flex: 2,
      }}
    >
      <div
        style={{
          width: "inherit",
          paddingRight: "max(65px, 7.5%)",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Checkbox
          checked={showPercenileLines}
          onChange={() => setShowPercentileLines(!showPercenileLines)}
        >
          Show Percentile Benchmarks
        </Checkbox>
      </div>
      <div className=" graphs" style={{ margin: 0, maxHeight: 400 }}>
        <BarGraphOverview
          data={cohortExecutives.slice(0, 40)}
          year={year}
          company={
            `${executive.name}'s ${cohortTableSelector}` +
            (cohortExecutives.length > 40
              ? " (First 40 Executives in Table)"
              : "")
          }
          measure={measure}
          percentileLines={percentileLines}
          showPercenileLines={showPercenileLines}
          currentExecutivePage={executive}
        />
      </div>
    </div>
  );
}
