import { HIDE_PAYWALL, SHOW_PAYWALL } from "../constants/PaywallConstant";

export const displayPaywall = () => ({
    type: SHOW_PAYWALL,
})

export const hidePaywall = () => ({
    type: HIDE_PAYWALL,
})

