import React, { useState, useMemo } from "react";
import { Switch, Tooltip } from "antd";

import CustomInput from "./utils/CustomInput";
import FinalValue from "./utils/FinalValue";

import { OptionsFAQTopics } from "../../assets/faqTopicsOptions";
import { hasHoverText } from "../../utils/Constants/systemSetting";

export default function BlackScholesCalculator({
  companyInfo,
  lookbackVolatilityBlendedAverage,
  VolatilitySwitch,
  showVolatilityEditView,
}) {
  const [strikePriceDividendAdjusted, setStrikePriceDividendAdjusted] =
    useState(false);

  const filterInput = (input, min, max) => {
    let newInput = input.replace(/[^0-9.]/g, "").slice(0, 15);
    if (newInput.split(".").length > 2) {
      newInput = newInput.split(".").slice(0, 2).join(".");
    }
    if (newInput === "") return "";
    if (newInput >= max) return max;
    if (newInput <= min) return min;
    return newInput;
  };

  const [blackScholesParameters, setBlackScholesParameters] = useState({
    annualVolatility: {
      label: "Annual Volatility",
      value: 31.0, //percent
      min: 0,
      max: 1e6,
      hoverText: OptionsFAQTopics.annual_volatility.short_desc,
    },
    timeToMaturity: {
      label: "Time To Maturity, in years",
      value: 10,
      min: 0,
      max: 1000,
      hoverText: OptionsFAQTopics.time_to_maturity.short_desc,
    },
    strikePrice: {
      label: "Strike Price",
      value: 100,
      min: 0,
      max: 1e6,
      hoverText: OptionsFAQTopics.strike_price.short_desc,
    },
    currentStockPrice: {
      label: "Current Stock Price",
      value: 100,
      min: 0,
      max: 1e6,
      hoverText: OptionsFAQTopics.current_stock_price.short_desc,
    },

    annualRiskFreeRate: {
      label: "Continuously Compounded Annual Riskfree Rate",
      value: 4.67, // percent
      min: 0,
      max: 1e6,
      hoverText:
        OptionsFAQTopics.continuously_compounded_riskfree_rate.short_desc,
    },
    dividendAdjustedCurrentStockPrice: {
      label: "Dividend Adjusted Current Stock Price",
      value: 99.03, //Just for testing, 100 can be default
      min: 0,
      max: 1e6,
      hoverText:
        OptionsFAQTopics.expected_future_dividends_selection.short_desc,
    },
  });

  const calculateNormalDistribution = (z, method = 1) => {
    if (method === 1) {
      const GetZPercent = (z) => {
        //z == number of standard deviations from the mean

        //if z is greater than 6.5 standard deviations from the mean
        //the number of significant digits will be outside of a reasonable
        //range
        if (z < -6.5) return 0.0;
        if (z > 6.5) return 1.0;

        var factK = 1;
        var sum = 0;
        var term = 1;
        var k = 0;
        var loopStop = Math.exp(-23);
        while (Math.abs(term) > loopStop) {
          term =
            //Note: 1/(sqrt(2*pi)) === 0.3989422804
            (((0.3989422804 * Math.pow(-1, k) * Math.pow(z, k)) /
              (2 * k + 1) /
              Math.pow(2, k)) *
              Math.pow(z, k + 1)) /
            factK;
          sum += term;
          k++;
          factK *= k;
        }
        sum += 0.5;

        return sum;
      };
      return GetZPercent(z);
    }

    if (method === 2) {
      const T = 1 / (1 + 0.2316419 * Math.abs(z));
      const D = 0.3989423 * Math.exp((-z * z) / 2);
      const cumulativeDistribution =
        D *
        T *
        (0.3193815 +
          T * (-0.3565638 + T * (1.781478 + T * (-1.821256 + T * 1.330274))));
      if (z > 0) return 1 - cumulativeDistribution;
      return cumulativeDistribution;
    }
  };

  const calculatedBlackScholesValues = useMemo(() => {
    //Note: Math.log(x) === ln(x) in excel
    const sigma = blackScholesParameters.annualVolatility.value / 100; //if as a percent
    const T = blackScholesParameters.timeToMaturity.value;
    const K = blackScholesParameters.strikePrice.value;
    const S0 = blackScholesParameters.currentStockPrice.value;
    const S0Div =
      blackScholesParameters.dividendAdjustedCurrentStockPrice.value;
    const r = blackScholesParameters.annualRiskFreeRate.value / 100;

    const S0Selected = strikePriceDividendAdjusted ? S0Div : S0;

    const d1Numerator =
      Math.log(S0Selected / K) + (r + 0.5 * Math.pow(sigma, 2)) * T;
    const d1Denominator = sigma * Math.sqrt(T);
    const d1 = d1Numerator / d1Denominator;

    const d2 = d1 - sigma * Math.sqrt(T);

    const N_D1 = calculateNormalDistribution(d1);
    const N_D2 = calculateNormalDistribution(d2);

    const callPriceEstimate = S0Selected * N_D1 - K * Math.exp(-r * T) * N_D2;
    const putPriceEstimate =
      callPriceEstimate + (K * Math.exp(-r * T) - S0Selected);

    return { callPriceEstimate, putPriceEstimate };
  }, [blackScholesParameters, strikePriceDividendAdjusted]);

  const [storedPrevVolatility, setStoredPrevVolatility] = useState(31.0);
  useMemo(() => {
    if (showVolatilityEditView && !isNaN(lookbackVolatilityBlendedAverage)) {
      setBlackScholesParameters((prev) => {
        const newParams = { ...prev };
        const prevValue = newParams["annualVolatility"];

        if (prevValue.value !== lookbackVolatilityBlendedAverage.toFixed(5))
          setStoredPrevVolatility(prevValue.value);

        newParams["annualVolatility"] = {
          ...prevValue,
          value: lookbackVolatilityBlendedAverage.toFixed(5),
        };
        return newParams;
      });
    } else {
      setBlackScholesParameters((prev) => {
        const newParams = { ...prev };
        const prevValue = newParams["annualVolatility"];
        newParams["annualVolatility"] = {
          ...prevValue,
          value: storedPrevVolatility,
        };
        return newParams;
      });
    }
  }, [
    lookbackVolatilityBlendedAverage,
    showVolatilityEditView,
    storedPrevVolatility,
  ]);

  return (
    <div>
      <VolatilitySwitch />
      {Object.entries(blackScholesParameters).map(
        ([parameterKey, parameterValues], index) => (
          <div
            key={index}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            {parameterKey === "dividendAdjustedCurrentStockPrice" ? (
              <div></div>
            ) : (
              <CustomInput
                state={parameterValues.value}
                setState={(val) =>
                  setBlackScholesParameters((prev) => {
                    val = filterInput(
                      val,
                      parameterValues.min,
                      parameterValues.max
                    );
                    const newParams = { ...prev };
                    const prevValue = newParams[parameterKey];
                    newParams[parameterKey] = { ...prevValue, value: val };
                    return newParams;
                  })
                }
                heading={parameterValues.label + ":"}
                style={{ margin: 15 }}
                disabled={
                  parameterKey === "annualVolatility" && showVolatilityEditView
                }
                key={parameterKey}
                hoverText={parameterValues?.hoverText}
              />
            )}
          </div>
        )
      )}
      <div style={{ width: "100%", display: "flex", margin: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Tooltip
            title={OptionsFAQTopics.dividend_paying_stock.short_desc}
            overlayClassName="white-link"
            overlayInnerStyle={{ width: 500 }}
          >
            <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
              {hasHoverText(
                "Use Dividend Adjusted Stock Price: "
                  .split(" ")
                  .map((head, index) => <span key={index}>{head}&nbsp;</span>)
              )}
            </h3>
          </Tooltip>
          <Switch
            onChange={() =>
              setStrikePriceDividendAdjusted(!strikePriceDividendAdjusted)
            }
            checked={strikePriceDividendAdjusted}
          />
        </div>
      </div>
      {Object.entries(blackScholesParameters)
        .filter(
          ([key, val]) =>
            key === "dividendAdjustedCurrentStockPrice" &&
            strikePriceDividendAdjusted
        )
        .map(([parameterKey, parameterValues], index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <CustomInput
              state={parameterValues.value}
              setState={(val) =>
                setBlackScholesParameters((prev) => {
                  val = filterInput(
                    val,
                    parameterValues.min,
                    parameterValues.max
                  );
                  const newParams = { ...prev };
                  const prevValue = newParams[parameterKey];
                  newParams[parameterKey] = { ...prevValue, value: val };
                  return newParams;
                })
              }
              heading={parameterValues.label + ":"}
              style={{ margin: 15 }}
              disabled={
                parameterKey === "annualVolatility" && showVolatilityEditView
              }
              key={parameterKey}
              hoverText={parameterValues?.hoverText}
            />
          </div>
        ))}

      <div style={{ margin: 35 }} />
      <hr />
      <FinalValue
        heading="Black-Scholes Call Price Estimate"
        value={calculatedBlackScholesValues?.callPriceEstimate}
        headingWidth={417}
        decimalPlaces={2}
        isDollar={true}
      />
      {/* <FinalValue
        heading="Black-Scholes Put Price Estimate"
        value={calculatedBlackScholesValues?.putPriceEstimate}
        headingWidth={417}
        decimalPlaces={6}
      /> */}
      <hr style={{ marginTop: 4 }} />
    </div>
  );
}
