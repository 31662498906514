import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div
      className="container w-50"
      style={{ marginTop: "250px", minHeight: "90vh" }}
    >
      <div className="row pt-5">
        <div className="col-6">
          <img
            style={{ width: "100%" }}
            src={require("../assets/404error.png").default}
            alt="error 404"
          />
        </div>
        <div className="col-6 text-center" style={{ margin: "auto" }}>
          <h2>Page Not Found</h2>
          <Link
            className="btn text-white fw-bold"
            style={{
              backgroundColor: "var(--main)",
              borderRadius: "0.5rem",
            }}
            to="/"
          >
            <p style={{ color: "var(--blue)" }}>Back To Home Page</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
