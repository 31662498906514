import Input from "../../Input/Input";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../../JSS/Button";
import { Tooltip, Popover } from "antd";
import { PASSWORD_REGEX } from "../../../utils/Constants/systemSetting";
import "../../../css/profile-info.css";
import toast from "react-hot-toast";
import { updateUserPassword } from "../../../redux/actions/UserAction";
import UserService from "../../../services/UserService";

export default function Security() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.UserReducer);

  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const [disabled, setDisabled] = useState(true);
  const [passwordInvalid, setPasswordInvalid] = useState(true);
  const handleChange = (e) => {
    let { name, value } = e.target;
    const values = { ...state };
    values[name] = value;
    let valid = true;
    if (name === "newPassword") {
      if (value.length < 6) {
        valid = false;
        setPasswordInvalid(true);
      } else {
        setPasswordInvalid(false);
      }
    }
    for (let key in values) {
      if (values[key].trim() === "") {
        valid = false;
      }
      if (values[key].length > 20) {
        values[key] = values[key].substring(0, 20);
      }
    }
    setDisabled(!valid);
    setState({ ...values });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const newUserDetails = {
        ...state,
        email: user?.email,
      };
      setDisabled(true);
      const { status, data } = await UserService.updateUserPassword(
        newUserDetails
      );
      if (status == 200) {
        if (typeof data == "string") {
          toast.success(data);
        } else {
          toast.success("Password has been reset.");
        }
        setState({
          currentPassword: "",
          newPassword: "",
        });
      } else {
        setDisabled(false);
      }
    } catch (err) {
      console.log(err);
      if (err?.response && err.response?.data) {
        toast.error(err.response?.data);
      } else {
        toast.error(
          "Password cannot be updated right now. Please try again later."
        );
      }
      setDisabled(false);
    }
  };

  return (
    <div className="profile-info pb-5">
      <form className="security">
        <div className="container">
          <h3>Change password</h3>
          {user?.oAuthID ? (
            <p>Password cannot be changed for Google and Facebook accounts.</p>
          ) : (
            <>
              <p>
                You're changing the password for: <strong>{user.email}</strong>
              </p>

              <Input
                name="currentPassword"
                label="Current Password"
                required
                type="password"
                onChange={handleChange}
                value={state.currentPassword}
              />

              <Tooltip
                overlayStyle={{ maxWidth: "345px" }}
                visible={passwordInvalid && state.newPassword !== ""}
                overlayInnerStyle={{
                  borderRadius: "5px",
                  color: "var(--white)",
                  backgroundColor: "var(--dark-red)",
                }}
                title={passwordInvalid ? "Password is too short." : ""}
                placement="right"
              >
                <Input
                  name="newPassword"
                  value={state.newPassword}
                  onChange={handleChange}
                  required
                  type="password"
                  label="New Password"
                />
              </Tooltip>
              <div className="btn-group" style={{ marginTop: 25 }}>
                <Button primary disabled={disabled} onClick={handleSubmit}>
                  Save Changes
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
