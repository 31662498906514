import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import allArticles from "../assets/articles/allArticles.json";

export default function ArticlesList() {
  const { year } = useParams();

  return (
    <div className="overview container mb-3" style={{ minHeight: "65vh" }}>
      <div
        className="container"
        style={{
          marginBottom: "20px",
          marginTop: "100px",
        }}
      >
        <h1>{year ? `${year} ` : "All "}Articles</h1>
        {Object.entries(allArticles).map(([articlesYear, articles]) => {
          return (
            <div>
              {!year && (
                <h2>
                  <Link
                    style={{ color: "black" }}
                    to={`/articles/${articlesYear}`}
                  >
                    {articlesYear}
                  </Link>
                </h2>
              )}
              <ul>
                {articles.map((article) => (
                  <li key={articlesYear + article.id}>
                    <Link to={`/articles/${articlesYear}/${article.id}`}>
                      {article.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}
