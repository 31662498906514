import React, { useEffect, useState } from "react";
import Input from "../../Input/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../../JSS/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Select } from "antd";
import "../../../css/profile-info.css";
import toast from "react-hot-toast";
import { updateUser } from "../../../redux/actions/UserAction";
import UserService from "../../../services/UserService";
import { Popover, Checkbox } from "antd";
import { Link } from "react-router-dom";

const { Option } = Select;

export default function ProfileInfo() {
  const dispatch = useDispatch();

  //   const [startDate, setStartDate] = useState(new Date());

  const user = useSelector((state) => state.UserReducer);
  const [disabled, setDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [showPhone, setShowPhone] = useState(false);

  const [state, setState] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    address: "",
    zip: "",
    companyName: "",
    companyWebsite: "",
    employees: "",
    emailConsent: false,
  });

  useEffect(() => {
    setState({
      fname: user?.fname || "",
      lname: user?.lname || "",
      email: user?.email || "",
      phone: user?.phone || "",
      address: user?.address || "",
      zip: user?.zip || "",
      companyName: user?.companyName || "",
      companyWebsite: user?.companyWebsite || "",
      employees: user?.employees || "",
      emailConsent: user?.emailConsent || false,
    });
    setInitialValues({ ...state });
  }, [user]);

  const isValidChanges = (values) => {
    const iValues = { ...initialValues };
    let valid = true;
    let changes = false;
    for (let key in values) {
      if (key !== "emailConsent" && values[key].trim() === "") {
        valid = false;
      }
      for (let iKey in iValues) {
        if (iKey === key) {
          if (iValues[iKey] !== values[key]) {
            changes = true;
          }
          // Allowed to be "" if inital value was ""
          if (!valid && !iValues[iKey]) {
            valid = true;
          }
        }
      }
    }
    if (valid && changes) {
      return true;
    }
    return false;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const values = { ...state };
    values[name] = value;
    let valid = true;
    if (name === "zip") {
      values["zip"] = value.replace(/[^0-9]/gi, "");
      if (value.length > 5) {
        values["zip"] = value.substring(0, 5);
      }
    }
    if (
      ["address", "companyName", "companyWebsite"].includes(name) &&
      value.length > 50
    ) {
      values[name] = value.substring(0, 50);
    }

    for (let key in values) {
      // if (values[key].trim() === "") {
      //   valid = false;
      // }
      if (key === "zip" && values[key].trim().length !== 5) {
        valid = false;
      }
    }
    setState({ ...values });
    setDisabled(!isValidChanges({ ...values }) || !valid);
  };

  const handleChangePhone = (phone) => {
    const values = { ...state };
    values["phone"] = phone;
    setState({ ...values });
    setDisabled(!isValidChanges({ ...values }));
  };

  const handleSelectChange = (value) => {
    const values = { ...state };
    values["employees"] = value;
    setState({ ...values });
    setDisabled(!isValidChanges({ ...values }));
  };

  const handleCheckboxChange = () => {
    const values = { ...state };
    values["emailConsent"] = !values["emailConsent"];
    setState({ ...values });
    setDisabled(!isValidChanges({ ...values }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const newUserDetails = {
        ...state,
      };

      setDisabled(true);
      const { status, data } = await UserService.updateUser(newUserDetails);
      if (status == 200) {
        await dispatch(updateUser(newUserDetails));
        toast.success("Your profile is up-to-date!");
      } else {
        setDisabled(false);
      }
    } catch (err) {
      //   console.log(err);
      console.log(err?.response);
      toast.error(
        "Profile cannot be updated right now. Please try again later."
      );
      setDisabled(false);
    }
  };
  return (
    <div className="profile-info pb-5">
      <div className="container">
        <h3>Personal Information</h3>
        <form className="row">
          <div className="col-6">
            <Input
              onChange={handleChange}
              value={state.fname}
              style={{ borderColor: "var(--secondary)" }}
              name="fname"
              label="First Name"
              required
            />
          </div>
          <div className="col-6">
            <Input
              onChange={handleChange}
              value={state.lname}
              style={{ borderColor: "var(--secondary)" }}
              name="lname"
              label="Last Name"
              required
            />
          </div>
          <Popover
            overlayInnerStyle={{
              // padding: "10px",
              color: "var(--secondary)",
            }}
            color="white"
            html={true}
            title=""
            content={
              <>
                <div>Email associated with your account.</div>
                <div>
                  If you see an error, please{" "}
                  <Link to="/contact">contact us</Link>.
                </div>
              </>
            }
            placement="right"
          >
            <div>
              <Input
                name="email"
                onChange={handleChange}
                disabled={true}
                style={{ borderColor: "var(--gray)" }}
                value={state.email}
                // required
                // label="Email"
              />
            </div>
          </Popover>
          {showPhone ? (
            <div>
              <PhoneInput
                name="phone"
                placeholder="Phone Number"
                country="us"
                preferredCountries={["us"]}
                // onlyCountries={["us"]}
                onChange={(phone) => handleChangePhone(phone)}
                onBlur={() => (!state.phone ? setShowPhone(false) : "")}
                value={state.phone}
                inputProps={{
                  autoFocus: true,
                }}
                inputStyle={{
                  borderRadius: "0.5rem",
                  // border: `1px solid ${state.errors.phone}`,
                  borderColor: "var(--secondary)",
                  width: "100%",
                  padding: "0px 12px 0px 48px",
                  fontSize: "1.2em",
                  height: "47px",
                  autoFocus: true,
                }}
                containerStyle={{ marginTop: "15px" }}
              />
            </div>
          ) : (
            <Input
              name="phone"
              onChange={handleChange}
              style={{ borderColor: "var(--secondary)" }}
              value={state.phone}
              required
              label="Phone Number"
              onFocus={() => {
                setShowPhone(true);
              }}
            />
          )}

          <Input
            name="address"
            onChange={handleChange}
            style={{ borderColor: "var(--secondary)" }}
            value={state.address}
            required
            label="Address"
          />
          <Input
            name="zip"
            onChange={handleChange}
            style={{ borderColor: "var(--secondary)" }}
            value={state.zip}
            required
            label="Zip Code"
          />

          <Input
            name="companyName"
            onChange={handleChange}
            style={{ borderColor: "var(--secondary)" }}
            value={state.companyName}
            required
            label="Company Name"
          />

          <Input
            name="companyWebsite"
            onChange={handleChange}
            style={{ borderColor: "var(--secondary)" }}
            value={state.companyWebsite}
            required
            label="Company Website"
          />
          <div className="select-container" style={{ width: "95.5%" }}>
            <Select
              name="employees"
              className="antd-select"
              size="large"
              value={state.employees || null}
              placeholder="Number of Employees"
              onChange={handleSelectChange}
              style={{ width: "100%", marginTop: 12 }}
              bordered={false}
              // dropdownStyle={{ padding: "-50px" }}
            >
              <Option key="1" value="1-10">
                1-10 employees
              </Option>
              <Option key="2" value="10-100">
                10-100 employees
              </Option>
              <Option key="3" value="100-1000">
                100-1000 employees
              </Option>
              <Option key="4" value="1000+">
                1000+ employees
              </Option>
            </Select>
          </div>
          <Checkbox
            checked={state.emailConsent}
            onChange={() => handleCheckboxChange()}
            style={{ fontSize: "0.9em", margin: 10 }}
          >
            Receive email updates on C-level compensation changes, regulatory
            changes, and our product's new features.
          </Checkbox>

          <div className="button">
            <Button primary disabled={disabled} onClick={handleSubmit}>
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
