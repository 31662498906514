import { CSuiteBaseService } from "./CSuiteBaseServices";

class CustomerService extends CSuiteBaseService {
  getSubscription = (token) => {
    return this.get(`payment/get-subscription?token=${token}`);
  };

  getSubscriptionHistory = (token, sub) => {
    return this.get(
      `payment/get-subscription-history?token=${token}&sub=${sub}`
    );
  };

  getPaymentIntent = (token, paymentIntent) => {
    return this.get(
      `payment/get-payment-intent?token=${token}&paymentIntent=${paymentIntent}`
    );
  };

  getPaymentMethod = (token) => {
    return this.get(`payment/get-payment-method?token=${token}`);
  };

  getPaymentMethodID = (token, paymentID) => {
    return this.get(
      `payment/get-payment-method-id?token=${token}&paymentID=${paymentID}`
    );
  };

  updatePaymentMethod = (paymentMethod) => {
    return this.post(`payment/update-payment-method`, paymentMethod);
  };

  cancelSubscription = (cancellation) => {
    return this.post(`payment/cancel-subscription`, cancellation);
  };

  renewSubscription = (token) => {
    return this.post(`payment/renew-subscription`, token);
  };

  updatePaymentPlan = (token) => {
    return this.post(`payment/update-plan`, token);
  };
}

export default new CustomerService();
