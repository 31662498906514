import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Radio, Tooltip } from "antd";
import { defaultPeerGroupOptions } from "../../../utils/Constants/systemSetting.js";

const preInitialSelectorOptions = defaultPeerGroupOptions;

export default function CohortSelector({
  heading,
  companyInfo,
  setCohortTableTickers,
  cohortTableSelector,
  setCohortTableSelector,
  removeCustomCohort = false,
  removeAllCustomGroups = false,
  onlyIncludePeerGroups = false,
  additionalOptions = [],
  updateKey = 0,
}) {
  const { companies } = useSelector((state) => state.CompaniesReducer);
  const user = useSelector((state) => state.UserReducer);

  const [customUserPeerGroups, setCustomUserPeerGroups] = useState(
    user?.customPeerGroups || {}
  );

  useMemo(() => {
    if (user?.customPeerGroups && Object.keys(user?.customPeerGroups).length) {
      setCustomUserPeerGroups(user?.customPeerGroups);
    }
  }, [user?.customPeerGroups]);

  const isCustomPeerGroup = useCallback(
    (peerGroupKey) =>
      Object.keys(customUserPeerGroups).length &&
      Object.keys(customUserPeerGroups).some((key) => key === peerGroupKey),
    [customUserPeerGroups]
  );

  useMemo(() => {
    if (!user?.customPeerGroups) return;
    const customPeerGroupKeys = Object.keys(user?.customPeerGroups);
    if (customPeerGroupKeys.length) {
      customPeerGroupKeys.forEach((key) => {
        if (!removeAllCustomGroups && !additionalOptions.includes(key)) {
          additionalOptions.push(key);
        }
      });
    }
  }, [user?.customPeerGroups, additionalOptions, removeAllCustomGroups]);

  let initialSelectorOptions =
    preInitialSelectorOptions.concat(additionalOptions);

  //"None" should be first option if additional option
  if (
    initialSelectorOptions.includes("None") &&
    initialSelectorOptions[0] !== "None"
  ) {
    initialSelectorOptions = initialSelectorOptions.filter(
      (item) => item !== "None"
    );
    initialSelectorOptions.unshift("None");
  }

  const [cohortTableSelectorOptions, setCohortTableSelectorOptions] = useState(
    initialSelectorOptions
  );

  const onCohortSelectorChange = (e) => {
    setCohortTableSelector(e.target.value);
  };

  const [resetRadioOptionsCount, setResetRadioOptionsCount] = useState(0);

  useEffect(() => {
    const currentTableSelectorOptions = cohortTableSelectorOptions;
    if (!companyInfo?.AnalystCohort?.length) {
      currentTableSelectorOptions.splice(
        currentTableSelectorOptions.indexOf("Analyst Peer Group"),
        1
      );
    }
    if (!companyInfo?.ProxyCohort?.length) {
      currentTableSelectorOptions.splice(
        currentTableSelectorOptions.indexOf("Proxy Peer Group"),
        1
      );
    }
    if (!companyInfo?.ProxyCohortCompensation?.length) {
      currentTableSelectorOptions.splice(
        currentTableSelectorOptions.indexOf("Proxy Peer Group (Compensation)"),
        1
      );
    }
    if (!companyInfo?.ProxyCohortPerformance?.length) {
      currentTableSelectorOptions.splice(
        currentTableSelectorOptions.indexOf("Proxy Peer Group (Performance)"),
        1
      );
    }

    if (!companyInfo?.CompAIProxyCohort?.length) {
      currentTableSelectorOptions.splice(
        currentTableSelectorOptions.indexOf("Comp AI Proxy Peer Group"),
        1
      );
    }
    if (!companyInfo?.CompAIProxyCohortCompensation?.length) {
      currentTableSelectorOptions.splice(
        currentTableSelectorOptions.indexOf(
          "Comp AI Proxy Peer Group (Compensation)"
        ),
        1
      );
    }
    if (!companyInfo?.CompAIProxyCohortPerformance?.length) {
      currentTableSelectorOptions.splice(
        currentTableSelectorOptions.indexOf(
          "Comp AI Proxy Peer Group (Performance)"
        ),
        1
      );
    }

    if (!companyInfo?.SIC || onlyIncludePeerGroups) {
      [
        "SIC Major Group (2)",
        "SIC Industry Group (3)",
        "SIC Industry (4)",
      ].forEach((option) => {
        currentTableSelectorOptions.splice(
          currentTableSelectorOptions.indexOf(option),
          1
        );
      });
    }
    if (onlyIncludePeerGroups) {
      ["Sector Cohort", "Industry Cohort"].forEach((option) => {
        currentTableSelectorOptions.splice(
          currentTableSelectorOptions.indexOf(option),
          1
        );
      });
    }
    if (removeCustomCohort) {
      currentTableSelectorOptions.splice(
        currentTableSelectorOptions.indexOf("New Custom Peer Group"),
        1
      );
    }
    setCohortTableSelectorOptions(currentTableSelectorOptions);
    setResetRadioOptionsCount((prev) => prev + 1);
  }, [
    companyInfo,
    cohortTableSelectorOptions,
    removeCustomCohort,
    onlyIncludePeerGroups,
  ]);

  useEffect(() => {
    setCohortTableSelector((prev) =>
      !cohortTableSelectorOptions.find((item) => item === prev)
        ? cohortTableSelectorOptions[0]
        : prev
    );
  }, [cohortTableSelectorOptions, setCohortTableSelector]);

  useEffect(() => {
    if (!companyInfo) return;
    switch (cohortTableSelector) {
      case "Analyst Peer Group":
        setCohortTableTickers(companyInfo?.AnalystCohort);
        break;
      case "Proxy Peer Group":
        setCohortTableTickers(companyInfo?.ProxyCohort);
        break;
      case "Proxy Peer Group (Compensation)":
        setCohortTableTickers(companyInfo?.ProxyCohortCompensation);
        break;
      case "Proxy Peer Group (Performance)":
        setCohortTableTickers(companyInfo?.ProxyCohortPerformance);
        break;
      case "Comp AI Proxy Peer Group":
        setCohortTableTickers(companyInfo?.CompAIProxyCohort);
        break;
      case "Comp AI Proxy Peer Group (Compensation)":
        setCohortTableTickers(companyInfo?.CompAIProxyCohortCompensation);
        break;
      case "Comp AI Proxy Peer Group (Performance)":
        setCohortTableTickers(companyInfo?.CompAIProxyCohortPerformance);
        break;
      case "Sector Cohort":
        const sectorCompanies = Object.values(companies)
          .filter((company) => company.Sector === companyInfo.Sector)
          .map((company) => company.Ticker);
        setCohortTableTickers(sectorCompanies);
        break;
      case "Industry Cohort":
        const industryCompanies = Object.values(companies)
          .filter((company) => company.Industry === companyInfo.Industry)
          .map((company) => company.Ticker);
        setCohortTableTickers(industryCompanies);
        break;
      case "SIC Major Group (2)":
        const SICMajorGroupCompanies = Object.values(companies)
          .filter(
            (company) =>
              company?.SIC &&
              company.SIC.startsWith(companyInfo.SIC.slice(0, 2))
          )
          .map((company) => company.Ticker);
        setCohortTableTickers(SICMajorGroupCompanies);
        break;
      case "SIC Industry Group (3)":
        const SICIndustryGroup = Object.values(companies)
          .filter(
            (company) =>
              company?.SIC &&
              company.SIC.startsWith(companyInfo.SIC.slice(0, 3))
          )
          .map((company) => company.Ticker);
        setCohortTableTickers(SICIndustryGroup);
        break;
      case "SIC Industry (4)":
        const SICIndustry = Object.values(companies)
          .filter(
            (company) => company?.SIC && company.SIC.startsWith(companyInfo.SIC)
          )
          .map((company) => company.Ticker);
        setCohortTableTickers(SICIndustry);
        break;
      case "New Custom Peer Group":
        setCohortTableTickers([]);
        break;
      default:
        if (isCustomPeerGroup(cohortTableSelector)) {
          setCohortTableTickers(customUserPeerGroups[cohortTableSelector]);
          break;
        } else {
          setCohortTableTickers([]);
          break;
        }
    }
  }, [
    companyInfo,
    cohortTableSelectorOptions,
    cohortTableSelector,
    companies,
    setCohortTableTickers,
    customUserPeerGroups,
    isCustomPeerGroup,
  ]);

  const cohortSelectorHoverTextMap = {
    "Analyst Peer Group":
      "Compare to an equity analyst-selected peer group for this company.",
    "Proxy Peer Group":
      "Compare to this company's selected peer group found in their proxy statements.",
    "Proxy Peer Group (Compensation)":
      "Compare to this company's selected compensation peer group found in their proxy statements.",
    "Proxy Peer Group (Performance)":
      "Compare to this company's selected performance peer group found in their proxy statements.",
    "Comp AI Proxy Peer Group":
      "Compare to this company's selected peer group found by Comp AI in their proxy statements.",
    "Comp AI Proxy Peer Group (Compensation)":
      "Compare to this company's selected compensation peer group found by Comp AI in their proxy statements.",
    "Comp AI Proxy Peer Group (Performance)":
      "Compare to this company's selected performance peer group found by Comp AI in their proxy statements.",
    "Sector Cohort": `Compare to all ${
      companyInfo?.Sector ? `${companyInfo.Sector} ` : ""
    }companies${!companyInfo?.Sector ? " in this company's sector" : ""}.`,
    "Industry Cohort": `Compare to all ${
      companyInfo?.Industry ? `${companyInfo.Industry} ` : ""
    }companies${!companyInfo?.Industry ? " in this company's industry" : ""}.`,
    "SIC Major Group (2)": `Compare to all companies that match this company's first two SIC code digits${
      companyInfo?.SIC ? ` (${companyInfo.SIC.slice(0, 2)})` : ""
    }.`,
    "SIC Industry Group (3)": `Compare to all companies that match this company's first three SIC code digits${
      companyInfo?.SIC ? ` (${companyInfo.SIC.slice(0, 3)})` : ""
    }.`,
    "SIC Industry (4)": `Compare to all companies that match this company's SIC code${
      companyInfo?.SIC ? ` (${companyInfo.SIC})` : ""
    }.`,
    "New Custom Peer Group": `Create your own peer group by clicking "Edit Comparison Group" below.`,
    "Create Peer Group With Metrics": `Create your own custom peer group based on company metrics.`,
    None: `Don't compare to other peer group types.`,
  };

  return (
    <div
      className="cohort-selector"
      style={{
        display: "flex",
        // alignItems: "start",
        // justifyContent: "start",
        marginTop: 10,
      }}
      key={updateKey}
    >
      {heading && (
        <div className="title">
          <h3
            style={{
              fontSize: 20,
              marginRight: 10,
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            {" "}
            {heading.split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
        </div>
      )}
      <Radio.Group
        defaultValue={initialSelectorOptions[0]}
        size="medium"
        onChange={onCohortSelectorChange}
        value={cohortTableSelector}
        key={updateKey + resetRadioOptionsCount}
      >
        {cohortTableSelectorOptions.map((option, index) => {
          return (
            <span key={updateKey + index}>
              <Tooltip
                title={
                  cohortSelectorHoverTextMap?.[option] ||
                  (isCustomPeerGroup(option)
                    ? "This is a Custom Peer Group you created. Click to select."
                    : "Click to select.")
                }
              >
                <span style={{ paddingTop: 10 }}>
                  {option === "SIC Major Group (2)" && <p />}
                  {option === "New Custom Peer Group" && <p />}
                  <Radio.Button
                    value={option}
                    key={option}
                    className="custom-radio-button"
                    style={{ zIndex: 2 }}
                  >
                    {additionalOptions.includes(option) && option !== "None" ? (
                      isCustomPeerGroup(option) ? (
                        <b>
                          <i>{option}</i>
                        </b>
                      ) : (
                        <b>{option}</b>
                      )
                    ) : (
                      <span>{option}</span>
                    )}
                  </Radio.Button>
                </span>
              </Tooltip>
            </span>
          );
        })}
      </Radio.Group>
    </div>
  );
}
