import React, { useState } from "react";
import "./input.css";

export default function Input(props) {
  const { label, type, value, ...restProps } = props;

  const [show, toggle] = useState(false);

  return (
    <div className="input-container">
      <input value={value} type={show ? "text" : type} {...restProps} />
      <label>{label}</label>
      {type === "password" && value !== "" && (
        <div
          onClick={() => {
            toggle(!show);
          }}
          className="eye"
        >
          <img
            src={require("../../assets/eye.png").default}
            alt="eye"
            className={!show ? "d-none" : "d-block"}
          />
          <img
            src={require("../../assets/closed-eye.png").default}
            alt="closed eye"
            className={show ? "d-none" : "d-block"}
          />
        </div>
      )}
    </div>
  );
}
