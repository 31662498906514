import { Tooltip, DatePicker } from "antd";
import { hasHoverText } from "../../../utils/Constants/systemSetting";

export default function CustomDatePicker({
  state,
  setState,
  heading = "",
  style = {},
  hoverText = "",
  disabled = false,
  isDateRange = false,
}) {
  const { RangePicker } = DatePicker;
  return (
    <div style={{ width: "100%", display: "flex", ...style }}>
      {heading && hoverText ? (
        <Tooltip title={hoverText} overlayClassName="white-link">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
              {hasHoverText(
                <span>
                  {heading.split(" ").map((head, index) => (
                    <span key={index}>{head}&nbsp;</span>
                  ))}
                </span>
              )}
            </h3>
          </div>
        </Tooltip>
      ) : heading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
            {heading.split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
        </div>
      ) : (
        <div></div>
      )}
      {isDateRange ? (
        <RangePicker
          size="middle"
          onChange={(newDateRange) => {
            setState(newDateRange);
          }}
          value={state}
          style={{ width: 250, textAlign: "center" }}
          disabled={disabled}
        />
      ) : (
        <DatePicker
          size="middle"
          onChange={(newDate) => {
            setState(newDate);
          }}
          value={state}
          style={{ width: 150 }}
          disabled={disabled}
        />
      )}
    </div>
  );
}
