import React from "react";
import "../css/homepage.css";

const trustedByCompanies = [
  {
    name: "Auto Nation",
    logo: "/assets/trusted-by/autonation-logo.png",
    url: "https://www.agilitihealth.com/",
  },
  {
    name: "Comcast",
    logo: "/assets/trusted-by/comcast-logo.png",
    url: "https://corporate.comcast.com/",
  },
  {
    name: "Allied Universal",
    logo: "/assets/trusted-by/allied-universal-logo.svg",
    url: "https://www.aus.com/",
  },
  {
    name: "Textron",
    logo: "/assets/trusted-by/textron-logo.png",
    url: "https://www.textron.com/",
  },
  {
    name: "GLDD",
    logo: "/assets/trusted-by/gldd-logo.png",
    url: "https://gldd.com/",
  },
  {
    name: "Energy Transfer",
    logo: "/assets/trusted-by/energy-transfer-logo.png",
    url: "https://www.energytransfer.com/",
  },
  {
    name: "TIAA",
    logo: "/assets/trusted-by/tiaa-logo.svg",
    url: "https://www.tiaa.org/",
  },
  {
    name: "Southern Glazer's",
    logo: "/assets/trusted-by/sgws-logo.svg",
    url: "https://southernglazers.com/",
  },
  {
    name: "Agiliti",
    logo: "/assets/trusted-by/agiliti-logo.png",
    url: "https://www.agilitihealth.com/",
  },
  {
    name: "Hair Club",
    logo: "/assets/trusted-by/hair-club-logo.svg",
    url: "https://www.hairclub.com/",
  },
];

const includeLinkToSite = false;

export default function TrustedBy() {
  const FeaturedInArticle = ({
    articleName,
    articleLogo,
    articleURL = null,
    customWidthPercent = null,
    index,
  }) => {
    return includeLinkToSite && articleURL ? (
      <div key={index}>
        <a
          href={articleURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ verticalAlign: "bottom", textAlign: "center" }}
        >
          <div
            className="logo-container with-link marquee__item"
            style={{
              display: "flex",
              justifyContent: "center",
              "--marquee-item-index": index,
            }}
          >
            <img
              className="article-logo"
              src={articleLogo}
              style={{
                width: customWidthPercent ? customWidthPercent : "100%",
                verticalAlign: "bottom",
                objectFit: "contain",
              }}
              alt={articleName + "-logo"}
            />
          </div>
        </a>
      </div>
    ) : (
      <div
        className="logo-container marquee__item"
        style={{ "--marquee-item-index": index, display: "flex" }}
        key={index}
      >
        <img
          className="article-logo"
          src={articleLogo}
          style={{
            width: customWidthPercent ? customWidthPercent : "100%",
            verticalAlign: "bottom",
            objectFit: "contain",
          }}
          alt={articleName + "-logo"}
        />
      </div>
    );
  };

  return (
    <div className="featured-in">
      <div className="section">
        <div className="centered-flex" style={{ overflow: true }}>
          <div className="inner-box">
            <h2 className="hero-text">TRUSTED BY</h2>
            <div
              style={{
                marginTop: 50,
              }}
            >
              <div
                class="marquee marquee--n"
                style={{ "--marquee-items": trustedByCompanies.length }}
              >
                {trustedByCompanies.map((company, index) => {
                  return (
                    <FeaturedInArticle
                      articleName={company.name}
                      articleLogo={company.logo}
                      articleURL={company.url}
                      customWidthPercent={company.customWidthPercent}
                      index={index}
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="divider-line" />
    </div>
  );
}
