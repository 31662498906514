import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { dataKeyMap } from "../../../utils/Constants/systemSetting.js";

export default function CorrelationGraph({
  formattedTableData,
  correlationType,
  measureOptions,
  measuresInGraph,
}) {
  const allMeasures = Object.values(measureOptions);

  const data = useMemo(() => {
    const newData = [];
    for (const d of formattedTableData) {
      if (
        !measuresInGraph.includes(allMeasures[d.measure].title) ||
        isNaN(parseFloat(d.correlation[correlationType])) ||
        (Math.round(Math.abs(d.correlation[correlationType].toPrecision(4))) ===
          1 &&
          d.correlation.datapoints === 2)
      ) {
        continue;
      }
      if (!newData.some((item) => item.name === allMeasures[d.measure].title)) {
        const datapoint = { name: allMeasures[d.measure].title };
        datapoint[`${dataKeyMap[d.compensationType].title}`] =
          d.correlation[correlationType].toPrecision(4);
        newData.push(datapoint);
      } else {
        const dataIndex = newData.findIndex(
          (item) => item.name === allMeasures[d.measure].title
        );
        const newDatapoint = { ...newData[dataIndex] };
        newDatapoint[`${dataKeyMap[d.compensationType].title}`] =
          d.correlation[correlationType].toPrecision(4);
        newData[dataIndex] = newDatapoint;
      }
    }
    return newData;
  }, [formattedTableData, allMeasures, correlationType, measuresInGraph]);

  const compensationBars = [
    { key: "salary", color: "var(--graph-salary)" },
    { key: "bonus", color: "var(--graph-bonus)" },
    { key: "stockAward", color: "var(--graph-stock-awards)" },
    { key: "RSUs", color: "var(--graph-rsus)" },
    { key: "PSUs", color: "var(--graph-psus)" },
    { key: "SARaward", color: "var(--graph-sar)" },
    { key: "optionAward", color: "var(--graph-option-awards)" },
    { key: "nonEquity", color: "var(--graph-non-equity)" },
    { key: "pension", color: "var(--graph-pension)" },
    { key: "other", color: "var(--graph-other)" },
    { key: "total", color: "var(--secondary)" },
  ];
  const filteredCompensationBars = compensationBars.filter((bar) =>
    data.some((item) => Object.keys(item).includes(dataKeyMap[bar.key].title))
  );

  return (
    <ResponsiveContainer width={"100%"} height={400}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
        barGap={1}
        barCategoryGap={5}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis
          dataKey="name"
          fontSize={8}
          interval={0}
          minTickGap={10}
          tickFormatter={(tick) =>
            tick.length > 12 ? tick.slice(0, 10) + "..." : tick
          }
        />
        <YAxis domain={correlationType === "correlation" ? [-1, 1] : [0, 1]} />
        <Tooltip />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "30px" }} />
        <Brush
          dataKey="name"
          height={30}
          stroke="#8884d8"
          travellerWidth={10}
        />
        {filteredCompensationBars.map((bar) => (
          <Bar dataKey={dataKeyMap[bar.key].title} fill={bar.color} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
