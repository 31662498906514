import React, { useMemo, createRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../css/faq.css";
import { CalculatorFAQTopics } from "../assets/faqTopicsCalculators";
import { OptionsFAQTopics } from "../assets/faqTopicsOptions";
import NotFound from "./notFound";

export default function FAQ() {
  const location = useLocation();
  const locationHash = location.hash.replace("#", "");

  // Update this array if there are new files containing FAQs!
  const allFAQTopics = [CalculatorFAQTopics, OptionsFAQTopics];
  const FAQTopicKeys = allFAQTopics
    .map((FAQTopics) => [...Object.keys(FAQTopics)])
    .flat();

  const FAQrefs = useMemo(() => {
    const newFAQrefs = {};
    FAQTopicKeys.forEach((topic) => (newFAQrefs[topic] = createRef(null)));
    return newFAQrefs;
  }, [FAQTopicKeys]);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const [isActiveSubscriber, setIsActiveSubscriber] = useState(
    ["active", "trialing"].includes(subscription?.status)
  );
  useEffect(() => {
    setIsActiveSubscriber(
      ["active", "trialing"].includes(subscription?.status)
    );
  }, [subscription]);

  const [highlightText, setHighlightText] = useState(true);

  useEffect(() => {
    if (FAQrefs?.[locationHash]) {
      scrollToRef(FAQrefs[locationHash]);
    }
    setTimeout(function () {
      setHighlightText(false);
    }, 1250);
  }, [locationHash, FAQrefs]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {!isActiveSubscriber ? (
        <div style={{ height: "90vh" }}>
          <NotFound />
        </div>
      ) : (
        <div className="faq">
          <h1 style={{ textAlign: "center" }}>
            Frequently Asked Questions (FAQ)
          </h1>
          <h2>Company Calculators</h2>
          {Object.keys(CalculatorFAQTopics).map((FAQkey) => (
            <div
              key={FAQkey}
              ref={FAQrefs[FAQkey]}
              style={{
                backgroundColor:
                  highlightText && locationHash === FAQkey ? "lightblue" : "",
                transition: "background-color 1000ms linear",
                paddingLeft: 50,
              }}
            >
              <h3>{CalculatorFAQTopics[FAQkey].title}</h3>
              {CalculatorFAQTopics[FAQkey].long_desc}
            </div>
          ))}
          <h2>Options Calculators</h2>
          {Object.keys(OptionsFAQTopics)
            .filter((FAQkey) => OptionsFAQTopics[FAQkey]?.long_desc)
            .map((FAQkey) => (
              <div
                key={FAQkey}
                ref={FAQrefs[FAQkey]}
                style={{
                  backgroundColor:
                    highlightText && locationHash === FAQkey ? "lightblue" : "",
                  transition: "background-color 1000ms linear",
                }}
              >
                {FAQkey === "performance_share_awards_assumptions" && (
                  <h2>Performance Share Awards Calculators</h2>
                )}
                <div style={{ paddingLeft: 50 }}>
                  <h3>{OptionsFAQTopics[FAQkey].title}</h3>
                  {OptionsFAQTopics[FAQkey].long_desc}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
