import { HIDE_LOADING, SHOW_LOADING } from "../constants/LoadingConstant"

const initialState = {
    show: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOADING: {
            return {...state, show: true};
        }
        case HIDE_LOADING: {
            return {...state, show: false};
        }
   

    default:
        return state
    }
}
