import React, { useEffect, useState } from "react";
import Input from "../../Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentMethod } from "../../../redux/actions/CustomerAction";
import { Icon } from "@iconify/react";
import "./billing.css";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {
  CARD_OPTIONS,
  EMAIL_REGEX,
} from "../../../utils/Constants/systemSetting";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "../../../JSS/Button";
import {
  HIDE_LOADING,
  SHOW_LOADING,
} from "../../../redux/constants/LoadingConstant";
import toast from "react-hot-toast";
import CustomerService from "../../../services/CustomerService";
import { Tooltip } from "antd";
import { updateUser } from "../../../redux/actions/UserAction";
import UserService from "../../../services/UserService";
import { useHistory } from "react-router-dom";

export default function Billing() {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const { paymentMethod } = useSelector((state) => state.CustomerReducer);
  const { id } = useSelector((state) => state.UserReducer);
  const user = useSelector((state) => state.UserReducer);

  const [changing, setChanging] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    address: "",
    zip: "",
    companyName: "",
    companyWebsite: "",
    employees: "",
  });

  const [cardValid, setCardValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [cardStatus, setCardStatus] = useState({
    cardNumber: {},
    cardCvc: {},
    cardExpiry: {},
  });

  useEffect(() => {
    setState({
      fname: user?.fname || "",
      lname: user?.lname || "",
      email: user?.email || "",
      phone: user?.phone || "",
      address: user?.address || "",
      zip: user?.zip || "",
      companyName: user?.companyName || "",
      companyWebsite: user?.companyWebsite || "",
      employees: user?.employees || "",
    });
    if (user?.fname && user?.lname && user?.address && user?.zip) {
      setFormValid(true);
    }
  }, [user]);

  useEffect(() => {
    dispatch(getPaymentMethod());
  }, [changing, dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const values = { ...state };
    values[name] = value;
    let valid = true;

    if (name === "zip") {
      values["zip"] = value.replace(/[^0-9]/gi, "");
      if (value.length > 5) {
        values["zip"] = value.substring(0, 5);
      }
    }

    for (let key in values) {
      if (
        key !== "companyName" &&
        key !== "companyWebsite" &&
        key !== "employees"
      )
        if (values[key].trim() === "") {
          valid = false;
        }
      if (key === "zip" && values[key].trim().length !== 5) {
        valid = false;
      }
    }

    setFormValid(valid);
    setState({ ...values });
  };
  const handleCardChange = (e) => {
    const fieldType = e.elementType;
    const values = { ...cardStatus };
    values[fieldType] = e;
    setCardStatus({ ...values });
  };
  useEffect(() => {
    let valid = true;
    for (const value of Object.values(cardStatus)) {
      if (!value?.complete) {
        valid = false;
      }
    }
    setCardValid(valid);
  }, [cardStatus]);

  useEffect(() => {
    if (cardValid && formValid) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [cardValid, formValid]);

  const checkoutInput = (name, label, required = true) => {
    return (
      <div className="checkout-input-container">
        <p>{label}</p>
        <div className="input-container">
          <input
            className="checkout-input"
            name={name}
            onChange={handleChange}
            // placeholder={label}
            style={{
              border:
                name === "zip" && state.zip !== "" && state.zip.length < 5
                  ? "1px solid red"
                  : "",
            }}
            value={state[name]}
            id={name}
          />
        </div>
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    try {
      if (!stripe || !elements) {
        return;
      }

      const payment = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("cardNumber"),
        billing_details: {
          name: `${state.fname} ${state.lname}`,
          email: user?.email,
          phone: user?.phone,
          address: {
            postal_code: state.zip,
          },
        },
      });

      if (payment.error) {
        console.log(payment.error.message);
      } else {
        dispatch({
          type: SHOW_LOADING,
        });

        const { data } = await CustomerService.updatePaymentMethod({
          payment_method: payment.paymentMethod,
          uid: id,
        });

        /* TODO: update user profile with update-user endpoint and {data} provided on success */
        try {
          const newUserDetails = {
            ...state,
          };
          const { status, data } = await UserService.updateUser(newUserDetails);
          if (status == 200) {
            await dispatch(updateUser(newUserDetails));
          }
        } catch (err) {
          /* Don't really have to update profile info, it can fail */
          //   console.log(err);
          console.log(err?.response);
          // toast.error(
          //   "Profile cannot be updated right now. Please try again later."
          // );
          // setDisabled(false);
        }

        toast.success("Payment method was successfully updated.");
        setState({
          fname: "",
          lname: "",
          address: "",
          zip: "",
        });
        setChanging(false);

        // if (data.stripeResponse.status !== "succeeded") {
        //   toast.success("error");
        // }

        // if (status === "require_action") {
        //     stripe.confirmCardPayment(client_secret).then((res) => {
        //         if (res.error) {
        //             console.log(res.error);
        //         } else {
        //           toast.success('Thanh you for your Subscription!');
        //           history.push('/');
        //         }
        //     });
        // } else {
        //     toast.success('Thanh you for your Subscription!');
        //     history.push('/')
        // }
      }
    } catch (err) {
      console.log(err?.response || err);
      toast.error(
        typeof err?.response?.data === "string"
          ? err.response.data
          : typeof err?.response?.data?.raw?.message === "string"
          ? `Failed to update payment. ${err.response.data.raw.message}`
          : "Cannot update payment right now. Please try again later."
      );
    } finally {
      dispatch({
        type: HIDE_LOADING,
      });
    }
  };

  return (
    <div
      className="billing"
      style={{ backgroundColor: changing ? "var(--gray-2)" : null }}
    >
      {changing ? (
        <form onSubmit={handleSubmit} className="payment-card">
          <div className="title">
            <i className="fa fa-lock" style={{ backgroundColor: "white" }}></i>
            <p>Edit payment details:</p>
          </div>
          <div id="card-element">
            <p>Credit/debit card number</p>
            <Tooltip
              overlayStyle={{ maxWidth: "345px" }}
              visible={cardStatus.cardNumber?.error?.message ? true : false}
              overlayInnerStyle={{
                borderRadius: "5px",
                color: "var(--white)",
                backgroundColor: "var(--dark-red)",
              }}
              title={cardStatus.cardNumber?.error?.message}
              placement="top"
            >
              <CardNumberElement
                options={CARD_OPTIONS}
                className="stripe-input"
                id="cardNumber"
                onChange={handleCardChange}
              />
            </Tooltip>
          </div>
          <div className="row">
            <div className="col-6">
              <p>Expiration date</p>
              <Tooltip
                overlayStyle={{ maxWidth: "345px" }}
                visible={cardStatus.cardExpiry?.error?.message ? true : false}
                overlayInnerStyle={{
                  borderRadius: "5px",
                  color: "var(--white)",
                  backgroundColor: "var(--dark-red)",
                }}
                title={cardStatus.cardExpiry?.error?.message}
                placement="top"
              >
                <CardExpiryElement
                  options={CARD_OPTIONS}
                  className="stripe-input"
                  id="cardExpiration"
                  onChange={handleCardChange}
                />
              </Tooltip>
            </div>
            <div className="col-6">
              <p>Security code</p>
              <Tooltip
                overlayStyle={{ maxWidth: "345px" }}
                visible={cardStatus.cardCvc?.error?.message ? true : false}
                overlayInnerStyle={{
                  borderRadius: "5px",
                  color: "var(--white)",
                  backgroundColor: "var(--dark-red)",
                }}
                title={cardStatus.cardCvc?.error?.message}
                placement="right"
              >
                <CardCvcElement
                  options={CARD_OPTIONS}
                  className="stripe-input"
                  id="securityCode"
                  onChange={handleCardChange}
                />
              </Tooltip>
            </div>
          </div>
          <div className="row">
            <div className="col-6">{checkoutInput("fname", "First name")}</div>
            <div className="col-6">{checkoutInput("lname", "Last name")}</div>
          </div>
          <div className="row">
            <div className="col-8">
              {checkoutInput("address", "Billing address")}{" "}
            </div>
            <Tooltip
              overlayStyle={{ maxWidth: "345px" }}
              visible={state.zip !== "" && state.zip.length < 5}
              overlayInnerStyle={{
                borderRadius: "5px",
                color: "var(--white)",
                backgroundColor: "var(--dark-red)",
              }}
              title="Zip code is too short."
              placement="left"
            >
              <div className="col-4" style={{ height: 90 }}>
                {checkoutInput("zip", "Zip code")}
              </div>
            </Tooltip>
          </div>
          <div className="mt-4 d-flex justify-content-center place-order">
            <Button className="me-3" disabled={disabled} type="submit" primary>
              Update
            </Button>
            <Button
              onClick={() => {
                setChanging(false);
              }}
              color="white"
            >
              Cancel
            </Button>
          </div>
        </form>
      ) : paymentMethod ? (
        <div className="card-detail">
          <Icon icon={`logos:${paymentMethod.brand}`} width="30" />
          <p>**** {paymentMethod.last4}</p>
          <button
            onClick={() => {
              setChanging(true);
            }}
          >
            Change
          </button>
        </div>
      ) : (
        <div className="card-detail">
          You have no billing information{" "}
          <button
            onClick={() => {
              setChanging(true);
            }}
          >
            Add a Payment Method
          </button>
        </div>
      )}
    </div>
  );
}
