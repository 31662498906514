import styled from "styled-components";
// border-radius: 100px;

export const LinkButton = styled.div`
  font-size: calc(0.8rem + 0.15vw);
  color: ${(props) => (props.primary ? "var(--white)" : "var(--secondary)")};
  background-color: ${(props) =>
    props.primary
      ? "var(--complement)"
      : props.secondary
      ? "var(--primary)"
      : "var(--white)"};
  border: ${(props) =>
    props.primary
      ? "2px solid var(--complement)"
      : props.secondary
      ? "2px solid var(--primary)"
      : "2px solid var(--secondary)"};
  border-radius: 5px;
  padding: 5px 20px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block;

  &:hover {
    color: ${(props) =>
      props.primary
        ? "var(--white)"
        : props.secondary
        ? "var(--secondary)"
        : "var(--white)"};
    transform: translateY(-3px);
    background-color: ${(props) =>
      props.primary
        ? "var(--complement-1)"
        : props.secondary
        ? "var(--primary-1)"
        : "var(--black)"};
    border: ${(props) =>
      props.primary
        ? "2px solid var(--complement-1)"
        : props.secondary
        ? "2px solid var(--primary-1)"
        : "2px solid var(--black)"};
  }
`;
