import {
  SET_FIRST_FILTER,
  SET_SECOND_FILTER,
  TOGGLE_COMPARISON_FILTER,
} from "../constants/ComparisonTableConstant";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  currentTable: "first-filter",
  firstFilter: {
    salary: {
      salaryMin: 0,
      salaryMax: "",
    },
    percentile: {
      percentileMin: 0,
      percentileMax: 100,
    },
    payRatio: {
      payRatioMin: 0,
      payRatioMax: 1e5,
    },
    industry: "all",
    companyRank: {
      companyRankMin: 0,
      companyRankMax: "",
    },
    marketCap: {
      marketCapMin: 0,
      marketCapMax: "",
    },
    revenue: {
      revenueMin: 0,
      revenueMax: "",
    },
    employee: {
      employeeMin: 0,
      employeeMax: "",
    },
  },
  secondFilter: {
    salary: {
      salaryMin: 0,
      salaryMax: "",
    },
    percentile: {
      percentileMin: 0,
      percentileMax: 100,
    },
    payRatio: {
      payRatioMin: 0,
      payRatioMax: 1e5,
    },
    industry: "all",
    companyRank: {
      companyRankMin: 0,
      companyRankMax: "",
    },
    marketCap: {
      marketCapMin: 0,
      marketCapMax: "",
    },
    revenue: {
      revenueMin: 0,
      revenueMax: "",
    },
    employee: {
      employeeMin: 0,
      employeeMax: "",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FIRST_FILTER: {
      return { ...state, firstFilter: { ...action.filter } };
    }
    case SET_SECOND_FILTER: {
      return { ...state, secondFilter: { ...action.filter } };
    }
    case TOGGLE_COMPARISON_FILTER: {
      return { ...state, currentTable: action.filter };
    }
    default:
      return state;
  }
};
