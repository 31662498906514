import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "../css/cancel.css";
import moment from "moment";
import { Button } from "../JSS/Button";
import {
  cancelSubscription,
  renewSubscription,
} from "../redux/actions/CustomerAction";
import { ACCESS_TOKEN } from "../utils/Constants/systemSetting";

export default function CancelSubscription() {
  const [cancelationState, setCancelationState] = useState("notCanceled");
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState("");

  const handleChange = (e) => {
    setState(e.target.value);
  };

  const { subscription } = useSelector((state) => state.CustomerReducer);

  return (
    <div className="cancel">
      {cancelationState === "notCanceled" ? (
        <div>
          <h2>Are you sure you want to cancel your subscription?</h2>
          <br />
          <p>
            Your subscription will remain active until{" "}
            <h3>
              {moment(subscription?.current_period_end * 1000).format(
                "MMMM DD, YYYY"
              )}
            </h3>
          </p>
          <p />
          <p>
            If you unsubscribe now, after the above date, you will no longer
            have access to live and up-to-date C&#8209;Suite Compensation data
            and analytics tools to easily determine C&#8209;Suite executive pay.
          </p>
          <div className="button-group">
            <p>Are you sure you want to unsubscribe?</p>
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <Link
                    onClick={() => {
                      setCancelationState("canceled");
                      const token = localStorage.getItem(ACCESS_TOKEN);
                      dispatch(
                        cancelSubscription({
                          reason: state,
                          token,
                        })
                      );
                    }}
                    className="cancel-btn"
                  >
                    <Button primary>Cancel subscription</Button>
                  </Link>
                </div>
                <div className="col-6">
                  <Link to="/membership">
                    <Button secondary>Back to subscription</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : cancelationState === "canceled" ? (
        <div className="canceled">
          {" "}
          <h2>Goodbye for now!</h2>
          <div className="button-group">
            <p style={{ color: "black" }}>
              Your subscription will remain active until{" "}
              {moment(subscription?.current_period_end * 1000).format(
                "MMMM DD, YYYY"
              )}
              . You will not be charged again.
            </p>
            <br />
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <Link to="/membership">
                    <Button primary>Back to subscription</Button>
                  </Link>
                </div>
                <div className="col-6">
                  <div className="cancel-btn">
                    <Button
                      onClick={() => {
                        setCancelationState("renewed");
                        const token = localStorage.getItem(ACCESS_TOKEN);
                        dispatch(
                          renewSubscription({
                            token,
                          })
                        );
                      }}
                      secondary
                    >
                      Re-Subscribe
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="renewed">
          {" "}
          <h2>Welcome back!</h2>
          <div
            className="button-group"
            style={{ marginTop: 50, color: "black" }}
          >
            <p style={{ color: "black" }}>
              You will not be charged today. Your first recurring charge will be
              on{" "}
              {moment(subscription?.current_period_end * 1000).format(
                "MMMM DD, YYYY"
              )}
              .
            </p>
            <p style={{ color: "black" }}>
              {" "}
              To stop future charges, turn off recurring billing on the
              membership page before your next billing date.
            </p>
            <br />
            <Link to="/membership">
              <Button primary style={{ width: "50%" }}>
                Back to subscription
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
