// import { getExecPosition, getExecPositionGroup } from "./getExecPosition";

export const measureOptions = {
  revenue: {
    title: "Revenue",
    description: "Top line revenue or total sales.",
    dataSource: "XBRL revenue tag",
    getValue: function (companyInfo, year) {
      const revenue = companyInfo?.Revenue?.[year]?.value;
      if (!revenue) return "N/A";
      return revenue;
    },
    dataType: "dollar",
  },
  revenueGrowthPercent: {
    title: "Revenue Growth %",
    description:
      "The percentage change in revenue from the previous year to current year. Expressed as a percentage from -Infinity% to Infitity%.",
    dataSource:
      "((Current Year's revenue - Last year's revenue) / last year's revenue)",
    getValue: function (companyInfo, year) {
      const currentRevenue = companyInfo?.Revenue?.[year]?.value;
      const previousRevenue = companyInfo?.Revenue?.[year - 1]?.value;
      if (!currentRevenue || !previousRevenue) return "N/A";
      const revenueGrowth =
        (100 * (currentRevenue - previousRevenue)) / previousRevenue;
      return revenueGrowth;
    },
    dataType: "percent",
  },
  grossProfit: {
    title: "Gross Profit",
    description: "Revenue less the cost of goods sold.",
    dataSource:
      "((Revenue - Cost of Goods Sold)/Revenue)) [COGS = CostOfRevenue]",
    getValue: function (companyInfo, year) {
      const revenue = companyInfo?.Revenue?.[year]?.value;
      const costsOfGoodsSold = companyInfo?.Measures?.CostOfRevenue?.[year];
      if (!revenue || !costsOfGoodsSold) return null;

      const value = revenue - costsOfGoodsSold;
      return value;
    },
    dataType: "dollar",
  },
  grossProfitPercent: {
    title: "Gross Profit %",
    description: "Gross profit relative to revenue.",
    dataSource: "((Revenue - Cost of Goods Sold)/Revenue))",
    getValue: function (companyInfo, year) {
      const revenue = companyInfo?.Revenue?.[year]?.value;
      const costsOfGoodsSold = companyInfo?.Measures?.CostOfRevenue?.[year];
      if (!revenue || !costsOfGoodsSold) return null;

      const value = (100 * (revenue - costsOfGoodsSold)) / revenue;
      return value;
    },
    dataType: "percent",
  },
  //// No easy way to scalably calculate this with current tags, so ignore for now:
  // EBIDTA: {
  //   title: "EBIDTA",
  //   description:
  //     "Earnings before interest, depreciation, taxes, and amortization.",
  //   dataSource:
  //     "No easy way to scalably calculate this with current tags, so ignore",
  //   getValue: function (companyInfo, year) {
  //     return null;
  //   },
  //   dataType: "dollar",
  // },
  netIncome: {
    title: "Net Income",
    description:
      "Bottom line profit or loss. Also known as earnings or profit / loss.",
    dataSource: "XBRL netIncome tag",
    getValue: function (companyInfo, year) {
      const netIncome = companyInfo?.NetIncome?.[year]?.value;
      return netIncome;
    },
    dataType: "dollar",
  },
  netIncomePercent: {
    title: "Net Income %",
    description: "Net income relative to revenue.",
    dataSource: "Net Income / Revenue",
    getValue: function (companyInfo, year) {
      const netIncome = companyInfo?.NetIncome?.[year]?.value;
      const revenue = companyInfo?.Revenue?.[year]?.value;
      if (!netIncome || !revenue) return "N/A";
      const netIncomePercent = (100 * netIncome) / revenue;
      return netIncomePercent;
    },
    dataType: "percent",
  },
  netIncomeGrowth: {
    title: "Net Income Growth %",
    description:
      "The percentage change in net income from the previous year to current year. If the previous year net income was negative and the current year positive, this isn't reported as a percentage.",
    dataSource:
      "((Current Year's Net Income - Last year's Net Income) / last year's Net Income)",
    getValue: function (companyInfo, year) {
      const currentNetIncome = companyInfo?.NetIncome?.[year]?.value;
      const previousNetIncome = companyInfo?.NetIncome?.[year - 1]?.value;
      if (!currentNetIncome || !previousNetIncome) return "N/A";
      if (Math.min(previousNetIncome, currentNetIncome) <= 0) {
        if (currentNetIncome - previousNetIncome > 0) {
          return "P";
        } else {
          return "N";
        }
      } else {
        // Based on exec formula:
        // const netIncomeGrowth = currentNetIncome / previousNetIncome - 1;
        // Based on dataSource method:
        const netIncomeGrowth =
          (100 * (currentNetIncome - previousNetIncome)) / previousNetIncome;
        return netIncomeGrowth;
      }
    },
    dataType: "percent",
  },
  EPS: {
    title: "EPS",
    description:
      "Earnings per share. The portion of a company's profit allocated to each outstanding share of common stock.",
    dataSource: "XBRL tag: EarningsPerShareDiluted",
    getValue: function (companyInfo, year) {
      const EPS = companyInfo?.Measures?.EarningsPerShareDiluted?.[year];
      if (!EPS) return null;
      return EPS;
    },
    dataType: "ratio",
  },
  EPS_Growth: {
    title: "EPS Growth %",
    description:
      "The percentage change in EPS (earnings per share) from the previous year to current year",
    dataSource: "((Current Year's EPS - Last year's EPS) / last year's EPS)",
    getValue: function (companyInfo, year) {
      const previousEPS =
        companyInfo?.Measures?.EarningsPerShareDiluted?.[year - 1];
      const currentEPS = companyInfo?.Measures?.EarningsPerShareDiluted?.[year];
      if (!previousEPS || !currentEPS) return null;
      const EPS_Growth = (100 * (currentEPS - previousEPS)) / previousEPS;
      return EPS_Growth;
    },
    dataType: "percent",
  },
  operatingProfit: {
    title: "Operating Profit",
    description:
      "Gross Profit - Operating Expenses - Depreciation & Amortization",
    dataSource: "XBRL tag: OperatingIncomeLoss (or nonInterestIncome)",
    getValue: function (companyInfo, year) {
      const operatingProfit = companyInfo?.Measures?.nonInterestIncome?.[year];
      if (!operatingProfit) return null;
      return operatingProfit;
    },
    dataType: "dollar",
  },
  CAP: {
    title: "CAP",
    description:
      "Summary Compensation Table’s Total Compensation minus change in present value of defined benefit plans service cost (current and prior) for defined benefit plans plus pre-vesting dividends or earnings paid plus change in fair value of unvested stock or option awards.",
    dataSource: "XBRL Tag: PeoActuallyPaidCompAmt",
    getValue: function (companyInfo, year) {
      const CAP = companyInfo?.Measures?.PeoActuallyPaidCompAmt?.[year];
      if (!CAP) return null;
      return CAP;
    },
    dataType: "dollar",
  },
  TSR: {
    title: "TSR",
    description:
      "Total shareholder return. The return on investment for a shareholder, including capital gains / losses and dividends based on initial investment of $100.",
    dataSource: "XBRL Tag: TotalShareholderRtnAmt",
    getValue: function (companyInfo, year) {
      const TSR = companyInfo?.Measures?.TotalShareholderRtnAmt?.[year];
      if (!TSR) return null;
      return TSR;
    },
    dataType: "dollar",
  },
  CAP_TSR: {
    title: "CAP/TSR",
    description:
      "The ratio of executive compensation actually paid to total shareholder return based on initial investment of $100. Measures compensation actually paid relative to the total shareholder return based on initial investment of $100.",
    dataSource: "CAP/TSR",
    getValue: function (companyInfo, year) {
      const CAP = companyInfo?.Measures?.PeoActuallyPaidCompAmt?.[year];
      const TSR = companyInfo?.Measures?.TotalShareholderRtnAmt?.[year];
      if (!CAP || !TSR) return null;
      const CAP_TSR = CAP / TSR;
      return CAP_TSR;
    },
    dataType: "ratio",
  },
  CAP_revenue: {
    title: "CAP/Revenue",
    description:
      "The ratio of compensation actually paid to revenue. Measures compensation actually paid relative to the company's revenue.",
    dataSource: "CAP/Revenue",
    getValue: function (companyInfo, year) {
      const CAP = companyInfo?.Measures?.PeoActuallyPaidCompAmt?.[year];
      const revenue = companyInfo?.Revenue?.[year]?.value;
      if (!CAP || !revenue) return null;
      const CAP_revenue = CAP / revenue;
      return CAP_revenue;
    },
    dataType: "ratio",
  },
  CAP_netIncome: {
    title: "CAP/Net Income",
    description:
      "The ratio of compensation actually paid to net income. Measures compensation actually paid relative to the company's profit or loss.",
    dataSource: "CAP/Net Income",
    getValue: function (companyInfo, year) {
      const CAP = companyInfo?.Measures?.PeoActuallyPaidCompAmt?.[year];
      const netIncome = companyInfo?.NetIncome?.[year]?.value;
      if (!CAP || !netIncome) return null;
      const CAP_netIncome = CAP / netIncome;
      return CAP_netIncome;
    },
    dataType: "ratio",
  },
  assets: {
    title: "Assets",
    description: "Total assets for the year selected.",
    dataSource: "XBRL tag: Assets",
    getValue: function (companyInfo, year) {
      const assets = companyInfo?.Measures?.Assets?.[year];
      if (!assets) return null;
      return assets;
    },
    dataType: "dollar",
  },
  ROA: {
    title: "ROA",
    description:
      "Return on assets. The percentage of net income to total assets. Measures how efficiently a company is using its assets to generate profit. Value can be negitive.",
    dataSource:
      "(Net Income / Average of Total Assets over the preceding four quarters)",
    getValue: function (companyInfo, year) {
      const netIncome = companyInfo?.NetIncome?.[year]?.value;
      const assets = companyInfo?.Measures?.Assets?.[year];
      if (!netIncome || !assets) return null;
      const ROA = (100 * netIncome) / assets;
      return ROA;
    },
    dataType: "percent",
  },
  ROE: {
    title: "ROE",
    description:
      "Return on equity. The percentage of net income to shareholder equity. Measures how much profit a company is generating relative to the amount of money shareholders have invested.",
    dataSource:
      "(us-gaap:NetIncomeLoss / ((us-gaap:StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest + us-gaap:StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest)/2)). Note: StockholdersEquity can be used in place of StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest (AMN example).",
    getValue: function (companyInfo, year) {
      const actualROE =
        companyInfo?.Measures?.ReturnOnAverageCommonEquity?.[year];
      if (actualROE) {
        return actualROE * 100;
      }
      const netIncome = companyInfo?.NetIncome?.[year]?.value;
      const currentShareholderEquity =
        companyInfo?.Measures
          ?.StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest?.[
          year
        ];
      const previousShareholderEquity =
        companyInfo?.Measures
          ?.StockholdersEquityIncludingPortionAttributableToNoncontrollingInterest?.[
          year - 1
        ];
      if (!netIncome || !currentShareholderEquity || !previousShareholderEquity)
        return null;
      const calculatedROE =
        (100 * netIncome) /
        ((currentShareholderEquity + previousShareholderEquity) / 2);
      return calculatedROE;
    },
    dataType: "percent",
  },
  ROCE: {
    title: "ROCE",
    description:
      "Return on capital employed. The percent of operating profit to capital employed. Measures how efficiently a company is using its capital to generate profit.",
    dataSource:
      "(EBIT / Capital Employed.)  EBIT = NI + Interest Expense + Tax Expense. Capital Employed / Total Assets - Current Liabilities",
    getValue: function (companyInfo, year) {
      const netIncome = companyInfo?.NetIncome?.[year]?.value;
      const interestExpense =
        companyInfo?.Measures?.InterestIncomeExpenseNet?.[year];
      const taxExpense = companyInfo?.Measures?.IncomeTaxExpenseBenefit?.[year];
      const assets = companyInfo?.Measures?.Assets?.[year];
      const currentLiabilities =
        companyInfo?.Measures?.LiabilitiesCurrent?.[year];
      if (
        !netIncome ||
        !interestExpense ||
        !taxExpense ||
        !assets ||
        !currentLiabilities
      ) {
        return null;
      }
      const ROCE =
        (100 * (netIncome + interestExpense + taxExpense)) /
        (assets - currentLiabilities);
      return ROCE;
    },
    dataType: "percent",
  },
  currentRatio: {
    title: "Current Ratio",
    description:
      "The ratio of current assets to current liabilities. Measures a company's ability to pay off short-term debts using its short-term assets.",
    dataSource: "us-gaap:AssetsCurrent / us-gaap:LiabilitiesCurrent",
    getValue: function (companyInfo, year) {
      const assets = companyInfo?.Measures?.Assets?.[year];
      const currentLiabilities =
        companyInfo?.Measures?.LiabilitiesCurrent?.[year];
      if (!assets || !currentLiabilities) return null;
      const currentRatio = assets / currentLiabilities;
      return currentRatio;
    },
    dataType: "ratio",
  },
  OCF: {
    title: "OCF",
    description:
      "Operating cash flow. The amount of cash generated by a company's normal business operations during the year selected.",
    dataSource: "us-gaap:NetCashProvidedByUsedInOperatingActivities",
    getValue: function (companyInfo, year) {
      const OCF =
        companyInfo?.Measures?.NetCashProvidedByUsedInOperatingActivities?.[
          year
        ];
      if (!OCF) return null;
      return OCF;
    },
    dataType: "dollar",
  },
  OCF_Revenue: {
    title: "OCF/Revenue",
    description:
      "The ratio of operating cash flow to revenue. Measures how much operating cash flow a company generates relative to its revenue.",
    dataSource: "OCF/Revenue",
    getValue: function (companyInfo, year) {
      const OCF =
        companyInfo?.Measures?.NetCashProvidedByUsedInOperatingActivities?.[
          year
        ];
      const revenue = companyInfo?.Revenue?.[year]?.value;
      if (!OCF || !revenue) return null;
      return OCF / revenue;
    },
    dataType: "ratio",
  },
  OCF_Assets: {
    title: "OCF/Assets",
    description:
      "The ratio of operating cash flow to assets. Measures how much operating cash flow a company generates relative to its total assets. Values can be negitive.",
    dataSource: "OCF/Assets",
    getValue: function (companyInfo, year) {
      const OCF =
        companyInfo?.Measures?.NetCashProvidedByUsedInOperatingActivities?.[
          year
        ];
      const assets = companyInfo?.Measures?.Assets?.[year];
      if (!OCF || !assets) return null;
      return OCF / assets;
    },
    dataType: "ratio",
  },
  //TODO: Beginning of Default measures for Coselectedmeasure
  adjustedEBITDA: {
    title: "Adjusted EBITDA",
    description:
      "Adjusted earnings before interest, taxes, depreciation, and amortization.",
    dataSource: "None currently available.",
    getValue: function (companyInfo, year) {
      return null;
    },
    dataType: "dollar",
    isAlwaysSelectable: true,
  },
  freeCashFlow: {
    title: "Free Cash Flow",
    description:
      "Free cash flow (FCF) represents the cash that a company generates after accounting for cash outflows to support operations and maintain its capital assets.",
    dataSource: "None currently available.",
    getValue: function (companyInfo, year) {
      return null;
    },
    dataType: "dollar",
    isAlwaysSelectable: true,
  },
  operatingIncome: {
    title: "Operating Income",
    description:
      "Operating income is an accounting figure that measures the amount of profit realized from a business's operations after deducting operating expenses such as wages, depreciation, and cost of goods sold (COGS).",
    dataSource: "None currently available.",
    getValue: function (companyInfo, year) {
      return null;
    },
    dataType: "dollar",
    isAlwaysSelectable: true,
  },
  ROIC: {
    title: "ROIC",
    description:
      "Return on invested capital (ROIC) is a calculation used to assess a company's efficiency in allocating capital to profitable investments.",
    dataSource: "None currently available.",
    getValue: function (companyInfo, year) {
      return null;
    },
    dataType: "dollar",
    isAlwaysSelectable: true,
  },
  netSales: {
    title: "Net Sales",
    description:
      "Net sales is the sum of a company's gross sales minus its returns, allowances, and discounts.",
    dataSource: "None currently available.",
    getValue: function (companyInfo, year) {
      return null;
    },
    dataType: "dollar",
    isAlwaysSelectable: true,
  },
  salesToAssets: {
    title: "Sales to Total Assets",
    description:
      "Sales to Total Assets is net annual sales divided by the aggregate amount of all assets.",
    dataSource: "None currently available.",
    getValue: function (companyInfo, year) {
      return null;
    },
    dataType: "ratio",
    isAlwaysSelectable: true,
  },
  //TODO: end of default measure section
  //// Ignore: this is a current value, all measures should be toggle-able by year.
  // marketCap: {
  //   title: "Market Cap",
  //   description:
  //     "The total market value of a company's outstanding shares of stock. Calculated by multiplying the current stock price by the total number of outstanding shares.",
  //   dataSource: "Market cap based on finance data",
  //   getValue: function (companyInfo, year, marketCaps) {
  //     const marketCap = marketCaps?.find(
  //       (company) => company.Ticker === companyInfo.Ticker
  //     );
  //     if (!marketCap) return "N/A";
  //     return parseInt(marketCap?.marketCap);
  //   },
  //   dataType: "dollar",
  // },
  //// Ignore until other data collected
  // firmMarketCap: {
  //   title: "Firm Market Cap",
  //   description:
  //     "The total market value of a company's outstanding shares of stock. Calculated by multiplying the stock price by the total number of outstanding shares on the last trading day of the year.",
  //   dataSource:
  //     "Shares Outstanding x Share Price at Last Trading Day of Year | Get 12/30 for each year share price, and us-gaap measure for outstanding shares at end of year",
  // },
  // sectorMarketCap: {
  //   title: "Sector Market Cap",
  //   description:
  //     "The total market value of all companies in a particular sector based on firm market cap.",
  //   dataSource: "Sum up all Firm market caps within a sector",
  // },
  // industryMarketCap: {
  //   title: "Industry Market Cap",
  //   description:
  //     "The total market value of all companies in a particular industry based on firm market cap.",
  //   dataSource: "Sum up all Firm market caps within a industry",
  // },
  GDP: {
    title: "GDP",
    description:
      "The total value of all goods and services produced by the US in the selected year.",
    dataSource:
      "Get US GDP for each year end: https://data.worldbank.org/country/united-states?view=chart",
    getValue: function (companyInfo, year) {
      const billion = 1000000000;
      const GDPInBillions = {
        2021: 23315.08,
        2020: 21060.47,
        2019: 21380.98,
        2018: 20533.06,
      };
      const GDP = GDPInBillions?.[year];
      if (!GDP) return null;
      return GDP * billion;
    },
    dataType: "dollar",
  },
  GDPGrowth: {
    title: "GDP Growth %",
    description:
      "The percentage change in the total value of all goods and services produced by the US year over year.",
    dataSource: "Current Year GDP/ Previous Year GDP",
    getValue: function (companyInfo, year) {
      const GDPgrowthValues = {
        2021: 10.71,
        2020: -1.5,
        2019: 4.13,
        2018: 5.42,
      };
      const GDPgrowth = GDPgrowthValues?.[year];
      if (!GDPgrowth) return null;
      return GDPgrowth;
    },
    dataType: "percent",
  },
  //// Ignore until other data collected
  // SectorGrowth: {
  //   title: "Sector Growth %",
  //   description:
  //     "The percentage change in the total market value of all companies in a particular sector year over year.",
  //   dataSource:
  //     "Can be computed from Sector Market Cap Data of row 26. (Current year Sector Market Cap - Previous year Sector Market Cap) / Previous Year's Sector Market Cap",
  // },
  // sharePrice: {
  //   title: "Share Price",
  //   description: "Share Price on the last trading day of the year.",
  //   dataSource: "From historical financial data 12/30",
  // },
  payRatio: {
    title: "CEO Pay Ratio",
    description:
      "Ratio of CEO compensation to median employee at company. Values can range from 0:1 to 100,000:1.",
    dataSource:
      "Pay ratio provided in 10-K/20-F or calculated from CEO pay and industry median pay",
    getValue: function (companyInfo, year) {
      const payRatio = companyInfo?.PayRatio?.[year]?.value;
      // const { medianByYear, allExecutives } = options;
      // let alternatePayRatio = null;
      // let industryMedian = null;
      // let CEOpay = null;
      // if (!payRatio) {
      //   const companyExecutives = allExecutives.filter(
      //     (exec) => exec.Ticker === companyInfo.Ticker
      //   );
      //   const CEOcomps = [];

      //   for (const executive of companyExecutives) {
      //     const positions = getExecPosition(executive, year);
      //     if (
      //       positions.some(
      //         (pos) =>
      //           !pos.toLowerCase().includes("former") &&
      //           getExecPositionGroup(pos)?.abbreviation === "ceo"
      //       )
      //     ) {
      //       if (executive?.compensation?.[year]?.total)
      //         CEOcomps.push(executive?.compensation?.[year]?.total);
      //     }
      //   }
      //   CEOpay = Math.max(...CEOcomps);
      //   if (!CEOpay || CEOpay < 0 || isNaN(parseFloat(CEOpay))) {
      //     return null;
      //   }
      //   const medianInYear = medianByYear
      //     ? Object.values(medianByYear).filter(
      //         (med) => parseInt(med.year) === parseInt(year)
      //       )
      //     : [];
      //   const naics = companyInfo?.NAICS;
      //   const division = naics ? naics.slice(null, 2) : "";
      //   if (!medianInYear?.length && !division) return null;
      //   const medianInDivision = medianInYear[0]?.data?.[division];
      //   if (!medianInDivision) return null;
      //   industryMedian = parseFloat(medianInDivision?.median);
      // }
      // alternatePayRatio = CEOpay / industryMedian;
      // if (!payRatio && !alternatePayRatio) return null;
      if (!payRatio) return null;
      const value = payRatio;
      return value;
    },
    isCalculated: function (companyInfo, year) {
      if (companyInfo?.PayRatio?.[year]?.calculated) {
        return true;
      }
      return false;
    },
    dataType: "ratio",
  },
};
