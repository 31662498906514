import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "../css/searchBar.css";
// const validTickers = require("../assets/validTickers.json");
import slugifyName from "../utils/slugifyName";
import {
  sortClosestCompany,
  sortClosestExecutive,
} from "../utils/ExecutiveData/sortClosest";

export default function SearchBar({ expand, setExpand, mode, isHovered }) {
  const { companies } = useSelector((state) => state.CompaniesReducer);
  const { csuite: executives } = useSelector(
    (state) => state.ExecutivesReducer
  );
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const history = useHistory();
  const [companiesList, setCompaniesList] = useState([]);
  useEffect(() => {
    const companiesValues = Object.values(companies);
    if (companiesValues.length >= 9) {
      setCompaniesList(companiesValues);
    }
  }, [companies]);
  const [styles, setStyles] = useState({
    input: {
      cursor: "text",
      visibility: "visible",
      maxHeight: "320px",
      autoComplete: "off",
      transitionDelay: "0s",
    },
    img: {
      backgroundColor: "transparent",
      cursor: "text",
    },
    result: {
      visibility: "hidden",
      opacity: 1,
      maxHeight: 320,
      transitionDelay: "0.3s",
    },
  });
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 200 });
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const [searchVal, setSearchVal] = useState("");
  const [renderResult, setRenderResult] = useState(<span>Loading...</span>);

  const [isActiveSubscriber, setIsActiveSubscriber] = useState(
    ["active", "trialing"].includes(subscription?.status) || false
  );
  const [isMobile, setIsMobile] = useState(
    window.navigator.userAgentData?.mobile ||
      window.navigator.userAgent.includes("Mobile")
  );
  useEffect(() => {
    setIsActiveSubscriber(
      ["active", "trialing"].includes(subscription?.status)
    );
    setIsMobile(
      window.navigator.userAgentData?.mobile ||
        window.navigator.userAgent.includes("Mobile")
    );
  }, [subscription]);

  const [searchBarWasActive, setSearchBarWasActive] = useState(false);
  const [filteredExecutives, setFilteredExecutives] = useState([]);

  const executiveSearchResultsRef = useRef(null);
  const companySearchResultsRef = useRef(null);

  const executivesList = useMemo(() => {
    const filteredAndSortedList = executives
      .filter(
        (item) =>
          searchVal === "" ||
          searchVal
            .toLowerCase()
            .split(" ")
            .every(
              (val) =>
                val === "" ||
                item.name.toLowerCase().includes(val) ||
                item.Ticker.toLowerCase().includes(val) ||
                item.company.toLowerCase().includes(val)
            )
      )
      .sort((a, b) => {
        return sortClosestExecutive(a, b, searchVal);
      });
    setFilteredExecutives(filteredAndSortedList);
    // Slice the filtered and sorted list to get the visible range
    const visibleExecutives =
      filteredAndSortedList.length < 200
        ? filteredAndSortedList
        : filteredAndSortedList.slice(visibleRange.start, visibleRange.end);
    return visibleExecutives.map((result, index) => (
      <Link
        to={`/company/${result.Ticker}/${slugifyName(result.name)}`}
        key={`executive-${index}`}
        style={{ color: "black" }}
        onClick={() => {
          setExpand(false);
          setSearchVal("");
        }}
      >
        <div
          title={result.name + " [" + result.Ticker + "]"}
          key={`executive-${index}`}
        >
          {result.name + " [" + result.Ticker + "] "}
        </div>
      </Link>
    ));
  }, [searchVal, executives, setExpand, visibleRange]);

  //TODO: Fix sorting for companies and executives
  // ...probably mixed up new functions and applied it to companies instead of executives.

  const sortedCompaniesList = useMemo(() => {
    const filteredAndSortedCompanyList = companiesList
      .sort((a, b) => sortClosestCompany(a, b, searchVal))
      .filter(
        (item) =>
          // validTickers.includes(item?.Ticker) &&
          item?.Company?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
          item?.Ticker?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
          item?.AlternateTickers?.some((ticker) =>
            ticker.toLowerCase().includes(searchVal.toLowerCase())
          )
      )
      .map((result, index) => {
        // setFilteredCompanies(filteredAndSortedCompanyList);
        // const visibleCompanies =
        //   filteredAndSortedCompanyList.length < 200
        //     ? filteredAndSortedCompanyList
        //     : filteredAndSortedCompanyList.slice(
        //         visibleRange.start,
        //         visibleRange.end
        //       );
        // return visibleCompanies.map((result, index) => {
        const tickers = result.AlternateTickers.length
          ? result.AlternateTickers.join()
          : result.Ticker;
        return (
          <Link
            to={`/company/${result.Ticker}`}
            key={index}
            style={{ color: "black" }}
            onClick={() => {
              setExpand(false);
              setSearchVal("");
            }}
          >
            <div title={result.Company + " [" + tickers + "]"} key={index}>
              {result.Company + " [" + tickers + "]"}
            </div>
          </Link>
        );
      });
    return filteredAndSortedCompanyList;
  }, [companiesList, setExpand, searchVal, visibleRange]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isActiveSubscriber) {
      history.push("/subscribe");
    }
    return false;
  };

  useEffect(() => {}, [mode]);

  const handleScroll = useCallback(
    //handle virtual scroll for executives and company list (for performance)
    (event, numberOfItems) => {
      const itemHeight = mode === "executive" ? 0.54 : 0.8;
      const { scrollTop, scrollHeight, clientHeight } = event.target;

      const end = Math.min(
        Math.floor(scrollTop + clientHeight / itemHeight),
        numberOfItems
      );
      const start = Math.ceil(end - clientHeight / itemHeight);

      // console.log(start, end, filteredExecutives.length);

      setVisibleRange({
        start,
        end,
      });
    },
    [setVisibleRange, mode]
  );

  useEffect(() => {
    executiveSearchResultsRef.current?.scrollTo(0, 0);
    companySearchResultsRef.current?.scrollTo(0, 0);
    setVisibleRange({ start: 0, end: 200 });
  }, [mode]);
  const memoizedResult = useMemo(() => {
    if (isActiveSubscriber) {
      return (
        <>
          {mode === "executive" ? (
            <span
              style={{
                display: mode === "executive" ? "block" : "none",
                height: filteredExecutives.length <= 10 ? "auto" : "300px",
                overflowY: "auto",
              }}
              onScroll={(e) => handleScroll(e, filteredExecutives.length)}
              ref={executiveSearchResultsRef}
            >
              {executivesList}
            </span>
          ) : (
            // <span
            //   style={{
            //     display: mode === "company" ? "block" : "none",
            //     height: filteredCompanies.length <= 10 ? "auto" : "300px",
            //     overflowY: "auto",
            //   }}
            //   onScroll={(e) => handleScroll(e, filteredCompanies.length)}
            //   ref={companySearchResultsRef}
            // >
            //   {sortedCompaniesList}
            //   </span>
            <span style={{ display: mode === "company" ? "" : "none" }}>
              {/* if virtual company list presents issues */}
              {sortedCompaniesList}
            </span>
          )}
        </>
      );
    } else {
      return (
        <span
          onClick={() => {
            history.push("/subscribe");
            setExpand(false);
          }}
        >
          Subscribe to enable company and executive search
        </span>
      );
    }
  }, [
    executivesList,
    history,
    isActiveSubscriber,
    mode,
    setExpand,
    sortedCompaniesList,
    handleScroll,
    filteredExecutives.length,
    filteredCompanies.length,
  ]);
  useEffect(() => {
    setRenderResult(memoizedResult);
  }, [memoizedResult, setRenderResult]);
  useEffect(() => {
    // removes results dropdown from the searchbar
    const blurSearchBar = () => {
      setStyles({
        result: {
          opacity: 0,
          maxHeight: 0,
        },
      });
    };
    let blurSearchBarTimer;

    // check if the search bar was previously active, but no longer is hovered
    if (searchBarWasActive && !isHovered) {
      blurSearchBarTimer = setTimeout(() => {
        blurSearchBar();
        setSearchBarWasActive(false);
      }, 600);
    }
    // Don't blur the searchbar if it's hovered again
    if (blurSearchBarTimer) {
      return () => clearTimeout(blurSearchBarTimer);
    }
  }, [isHovered, searchBarWasActive]);

  return expand ? (
    <div
      className={`search-bar${isActiveSubscriber ? "" : " not-logged-in"}`}
      autoComplete="off"
    >
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <input
          onBlur={() => {
            if (isHovered) {
              setSearchBarWasActive(true);
            }
            setStyles((prevState) => ({
              ...prevState,
              img: {
                cursor: "text",
                backgroundColor: "transparent",
              },
              input: { cursor: "text" },
              result: {
                visibility: "visible",
                opacity: isHovered ? 1 : 0,
                maxHeight: isHovered ? 320 : 0,
                transitionDelay: "0.3s",
              },
            }));
          }}
          onFocus={() => {
            setStyles((prevState) => ({
              ...prevState,
              img: { cursor: "text", backgroundColor: "transparent" },
              input: { cursor: "text" },
              result: {
                visibility: "visible",
                opacity: 1,
                maxHeight: 320,
                transitionDelay: "0.2s",
              },
            }));
          }}
          style={{ paddingBottom: "calc(0.15vh - 1px)" }}
          type="text"
          className="input"
          value={searchVal}
          /* contains invisible character to prevent chrome autofill */
          placeholder={
            !isActiveSubscriber
              ? "Search by Comp" + "​" + "any Name or Executive..."
              : mode === "company"
              ? "Search by Comp" + "​" + "any Name or Ticker..."
              : "Search by Executive Name or Ticker..."
          }
          onChange={(e) => {
            setSearchVal(e.target.value);
          }}
        />
        <img
          src={require("../assets/search.svg").default}
          style={{
            padding: "10px",
            position: "absolute",
            cursor: !isActiveSubscriber && "pointer",
          }}
          alt="search icon"
          onClick={() => {
            !isActiveSubscriber && history.push("/subscribe");
          }}
        />
        <div style={styles.result} className="search-result">
          {companiesList.length >= 9 || !isActiveSubscriber
            ? renderResult
            : isMobile
            ? "Search is not currently available for mobile devices."
            : "loading..."}
        </div>
      </form>
    </div>
  ) : (
    <div className="search-bar">
      <input
        onBlur={() => {
          setStyles((prevState) => ({
            ...prevState,
            result: {
              visibility: "visible",
              opacity: 1,
              maxHeight: 320,
              transitionDelay: "0.3s",
            },
          }));
        }}
        onFocus={() => {
          setStyles((prevState) => ({
            ...prevState,
            result: {
              visibility: "visible",
              cursor: "text",
              opacity: 1,
              maxHeight: 320,
              transitionDelay: "0.2s",
            },
          }));
        }}
        style={styles.input}
        className="input"
        onChange={(e) => {
          setSearchVal(e.target.value);
        }}
      />
      <img
        style={styles.img}
        src={require("../assets/search.svg").default}
        alt="search icon"
        onClick={() => {
          !isActiveSubscriber && history.push("/subscribe");
        }}
      />
      <div style={styles.result} className="search-result">
        {/* {renderResult} */}
        {(companiesList.length >= 9 && mode === "company") ||
        (executives.length >= 50 && mode === "executive")
          ? renderResult
          : "Loading..."}
      </div>
    </div>
  );
}
