export const GET_USER_IP_SAGA = "GET_USER_IP_SAGA";
export const SET_USER_IP = "SET_USER_IP";

export const CREATE_NEW_USER_IP_SAGA = "CREATE_NEW_USER_IP_SAGA";

export const CHECK_TABLE_USE_LIMIT_SAGA = "CHECK_TABLE_USE_LIMIT_SAGA";
export const SET_TABLE_USE_LIMIT_SAGA = "SET_TABLE_USE_LIMIT_SAGA";

export const SIGN_UP_SAGA = "SIGN_UP_SAGA";
export const SIGN_IN_SAGA = "SIGN_IN_SAGA";
export const SIGN_IN_ADMIN_SAGA = "SIGN_IN_ADMIN_SAGA";

export const USER_LOG_IN_SAGA = "USER_LOG_IN";
export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_USER_INFO = "SET_USER_INFO";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";

export const GET_USER_INFO_SAGA = "GET_USER_INFO_SAGA";
export const RESEND_ACTIVATION_LINK_SAGA = "RESEND_ACTIVATION_LINK_SAGA";

export const SIGN_OUT = "SIGN_OUT";

export const RESET_PASSWORD_REQUEST_SAGA = "RESET_PASSWORD_REQUEST_SAGA";
export const VERIFY_SECRET_CODE_SAGA = "VERIFY_SECRET_CODE_SAGA";
export const RESET_NEW_PASSWORD_SAGA = "RESET_NEW_PASSWORD_SAGA";
