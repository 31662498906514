import React, { useEffect, useState } from "react";
import "../css/navigation.css";
import { LinkButton } from "../JSS/LinkButton";
import SearchBar from "./SearchBar";
import Authentication from "../utils/Authentication/Authentication";
import { Menu, Dropdown, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_INFO_SAGA } from "../redux/constants/UserConstant";
import { ACCESS_TOKEN, USER_INFO, KEY } from "../utils/Constants/systemSetting";
import { signOut, resendActivationLink } from "../redux/actions/UserAction";
import { Link } from "react-router-dom";
import { store } from "../redux/reducers/configStore";
import menuIcon from "../assets/menu2.svg";
import closeMenu from "../assets/close.svg";
import { useHistory } from "react-router";

export default function NavigationBar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const [onTop, setOnTop] = useState("on-top");
  const [expand, setExpand] = useState(false);
  const [aboutMenuOpen, setAboutMenuOpen] = useState(false);
  const [searchBarMode, setSearchBarMode] = useState("company");
  const [searchBarHover, setSearchBarHover] = useState(false);

  const history = useHistory();

  const [isDesktop, setDesktop] = useState(window.innerWidth > 500);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 550);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const [isActiveSubscriber, setIsActiveSubscriber] = useState(
    ["active", "trialing"].includes(subscription?.status)
  );
  useEffect(() => {
    setIsActiveSubscriber(
      ["active", "trialing"].includes(subscription?.status)
    );
  }, [subscription]);

  useEffect(() => {
    if (window.pageYOffset === 0) {
      setOnTop("on-top");
    } else {
      setOnTop("");
    }
    window.onscroll = function () {
      if (window.pageYOffset === 0) {
        setOnTop("on-top");
      } else {
        setOnTop("");
      }
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      dispatch({
        type: GET_USER_INFO_SAGA,
      });
    }
  }, [user?.isAuthenticated, dispatch]);

  const handleSignOut = async () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER_INFO);
    localStorage.removeItem(KEY);
    dispatch(signOut());
    history.go(0);
  };

  const menu = (
    <Menu className="user-menu">
      <Menu.Item
        onClick={() => {
          history.push("/profile");
          setExpand(false);
        }}
        className="profile"
        key="profile"
      >
        <p className="email">{user.email}</p>
        <p>See your profile</p>
      </Menu.Item>
      {!Authentication.isActivated() && (
        <Menu.Item
          className="menu verify-account"
          key="verify"
          onClick={() => {
            dispatch(resendActivationLink(user.email));
            setExpand(false);
          }}
        >
          <div className="d-flex align-items-center ">
            <div className="menu-img">
              <img
                src={require("../assets/verified.png").default}
                alt="verify"
              />
            </div>
            <p>
              Verify Account <i className="fa fa-exclamation"></i>
            </p>
          </div>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          history.push("/membership");
          setExpand(false);
        }}
        className="menu"
        key="membership"
      >
        <div className="d-flex align-items-center">
          <div className="menu-img">
            <img
              src={require("../assets/membership-card.png").default}
              alt="membership card"
            />
          </div>
          <p>Membership</p>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.push("/tutorials");
          setExpand(false);
        }}
        className="menu"
        key="tutorials"
      >
        <div className="d-flex align-items-center">
          <div className="menu-img">
            <img src={require("../assets/videos.svg").default} alt="help" />
          </div>
          <p>Tutorial Videos</p>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          history.push("/contact");
          setExpand(false);
        }}
        className="menu"
        key="support"
      >
        <div className="d-flex align-items-center">
          <div className="menu-img">
            <img src={require("../assets/help.png").default} alt="help" />
          </div>
          <p>Help & Support</p>
        </div>
      </Menu.Item>
      <Menu.Item className="menu" key="signOut">
        <div
          onClick={async () => {
            await handleSignOut();
            setExpand(false);
          }}
          className="d-flex align-items-center"
        >
          <div className="menu-img">
            <img src={require("../assets/logout.png").default} alt="logout" />
          </div>
          <p className="text-danger">Sign Out</p>
        </div>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://platform.linkedin.com/in.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const linkedinButton = (
    <li>
      <script src="https://platform.linkedin.com/in.js" type="text/javascript">
        {" "}
        lang: en_US
      </script>
      <div
        style={{
          paddingTop: 28.25,
        }}
      >
        <script
          type="IN/FollowCompany"
          data-id="89295333"
          data-counter="right"
        ></script>
      </div>
    </li>
  );

  return (
    <div
      className={`navigation-bar mx-auto d-flex align-items-center  ${onTop}`}
    >
      <div className="brand">
        <Link to="/">
          <img
            className="logo"
            style={{
              maxWidth: 400,
              display: "inline-block",
              maxHeight: 65,
              objectFit: "contain",
            }}
            src={"/logos/CSuiteLogo(R)_NoSlogan.svg"}
            alt="Logo"
          ></img>
        </Link>
      </div>
      <nav className="navigation-bar-menu d-flex justify-content-between align-items-center  flex-grow-1">
        <ul className="navbar-menu my-auto" style={{ padding: 0 }}>
          <li className={aboutMenuOpen ? "underlined" : "can-be-underlined"}>
            <Dropdown
              overlay={
                <div className="about-dropdown">
                  <div className="item">
                    <Link to="/about" onClick={() => setAboutMenuOpen(false)}>
                      About Us
                    </Link>
                  </div>
                  <div className="item" onClick={() => setAboutMenuOpen(false)}>
                    <Link to="/board-of-advisors">Board of Advisors</Link>
                  </div>
                </div>
              }
              onVisibleChange={(open) => {
                setAboutMenuOpen(open);
              }}
            >
              <div
                style={{ padding: "30px 10px" }}
                onClick={() => setAboutMenuOpen(true)}
              >
                About
              </div>
            </Dropdown>
          </li>
          {(!Authentication.isAuthenticated() || !isActiveSubscriber) && (
            <li>
              <Link to="/subscribe">Subscription</Link>
            </li>
          )}

          <li>
            <Link to="/contact">Contact</Link>
          </li>
          {linkedinButton}
        </ul>
        <ul
          className="button-group d-flex align-items-center p-0 my-auto"
          onMouseEnter={() => setSearchBarHover(true)}
          onMouseLeave={() => setSearchBarHover(false)}
        >
          {isActiveSubscriber && (
            <li style={{ marginRight: 0 }}>
              <Button
                onClick={() => {
                  setSearchBarMode(
                    searchBarMode === "company" ? "executive" : "company"
                  );
                }}
                style={{
                  marginRight: 0,
                  height: 40,
                  borderRadius: 5,
                  width: 160,
                }}
                className="search-bar-button"
              >
                {searchBarMode === "company"
                  ? "Company Search:"
                  : "Executive Search:"}
              </Button>
            </li>
          )}
          <li style={{ marginLeft: 0.5 }}>
            <SearchBar
              expand
              setExpand={setExpand}
              mode={searchBarMode}
              isHovered={searchBarHover}
            />
          </li>
          {Authentication.isAuthenticated() ? (
            <>
              <li className="user">
                <Dropdown
                  overlayStyle={{ width: "280px" }}
                  overlay={menu}
                  placement="bottomRight"
                  arrow
                >
                  <div className="avatar">
                    <i className="fa fa-user-alt"></i>
                  </div>
                </Dropdown>
                {!Authentication.isActivated() && (
                  <div className="verify-alert">
                    <i className="fa fa-exclamation"></i>
                  </div>
                )}
              </li>
            </>
          ) : (
            <>
              <li className="navbar-menu">
                <Link to="/login">Log&nbsp;in</Link>
              </li>
              <li className="buttons">
                <Link to="/subscribe">
                  <LinkButton>Join</LinkButton>
                </Link>
              </li>
              <li className="buttons">
                <Link to="/request-demo">
                  <LinkButton primary>Request&nbsp;Demo</LinkButton>
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>

      {/* Show when width is smaller than 900px */}
      <div className={`small-menu ${expand && "expand"}`}>
        {Authentication.isAuthenticated() && (
          <div className="user">
            <Dropdown
              overlayStyle={{ width: "280px" }}
              overlay={menu}
              placement="bottomRight"
              arrow
            >
              <div className="avatar">
                <i className="fa fa-user-alt"></i>
              </div>
            </Dropdown>
            {!Authentication.isActivated() && (
              <div className="verify-alert">
                <i className="fa fa-exclamation"></i>
              </div>
            )}
          </div>
        )}
        <img
          onClick={() => {
            setExpand(!expand);
          }}
          src={!expand ? menuIcon : closeMenu}
          alt="hamburger menu icon"
        />
        <div className="small-menu-expand">
          <ul>
            <li>
              <Link
                to="/about"
                onClick={() => {
                  setExpand(false);
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/board-of-advisors"
                onClick={() => {
                  setExpand(false);
                }}
              >
                Board of Advisors
              </Link>
            </li>
            <hr></hr>
            {(!Authentication.isAuthenticated() || !isActiveSubscriber) && (
              <li>
                <Link
                  to="/subscribe"
                  onClick={() => {
                    setExpand(false);
                  }}
                >
                  Subscription
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/contact"
                onClick={() => {
                  setExpand(false);
                }}
              >
                Contact
              </Link>
            </li>
            {linkedinButton}

            <li
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
              onMouseEnter={() => setSearchBarHover(true)}
              onMouseLeave={() => setSearchBarHover(false)}
            >
              {isActiveSubscriber && (
                <Button
                  onClick={() => {
                    setSearchBarMode(
                      searchBarMode === "company" ? "executive" : "company"
                    );
                  }}
                  style={{
                    height: 40,
                    borderRadius: 5,
                    marginBottom: 5,
                    width: 160,
                  }}
                  className="search-bar-button"
                >
                  {searchBarMode === "company"
                    ? "Company Search:"
                    : "Executive Search:"}
                </Button>
              )}

              <SearchBar
                expand
                setExpand={setExpand}
                mode={searchBarMode}
                isHovered={searchBarHover}
              />
            </li>
          </ul>
          {!Authentication.isAuthenticated() && (
            <div className="menu-footer">
              <hr></hr>
              <div>
                <ul
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <li
                    style={{
                      height: "50px",
                      padding: " 0px 16px",
                      textAlign: "center",
                      lineHeight: "50px",
                    }}
                  >
                    <Link
                      to="/login"
                      onClick={() => {
                        setExpand(false);
                      }}
                    >
                      Log in
                    </Link>
                  </li>
                  <li className="signupButton">
                    <Link to="/subscribe" onClick={() => setExpand(false)}>
                      <LinkButton
                        style={{
                          marginTop: 0,
                          textDecoration: "none",
                          justifySelf: "center",
                        }}
                      >
                        Join
                      </LinkButton>
                    </Link>
                  </li>

                  <li
                    style={{
                      width: "100%",
                      height: "50px",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Link to="/request-demo" onClick={() => setExpand(false)}>
                      <LinkButton
                        primary
                        style={{
                          marginTop: 0,
                          textDecoration: "none",
                          color: "white",
                          justifySelf: "center",
                        }}
                      >
                        Request Demo
                      </LinkButton>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
