import React, { useState, useEffect, useRef, useMemo } from "react";
import { Input, Tooltip } from "antd";
import { formatMeasure, emdash } from "../../../utils/Constants/systemSetting";

export default function EditableTableCell({
  cellData,
  dataType,
  isPayRatio = false,
  updateItem = null,
  updateKey = [],
  setUpdate = () => null,
  originalValue = null,
  currentValue = null,
  setTriggerUpdate = () => null,
  setCopy = () => null,
  year = null,
  setManualUpdate = () => null,
  manualKey = [],
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(undefined);
  const inputRef = useRef(null);

  const formatValue = (val) => {
    if (!val || val === emdash || val === "N/A" || val === "0" || val === "-")
      return emdash;
    if (dataType === "dollar") {
      return formatMeasure(
        parseFloat(val?.toString()?.replaceAll(",", "")),
        "dollar"
      )
        ?.toString()
        ?.replace("$", "");
    } else if (dataType) {
      return formatMeasure(
        parseFloat(val?.toString()?.replaceAll(",", "")),
        dataType
      );
    }
    return val;
  };

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const filterInput = (input) => {
    let newInput = input.replace(/[^0-9.-]/g, "").slice(0, 15);
    if (newInput.split(".").length > 2) {
      newInput = newInput.split(".").slice(0, 2).join(".");
    }
    return newInput;
  };

  function updateNestedKey(object, keyPath, newVal, isUpdated) {
    const keys = keyPath.split(".");
    const results = { ...object };

    function updateRecursively(obj, keys, newVal) {
      if (keys.length === 1) {
        obj[keys[0]] = newVal;
        obj["manuallyEdited"] = isUpdated;
      } else {
        if (!obj[keys[0]]) {
          obj[keys[0]] = {};
        }
        updateRecursively(obj[keys[0]], keys.slice(1), newVal);
      }
    }
    updateRecursively(results, keys, newVal);
    return results;
  }

  if (!value && cellData !== emdash && !editing) {
    setValue(
      currentValue
        ? currentValue?.toString().replaceAll(",", "")
        : originalValue
        ? originalValue.toString().replaceAll(",", "")
        : cellData.toString().replaceAll(",", "")
    );
  }

  const valuesChanged =
    (value || value === "") &&
    value.toString().replaceAll(",", "").replaceAll("%", "") !==
      cellData.toString().replaceAll(",", "").replaceAll("%", "");

  const getNumericalValue = (val) => {
    if (!val || isNaN(parseFloat(val))) return null;
    return parseFloat(val.toString().replaceAll(",", "").replaceAll("%", ""));
  };

  const numericalValue = getNumericalValue(value);

  // To mark if " * " is needed next to datapoint
  let isUpdated =
    (!originalValue && numericalValue) ||
    (originalValue === emdash && numericalValue) ||
    (originalValue !== emdash &&
      numericalValue !== getNumericalValue(originalValue));

  // TODO: Implement If needed for CSV export
  // if (isUpdated && year) {
  //   setTriggerUpdate(true);
  //   setCopy((prev) => {
  //     const prevValue = { ...prev };
  //     const prevData = prevValue
  //       ? prevValue.find((item) => item.year === year)
  //       : {};
  //     const newValue = updateNestedKey(
  //       prevData,
  //       updateKey.join("."),
  //       numericalValue
  //     );
  //     console.log(newValue);
  //     return newValue;
  //   });
  // }

  if (!editing && value && valuesChanged && updateItem) {
    const newItem = updateNestedKey(
      updateItem,
      updateKey.join("."),
      numericalValue,
      isUpdated
    );
    setUpdate((prev) => {
      if (prev.some((item) => item.key === newItem.key)) {
        prev[prev.indexOf(prev.find((item) => item.key === newItem.key))] =
          newItem;
        return prev;
      } else {
        return [...prev, newItem];
      }
    });
  }
  useMemo(() => {
    if (
      isUpdated &&
      valuesChanged &&
      (numericalValue || (value === "" && !editing))
    ) {
      setManualUpdate((prev) => {
        const currentData = { ...prev };
        if (numericalValue) {
          if (!currentData[manualKey[0]]) {
            currentData[manualKey[0]] = {};
          }
          currentData[manualKey[0]][manualKey[1]] = value;
        } else if (
          numericalValue === null &&
          currentData?.[manualKey[0]]?.[manualKey[1]]
        ) {
          const oldData = currentData[manualKey[0]];
          delete oldData[manualKey[1]];
          if (!Object.keys(oldData).length) {
            delete currentData[manualKey[0]];
          } else {
            currentData[manualKey[0]] = oldData;
          }
        }
        return currentData;
      });
    }
  }, [
    isUpdated,
    manualKey,
    numericalValue,
    value,
    valuesChanged,
    setManualUpdate,
    editing,
  ]);

  return (
    <div>
      {!editing && (
        <>
          <Tooltip title={"Click to edit this value"}>
            <div
              onClick={() => {
                setEditing(true);
              }}
              style={{ cursor: "pointer" }}
            >
              {(((value || value === 0) &&
                value.toString().replaceAll(",", "").replaceAll("%", "") !==
                  cellData
                    .toString()
                    .replaceAll(",", "")
                    .replaceAll("%", "")) ||
              isUpdated
                ? formatValue(value) + "*"
                : formatValue(cellData)) + (isPayRatio ? " : 1" : "")}
            </div>
          </Tooltip>
        </>
      )}

      <div style={{ display: editing ? "block" : "none" }}>
        <Input
          onChange={(e) => setValue(filterInput(e.target.value))}
          onBlur={() => {
            setEditing(false);
          }}
          value={value}
          ref={inputRef}
          onPressEnter={() => setEditing(false)}
        />
      </div>
    </div>
  );
}
