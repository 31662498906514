import React, { useRef, useState, useEffect } from "react";
import "../css/contact.css";
import { useSelector } from "react-redux";
import Input from "../components/Input/Input";
import { Button } from "../JSS/Button";
import {
  EMAIL_REGEX,
  PUBLIC_RECAPTCHA_SITE_KEY,
} from "../utils/Constants/systemSetting";
import ReCAPTCHA from "react-google-recaptcha";
import UserService from "../services/UserService";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { HIDE_LOADING, SHOW_LOADING } from "../redux/constants/LoadingConstant";

export default function ContactUs() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);

  const [disabled, setDisabled] = useState(true);
  const [captchaRef, setCaptchaRef] = useState(null);

  const reRef = useRef(null);

  const [state, setState] = useState({
    name: "",
    email: "",
    organization: "",
    comment: "",
  });

  useEffect(() => {
    setState({
      name: user?.fname ? [user?.fname, user?.lname].join(" ") : "",
      email: user?.email || "",
      organization: user?.companyName || "",
    });
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const values = { ...state };
    values[name] = value;
    let valid = true;

    if (name === "email") {
      if (!EMAIL_REGEX.test(value)) {
        valid = false;
      }
    }
    for (let key in values) {
      if (values[key].trim() === "") {
        valid = false;
      }
    }
    setDisabled(!valid);
    setState({ ...values });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      dispatch({
        type: SHOW_LOADING,
      });

      const token = captchaRef;

      const contactForm = {
        ...state,
        token,
      };

      const { status, data } = await UserService.sendContactForm(contactForm);

      setState({
        name: "",
        email: "",
        organization: "",
        comment: "",
      });
      setCaptchaRef(null);
      reRef.current.reset();
      setDisabled(true);

      if (status && data && status === 200) {
        toast.success(data);
      } else {
        toast.error(
          "Message cannot not be sent right now. Please try again later."
        );
      }
    } catch (err) {
      //   console.log(err.response);
      toast.error(
        err?.response?.data ||
          "Message cannot not be sent right now. Please try again later."
      );
    } finally {
      dispatch({
        type: HIDE_LOADING,
      });
    }
  };

  return (
    <div className="contact">
      <div className="container content">
        {/* <div className="content"> */}
        <div className="text row">
          <form
            onSubmit={handleSubmit}
            className="contact-form col-12 col-lg-12"
          >
            <h3 style={{ fontSize: "1.75em", textAlign: "left" }}>
              Please fill out the form below and a representative will reach out
              to you shortly.
            </h3>
            <br />
            <Input
              value={state.name}
              onChange={handleChange}
              name="name"
              style={{ borderColor: "var(--secondary)" }}
              label="Name"
              required
            />
            <Input
              value={state.email}
              onChange={handleChange}
              name="email"
              style={{ borderColor: "var(--secondary)" }}
              label="Email"
              required
            />
            <Input
              value={state.organization}
              onChange={handleChange}
              name="organization"
              style={{ borderColor: "var(--secondary)" }}
              label="Organization"
              required
            />
            <p className="mt-3">How can we help you?</p>
            <textarea
              name="comment"
              onChange={handleChange}
              rows="4"
              value={state.comment}
            ></textarea>
            <div
              className="captchaContainer"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <ReCAPTCHA
                sitekey={PUBLIC_RECAPTCHA_SITE_KEY}
                size="normal"
                onChange={(e) => setCaptchaRef(e)}
                ref={reRef}
              />
            </div>

            <div className="text-center mt-3">
              <Button disabled={disabled || captchaRef === null} primary>
                Submit
              </Button>
            </div>
          </form>
          {/* <div className="col-lg-6 col-12 contact-info">
                         <div className="overlay-3"> 
                        <h1>Need help immediately?</h1>
                         </div>
                    </div>  */}
        </div>
        {/* <div className="overlay-2"></div> */}
        {/* </div> */}
      </div>
      {/* <div className="overlay"></div> */}
    </div>
  );
}
