import { takeLatest, call, put, select } from "@redux-saga/core/effects";
import PlansService from "../../services/PlansService";
import { ACCESS_TOKEN, STATUS_CODE } from "../../utils/Constants/systemSetting";
import { GET_ALL_PLANS_SAGA, SET_PLANS } from "../constants/PlansConstant";

function* getAllPlans() {
  try {
    const { status, data } = yield call(() => PlansService.getAllPlan());
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_PLANS,
        plans: data,
      });
    }
  } catch (err) {
    console.log(err);
    const { history } = yield select((state) => state.HistoryReducer);
    history.push("/");
  }
}

export function* watchingGetAllPlans() {
  yield takeLatest(GET_ALL_PLANS_SAGA, getAllPlans);
}
