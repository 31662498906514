import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  getExecPosition,
  posGroupMatch,
} from "../../utils/ExecutiveData/getExecPosition";
import { measureOptions } from "../../utils/ExecutiveData/executiveMeasure";
import { Table, Tooltip, Radio, Select, Button } from "antd";
import { Link } from "react-router-dom";
import slugifyName from "../../utils/slugifyName";
import {
  fromDollar,
  isFirstYearOfComp,
  formatMeasure,
} from "../../utils/Constants/systemSetting";
import { useSelector } from "react-redux";
import { sortClosestExecutive } from "../../utils/ExecutiveData/sortClosest";
import MeasurePercentileTable from "./CohortSection/MeasurePercentileTable";
import MeasureBarGraph from "./CohortSection/MeasureBarGraph";
import CohortSelector from "./CohortSection/CohortSelector";
import MeasureSelector from "../../utils/ExecutiveData/MeasureSelector";
import CohortPercentileCalculator from "./CohortSection/CohortPercentileCalculator";
import removeSVG from "../../assets/close.svg";

export default function CohortSection({
  companyInfo,
  executive,
  execPositionGroup,
  year,
  PDFprocessing,
}) {
  const { Option } = Select;
  const { companies } = useSelector((state) => state.CompaniesReducer);
  const { csuite: allExecutives } = useSelector(
    (state) => state.ExecutivesReducer
  );

  const [tableKey, setTableKey] = useState(0);
  // Radio buttons for selectable "measure"
  const [measure, setMeasure] = useState(Object.values(measureOptions)[0]);

  const [searchVal, setSearchVal] = useState("");
  const [addedCohortExecutives, setAddedCohortExecutives] = useState([]);
  const [executiveChildren, setExecutiveChildren] = useState([]);

  const [sortedTableKeys, setSortedTableKeys] = useState([]);
  const [useTableSortedData, setUseTableSortedData] = useState(false);

  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [tableData, setTableData] = useState([]);
  const [cohortTableTickers, setCohortTableTickers] = useState([]);
  const [cohortTableSelector, setCohortTableSelector] =
    useState("Analyst Cohort");
  const [removedExecutives, setRemovedExecutives] = useState([]);

  const cohortTableKeys = useMemo(() => {
    if (allExecutives.length < 50) return [];
    const cohortTableKeys = [executive];

    for (const exec of allExecutives) {
      if (
        (addedCohortExecutives.some(
          (name) => name.split(" [")[0] === exec.name
        ) ||
          (cohortTableTickers?.includes(exec.Ticker) &&
            posGroupMatch(year, exec, execPositionGroup))) &&
        // Old method for position detection, new one matches sortData.js
        // getExecPosition(exec, year).some((pos) =>
        //   execPositionGroup.some(
        //     (posGroup) =>
        //       (pos.toLowerCase().includes(posGroup?.term) ||
        //         pos.includes(posGroup?.abbreviation?.toUpperCase())) &&
        //       !pos.toLowerCase().includes("former")
        //   ))
        !cohortTableKeys.includes(exec) &&
        !removedExecutives.includes(exec)
      ) {
        cohortTableKeys.push(exec);
      }
    }
    return cohortTableKeys;
  }, [
    executive,
    year,
    allExecutives,
    execPositionGroup,
    addedCohortExecutives,
    cohortTableTickers,
    removedExecutives,
  ]);

  const cohortTableColumns = [
    {
      title: `Remove?`,
      dataIndex: "remove",
      key: "remove",
      align: "left",
      width: 20,
      responsive: PDFprocessing ? ["xs"] : null,
    },
    {
      title: `Name`,
      dataIndex: "name",
      key: "name",
      align: "left",
      sorter: (a, b) => {
        return a.name.props.children.localeCompare(b.name.props.children);
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      responsive: ["lg"],
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      align: "center",
      sorter: (a, b) => {
        return a.company.props.children.localeCompare(b.company.props.children);
      },
    },
    {
      title: `${measure.title}`,
      dataIndex: "measureValue",
      key: "measureValue",
      align: "center",
      responsive: ["md"],
      render: (value, record) => {
        return measure?.title !== "CEO Pay Ratio" || value === "N/A" ? (
          value
        ) : (
          <span>
            {value} : 1
            {measure?.isCalculated &&
            measure?.isCalculated(companies[record.ticker], year) ? (
              <sup> †</sup>
            ) : (
              ""
            )}
          </span>
        );
      },
      sorter: (a, b, sortOrder) => {
        const aVal = a.measureValue;
        const bVal = b.measureValue;
        // const sortOrder = column.sortOrder;

        // Check if the values are percentages
        const aIsPercent = aVal.toString().indexOf("%") !== -1;
        const bIsPercent = bVal.toString().indexOf("%") !== -1;

        // Check if the values are "N", "P", or "N/A"
        const aIsNPNA = aVal === "N" || aVal === "P" || aVal === "N/A";
        const bIsNPNA = bVal === "N" || bVal === "P" || bVal === "N/A";

        if (aIsNPNA && bIsNPNA) {
          if (aVal === "P") {
            return sortOrder === "ascend" ? -1 : 1; // "P" comes before "N" and "N/A"
          } else if (aVal === "N") {
            if (bVal === "N/A") {
              return sortOrder === "ascend" ? -1 : 1; // "N" comes before "N/A"
            }
            return sortOrder === "ascend" ? 1 : -1; // "N" comes after "P"
          } else if (aVal === "N/A") {
            return sortOrder === "ascend" ? 1 : -1; // "N/A" comes after "P" and "N"
          } else {
            return 0; // Both values are "N/A", "N", or "P"
          }
        } else if (aIsNPNA) {
          return sortOrder === "ascend" ? Number.MAX_VALUE : -Number.MAX_VALUE; // "N" or "P" comes last
        } else if (bIsNPNA) {
          return sortOrder === "ascend" ? -Number.MAX_VALUE : Number.MAX_VALUE; // "N" or "P" comes last
        } else if (aIsPercent && bIsPercent) {
          // Sort percentage values
          const aNum = parseFloat(aVal);
          const bNum = parseFloat(bVal);
          return bNum - aNum; //sortOrder === "ascend" ? bNum - aNum : aNum - bNum;
        } else {
          // Sort dollar values
          const aNum = fromDollar(aVal);
          const bNum = fromDollar(bVal);
          return bNum - aNum; //sortOrder === "ascend" ? bNum - aNum : aNum - bNum;
        }
      },
    },
    {
      title: `${measure.title} Rank in ${cohortTableSelector}`,
      dataIndex: "measureRank",
      key: "measureRank",
      align: "center",
      responsive: ["md"],
      width: "200px",
      sorter: (a, b, sortOrder) => {
        const aRank = parseInt(
          a.measureRank.toString().replace(/[^0-9.-]+/g, "")
        );
        const bRank = parseInt(
          b.measureRank.toString().replace(/[^0-9.-]+/g, "")
        );

        if (!isNaN(aRank) && !isNaN(bRank)) {
          return sortOrder === "descend" ? aRank - bRank : aRank - bRank;
        } else if (isNaN(aRank) && !isNaN(bRank)) {
          return sortOrder === "descend" ? -Number.MAX_VALUE : Number.MAX_VALUE; // Put NaN values at the end
        } else if (!isNaN(aRank) && isNaN(bRank)) {
          return sortOrder === "descend" ? Number.MAX_VALUE : -Number.MAX_VALUE; // Put NaN values at the end
        }
        return 0;
      },
    },
    {
      title: `${measure.title} Percentile in ${cohortTableSelector}`,
      dataIndex: "measurePercentile",
      key: "measurePercentile",
      align: "center",
      width: "250px",
      sorter: (a, b, sortOrder) => {
        const aRank = parseInt(
          a.measureRank.toString().replace(/[^0-9.-]+/g, "")
        );
        const bRank = parseInt(
          b.measureRank.toString().replace(/[^0-9.-]+/g, "")
        );

        if (!isNaN(aRank) && !isNaN(bRank)) {
          return sortOrder === "descend" ? aRank - bRank : aRank - bRank;
        } else if (isNaN(aRank) && !isNaN(bRank)) {
          return sortOrder === "descend" ? -Number.MAX_VALUE : Number.MAX_VALUE; // Put NaN values at the end
        } else if (!isNaN(aRank) && isNaN(bRank)) {
          return sortOrder === "descend" ? Number.MAX_VALUE : -Number.MAX_VALUE; // Put NaN values at the end
        }
        return 0;
      },
    },
  ];

  const [showAllCohort, setShowAllCohort] = useState(false);
  useMemo(() => {
    if (showAllCohort) {
      setTablePagination({
        pageSize: cohortTableKeys.length,
        current: 1,
      });
    }
    if (!showAllCohort) {
      setTablePagination({
        pageSize: 10,
        current: 1,
      });
    }
  }, [showAllCohort, cohortTableKeys.length]);

  // const cohortTableData = useMemo(() => {
  useMemo(() => {
    const getCompanyLink = (ticker, name) => {
      return <Link to={`/company/${ticker}`}>{name}</Link>;
    };
    const getExecutiveLink = (ticker, name) => {
      return <Link to={`/company/${ticker}/${slugifyName(name)}`}>{name}</Link>;
    };

    const allCohortMeasures = [];
    if (!cohortTableKeys.length) return;
    cohortTableKeys.forEach((key) =>
      allCohortMeasures.push(
        measure?.getValue && measure.getValue(companies[key?.Ticker], year)
      )
    );
    allCohortMeasures.sort((a, b) => {
      // Check if the values are percentages
      if (a === null) a = "N/A";
      if (b === null) b = "N/A";
      a = a.toString();
      b = b.toString();
      const aIsPercent = a.indexOf("%") !== -1;
      const bIsPercent = b.indexOf("%") !== -1;

      if (a === "N/A" || a === "N" || a === "P") {
        return 1;
      } else if (b === "N/A" || b === "N" || b === "P") {
        return -1;
      } else if (aIsPercent && bIsPercent) {
        // Sort percentage values
        const aNum = parseFloat(a);
        const bNum = parseFloat(b);
        return bNum - aNum;
      } else {
        // Sort dollar values
        const aNum = fromDollar(a);
        const bNum = fromDollar(b);
        return bNum - aNum;
      }
    });

    setSortedTableKeys(
      cohortTableKeys
        .map((key, index) => {
          if (!key?.Ticker) return {};
          const measureValue =
            measure?.getValue && measure.getValue(companies[key?.Ticker], year);
          return {
            key: key?.name + index,
            remove: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: key?.name !== executive?.name ? "pointer" : "",
                  padding: 5,
                }}
                onClick={() =>
                  setRemovedExecutives((prevRemovedExecutives) => [
                    ...prevRemovedExecutives,
                    key,
                  ])
                }
              >
                {key?.name !== executive?.name && (
                  <img src={removeSVG} alt="remove" width={11} />
                )}
              </div>
            ),
            name: getExecutiveLink(
              key?.Ticker,
              isFirstYearOfComp(key, year) ? key?.name + "*" : key?.name
            ),
            title: getExecPosition(key, year)?.join(", "),
            company: getCompanyLink(key?.Ticker, key?.company),
            ticker: key?.Ticker,
            measureValue: measureValue
              ? formatMeasure(measureValue, measure.dataType)
              : "N/A",
            measureRank:
              measureValue && !["N/A", "N", "P"].includes(measureValue)
                ? allCohortMeasures.indexOf(measureValue) + 1
                : "–",
            measurePercentile:
              measureValue && !["N/A", "N", "P"].includes(measureValue)
                ? parseFloat(
                    100 -
                      (allCohortMeasures.indexOf(measureValue) /
                        (allCohortMeasures.filter(
                          (measure) => !["N/A", "N", "P"].includes(measure)
                        ).length - 1 || 1)) *
                        100
                  ).toFixed(2)
                : "–",
          };
        })
        .sort((a, b) => a.order - b.order)
    );
    // Use this data for bar graph when measure/cohort is changed, since table sorter and pagination get reset:
    setUseTableSortedData(false);
    setTablePagination({
      current: 1,
      pageSize: showAllCohort ? cohortTableKeys.length : 10,
    });
  }, [cohortTableKeys, year, companies, measure, executive, showAllCohort]);

  useEffect(() => {
    setExecutiveChildren(
      allExecutives
        .filter(
          (item) =>
            // item.compensation?.[year]?.total &&
            cohortTableKeys.every(
              (key) =>
                key.key !== item.key ||
                (key.name !== item.name && key.Ticker !== item.Ticker)
            ) &&
            (searchVal === "" ||
              searchVal
                .toLowerCase()
                .split(" ")
                .every(
                  (val) =>
                    val === "" ||
                    item.name.toLowerCase().includes(val) ||
                    item.Ticker.toLowerCase().includes(val) ||
                    item.company.toLowerCase().includes(val)
                ))
        )
        .sort((a, b) => {
          return sortClosestExecutive(a, b, searchVal);
        })
        .map((res) => (
          <Option key={res.name + " [" + res.Ticker + "]" + res.key}>
            {res.name + " [" + res.Ticker + "]"}
          </Option>
        ))
    );
  }, [allExecutives, searchVal, year, cohortTableKeys]);

  useEffect(() => {
    // reset props when page is changed
    setAddedCohortExecutives([]);
    setRemovedExecutives([]);
    setMeasure(Object.values(measureOptions)[0]);
  }, [executive]);

  const [pageContainsNewComp, setPageContainsNewComp] = useState(false);
  const [pageContainsCalculatedPayRatio, setPageContainsCalculatedPayRatio] =
    useState(false);
  useEffect(() => {
    setPageContainsNewComp(
      cohortTableKeys.some((key) => isFirstYearOfComp(key, year))
    );
    setPageContainsCalculatedPayRatio(
      cohortTableKeys &&
        cohortTableKeys.some(
          (key) =>
            measure?.isCalculated &&
            measure?.isCalculated(companies[key.Ticker], year)
        )
    );
  }, [cohortTableKeys, year, companies, measure]);

  // Updates bar graph based on pagination and sorter changes via currentDataSource
  const handleTableChange = (pagination, filter, sorter, extra) => {
    const currentData = extra.currentDataSource;
    setTableData(currentData);
    setTablePagination(pagination);
    setUseTableSortedData(true);
  };

  // Don't remove executives from "Added Executives" been manually removed:
  useEffect(() => {
    const removedExecutivesNames = removedExecutives.map(
      (exec) => `${exec.name} [${exec.Ticker}]${exec.key}`
    );
    const newAddedExecutives = addedCohortExecutives.filter((execName) => {
      return !removedExecutivesNames.includes(execName);
    });
    // Only update state if it has actually changed
    if (
      newAddedExecutives.length !== addedCohortExecutives.length ||
      !newAddedExecutives.every((exec, i) => exec === addedCohortExecutives[i])
    ) {
      setAddedCohortExecutives(newAddedExecutives);
    }
  }, [addedCohortExecutives, removedExecutives]);

  // Reset removed executives when chohort is changed
  useEffect(() => {
    setRemovedExecutives([]);
  }, [cohortTableSelector]);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const [scrollToPeerGroup, setScrollToPeerGroup] = useState(false);
  const peerGroupTableRef = useRef(null);
  const [showPeerGroup, setShowPeerGroup] = useState(false);
  const [highlightSection, setHighlightSection] = useState(false);
  useEffect(() => {
    if (scrollToPeerGroup) {
      scrollToRef(peerGroupTableRef);
      setHighlightSection(true);
      setShowPeerGroup(true);
      setScrollToPeerGroup(false);
    }
    setTimeout(function () {
      setHighlightSection(false);
    }, 1800);
    return;
  }, [
    peerGroupTableRef,
    showPeerGroup,
    scrollToPeerGroup,
    setScrollToPeerGroup,
  ]);

  return (
    <div className="cohort-table-section" id="cohort-section">
      <div id="cohort-selectors-and-table">
        <h3>{executive.name} Cohort Summary</h3>
        <CohortSelector
          heading="Select Cohort:"
          companyInfo={companyInfo}
          setCohortTableTickers={setCohortTableTickers}
          cohortTableSelector={cohortTableSelector}
          setCohortTableSelector={setCohortTableSelector}
        />
        <div style={{ display: "flex", marginTop: 5 }}>
          <h3
            style={{
              fontSize: 20,
              marginRight: 10,
              marginTop: 13.5,
            }}
          >
            {"Edit Comparison Group:".split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => {
                setScrollToPeerGroup(true);
              }}
              style={{ margin: 10, padding: "10px, 20px" }}
            >
              Edit Comparison Group
            </Button>
          </div>
        </div>
        <MeasureSelector
          heading="Select Measure:"
          measure={measure}
          setMeasure={setMeasure}
          tableKey={tableKey}
          setTableKey={setTableKey}
        />
        <div
          className="industry (company_filter)"
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: highlightSection ? "lightblue" : "",
              transition: "background-color 1000ms linear",
            }}
            ref={peerGroupTableRef}
          >
            <div style={{ display: "flex" }}>
              <div className="title" style={{ width: 200 }}>
                <h5 style={{ marginBottom: 0 }}>Add Executive(s):</h5>
              </div>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                allowClear
                value={addedCohortExecutives}
                filterOption={false}
                onSearch={(val) => {
                  setSearchVal(val);
                }}
                onBlur={() => {
                  setSearchVal("");
                }}
                loading={allExecutives?.length < 8}
                onChange={(value) => {
                  setSearchVal("");
                  setAddedCohortExecutives(value);
                }}
              >
                {executiveChildren}
              </Select>
            </div>
            {cohortTableKeys?.length > 10 && (
              <div
                className="d-inline-flex center title pt-3"
                style={{ width: 500 }}
              >
                <h5
                  style={{
                    marginBottom: 0,
                    marginRight: 10,
                    paddingTop: 5,
                  }}
                >
                  Show All:
                </h5>

                <Button
                  className="px-4"
                  onClick={() => {
                    setShowAllCohort(!showAllCohort);
                  }}
                >
                  {showAllCohort ? "Show First 10" : "Show All"}
                </Button>
              </div>
            )}

            <div
              className="cohort-table"
              style={{ marginBottom: 50, marginTop: 25 }}
            >
              <Table
                columns={cohortTableColumns}
                dataSource={sortedTableKeys}
                pagination={{ ...tablePagination, hideOnSinglePage: true }}
                bordered={true}
                onChange={handleTableChange}
                key={tableKey}
                size={PDFprocessing ? "small" : "large"}
                style={{ width: "97.5%", margin: "auto" }}
              />
              <div>
                {pageContainsNewComp ? (
                  <span>
                    *<sup> </sup> {year} was this executive's first year of
                    compensation.
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div>
            {pageContainsCalculatedPayRatio ? (
              <span>
                <sup>† </sup> These CEO Pay Ratios were calculated from the
                company's CEO total compensation and the company's sector median
                pay.
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div id="cohort-summary-section">
        {PDFprocessing && <h3>{executive.name} Cohort Summary (Cont.)</h3>}
        <div
          className="measure-summary-section"
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <MeasurePercentileTable
            cohortTableSelector={cohortTableSelector}
            measure={measure}
            sortedTableKeys={sortedTableKeys}
          />
          <MeasureBarGraph
            year={year}
            executive={executive}
            measure={measure}
            cohortTableSelector={cohortTableSelector}
            sortedTableKeys={useTableSortedData ? tableData : sortedTableKeys}
            tablePagination={tablePagination}
          />
        </div>
        <div
          className="sector-percentile-section"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <CohortPercentileCalculator
            cohortTableSelector={cohortTableSelector}
            cohortExecutives={cohortTableKeys}
            year={year}
          />
        </div>
      </div>
    </div>
  );
}
