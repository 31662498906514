import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../JSS/Button";
import { resetPasswordRequest } from "../../redux/actions/UserAction";
import { EMAIL_REGEX } from "../../utils/Constants/systemSetting";
import Input from "../Input/Input";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [state, setState] = useState("");

  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value } = e.target;
    let valid = true;
    if (!EMAIL_REGEX.test(value)) {
      valid = false;
    }
    setState(value);
    setDisabled(!valid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      resetPasswordRequest({
        email: state,
      })
    );

    setState("");
  };

  return (
    <div className="login-signup forgot-password">
      <div className="container">
        <div className="background email">
          <div className="overlay-color"></div>
        </div>
        <form onSubmit={handleSubmit} className="content">
          <h1>Forgot Your Password?</h1>
          <div className="description">
            <p>Enter the email address associated with your account.</p>
            <p>
              Instructions to reset your password will be sent to your email.
            </p>
          </div>
          <Input
            value={state}
            onChange={handleChange}
            label="Email"
            id="email"
            required
          />
          <div className="btn-group">
            <Link to="/login">Go Back</Link>
            <Button disabled={disabled} primary>
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
