import { takeLatest, call, put, select } from "@redux-saga/core/effects";
import {
  CHECK_TABLE_USE_LIMIT_SAGA,
  CREATE_NEW_USER_IP_SAGA,
  GET_USER_INFO_SAGA,
  GET_USER_IP_SAGA,
  RESET_NEW_PASSWORD_SAGA,
  RESET_PASSWORD_REQUEST_SAGA,
  SET_TABLE_USE_LIMIT_SAGA,
  SET_USER_INFO,
  UPDATE_USER_DETAILS,
  UPDATE_USER_PASSWORD,
  SET_USER_IP,
  SIGN_IN_SAGA,
  USER_LOG_IN_SAGA,
  SET_USER_DETAIL,
  SIGN_UP_SAGA,
  VERIFY_SECRET_CODE_SAGA,
  RESEND_ACTIVATION_LINK_SAGA,
} from "../constants/UserConstant";
import UserService from "../../services/UserService";
import {
  ACCESS_TOKEN,
  STATUS_CODE,
  USER_INFO,
  KEY,
} from "../../utils/Constants/systemSetting";
import { SET_PAYWALL } from "../constants/PaywallConstant";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import { HIDE_LOADING, SHOW_LOADING } from "../constants/LoadingConstant";
import { setForgotPasswordComponent } from "../actions/ForgotPasswordAction";
import EnterEmail from "../../components/ForgotPassword/EnterEmail";
import NewPassword from "../../components/ForgotPassword/NewPassword";
import EnterVerificationCode from "../../components/ForgotPassword/EnterVerificationCode";
import { SET_FORGOT_PASSWORD_COMPONENT } from "../constants/ForgotPasswordConstant";
import { useDispatch } from "react-redux";

function* getUserIP(action) {
  return;
  try {
    const { data, status } = yield call(() => UserService.getUserIP());
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: CREATE_NEW_USER_IP_SAGA,
        ip: data.IPv4,
      });

      yield put({
        type: SET_USER_IP,
        ip: data.IPv4,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
function* login(action) {
  try {
    const { data, status } = yield call(() => UserService.login(action.user));
    if (status === STATUS_CODE.SUCCESS) {
      localStorage.setItem(ACCESS_TOKEN, data.token);
      localStorage.setItem(USER_INFO, data.userInfo);
      const decoded = jwt_decode(data.userInfo);
      yield put({
        type: SET_USER_DETAIL,
        user: decoded,
      });
      const { history } = yield select((state) => state.HistoryReducer);
      // history.goBack();
      // window.location.reload();
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
    if (err.response?.status === STATUS_CODE.NOT_FOUND) {
      toast.error("Your Email is not valid!");
    } else if (err.response?.status === STATUS_CODE.NOT_ACCEPTABLE) {
      toast.error("Your Password is not correct!");
    }
  }
}

function* createNewUserIP(action) {
  try {
    const { data, status } = yield call(() =>
      UserService.createNewIP({ ip: action.ip })
    );
  } catch (err) {
    console.log(err.response);
  }
}

function* checkTableUseLimit(action) {
  try {
    const { ip } = yield select((state) => state.UserReducer);
    const token = localStorage.getItem(ACCESS_TOKEN);
    const { status, data } = yield call(() =>
      UserService.checkTableUseLimit(ip, token)
    );

    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_PAYWALL,
        allow: data.allow,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* setTableUseLimit(action) {
  try {
    const { ip } = yield select((state) => state.UserReducer);
    const { subscription } = yield select((state) => state.CustomerReducer);
    if (subscription && subscription.status === "active") {
      yield put({
        type: SET_PAYWALL,
        allow: true,
      });
    } else {
      const { status, data } = yield call(() =>
        UserService.setTableUseLimit({ ip: ip })
      );
      if (status === STATUS_CODE.SUCCESS) {
        yield put({
          type: SET_PAYWALL,
          allow: data.allow,
        });
      }
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* signUp(action) {
  try {
    yield put({
      type: SHOW_LOADING,
    });
    const { status, data } = yield call(() =>
      UserService.signUp(action.newUser)
    );
    if (status === STATUS_CODE.SUCCESS) {
      localStorage.setItem(ACCESS_TOKEN, data.token);
      localStorage.setItem(USER_INFO, data.userInfo);

      const decoded = jwt_decode(data.userInfo);
      yield put({
        type: SET_USER_INFO,
        user: decoded,
      });
      yield put({
        type: HIDE_LOADING,
      });
      const { history } = yield select((state) => state.HistoryReducer);
      history.push("/");
      window.location.reload();
    }
  } catch (err) {
    toast.error(err.response?.data);
    console.log(err.response);
    yield put({
      type: HIDE_LOADING,
    });
  }
}

function* signIn(action) {
  try {
    yield put({
      type: SHOW_LOADING,
    });
    const { status, data } = yield call(() => UserService.signIn(action.user));
    if (status === STATUS_CODE.SUCCESS) {
      localStorage.setItem(ACCESS_TOKEN, data.token);
      localStorage.setItem(USER_INFO, data.userInfo);

      const decoded = jwt_decode(data.userInfo);
      localStorage.setItem(KEY, decoded.key);
      yield put({
        type: SET_USER_INFO,
        user: decoded,
      });
      yield put({
        type: HIDE_LOADING,
      });

      // const { history } = yield select((state) => state.HistoryReducer);
      // console.log(history);
      // history.push("/");
      window.location.reload();
    }
  } catch (err) {
    yield put({
      type: HIDE_LOADING,
    });
    toast.error(err.response.data);
    console.log(err.response);
  }
}

function* getUserInfo() {
  try {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
      const { status, data } = yield call(() => UserService.getUserInfo(token));

      if (status === STATUS_CODE.SUCCESS) {
        if (status === STATUS_CODE.SUCCESS) {
          localStorage.setItem(ACCESS_TOKEN, data.newToken);
          localStorage.setItem(USER_INFO, data.newUserInfo);

          const decoded = jwt_decode(data.newUserInfo);
          localStorage.setItem(KEY, decoded.key);
          yield put({
            type: SET_USER_INFO,
            user: decoded,
          });
        }
      }
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* updateUserDetails(action) {
  try {
    const { status, data } = yield call(() =>
      UserService.updateUser(action.newUserDetails)
    );

    if (status === STATUS_CODE.SUCCESS) {
      localStorage.setItem(ACCESS_TOKEN, data.newToken);
      localStorage.setItem(USER_INFO, data.newUserInfo);

      const decoded = jwt_decode(data.newUserInfo);
      yield put({
        type: SET_USER_INFO,
        user: decoded,
      });
    }
  } catch (err) {
    console.log(err.response);
    // toast.error(
    //   "Profile could not be updated right now. Please try again later"
    // );
  }
}

function* updateUserPassword(action) {
  try {
    const { status, data } = yield call(() =>
      UserService.updateUserPassword(action.newUserDetails)
    );

    if (status === STATUS_CODE.SUCCESS) {
      // console.log(data);
      toast.success("Your profile is up-to-date!");
    }
  } catch (err) {
    console.log(err.response);
    // toast.error(
    //   "Profile could not be updated right now. Please try again later"
    // );
  }
}

function* resetPasswordRequest(action) {
  try {
    yield put({
      type: SHOW_LOADING,
    });
    const { status, data } = yield call(() =>
      UserService.resetPasswordRequest(action.email)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_FORGOT_PASSWORD_COMPONENT,
        Component: <EnterVerificationCode email={action.email.email} />,
      });
      toast.success("Verification code has been sent to your email!");
    }
  } catch (err) {
    console.log(err.response);
    toast.error(err.response.data);
  } finally {
    yield put({
      type: HIDE_LOADING,
    });
  }
}

function* resendActivationLink(action) {
  try {
    const { status } = yield call(() =>
      UserService.resendActivationLink(action.email)
    );
    if (status === STATUS_CODE.SUCCESS) {
      toast.success("Activation Link Has Been Sent to your Email");
    }
  } catch (err) {
    toast.error(err.response.data);
  }
}

function* verifySecretCode(action) {
  try {
    yield put({
      type: SHOW_LOADING,
    });
    const { status, data } = yield call(() =>
      UserService.verifySecretCode(action.code)
    );

    if (status === STATUS_CODE.SUCCESS && data.isAllow) {
      yield put({
        type: SET_FORGOT_PASSWORD_COMPONENT,
        Component: <NewPassword email={data.email} />,
      });
    }
  } catch (err) {
    console.log(err.response);
    toast.error(err.response.data.message);
  } finally {
    yield put({
      type: HIDE_LOADING,
    });
  }
}

export function* resetNewPassword(action) {
  try {
    const { status, data } = yield call(() =>
      UserService.resetNewPassword(action.newPassword)
    );
    if (status === STATUS_CODE.SUCCESS) {
      toast.success("Your password has been reset.");
      const { history } = yield select((state) => state.HistoryReducer);

      history.push("/login");
      yield put({
        type: SET_FORGOT_PASSWORD_COMPONENT,
        Component: <EnterEmail />,
      });
    }
  } catch (err) {
    console.log(err.response);
    toast.error(err.response.data);
  }
}

export function* watchingGetUserIP() {
  yield takeLatest(GET_USER_IP_SAGA, getUserIP);
}

export function* watchingCreateNewUserIP() {
  yield takeLatest(CREATE_NEW_USER_IP_SAGA, createNewUserIP);
}

export function* watchingCheckTableUseLimit() {
  yield takeLatest(CHECK_TABLE_USE_LIMIT_SAGA, checkTableUseLimit);
}

export function* watchingSetTableUseLimit() {
  yield takeLatest(SET_TABLE_USE_LIMIT_SAGA, setTableUseLimit);
}

export function* watchingSignUp() {
  yield takeLatest(SIGN_UP_SAGA, signUp);
}
export function* watchingLogIn() {
  yield takeLatest(USER_LOG_IN_SAGA, login);
}

export function* watchingSignIn() {
  yield takeLatest(SIGN_IN_SAGA, signIn);
}

export function* watchingGetUserInfo() {
  yield takeLatest(GET_USER_INFO_SAGA, getUserInfo);
}

export function* watchingUpdateUserDetails() {
  yield takeLatest(UPDATE_USER_DETAILS, updateUserDetails);
}

export function* watchingUpdateUserPassword() {
  yield takeLatest(UPDATE_USER_PASSWORD, updateUserPassword);
}

export function* watchingResetPasswordRequest() {
  yield takeLatest(RESET_PASSWORD_REQUEST_SAGA, resetPasswordRequest);
}

export function* watchingResendActivationLink() {
  yield takeLatest(RESEND_ACTIVATION_LINK_SAGA, resendActivationLink);
}

export function* watchingVerifySecretCode() {
  yield takeLatest(VERIFY_SECRET_CODE_SAGA, verifySecretCode);
}

export function* watchingResetNewPassword() {
  yield takeLatest(RESET_NEW_PASSWORD_SAGA, resetNewPassword);
}
