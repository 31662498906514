import { Link } from "react-router-dom";

export const CalculatorFAQTopics = {
  tsr_table: {
    title: "Peer Group TSR Table",
    short_desc: (
      <p>
        <Link to="/faq#tsr_table" target="_blank" rel="noopener noreferrer">
          This table is best read from top to bottom. The various rows show how
          an initial investment of $100 grows over the holding period at
          specific points in time. The bottom row reports the TSR for the full
          holding period for both the target firm and for a portfolio of its
          peers. Click to Read More.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        This table shows you how the realized total shareholder return compounds
        over time for the company and for a portfolio of its peers. C-Suite
        enables several methods for peer portfolios to be formed and maintained.
        The TSRs reported here assume a $100 initial investment is made at the
        closing price on the last trading day prior to the evaluation period.
        This initial investment then compounds (or cumulates) over time using
        the realized returns of the company and its associated peer group. Each
        row in the table represents the compounded total shareholder return
        inclusive of dividends to that point in time (i.e., except for year 1,
        the TSR in a given row does not reflect the TSR for that particular
        year). The bottom row of the table represents the TSR for the entire
        evaluation period where the initial investment is assumed to be
        liquidated on the last trading day. A TSR of 150.80 would represent a
        +50.8% total return (($150.80 – $100)/$100)) earned over the full
        holding period (i.e., this is not an annualized return). TSRs below 100
        represent negative total returns (e.g., a TSR of 90.10 would represent a
        holding period return of -9.90% (($90.10 – $100)/$100).
      </p>
    ),
  },
  equal_weighted: {
    title: "Peer Group TSR (Equal Weighted (EW))",
    short_desc: (
      <p>
        <Link
          to="/faq#equal_weighted"
          target="_blank"
          rel="noopener noreferrer"
        >
          The EW approach is only a diagnostic tool used to help the firm better
          understand how its TSR compares to its peer group. The approach
          calculates a TSR assuming that each firm in the peer group has equal
          influence on the TSR calculation. With this approach, no single firm
          in the peer group will have outsized influence on the calculation. The
          returns are inclusive of dividends. Click to Read More.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        This approach assumes that the initial $100 investment in the TSR
        calculation was evenly invested across all firms in the peer group at
        the beginning of the holding period. For example, if there are 10 firms
        in the peer group, this assumes $10 ($100/10) was initially invested in
        each peer firm at the beginning of the TSR period. C-Suite calculates
        cumulative TSRs from monthly returns, assuming dividends are reinvested
        on the ex-date. Further, this approach assumes monthly rebalancing where
        the portfolio weights are reset to equal at the beginning of each month.
        This has the effect of taking the gains accruing each month from
        high-flying winners and reinvesting those funds in the losers to
        calculate performance for the next month. This enables all peer firms to
        have equal impact on the reported TSR each month. These returns are then
        compounded over time to produce a TSR inclusive of dividend payments. By
        design, peer firms which are very large or very small have no
        differential impact on the calculation. This method may be useful for
        clients who wish to see how their company performed relative to mean of
        its peer group. Performance is inclusive of dividends and include the
        firm’s own returns in the TSR calculation. This approach is only
        diagnostic to help the firm better interpret its relative performance
        and is not Item (201) compliant.
      </p>
    ),
  },
  value_weighted: {
    title: "Peer Group TSR (Value Weighted (VW))",
    short_desc: (
      <p>
        <Link
          to="/faq#value_weighted"
          target="_blank"
          rel="noopener noreferrer"
        >
          This approach is used only as a diagnostic tool and differs only
          slightly from the Item (201) compliant approach. Using this approach,
          each firm in the peer group influences the peer TSR proportional to
          its market cap where the market cap takes into account month-to-month
          changes in share count. The returns to larger peer firms, by
          construction, will be have greater influence relative to smaller
          firms. Because market-caps can be heavily skewed in some industries,
          this TSR calculation can be heavily affected by the TSR of a single,
          large company. The returns are inclusive of dividends. Click to Read
          More.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        This approach assumes that the initial $100 investment in the peer group
        is invested proportional to each firm’s relative market-cap at the
        beginning of the holding period. For example, suppose there are 10 firms
        in the peer group; nine are each valued at $1B, but the tenth firm is
        valued at $11B. The VW method will set the portfolio weight for each of
        the first nine firms at 5% ($1B/$20B), but the 10th firm will be 55%
        ($11B/$20B). This approach enables larger peer firms to have more impact
        on the reported TSR calculation. C-Suite calculates cumulative TSRs from
        monthly returns, assuming dividends are reinvested on the ex-date. The
        distinction between this approach and that of the Item (201) compliant
        method is that this approach implies monthly rebalancing using the
        actual market-cap weights as they evolve over time due corporate
        transactions such as repurchases or equity offerings. Alternatively, the
        Item (201) compliant approach required for SEC disclosure is a more
        naïve approach and assumes no corporate transactions after portfolio
        formation. With the VW approach, if share count is constant for all
        firms in the peer group over time, the TSR produced by this method will
        be identical to the Item (201) compliant method. However, because firm
        share counts often change month to month, C-Suite’s approach allows for
        these corporate transactions affecting market-cap to be accurately
        incorporated each month such that the peer group VW TSR calculation
        maintains relative market-cap weights over time. (This approach mimics
        what an index like the S&P 100 or 500 index does periodically to
        accommodate corporate transactions.) While firms must use the Item (201)
        compliant method in their disclosure reports, the VW approach may be
        useful to clients who wish to better understand how their company
        performed relative to a peer group benchmark where large corporate
        transactions may have occurred during the reporting period. Performance
        is inclusive of dividends and includes the firm’s own returns in the TSR
        calculation.
      </p>
    ),
  },
  median_composite: {
    title: "Peer Group TSR (Median Composite (MC))",
    short_desc: (
      <p>
        <Link
          to="/faq#median_composite"
          target="_blank"
          rel="noopener noreferrer"
        >
          This approach is used only as a diagnostic tool and calculates a TSR
          each month during the reporting period by taking the median return
          within the peer group and then compounding those returns across the
          holding period. This approach eliminates the impact of unusual outlier
          returns arising from peer firms who experience extremely high or
          extremely low performance thus otherwise swaying the TSR. As such, one
          can describe this approach as reflecting the return performance of the
          “typical” firm over time. The returns are inclusive of dividends.
          Click to Read More.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        The TSRs produced by the EW and VW approaches can produce misleading
        comparisons. In the EW case, if we have ten firms in the peer group, but
        one firm has an extremely skewed return that the other nine peer firms
        don’t experience, this may dramatically increase the peer group TSR
        return calculation. If your firm behaves the same as the other nine
        lower performers, your firm’s TSR will look worse despite the fact that
        your performance is actually pretty typical of the majority of its
        peers. Similarly, in the VW case, if the largest firms in the peer group
        have extremely good or bad TSRs, this will provide skewed comparisons
        between your firm’s TSR and your peer group. The Median Composite
        approach removes the influence of extreme outliers. Each month during
        the TSR period, C-Suite identifies the median return within the peer
        group. From month to month, we cumulate each of these median monthly
        returns during the holding period. In one month, say January, Firm ABC
        might be the median company, but in February the median return might
        switch to Firm XYZ. Because this approach picks the “middle return” from
        the group each month, the return to this portfolio is not swayed by peer
        firms which experience run-away high or low performance. As such, this
        approach may allow the client to better understand how its TSR compares
        to that of “typical firm in the peer group performed.” Performance is
        inclusive of dividends and includes the firm’s own returns in the TSR
        calculation.
      </p>
    ),
  },
  item_201_compliant: {
    title: "Peer Group TSR (Item 201 Compliant)",
    short_desc: (
      <p>
        <Link
          to="/faq#item_201_compliant"
          target="_blank"
          rel="noopener noreferrer"
        >
          Clients must disclose this TSR calculation in their Pay for
          Performance Tables. This approach assumes that the initial $100
          investment in the peer group is invested proportional to each firm’s
          relative market-cap at the beginning of the holding period. The
          returns are inclusive of dividends. Click to Read More.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        Clients must disclose this TSR calculation in their Pay for Performance
        tables. This approach assumes that the initial $100 investment in the
        peer group is invested proportional to each firm’s relative market-cap
        at the beginning of the holding period. For example, suppose there are
        10 firms in the peer group; nine are each valued at $1B, but the tenth
        firm is valued at $11B. The VW method will set the portfolio weight for
        each of the first nine firms at 5% ($1B/$20B), but the 10th firm will be
        55% ($11B/$20B). This approach enables relatively larger peer firms to
        have more impact on the reported TSR calculation. When calculating the
        TSR, however, this approach implicitly holds the initial portfolio
        weights constant. Firms must report this calculation to be compliant;
        however, this approach may be subject to distortion for TSRs calculated
        over longer horizons such as three- and five-years. Performance is
        inclusive of dividends and includes the firm’s own returns in the TSR
        calculation.
      </p>
    ),
  },
};
