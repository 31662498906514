import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "../css/activate.css";
import { HIDE_LOADING, SHOW_LOADING } from "../redux/constants/LoadingConstant";
import UserService from "../services/UserService";
import toast from 'react-hot-toast';
import { STATUS_CODE } from "../utils/Constants/systemSetting";

export default function ActivateAccount(props) {
    const [time, setTime] = useState(5);
    const [status, setStatus] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (status) {
            if (time === 0) {
                props.history.push('/')
            }

            setTimeout(() => {
                setTime(time - 1);
            }, 1000);
        }
    }, [time, status]);

    useEffect(() => {
        dispatch({
            type: SHOW_LOADING,
        });

        const { id } = props.match.params;
        UserService.activateAccount(id)
            .then((res) => {
                console.log(res);
                dispatch({
                    type: HIDE_LOADING
                })
                if (res.status === STATUS_CODE.SUCCESS) {
                    setStatus(true);
                }
            })
            .catch((err) => {
                console.log(err.response);
                dispatch({
                    type: HIDE_LOADING
                })
                props.history.push('/');
            });
    }, []);

    const getStatus = async () => {
        const { id } = props.match.params;
        // const res = await UserService.activateAccount(id)
    };

    return (
        status && (
            <div className="activate-account">
                <div className="verified">
                    <i className="fa fa-check"></i>
                    <h3>
                        Your Account Has Been <span>Activated</span>!
                    </h3>
                    <div className="redirect">
                        Redirect to homepage in <span>{time}</span> seconds.
                    </div>
                </div>
            </div>
        )
    );
}
