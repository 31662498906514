import { takeLatest, call, put, select } from "@redux-saga/core/effects";
import toast from "react-hot-toast";
import CustomerService from "../../services/CustomerService";
import { ACCESS_TOKEN, STATUS_CODE } from "../../utils/Constants/systemSetting";
import {
  CANCEL_SUBSCRIPTION,
  GET_PAYMENT_METHOD_SAGA,
  GET_SUBSCRIPTION_SAGA,
  RENEW_SUBSCRIPTION,
  SET_CUSTOMER_SUBSCRIPTION,
  SET_PAYMENT_METHOD,
  UPDATE_PAYMENT_PLAN,
} from "../constants/CustomerConstant";
import { useHistory } from "react-router-dom";

function* getSubscription() {
  try {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (!token) {
      return;
    }
    const { status, data } = yield call(() =>
      CustomerService.getSubscription(token)
    );

    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_CUSTOMER_SUBSCRIPTION,
        subscription: data,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* getPaymentMethod() {
  try {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const { status, data } = yield call(() =>
      CustomerService.getPaymentMethod(token)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_PAYMENT_METHOD,
        paymentMethod: data,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* cancelSubscription(action) {
  try {
    const { status, data } = yield call(() =>
      CustomerService.cancelSubscription(action.cancellation)
    );

    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_CUSTOMER_SUBSCRIPTION,
        subscription: data,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* renewSubscription(action) {
  try {
    const { status, data } = yield call(() =>
      CustomerService.renewSubscription(action.token)
    );

    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_CUSTOMER_SUBSCRIPTION,
        subscription: data,
      });
    }
  } catch (err) {
    console.log(err.response);
  }
}

function* updatePaymentPlan(action) {
  try {
    const { status, data } = yield call(() =>
      CustomerService.updatePaymentPlan(action.token)
    );
    if (status === STATUS_CODE.SUCCESS) {
      yield put({
        type: SET_CUSTOMER_SUBSCRIPTION,
        subscription: data,
      });
      toast.success("Payment plan successfully updated!");
    }
  } catch (err) {
    toast.error(
      err.response === "string"
        ? err.response
        : "Cannot update payment plan right now. Please try again later."
    );
    console.log(err.response);
  }
}

export function* watchingGetSubscription() {
  yield takeLatest(GET_SUBSCRIPTION_SAGA, getSubscription);
}

export function* watchingGetPaymentMethod() {
  yield takeLatest(GET_PAYMENT_METHOD_SAGA, getPaymentMethod);
}

export function* watchingCancelSubscription() {
  yield takeLatest(CANCEL_SUBSCRIPTION, cancelSubscription);
}

export function* watchingRenewSubscription() {
  yield takeLatest(RENEW_SUBSCRIPTION, renewSubscription);
}

export function* watchingUpdatePaymentPlan() {
  yield takeLatest(UPDATE_PAYMENT_PLAN, updatePaymentPlan);
}
