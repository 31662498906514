import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableFilter } from "../../redux/actions/TableFilterAction";
import {
  BarChart,
  ComposedChart,
  Bar,
  Cell,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import SyncLoader from "react-spinners/SyncLoader";
import "../../css/graphs.css";
import { Tooltip as AntdTooltip } from "antd";
import {
  formatMeasure,
  abbreviateAndFormatMeasure,
} from "../../utils/Constants/systemSetting";

export default function BarGraph({
  data,
  year,
  setYear,
  yearsOptions = [],
  company = "",
  measure = "",
  percentileLines = [],
  showPercenileLines = false,
  isBoardPay = false,
  PDFprocessing = false,
  currentExecutivePage = null,
}) {
  const dispatch = useDispatch();
  const [minStep, setMinStep] = useState(0);
  const [stepSize, setStepSize] = useState(1000000);
  const [maxStep, setMaxStep] = useState(stepSize * 15);
  const [zoomed, setZoomed] = useState(false);
  const { filter } = useSelector((state) => state.TableFilterReducer);
  // const [year, setYear] = useState();

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  useEffect(() => {
    if (loaded) {
      dispatch(
        setTableFilter({
          ...filter,
          salary: {
            salaryMin: minStep !== 0 ? minStep.toString() : "",
            salaryMax: zoomed ? (maxStep + stepSize).toString() : "",
          },
          immediateUpdate: true,
        })
      );
    }
  }, [minStep, maxStep]);

  const [rawData, setRawData] = useState();
  const [dataset, setDataset] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // Load executives data //
  const processedData = [];

  useEffect(() => {
    setRawData(data);
  }, [data]);

  useEffect(() => {
    if (rawData?.length > 0) {
      setLoaded(true);
      setDataset([]);
      // generate list of all compenstaions
      for (let d in rawData) {
        let compensation = !isBoardPay ? rawData[d]?.compensation : rawData[d];
        if (isBoardPay && !compensation?.[year]) {
          compensation[year] = compensation;
        }

        let currentCount = 0;
        let currentSalary = 0;
        let currentBonus = 0;
        let currentStockAward = 0;
        let currentOptionAward = 0;
        let currentNonEquity = 0;
        let currentPension = 0;
        let currentOther = 0;
        let c = 0;
        for (let c in compensation) {
          if (c === year) {
            let execName = rawData[d].name;
            let total = compensation[c]?.total || 0;
            let salary =
              (!isBoardPay
                ? compensation[c]?.salary
                : compensation[c]?.feesEarnedOrPaid) || 0;
            let bonus = compensation[c]?.bonus || 0;
            let stockAward =
              compensation[c]?.["stockAward" + (isBoardPay ? "s" : "")] || 0;
            let optionAward =
              compensation[c]?.["optionAward" + (isBoardPay ? "s" : "")] || 0;
            let nonEquity = compensation[c]?.nonEquity || 0;
            let pension = compensation[c]?.pension || 0;
            let other = compensation[c]?.other || 0;
            currentCount++;
            currentSalary += salary;
            currentBonus += bonus;
            currentStockAward += stockAward;
            currentOptionAward += optionAward;
            currentNonEquity += nonEquity;
            currentPension += pension;
            currentOther += other;

            processedData.push({
              name: execName,
              "Number of Executives": currentCount,
              Salary: currentSalary,
              "Fees Earned or Paid in Cash": currentSalary,
              Bonus: currentBonus,
              "Stock Awards": currentStockAward,
              "Option Awards": currentOptionAward,
              "Non-Equity Incentive": currentNonEquity,
              "Change in Pension": currentPension,
              Other: currentOther,
            });
          }
        }
        // For exec page only:
        let value = rawData[d]?.value;
        let percentile = rawData[d]?.percentile;
        if (value) {
          const newValue = {
            name: rawData[d]?.name,
            company: rawData[d]?.company,
            isExecPage: rawData[d]?.isExecPage,
          };
          if (newValue?.isExecPage) {
            setPageContainsExecPageExec(true);
          }
          newValue[measure.title] = value;
          newValue["percentile"] = percentile;
          processedData.push(newValue);
        }

        setDataset(processedData);
      }
    } else setDataset([]);
  }, [rawData, year, minStep, maxStep, stepSize, zoomed, measure, isBoardPay]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      let payloadTotal = 0;
      if (payload.length > 1) {
        for (let i = 0; i < payload.length; i++) {
          if (payload[i]?.value) {
            payloadTotal += payload[i].value;
          }
        }
      }

      payloadTotal = Math.round(payloadTotal);

      const compensationLegend = (legend, color, value, total) => {
        if (value > 0 && (legend !== "Bonus" || !isBoardPay)) {
          return (
            <p className="desc" style={{ color: color }}>
              {legend}: {Math.round((value * 100) / total)}%
            </p>
          );
        }
      };
      const valueLegend = (legend, color, value, percentile = null) => {
        return (
          <>
            <p className="desc" style={{ color: color }}>
              {measure.title}:{" "}
              {measure?.title !== "CEO Pay Ratio" || value === "N/A"
                ? formatMeasure(value, measure.dataType)
                : formatMeasure(value, measure.dataType) + " : 1"}
            </p>
            {percentile && (
              <p className="desc" style={{ color: color }}>
                Percentile: {percentile}
              </p>
            )}
          </>
        );
      };
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            opacity: 0.875,
            padding: "5px",
          }}
        >
          {payloadTotal > 1 ? (
            <center>
              <p className="label">
                <b>{label}</b>
              </p>
              <p className="desc">
                <b>Total Compensation: ${payloadTotal.toLocaleString()}</b>
              </p>
              <b>
                Compensation Breakdown:
                {compensationLegend(
                  "Other",
                  "var(--graph-other)",
                  payload[6]?.value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Change in Pension",
                  "var(--graph-pension)",
                  payload[5]?.value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Non-Equity Incentive",
                  "var(--graph-non-equity)",
                  payload[4]?.value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Option Awards",
                  "var(--graph-option-awards)",
                  payload[3]?.value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Stock Awards",
                  "var(--graph-stock-awards)",
                  payload[2]?.value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Bonus",
                  "var(--graph-bonus)",
                  payload[1]?.value,
                  payloadTotal
                )}
                {compensationLegend(
                  !isBoardPay ? "Salary" : "Fees Earned or Paid in Cash",
                  "var(--graph-salary)",
                  payload[0]?.value,
                  payloadTotal
                )}
              </b>
            </center>
          ) : payload[0]?.value ? (
            <center>
              <p className="label">
                <b>{label}</b>
              </p>
              {payload[0]?.payload?.company && (
                <p className="label">
                  <b>{payload[0]?.payload?.company}</b>
                </p>
              )}
              <b>
                {valueLegend(
                  measure.title,
                  "var(--secondary)",
                  payload[0].value,
                  payload[0]?.payload?.percentile
                )}
              </b>
            </center>
          ) : null}
        </div>
      );
    }

    return null;
  };
  const formatLegend = (value, entry, index) => {
    const { color } = entry;
    if (measure?.title && value !== measure.title) return null;
    return (
      <span style={{ color, fontSize: "0.82em", marginLeft: 0 }}>{value}</span>
    );
  };

  const abbreviateName = useCallback(
    (value, payload = {}) => {
      // console.log(value);
      let isCurrentExecPage = false;
      if (currentExecutivePage?.name === value && payload.index === 0) {
        isCurrentExecPage = true;
      }
      try {
        const splitName = value.split(" ");
        let newName = "";
        for (const [index, name] of splitName.entries()) {
          if (index === 0) {
            newName += name[0] + ". ";
          }
          if (index === splitName.length - 1) {
            if (
              name === "Jr." ||
              name === "Jr" ||
              name === "Sr." ||
              name === "Sr" ||
              name === "III" ||
              name === "IV" ||
              name === "V" ||
              name === "M.D." ||
              name === "Ph.D."
            ) {
              newName += splitName[index - 1] + " ";
            }
            newName += name;
          }
        }
        return newName + (isCurrentExecPage ? "*" : "");
      } catch {
        return value;
      }
    },
    [currentExecutivePage]
  );

  const [pageContainsExecPageExec, setPageContainsExecPageExec] =
    useState(false);
  const renderCustomPercentileLegend = (props) => {
    // const { payload } = props;
    const renderBlackLegendText = (value) => {
      return (
        <span style={{ color: "var(--secondary)", whiteSpace: "nowrap" }}>
          {value}
        </span>
      );
    };
    const payloadForLegend = [
      { value: ">P90", type: "rect", color: "var(--secondary-4)" },
      { value: ">P75", type: "rect", color: "var(--secondary-3)" },
      { value: ">P50", type: "rect", color: "var(--secondary-2)" },
      { value: ">P25", type: "rect", color: "var(--secondary-1)" },
      { value: ">P0", type: "rect", color: "var(--secondary)" },
    ];
    if (pageContainsExecPageExec) {
      payloadForLegend.unshift({
        value: `${abbreviateName(currentExecutivePage?.name)}`,
        type: "rect",
        color: "var(--red)",
      });
    }
    return (
      <Legend
        payload={payloadForLegend}
        formatter={renderBlackLegendText}
        wrapperStyle={{ left: "27%", bottom: 5 }}
      />
    );
  };

  const getMeasureFillColor = (entry, index) => {
    let maxPercentileReached = { percentile: 0, amount: 0, index: 0 };
    percentileLines.forEach((line, idx) => {
      if (entry[measure.title] > line.amount) {
        maxPercentileReached = { ...line, index: idx + 1 };
      }
    });
    if (entry?.isExecPage) {
      return "var(--red)";
    }
    if (maxPercentileReached.index === 0) {
      return "var(--secondary)";
    } else {
      return `var(--secondary-${maxPercentileReached.index})`;
    }
  };

  const CustomizedAxisTick = useCallback(
    (props) => {
      const { x, y, stroke, payload } = props;
      const abbreviatedName =
        abbreviateName(payload.value, payload).length > 12
          ? abbreviateName(payload.value, payload).slice(0, 12) + "..."
          : abbreviateName(payload.value, payload);

      if (!measure?.title) {
        return (
          <g transform={`translate(${x},${y})`}>
            <text
              x={0}
              y={0}
              dy={7.5}
              textAnchor="middle"
              fill="#666"
              fontSize={10.5}
              color="var(--secondary)"
            >
              {abbreviatedName}
            </text>
          </g>
        );
      }

      const formattedLabel = (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={7.5}
            textAnchor="end"
            fill="#666"
            transform="rotate(-55)"
            fontSize={10.5}
            stroke={
              currentExecutivePage?.name === payload.value &&
              payload.index === 0
                ? "var(--red)"
                : ""
            }
            strokeWidth={0.1}
            fontWeight={
              currentExecutivePage?.name === payload.value &&
              payload.index === 0
                ? "bold"
                : ""
            }
          >
            {abbreviatedName}
          </text>
        </g>
      );
      return formattedLabel;
    },
    [measure, currentExecutivePage, abbreviateName]
  );
  const renderBar = (
    <ResponsiveContainer width="100%" height={325}>
      <ComposedChart
        width={600}
        height={375}
        margin={{
          top: 30,
          bottom: measure?.title ? 65 : 15,
          left: 50,
          right: 50,
        }}
        padding={{ left: 100 }}
        data={dataset}
      >
        <CartesianGrid />
        <XAxis
          dataKey="name"
          interval={
            measure?.title
              ? 0 //"equidistantPreserveStart"
              : dataset?.length >= 7
              ? 1
              : 0
          }
          tick={<CustomizedAxisTick />}
          tickFormatter={(payload, value) =>
            abbreviateName(value, payload).length > 12
              ? abbreviateName(value, payload).slice(0, 12) + "..."
              : abbreviateName(value, payload)
          }
        >
          <Label
            value="Executive Name"
            offset={measure?.title ? -60 : -10}
            position="insideBottom"
          />
        </XAxis>
        <YAxis
          tickFormatter={(value) =>
            abbreviateAndFormatMeasure(value, measure.dataType)
          }
        >
          <Label
            className="yaxis"
            value={
              (measure?.title ? measure.title : "Total Compensation") +
              ` in ${year}`
            }
            offset={-15}
            angle={270}
            position="insideLeft"
          />
        </YAxis>
        <Legend
          layout={measure?.title ? "horizontal" : "vertical"}
          align={measure?.title ? "center" : "right"}
          verticalAlign="top"
          content={measure?.title ? renderCustomPercentileLegend : null}
          // formatter={formatLegend}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: "var(--graph-hover-fill)" }}
        />
        <Bar
          dataKey={!isBoardPay ? "Salary" : "Fees Earned or Paid in Cash"}
          stackId="a"
          fill={"var(--graph-salary)"}
          isAnimationActive={!PDFprocessing}
        />
        {!isBoardPay && (
          <Bar
            dataKey="Bonus"
            stackId="a"
            fill={"var(--graph-bonus)"}
            isAnimationActive={!PDFprocessing}
          />
        )}
        <Bar
          dataKey="Stock Awards"
          stackId="a"
          fill={"var(--graph-stock-awards)"}
          isAnimationActive={!PDFprocessing}
        />
        <Bar
          dataKey="Option Awards"
          stackId="a"
          fill={"var(--graph-option-awards)"}
          isAnimationActive={!PDFprocessing}
        />
        <Bar
          dataKey="Non-Equity Incentive"
          stackId="a"
          fill={"var(--graph-non-equity)"}
          isAnimationActive={!PDFprocessing}
        />
        <Bar
          dataKey="Change in Pension"
          stackId="a"
          fill={"var(--graph-pension)"}
          isAnimationActive={!PDFprocessing}
        />
        <Bar
          dataKey="Other"
          stackId="a"
          fill={"var(--graph-other)"}
          isAnimationActive={!PDFprocessing}
        />
        {measure?.title && (
          <Bar
            dataKey={measure.title}
            stackId="a"
            fill={"var(--secondary)"}
            isAnimationActive={!PDFprocessing}
          >
            {dataset.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={getMeasureFillColor(entry, index)}
              />
            ))}
          </Bar>
        )}
        {showPercenileLines &&
          percentileLines.map((line) => (
            <ReferenceLine
              y={line.amount}
              key={line.percentile}
              stroke="var(--secondary)"
              strokeDasharray="5 3"
              label={
                <Label
                  value={`P${line.percentile}`}
                  offset={-25}
                  position="insideRight"
                />
              }
            />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );

  return (
    <div className="barChart" id="barChart" style={{ maxHeight: 415 }}>
      {dataset?.length > 1 || loaded ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {yearsOptions.length > 0 && (
            <div className="year-filter geography">
              <div
                className="title"
                style={{ height: "60px", marginBottom: "10px" }}
              >
                <h5 style={{ lineHeight: "42px" }}>Year:</h5>
              </div>
              {!PDFprocessing ? (
                <select
                  name="year"
                  onChange={handleYearChange}
                  value={year}
                  style={{ textAlign: "center" }}
                >
                  {yearsOptions}
                </select>
              ) : (
                <span>{year}</span>
              )}
            </div>
          )}
          <AntdTooltip
            overlayInnerStyle={{
              padding: "10px",
              color: "var(--secondary)",
            }}
            color="white"
            title={`Hover over a bar to see more details. ${
              dataset?.length === 40
                ? '"First 40 Executives" will change depending on the page of the table being viewed.'
                : ""
            }`}
            placement="top"
          >
            <div
              className="title above"
              style={{
                cursor: "help",
                marginTop: 25,
                marginBottom: 25,
                marginLeft: measure?.title ? "5%" : "",
              }}
            >
              {measure?.title
                ? measure?.title + " "
                : `${
                    !isBoardPay
                      ? "Executive Compensation Pay Range and"
                      : "Board Pay"
                  } Breakdown `}
              {company ? `for ${company}` : ""}
            </div>
          </AntdTooltip>
          {renderBar}
          {pageContainsExecPageExec && (
            <p style={{ fontSize: 11, marginRight: "470px" }}>
              * Current Executive Page
            </p>
          )}
        </div>
      ) : measure?.title ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexBasis: "100%",
          }}
        >
          <b>Insufficient Data For Bar Graph</b>
        </div>
      ) : (
        <div className="loaderContainer">
          <SyncLoader color={"var(--loader)"} loading={true} size={10} />
        </div>
      )}
    </div>
  );
}
