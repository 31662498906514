import React, { useState, useEffect, useRef, useMemo } from "react";
import Input from "../components/Input/Input";
// import Select from "../components/Select/Select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../css/demo.css";
import { Button } from "../JSS/Button";
import { Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import {
  EMAIL_REGEX,
  PUBLIC_RECAPTCHA_SITE_KEY,
} from "../utils/Constants/systemSetting";
import ReCAPTCHA from "react-google-recaptcha";
import { InlineWidget } from "react-calendly";
import { spawn } from "redux-saga/effects";
import toast from "react-hot-toast";
import UserService from "../services/UserService";

const { Option } = Select;

export default function DemoRequest() {
  const user = useSelector((state) => state.UserReducer);

  const [invalid, setInvalid] = useState(true);
  const [selectKey, setSelectKey] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(
    invalid || captchaRef === null
  );

  useEffect(() => {
    setButtonDisabled(invalid || captchaRef === null);
  }, [captchaRef, invalid]);

  const reRef = useRef(null);

  const [showPhone, setShowPhone] = useState(false);
  const [state, setState] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    companyName: "",
    companyWebsite: "",
    employees: "",
  });
  const handleSelectChange = (value) => {
    const values = { ...state };
    values["employees"] = value;
    let valid = true;

    for (let key in values) {
      if (values[key]?.trim() === "") {
        valid = false;
      }
    }
    setInvalid(!valid);
    setState({ ...values });
  };
  useMemo(() => {
    setState({
      fname: user?.fname || "",
      lname: user?.lname || "",
      email: user?.email || "",
      phone: user?.phone || "",
      companyName: user?.companyName || "",
      companyWebsite: user?.companyWebsite || "",
      employees: user?.employees || "",
    });
    const values = { ...state };
    let valid = true;
    for (let key in values) {
      if (values[key].trim() === "") {
        valid = false;
      }
    }
    setInvalid(!valid);
  }, [user]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const values = { ...state };
    values[name] = value;

    // Adjust autofill for phone, assume US phone (+1-...)
    if (name == "phone" && value.length === 10) {
      values[name] = "1" + value;
    }

    let valid = true;

    if (!EMAIL_REGEX.test(values.email)) {
      valid = false;
    }
    for (let key in values) {
      if (values[key].trim() === "") {
        valid = false;
      }
    }
    setInvalid(!valid);
    setState({ ...values });
  };

  const handleChangePhone = (phone) => {
    const values = { ...state };
    values["phone"] = phone;
    let valid = true;

    for (let key in values) {
      if (values[key].trim() === "") {
        valid = false;
      }
    }
    setInvalid(!valid);
    setState({ ...values });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const token = captchaRef;

      const demoRequestForm = {
        ...state,
        token,
      };

      const { status, data } = await UserService.sendDemoRequestForm(
        demoRequestForm
      );

      setSelectKey(selectKey + 1);
      setShowPhone(false);

      setState({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        companyName: "",
        companyWebsite: "",
        employees: "",
      });
      setCaptchaRef(null);
      reRef.current.reset();
      setInvalid(true);

      if (status && data && status === 200) {
        toast.success(data);
      } else {
        toast.error(
          "Message cannot not be sent right now. Please try again later."
        );
      }
    } catch (err) {
      if (err?.data && err.data.toString().includes("reCapcha")) {
        toast.error(err.data);
      } else {
        toast.error(
          "Message cannot not be sent right now. Please try again later."
        );
      }
    }
  };

  // Check window sizes for calendly widget:
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1200);
  const [isMobile, setMobile] = useState(window.innerWidth < 767);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1200);
    setMobile(window.innerWidth < 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="demo">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 demo-request" style={{ maxWidth: 750 }}>
            <center>
              <h1>Request a Free Demo:</h1>
            </center>
            <div className="row">
              <div className="col-6">
                <Input
                  name="fname"
                  onChange={handleChange}
                  style={{ borderColor: "var(--secondary)" }}
                  value={state.fname}
                  required
                  label="First Name"
                />
              </div>
              <div className="col-6">
                <Input
                  name="lname"
                  onChange={handleChange}
                  style={{ borderColor: "var(--secondary)" }}
                  value={state.lname}
                  required
                  label="Last Name"
                />
              </div>
            </div>

            <Input
              name="email"
              onChange={handleChange}
              style={{ borderColor: "var(--secondary)" }}
              value={state.email}
              required
              label="Email"
            />

            {showPhone ? (
              <div>
                <PhoneInput
                  name="phone"
                  placeholder="Phone Number"
                  country="us"
                  preferredCountries={["us"]}
                  // onlyCountries={["us"]}
                  onChange={(phone) => handleChangePhone(phone)}
                  onBlur={() => (!state.phone ? setShowPhone(false) : "")}
                  value={state.phone}
                  inputProps={{
                    autoFocus: true,
                  }}
                  inputStyle={{
                    borderRadius: "0.5rem",
                    // border: `1px solid ${state.errors.phone}`,
                    borderColor: "var(--secondary)",
                    width: "100%",
                    padding: "0px 12px 0px 48px",
                    fontSize: "1.2em",
                    height: "47px",
                    autoFocus: true,
                  }}
                  containerStyle={{ marginTop: "15px" }}
                />
              </div>
            ) : (
              <Input
                name="phone"
                type="text"
                onChange={handleChange}
                style={{ borderColor: "var(--secondary)" }}
                value={state.phone}
                required
                label="Phone Number"
                onFocus={() => {
                  setShowPhone(true);
                }}
              />
            )}

            <Input
              name="companyName"
              onChange={handleChange}
              style={{ borderColor: "var(--secondary)" }}
              value={state.companyName}
              required
              label="Company Name"
            />

            <Input
              name="companyWebsite"
              onChange={handleChange}
              style={{ borderColor: "var(--secondary)" }}
              value={state.companyWebsite}
              required
              label="Company Website"
            />
            <div className="select-container">
              <Select
                name="employees"
                className="antd-select"
                size="large"
                value={state.employees || null}
                key={selectKey}
                placeholder="Number of Employees"
                onChange={handleSelectChange}
                style={{ width: "100%", marginTop: 12 }}
                bordered={false}
                // dropdownStyle={{ padding: "-50px" }}
              >
                <Option key="1" value="1-10">
                  1-10 employees
                </Option>
                <Option key="2" value="10-100">
                  10-100 employees
                </Option>
                <Option key="3" value="100-1000">
                  100-1000 employees
                </Option>
                <Option key="4" value="1000+">
                  1000+ employees
                </Option>
              </Select>
            </div>

            <div
              className="captchaContainer"
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "12px 0 12px",
              }}
            >
              <ReCAPTCHA
                sitekey={PUBLIC_RECAPTCHA_SITE_KEY}
                size="normal"
                onChange={(e) => setCaptchaRef(e)}
                ref={reRef}
              />
            </div>

            <p className="pb-3" style={{ textAlign: "center" }}>
              By continuing, you are agreeing to our{" "}
              <a href="/privacy">Privacy Policy</a>,{" "}
              <a href="/terms">Terms of Use</a>, and to recieve occasional
              emails from our team.
            </p>

            <Tooltip
              overlayStyle={{ maxWidth: "345px" }}
              placement="right"
              overlayInnerStyle={{
                borderRadius: "5px",
                color: "var(--white)",
                backgroundColor: "var(--dark-red)",
              }}
              title={
                invalid
                  ? "Please fill out all fields."
                  : captchaRef === null
                  ? "Please complete the reCAPTCHA above."
                  : ""
              }
            >
              <div
                style={{
                  cursor: buttonDisabled ? "not-allowed" : "auto",
                  visibility: buttonDisabled ? "visible" : "hidden",
                }}
              >
                <Button
                  disabled={buttonDisabled}
                  style={{
                    pointerEvents: buttonDisabled ? "none" : "auto",
                    visibility: "visible",
                    marginTop: 0,
                  }}
                  primary
                  onClick={handleSubmit}
                >
                  Get Your Demo
                </Button>
              </div>
            </Tooltip>
          </div>
          <div
            className={"calendly-wrapper" + isDesktop ? "col-xl-6" : "col-md-6"}
          >
            <InlineWidget
              url="https://calendly.com/csuitecomp"
              styles={{
                height: isDesktop ? "550px" : isMobile ? "550px" : "800px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
