import { CSuiteBaseService } from "./CSuiteBaseServices";

class PaymentService extends CSuiteBaseService {
  createCustomer = (userAndPayment) => {
    return this.post("user/sign-up", userAndPayment);
  };
  resubscribeCustomer = (payment) => {
    return this.post(`payment/create-customer`, payment);
  };
}

export default new PaymentService();
