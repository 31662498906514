import React, { useState, useEffect, useMemo } from "react";
import html2canvas from "html2canvas";
import { usePDF } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Font,
  Image,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";
import Arimo from "../assets/Arimo/static/Arimo-Regular.ttf";
import ArimoBold from "../assets/Arimo/static/Arimo-Bold.ttf";
import { useSelector } from "react-redux";
const CSuiteLogoPath = "/logos/CSuiteLogo(R)_NoSlogan.png";

/* 
To use this component:
1. Set ids for each component that needs to be exported (e.g. "executive-table")
2. Set array of arrays of elements that need to be exported (each array is a separate page)
  (e.g. [["executive-table"],["executive-graph"]]
3. (optional) Use "setPDFprocessing" with "PDFprocessing" (useState) to edit elements on page for PDF export (e.g. UI elements)
*/

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    display: "flex",
    flexWrap: "wrap",
    fontSize: 14,
    // textAlign: "center",
  },
  section: {
    margin: 15,
    marginTop: 0,
    padding: 15,
    alignItems: "center",
    flexGrow: 1,
    maxHeight: "85%",
  },
  logoSection: {
    alignItems: "left",
    flexGrow: 1,
    width: "100vw",
    marginTop: 15,
    marginLeft: 30,
  },
  logo: {
    width: 90,
  },
  image: {
    // marginBottom: 25,
  },
  footer: {
    fontSize: 8,
    fontFamily: "Arimo",
    position: "absolute",
    // distance of bottom:0 does not work, so this is the bottom of an A4 page in mm
    top: "255mm",
    left: 0,
    right: 0,
    textAlign: "center",
    // Full width, minus the padding of "section", and text and/or graphs don't show up unless part of "section"
    width: "94.5vw",
  },
});

export default function ExportPDFButton({
  elementIDgroups = [[]],
  setPDFprocessing = () => null,
}) {
  const user = useSelector((state) => state.UserReducer);

  const [pageElements, setPageElements] = useState([]);

  const [processing, setProcessing] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [updatePDF, setUpdatePDF] = useState(false);

  Font.register({
    family: "Arimo",
    src: Arimo,
    format: "truetype",
  });
  Font.register({
    family: "ArimoBold",
    src: ArimoBold,
    format: "truetype",
  });

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const PDF = () => {
    const NewPage = ({ elements }) => (
      <Page size="A4" style={styles.page}>
        <View style={styles.logoSection}>
          <Image style={styles.logo} src={CSuiteLogoPath} />
          <Text style={{ ...styles.filterKey, fontSize: 10, marginLeft: 6 }}>
            https://csuitecomp.com
          </Text>
        </View>

        <View style={styles.section}>
          {elements.map((element) => (
            <Image key={element.id} src={element.src} style={element.style} />
          ))}
          <Text style={styles.footer}>
            {`Downloaded by ${user.fname} ${
              user.lname
            } on ${new Date().toLocaleDateString()} for internal use only${
              user?.companyName ? ` by ${user.companyName}.` : "."
            }`}{" "}
            {`\n`}All Rights Reserved. C-Suite Comp<sup>&reg;</sup>
          </Text>
        </View>
      </Page>
    );
    return (
      <Document>
        {pageElements.map((elements, index) => (
          <NewPage key={index} elements={elements} />
        ))}
      </Document>
    );
  };

  const [instance, update] = usePDF({ document: <PDF /> });
  useEffect(() => {
    if (updatePDF && !instance.loading) {
      /* update PDF doc to perform export */
      document.body.style.width = "100%";
      update();
      setUpdatePDF(false);
      setTriggerDownload(true);
      setPageElements([]);
    }
  }, [updatePDF, update, instance]);

  useEffect(() => {
    if (triggerDownload && !instance.loading) {
      const a = document.createElement("a");
      a.href = instance.url;
      a.download = instance.url.split("/").pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTriggerDownload(false);
      setProcessing(false);
    }
  }, [triggerDownload, instance]);

  const [hidePDFbutton, setHidePDFbutton] = useState(false);
  const getPageElements = async (elementIDs) => {
    const newPageElements = [];

    for (const id of elementIDs) {
      const input = document.getElementById(id);
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL("image/png");
      newPageElements.push({ id: id, src: imgData, style: styles.image });
    }
    setHidePDFbutton(false);
    return newPageElements;
  };

  const getAllPageElements = async (elementIDgroups) => {
    const allPageElements = [];
    for (const elementIDs of elementIDgroups) {
      const pageElement = await getPageElements(elementIDs);
      allPageElements.push(pageElement);
    }
    return allPageElements;
  };

  const handleDownloadPDF = async () => {
    // document.body.style.width = "1800px";
    /* Allow browser time to re-render page for formatting, may need to adjust: */
    await sleep(1000);
    setProcessing(true);
    setHidePDFbutton(true);

    const allPageElements = await getAllPageElements(elementIDgroups);

    setPageElements(allPageElements);
    setUpdatePDF(true);
  };

  useMemo(() => {
    if (processing) {
      setPDFprocessing(true);
    } else {
      setPDFprocessing(false);
    }
  }, [processing, setPDFprocessing]);

  if (hidePDFbutton) {
    return <div></div>;
  }
  if (updatePDF || instance.loading || triggerDownload || processing) {
    return <div>Processing PDF...</div>;
  }

  if (instance.error) return <div>Something went wrong: {"error"}</div>;

  return (
    <button
      onClick={() => {
        handleDownloadPDF();
      }}
    >
      <div>Export&nbsp;Page&nbsp;as&nbsp;PDF</div>
    </button>
  );
}
