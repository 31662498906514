import { CSuiteBaseService } from "./CSuiteBaseServices3";

class CompanyService extends CSuiteBaseService {
  disclosureSearch = (cik, search, token) => {
    return this.post(
      `company/disclosure-search?cik=${cik}&token=${token}`,
      search
    );
  };

  runHorseRace = (searchParams, token) => {
    return this.post(`company/run-horse-race?token=${token}`, searchParams);
  };

  runOptimisticHorseRace = (searchParams, token) => {
    return this.post(`company/run-horse-race?token=${token}`, searchParams);
  };
}

export default new CompanyService();
