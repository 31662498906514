import {
  SET_FIRST_FILTER,
  SET_SECOND_FILTER,
  TOGGLE_COMPARISON_FILTER
} from "../constants/ComparisonTableConstant";

export const setFirstFilter = filter => ({
  type: SET_FIRST_FILTER,
  filter
});

export const setSecondFilter = filter => ({
  type: SET_SECOND_FILTER,
  filter
});

export const toggleComparisonFilter = filter => ({
  type: TOGGLE_COMPARISON_FILTER,
  filter
});
