import React from "react";
import { Link } from "react-router-dom";
import { LinkButton } from "../JSS/LinkButton";
import "../css/about.css";
import { executivesTotal } from "../utils/Constants/executiveCount.js";
import { boardMembersTotal } from "../utils/Constants/boardMemberCount.js";
import presentation from "../assets/about/presentation.jpg";
import laptop from "../assets/about/laptop_work.jpg";
import charts from "../assets/about/paper_charts.jpg";
import demoAll from "../assets/mp4/demoAll.mp4";

export default function AboutUs() {
  return (
    <div className="about">
      <div className="container main">
        <div className="container section centered">
          <div className="row">
            <div className="col-md hero centered">
              <h1>
                Easily analyze, slice, dice, and visualize executive pay data
                for thousands of companies and over{" "}
                {(Math.floor(executivesTotal / 1000) * 1000).toLocaleString()}{" "}
                named executives and{" "}
                {(Math.floor(boardMembersTotal / 1000) * 1000).toLocaleString()}{" "}
                board members.
              </h1>
            </div>
            <div className="col-md centered">
              {/* <img
                className="img-fluid circle"
                style={{ marginTop: "2vh" }}
                src={presentation}
                alt="about_image_1"
                loading="lazy"
              /> */}
              <video
                autoPlay
                muted
                loop
                playsInline
                className="img-fluid circle"
                alt="demo_video_1"
                style={{ marginTop: "2vh" }}
                loading="lazy"
              >
                <source src={demoAll} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
          </div>
        </div>
        <div className="container section centered">
          <div className="row">
            <div className="col-md centered ">
              <div className="d-none d-md-block">
                {" "}
                <img
                  className="img-fluid circle .d-none .d-lg-block "
                  src={laptop}
                  alt="about_image_2"
                  loading="lazy"
                />
              </div>
            </div>
            <div className=" col-md centered">
              <p
                className="description"
                style={{
                  fontSize: "min(max(calc(0.55rem + 0.3vw), 0.8rem), 1.15rem)",
                }}
              >
                Trusted by Fortune 500 companies and the largest private firms
                in the US alike, C&#8209;Suite Comp&reg; provides an intuitive
                interface undergirded by a powerful analytic & visualization
                engine for executive compensation professionals to rapidly hone
                in on relevant executive pay data. Whether you are seeking
                aggregate compensation data or want to build your custom peer
                sets and comparator company groups to maximize compensations
                whilst remaining true to your compensation philosophy our
                platform makes it super simple to obtain information to advise
                internal and external clients with confidence. The level of
                granularity is second to none. With unlimited querying and
                searches driven by the highest quality data in the market you
                can extract detailed company and executive level analysis with a
                few simple clicks without having to wait on boutique advisory
                firms. All this at a tiny fraction of the cost charged by
                compensation advisory firms. Whether you are looking to keep
                tabs on the executive compensation market, align your incumbent
                C&#8209;Suite’s compensations to market standards, or make
                compensation offers to C-Level candidates, C&#8209;Suite
                Comp&reg; has you covered. To see how our platform can make your
                life as an executive compensation professional much easier{" "}
                <Link to="/contact">contact us</Link> today for a{" "}
                <Link to="/request-demo">live demo</Link>.
              </p>
            </div>
          </div>
        </div>
        <div className="container section centered">
          <div className="row">
            <div
              className="col-md centered CTA"
              style={{
                flexWrap: "wrap",
                alignContent: "center",
                marginBottom: "5vh",
              }}
            >
              <p
                style={{
                  width: "100%",
                  marginBottom: 10,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                Discover all that C&#8209;Suite Comp&reg; has to offer today!
              </p>
              <Link to="/request-demo">
                <LinkButton primary>Request Demo</LinkButton>
              </Link>
            </div>
            <div className="col-md centered">
              {" "}
              <img
                className="img-fluid circle"
                src={charts}
                alt="about_image_3"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
