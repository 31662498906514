export const GET_SUBSCRIPTION_SAGA = "GET_SUBSCRIPTION_SAGA";
export const SET_CUSTOMER_SUBSCRIPTION = "SET_CUSTOMER_SUBSCRIPTION";

export const GET_PAYMENT_METHOD_SAGA = "GET_PAYMENT_METHOD_SAGA";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const RENEW_SUBSCRIPTION = "RENEW_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";

export const UPDATE_PAYMENT_PLAN = "UPDATE_PAYMENT_PLAN";
