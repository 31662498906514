import React, { useEffect, useState } from "react";
import { Button } from "../../JSS/Button";
import ReactCodeInput from "react-code-input";
import { useDispatch } from "react-redux";
import { verifySecretCode } from "../../redux/actions/UserAction";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { resetPasswordRequest } from "../../redux/actions/UserAction";
import { CSuiteVCard } from "../../assets/C_Suite_Comp_AddressBook.js";

export default function EnterVerificationCode({ email }) {
  const [secretCode, setSecretCode] = useState("");

  const [disabled, setDisabled] = useState(true);
  const history = useHistory();

  const dispatch = useDispatch();

  const handleChange = (code) => {
    setSecretCode(code.toUpperCase());
  };

  useEffect(() => {
    if (secretCode.length === 6) {
      setDisabled(false);
      const code = {
        email,
        code: secretCode,
      };
      dispatch(verifySecretCode(code));
    }
  }, [secretCode, email, dispatch]);

  const resetPage = () => {
    history.go(0);
  };

  const [showSpamMessage, setShowSpamMessage] = useState(false);
  const handleVcardClick = () => {
    const vCardContent = `data:text/x-vcard;urlencoded,${CSuiteVCard}`;
    const encodedURI = encodeURI(vCardContent);
    const a = document.createElement("a");
    a.setAttribute("href", encodedURI);
    a.setAttribute("download", `C-Suite Comp.vcf`);
    a.click();
    a.remove();
  };

  return (
    <div className="login-signup forgot-password">
      <div className="container">
        <div className="background verification-code">
          <div className="overlay-color"></div>
        </div>
        <form className="content">
          <h1>Enter Verification Code</h1>
          <div className="description">
            <p>A verification code has been sent to your email.</p>
            <p> You will be able to change your password in the next step.</p>
          </div>
          <ReactCodeInput
            onChange={handleChange}
            forceUppercase={true}
            type="text"
            fields={6}
          />

          <p
            style={{
              fontSize: "1.1em",
              color: "var(--blue)",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(resetPasswordRequest({ email }));
              setShowSpamMessage(true);
            }}
          >
            Resend verification code
          </p>
          {showSpamMessage && (
            <div style={{ margin: 0 }}>
              <p style={{ margin: 3, fontWeight: "bold" }}>
                Not seeing the email?{" "}
              </p>
              <p style={{ margin: 0 }}>
                Make sure to check your Spam/Junk folder and{" "}
                <p
                  style={{ margin: 0 }}
                  className="link"
                  onClick={() => handleVcardClick()}
                >
                  add us to your contact list.
                </p>
              </p>
            </div>
          )}
          <div className="btn-group">
            <Link
              to="forgot-password"
              onClick={() => {
                resetPage();
              }}
            >
              Go Back
            </Link>
            <Button disabled={disabled} primary>
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
