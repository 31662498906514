import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "../css/cancel.css";
import moment from "moment";
import { Button } from "../JSS/Button";
import {
  getSubscription,
  renewSubscription,
  getPaymentMethod,
} from "../redux/actions/CustomerAction";
import { ACCESS_TOKEN } from "../utils/Constants/systemSetting";

export default function RenewSubscription() {
  const [cancelationState, setCancelationState] = useState("canceled");
  const dispatch = useDispatch();
  const history = useHistory();

  const { subscription, paymentMethod } = useSelector(
    (state) => state.CustomerReducer
  );
  useEffect(() => {
    dispatch(getSubscription());
    dispatch(getPaymentMethod());
  }, [dispatch]);

  function toTitleCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getPlanInterval = (interval) => {
    if (interval === "year") return "annual";
    if (interval === "month") return "month";
    return "loading...";
  };

  return (
    <div className="cancel">
      {cancelationState === "canceled" ? (
        <div className="canceled">
          {" "}
          <h2>Re-Subscribe to C-Suite Comp</h2>
          <p>
            Starting on{" "}
            {moment(subscription?.current_period_end * 1000).format(
              "MMMM DD, YYYY"
            )}
            , your plan will automatically renew and we'll charge you
            {paymentMethod?.brand
              ? `r ${toTitleCase(paymentMethod?.brand)} (ending in ${
                  paymentMethod.last4
                })`
              : ""}{" "}
            ${(subscription?.plan?.amount / 100).toLocaleString()} plus
            applicable taxes every {subscription?.plan?.interval}.
          </p>
          <p style={{ paddingTop: 10 }}>
            You can update your payment method on the{" "}
            <Link to="/membership">Membership</Link> page.
          </p>
          <div className="button-group">
            <p style={{ color: "black" }}>
              By clicking "Re&#8209;Subscribe", you agree to be charged{" "}
              {subscription?.plan?.amount
                ? `$${(
                    subscription?.plan?.amount / 100
                  ).toLocaleString()} ${getPlanInterval(
                    subscription?.plan?.interval
                  )}ly`
                : "the listed price"}{" "}
              (plus tax) on{" "}
              {moment(subscription?.current_period_end * 1000).format(
                "MMMM DD, YYYY"
              )}{" "}
              and your subscription will automatically renew{" "}
              {getPlanInterval(subscription?.plan?.interval)}ly until you cancel
              (price subject to change). Cancel anytime via{" "}
              <Link to="/contact" target="_blank" rel="noopener noreferrer">
                Customer Support.
              </Link>{" "}
              You also agree to the{" "}
              <Link to="/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </Link>{" "}
              and{" "}
              <Link to="/terms" target="_blank" rel="noopener noreferrer">
                Terms of Use.
              </Link>
            </p>
            <br />
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <Link to="/membership">
                    <Button secondary>Back to subscription</Button>
                  </Link>
                </div>
                <div className="col-6">
                  <div className="cancel-btn">
                    <Button
                      onClick={() => {
                        setCancelationState("renewed");
                        const token = localStorage.getItem(ACCESS_TOKEN);
                        dispatch(
                          renewSubscription({
                            token,
                          })
                        );
                      }}
                      primary
                    >
                      Re-Subscribe
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="renewed">
          {" "}
          <h2>Welcome back!</h2>
          <div
            className="button-group"
            style={{ marginTop: 50, color: "black" }}
          >
            <p style={{ color: "black" }}>
              You will not be charged today. Your first recurring charge will be
              on{" "}
              {moment(subscription?.current_period_end * 1000).format(
                "MMMM DD, YYYY"
              )}
              .
            </p>
            <div style={{ paddingTop: 25 }}></div>
            <p style={{ color: "black" }}>
              {" "}
              To stop future charges, turn off recurring billing on the
              membership page before your next billing date.
            </p>
            <br />
            <Link to="/membership">
              <Button primary style={{ width: "50%" }}>
                Back to subscription
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
