import { HIDE_PAYWALL, SET_PAYWALL, SHOW_PAYWALL } from "../constants/PaywallConstant"

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    showPaywall: false,
    allow: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_PAYWALL: {
            return {...state, showPaywall: true};
        }
        case HIDE_PAYWALL: {
            return {...state, showPaywall: false};
        }
        case SET_PAYWALL: {
            return {showPaywall: !action.allow, allow: action.allow}
        }
    default:
        return state
    }
}
