import Axios from "axios";
import { ACCESS_TOKEN, CSUITE_DOMAIN } from "../utils/Constants/systemSetting";

export class CSuiteBaseService {
  get = url => {
    return Axios({
      url: `${CSUITE_DOMAIN}/${url}`,
      method: "GET"
    });
  };

  post = (url, model) => {
    return Axios({
      url: `${CSUITE_DOMAIN}/${url}`,
      method: "POST",
      headers: {
        "Content-Type": "text/plain"
      },
      data: model
    });
  };

  put = (url, model) => {
    return Axios({
      url: `${CSUITE_DOMAIN}/${url}`,
      method: "PUT",
      headers: {
        // "Content-Type": "text/plain",
        Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN)
      },
      data: model
    });
  };
}
