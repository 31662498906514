import React from "react";
import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  STRIPE_KEY_2022,
  STRIPE_KEY_OLD,
} from "../../utils/Constants/systemSetting";

const PUBLIC_KEY = STRIPE_KEY_2022;

const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function StripeContainer(props) {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm
        params={props.params}
        plan={props.plan}
        accountEmail={props.accountEmail}
        setAccountEmail={props.setAccountEmail}
        coupon={props.coupon}
      />
    </Elements>
  );
}
