import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTableFilter } from "../../redux/actions/TableFilterAction";
import SyncLoader from "react-spinners/SyncLoader";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import "../../css/graphs.css";
import { Tooltip as AntdTooltip } from "antd";
import { emdash } from "../../utils/Constants/systemSetting";

export default function BarGraph({
  data,
  delay,
  update,
  setTriggerUpdate,
  currentYear,
  overview = false,
  isBoardPay = false,
}) {
  const dispatch = useDispatch();
  const [minStep, setMinStep] = useState(0);
  const [stepSize, setStepSize] = useState(1000000);
  const [maxStep, setMaxStep] = useState(stepSize * 15);
  const [zoomed, setZoomed] = useState(0);
  const [expanded, setExpanded] = useState(0);
  const { filter } = useSelector((state) => state.TableFilterReducer);
  const [updateFilter, setUpdateFilter] = useState(false);

  useEffect(() => {
    if (updateFilter) {
      dispatch(
        setTableFilter({
          ...filter,
          salary: {
            salaryMin: minStep !== 0 ? minStep.toString() : "",
            salaryMax: zoomed ? (maxStep + stepSize).toString() : "",
          },
          // immediateUpdate: true,
        })
      );
      setUpdateFilter(false);
      setTriggerUpdate(true);
    }
  }, [
    updateFilter,
    minStep,
    stepSize,
    maxStep,
    zoomed,
    filter,
    dispatch,
    setTriggerUpdate,
  ]);

  const resetBarChart = () => {
    setMinStep(0);
    setStepSize(isBoardPay ? 1e5 : 1000000);
    setMaxStep(isBoardPay ? 1000000 : 15 * 1000000);
    setZoomed(0);
    setExpanded(0);
  };

  useEffect(() => {
    resetBarChart();
  }, [isBoardPay]);

  useMemo(() => {
    if (
      (minStep >= 15000000 || zoomed) &&
      (Number(filter.salary.salaryMin) !== Number(minStep) ||
        (filter.salary.salaryMax &&
          Number(filter.salary.salaryMax - stepSize) !== Number(maxStep)))
    ) {
      resetBarChart();
    }
    // ignore missing dependencies, selective state update:
  }, [filter]);
  const [currentLabel, setCurrentLabel] = useState(0);

  const zoomBarChart = useCallback(() => {
    if (zoomed < 1 && currentLabel === maxStep) {
      setMinStep(currentLabel);
      setMaxStep(currentLabel * 10);
      setStepSize(stepSize * 5);
      setExpanded(expanded + 1);
    } else if ((zoomed < 2 && expanded < 2) || (zoomed < 1 && expanded < 3)) {
      setMinStep(currentLabel);
      setMaxStep(currentLabel + stepSize - stepSize / 10);
      setStepSize(stepSize / 10);
      setZoomed(zoomed + 1);
    } else {
      resetBarChart();
    }
  }, [currentLabel, expanded, zoomed, maxStep, stepSize]);

  const [rawData, setRawData] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // Load executives data //
  const processedData = [];

  useEffect(() => {
    if (!delay && !update) {
      //only update data after triggered delay or update
      setRawData(data);
    }
  }, [data, delay, update]);

  useEffect(() => {
    //reset bar chart if selection becomes empty
    if (loaded && rawData?.length === 0) {
      resetBarChart();
    }
  }, [loaded, rawData]);

  useEffect(() => {
    // update filters on any change to zoom level or graph min/max
    setUpdateFilter(true);
  }, [expanded, zoomed, maxStep, stepSize, minStep]);

  useMemo(() => {
    if (rawData?.length > 0) {
      setLoaded(true);
      setDataset([]);
      const allCompensation = [];
      // generate list of all compenstaions
      for (let d in rawData) {
        allCompensation.push(rawData[d]?.compensation);
      }
      for (let step = minStep; step <= maxStep; step += stepSize) {
        let currentCount = 0;
        let currentSalary = 0;
        let currentBonus = 0;
        let currentStockAward = 0;
        let currentOptionAward = 0;
        let currentNonEquity = 0;
        let currentPension = 0;
        let currentOther = 0;
        let currentTotal = 0;

        for (let c in allCompensation) {
          let total = allCompensation[c]?.[currentYear]?.total || 0;
          let salary = allCompensation[c]?.[currentYear]?.salary || 0;
          let bonus = allCompensation[c]?.[currentYear]?.bonus || 0;
          let stockAward = allCompensation[c]?.[currentYear]?.stockAward || 0;
          let optionAward = allCompensation[c]?.[currentYear]?.optionAward || 0;
          let nonEquity = allCompensation[c]?.[currentYear]?.nonEquity || 0;
          let pension = allCompensation[c]?.[currentYear]?.pension || 0;
          let other = allCompensation[c]?.[currentYear]?.other || 0;
          if (
            (total >= step && total < step + stepSize) ||
            (step === maxStep && total > maxStep + stepSize && !zoomed)
          ) {
            currentCount++;
            currentSalary += salary;
            currentBonus += bonus;
            currentStockAward += stockAward;
            currentOptionAward += optionAward;
            currentNonEquity += nonEquity;
            currentPension += pension;
            currentOther += other;
            currentTotal += total;
          }

          if (parseInt(c) === allCompensation.length - 1) {
            processedData.push({
              name:
                "$" +
                step.toLocaleString() +
                (step === maxStep && !zoomed ? "+" : ""),
              "Number of Executives": currentCount,
              Salary:
                currentTotal === 0
                  ? 0
                  : (currentCount * currentSalary) / currentTotal,
              "Fees Earned or Paid in Cash":
                currentTotal === 0
                  ? 0
                  : (currentCount * currentSalary) / currentTotal,
              Bonus:
                currentTotal === 0
                  ? 0
                  : (currentCount * currentBonus) / currentTotal,
              "Stock Awards":
                currentTotal === 0
                  ? 0
                  : (currentCount * currentStockAward) / currentTotal,
              "Option Awards":
                currentTotal === 0
                  ? 0
                  : (currentCount * currentOptionAward) / currentTotal,
              "Non-Equity Incentive":
                currentTotal === 0
                  ? 0
                  : (currentCount * currentNonEquity) / currentTotal,
              "Change in Pension":
                currentTotal === 0
                  ? 0
                  : (currentCount * currentPension) / currentTotal,
              Other:
                currentTotal === 0
                  ? currentCount
                  : (currentCount * currentOther) / currentTotal,
              Total: currentTotal,
            });
          }
        }
        if (step === maxStep) {
          setDataset(processedData);
        }
      }
    } else setDataset([]);
  }, [rawData, currentYear, minStep, maxStep, stepSize, zoomed, update]);

  let CustomTooltip = ({ active, payload, label }) => {
    useEffect(() => {
      if (active && payload && payload.length) {
        setCurrentLabel(parseInt(label.replace(/\$|,/g, "")));
      }
    });
    if (active && payload && payload.length) {
      // let payloadTotal = Math.round(
      //   payload[0].value +
      //     payload[1].value +
      //     payload[2].value +
      //     payload[3].value +
      //     payload[4].value +
      //     payload[5].value +
      //     payload[6].value
      // );
      let payloadTotal = payload[0].payload?.["Number of Executives"];
      const compensationLegend = (legend, color, value, total) => {
        if (value >= 0) {
          return (
            <p className="desc" style={{ color: color }}>
              {legend}: {Math.round((value * 100) / total)}%
            </p>
          );
        }
      };
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#fff",
            opacity: 0.875,
            padding: "5px",
          }}
        >
          <b>
            <p className="desc">
              Range:{" "}
              {label !== "$" + maxStep.toLocaleString() + "+"
                ? `${label} - ${
                    "$" +
                    (
                      parseInt(label.replace(/\$|,/g, "")) +
                      stepSize -
                      1
                    ).toLocaleString()
                  }`
                : `${label}`}
            </p>
          </b>

          <b>
            <p className="desc">
              Number of Executives:{" "}
              {payload[0].payload?.["Number of Executives"]?.toLocaleString()}
            </p>
          </b>

          {payloadTotal > 0 && (
            <center>
              <b>
                <p className="desc">Average Compensation Breakdown:</p>
                {compensationLegend(
                  "Other",
                  "var(--graph-other)",
                  payload[6].value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Change in Pension",
                  "var(--graph-pension)",
                  payload[5].value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Non-Equity Incentive",
                  "var(--graph-non-equity)",
                  payload[4].value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Option Awards",
                  "var(--graph-option-awards)",
                  payload[3].value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Stock Awards",
                  "var(--graph-stock-awards)",
                  payload[2].value,
                  payloadTotal
                )}
                {compensationLegend(
                  "Bonus",
                  "var(--graph-bonus)",
                  payload[1].value,
                  payloadTotal
                )}
                {compensationLegend(
                  isBoardPay ? "Fees Earned or Paid in Cash" : "Salary",
                  "var(--graph-salary)",
                  payload[0].value,
                  payloadTotal
                )}
              </b>
            </center>
          )}
        </div>
      );
    }

    return null;
  };
  const formatLegend = (value, entry, index) => {
    const { color } = entry;

    return (
      <span style={{ color, fontSize: "0.82em", marginLeft: 0 }}>{value}</span>
    );
  };

  const renderBar = (
    <ResponsiveContainer width="100%" height={325}>
      <BarChart
        margin={{ top: 30, bottom: 30 }}
        padding={{ left: 20 }}
        data={dataset}
      >
        <CartesianGrid />
        <XAxis dataKey="name">
          <Label
            value="Total Annual Compensation"
            offset={-10}
            position="insideBottom"
          />
        </XAxis>
        <YAxis>
          <Label
            className="yaxis"
            value="Number of Executives"
            offset={10}
            angle={270}
            position="insideLeft"
          />
        </YAxis>
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="center"
          formatter={formatLegend}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: "var(--graph-hover-fill)" }}
        />
        <Bar
          dataKey={isBoardPay ? "Fees Earned or Paid in Cash" : "Salary"}
          stackId="a"
          fill={"var(--graph-salary)"}
          onClick={() => {
            zoomBarChart();
          }}
          style={{ cursor: "pointer" }}
        />
        <Bar
          dataKey="Bonus"
          stackId="a"
          fill={"var(--graph-bonus)"}
          onClick={() => {
            zoomBarChart();
          }}
          style={{ cursor: "pointer" }}
        />
        <Bar
          dataKey="Stock Awards"
          stackId="a"
          fill={"var(--graph-stock-awards)"}
          onClick={() => {
            zoomBarChart();
          }}
          style={{ cursor: "pointer" }}
        />
        <Bar
          dataKey="Option Awards"
          stackId="a"
          fill={"var(--graph-option-awards)"}
          onClick={() => {
            zoomBarChart();
          }}
          style={{ cursor: "pointer" }}
        />
        <Bar
          dataKey="Non-Equity Incentive"
          stackId="a"
          fill={"var(--graph-non-equity)"}
          onClick={() => {
            zoomBarChart();
          }}
          style={{ cursor: "pointer" }}
        />
        <Bar
          dataKey="Change in Pension"
          stackId="a"
          fill={"var(--graph-pension)"}
          onClick={() => {
            zoomBarChart();
          }}
          style={{ cursor: "pointer" }}
        />
        <Bar
          dataKey="Other"
          stackId="a"
          fill={"var(--graph-other)"}
          onClick={() => {
            zoomBarChart();
          }}
          style={{ cursor: "pointer" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );

  return (
    <div className="barChart" id="barChart">
      {dataset?.length > 1 || loaded ? (
        <>
          <AntdTooltip
            overlayInnerStyle={{
              padding: "10px",
              color: "var(--secondary)",
            }}
            color="white"
            title="Hover over a bar to see more details. Click on the bar for a drill down."
            placement="top"
          >
            <div
              className="title above"
              style={{ cursor: "help", marginTop: 25 }}
            >
              Executive Compensation Pay Range and Breakdown
            </div>
          </AntdTooltip>
          {renderBar}
        </>
      ) : (
        <div className="loaderContainer">
          <SyncLoader color={"var(--loader)"} loading={true} size={10} />
        </div>
      )}
    </div>
  );
}
