import { ACCESS_TOKEN, USER_INFO } from "../Constants/systemSetting";
import jwt_decode from 'jwt-decode';

class Auth {
    isAuthenticated = () => {
        const token = localStorage.getItem(ACCESS_TOKEN)
        const auth = token !== null ? true : false;
        if (token) {
            const decoded = jwt_decode(token);
            if (Date.now() >= decoded.exp * 1000) {
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(USER_INFO);
                return false;
            }
        }
        return auth;
    }

    isActivated = () => {
        const token = localStorage.getItem(USER_INFO);
        if (token) {
            const decoded = jwt_decode(token);
            return decoded.isVerified;
        }
        return false;
    }
}

export default new Auth();