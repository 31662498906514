import React, { useState, useMemo } from "react";
import { Table, Tooltip } from "antd";
import {
  toDollar,
  dataKeyMap,
  getExecCompensationBreakdown,
  customParseFloat,
  formatMeasure,
  getPercentile,
  hasHoverText,
} from "../../../utils/Constants/systemSetting";
import "../../../css/overview.css";

export default function CohortPercentileCalculator({
  cohortTableSelector,
  cohortExecutives,
  year,
}) {
  const compensationTableKeys = useMemo(() => {
    const execTableKeys = [];
    for (const executive of cohortExecutives) {
      if (!executive?.compensation?.[year]) continue;

      for (const key of Object.keys(executive.compensation[year])) {
        if (
          !execTableKeys.includes(key) &&
          key !== "rank" &&
          key !== "undefined" &&
          key !== "originalTotal" &&
          key !== "manuallyEdited" &&
          key !== "sumOfCompensationComponents" &&
          executive.compensation[year][key] > 0
        ) {
          execTableKeys.push(key);
        }
      }
    }
    execTableKeys.sort((a, b) => dataKeyMap[a].order - dataKeyMap[b].order);
    return execTableKeys;
  }, [cohortExecutives, year]);

  const [customPercentile, setCustomPercentile] = useState(0);
  const cohortPercentileColumns = [
    {
      title: () => {
        return (
          <Tooltip title="Enter your desired percentile. The outputted values indicate the compensation components of the cohort group of executives at that percentile level. For e.g. if you enter 80 the outputted values indicate the compensation level below which 80% of the compensations fall for that cohort of executives.">
            {hasHoverText(
              <p>{cohortTableSelector} Compensation Percentile Calculator</p>
            )}
          </Tooltip>
        );
      },
      dataIndex: "type",
      key: "type",
      width: "50%",
    },
    {
      title: () => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "10px" }}>
              <p style={{ whiteSpace: "nowrap", margin: 0 }}>
                Custom Percentile:
              </p>
            </div>
            <input
              className="custom"
              type="number"
              name="custom"
              value={customPercentile}
              step="0.01"
              onChange={(e) => {
                let value = customParseFloat(e.target.value);
                if (value < 0) {
                  value = 0;
                } else if (value > 100) {
                  value = 100;
                }
                setCustomPercentile(value.toLocaleString());
              }}
              onBlur={() =>
                setCustomPercentile(
                  customPercentile === "" ? 0 : customPercentile
                )
              }
              style={{ width: 70, borderRadius: 5 }}
            />
          </div>
        );
      },
      dataIndex: "percentile",
      key: "percentile",
      width: "50%",
    },
  ];

  const sectorCompensationBreakdown = useMemo(() => {
    const compensationBreakdown = getExecCompensationBreakdown(
      cohortExecutives,
      year
    );
    const sortedCompesnationBreakdown = {};
    for (const [key, values] of Object.entries(compensationBreakdown)) {
      const sortedValues = values
        .filter((val) => val !== 0)
        .sort((a, b) => a - b);
      sortedCompesnationBreakdown[key] = sortedValues;
    }
    return sortedCompesnationBreakdown;
  }, [cohortExecutives, year]);

  const cohortPercentileData = compensationTableKeys.map((key) => ({
    key: key,
    type: dataKeyMap[key].title,
    percentile: formatMeasure(
      getPercentile(sectorCompensationBreakdown[key], customPercentile / 100),
      "dollar"
    ),
    style: { fontWeight: key === "total" ? "bold" : "" },
  }));

  const getRowStyle = (record, index) => {
    if (record.key === "total") {
      return "bold-column";
    }
    return "";
  };

  return (
    <div
      className="cohort-percentile-table"
      style={{
        marginBottom: 25,
        marginTop: 0,
        flex: 1,
      }}
    >
      <Table
        columns={cohortPercentileColumns}
        dataSource={cohortPercentileData}
        pagination={false}
        bordered={true}
        size="small"
        rowClassName={getRowStyle}
      />
    </div>
  );
}
