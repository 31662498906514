import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Tooltip } from "antd";
import dayjs from "dayjs";

import { getDailyPrices } from "../../redux/actions/CompanyAction";
import {
  emdash,
  getPercentileFromValue,
  hasHoverText,
} from "../../utils/Constants/systemSetting";

import { OptionsFAQTopics } from "../../assets/faqTopicsOptions";

import CohortSelector from "../ExecutiveOverview/CohortSection/CohortSelector";

import CustomDatePicker from "./utils/CustomDatePicker";
import CustomInput from "./utils/CustomInput";

const reviseTickerMap = {
  GOOG: "GOOGL",
  PARAA: "PARA",
  LBRDK: "LBRDA",
};

export default function PerformanceShareAwardsCalculator({ companyInfo }) {
  const dispatch = useDispatch();
  const dateFormat = "YYYY-MM-DD";

  const [cohortTableSelector, setCohortTableSelector] = useState("");
  const [cohortTableTickers, setCohortTableTickers] = useState([]);

  const [loading, setLoading] = useState(false);

  const { dailyPrices } = useSelector((state) => state.CompanyReducer);

  const revisedCohortTableTickers = useMemo(() => {
    const revisedCohortTableTickers = cohortTableTickers.map((item) => {
      if (Object.keys(reviseTickerMap).includes(item)) {
        return reviseTickerMap[item];
      }
      return item;
    });
    return revisedCohortTableTickers;
  }, [cohortTableTickers]);
  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");

    if (revisedCohortTableTickers?.length) {
      if (!revisedCohortTableTickers.includes(companyInfo.Ticker)) {
        revisedCohortTableTickers.push(companyInfo.Ticker);
      }

      dispatch(getDailyPrices([revisedCohortTableTickers], token));
    }
  }, [revisedCohortTableTickers, companyInfo, dispatch]);

  const filterInput = (input, min, max) => {
    let newInput = input.replace(/[^0-9.]/g, "").slice(0, 15);
    if (newInput.split(".").length > 2) {
      newInput = newInput.split(".").slice(0, 2).join(".");
    }
    if (newInput === "") return "";
    if (newInput >= max) return max;
    if (newInput <= min) return min;
    return newInput;
  };

  const headingWithHoverText = (
    heading,
    hoverText = "",
    value = "",
    fontSize = 18,
    tooltipPlacement = "top"
  ) => {
    return (
      <Tooltip
        title={hoverText}
        overlayClassName="white-link"
        overlayInnerStyle={{ width: fontSize === 18 ? 500 : 250 }}
        placement={tooltipPlacement}
        arrow={{ pointAtCenter: true }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            fontSize: fontSize,
            width: fontSize === 18 ? "10%" : "100%",
            justifyContent: fontSize !== 18 ? "center" : "",
          }}
        >
          <h4 style={{ fontSize: fontSize }}>
            {hasHoverText(
              heading
                .split(" ")
                .map((head, index) => <span key={index}>{head}&nbsp;</span>)
            )}
          </h4>
          {value && (
            <h4 style={{ fontSize: fontSize }}>
              :<b style={{ marginLeft: 10 }}>{value}</b>
            </h4>
          )}
        </div>
      </Tooltip>
    );
  };

  const [performanceAwardsParameters, setPerformanceAwardsParameters] =
    useState({
      startingWindow: {
        label: "Beginning Window",
        value: [
          dayjs("2023-02-28", dateFormat),
          dayjs("2023-03-28", dateFormat),
        ],
        dataType: "Date",
        hoverText: OptionsFAQTopics.performance_beginning_window.short_desc,
      },
      endingWindow: {
        label: "Ending Window",
        value: [
          dayjs("2024-02-28", dateFormat),
          dayjs("2024-03-28", dateFormat),
        ],
        dataType: "Date",
        hoverText: OptionsFAQTopics.performance_ending_window.short_desc,
      },
    });

  const datesAreEqual = (date1, date2) => {
    try {
      // For issues with timezones, use dates without time:
      const formatDate = (date) => {
        return dayjs(new Date(date).toISOString().split("T")[0], dateFormat);
      };

      const formattedDate1 = formatDate(date1);
      const formattedDate2 = formatDate(date2);
      if (Date.parse(formattedDate1) === Date.parse(formattedDate2)) {
        return true;
      }
      return false;
    } catch {
      return false;
    }
  };

  const [startingWindowData, setStartingWindowData] = useState([]);
  const [endingWindowData, setEndingWindowData] = useState([]);
  const [startingWindowAverage, setStartingWindowAverage] = useState("");
  const [endingWindowAverage, setEndingWindowAverage] = useState("");

  const calculatedTSRsforPeerGroup = useMemo(() => {
    if (!revisedCohortTableTickers.every((ticker) => dailyPrices?.[ticker])) {
      setLoading(true);
      return;
    }
    setLoading(false);
    const calculatedTSRsforPeerGroup = {};
    for (const ticker of revisedCohortTableTickers) {
      if (!dailyPrices?.[ticker]) continue;
      // const startDate = Object.keys(dailyPrices?.[ticker]).find((date) =>
      //   datesAreEqual(date, performanceAwardsParameters.startingWindow.value[0])
      // );
      // const endDate = Object.keys(dailyPrices?.[ticker]).find(
      //   (date) =>
      //     Date.parse(date) ===
      //     Date.parse(performanceAwardsParameters.endingWindow.value[1])
      // );
      let startDateStartFound = false;
      let startDateEndFound = false;
      const allLeadInValues = [];
      let endDateStartFound = false;
      let endDateEndFound = false;
      const allLeadOutValues = [];
      // if (startDate && endDate) {
      for (const [date, stockPrice] of [
        ...Object.entries(dailyPrices?.[ticker]),
      ]) {
        if (
          datesAreEqual(
            date,
            performanceAwardsParameters.startingWindow.value[0]
          ) ||
          (startDateStartFound && !startDateEndFound)
        ) {
          if (
            datesAreEqual(
              date,
              performanceAwardsParameters.startingWindow.value[1]
            )
          ) {
            allLeadInValues.push({ Ticker: ticker, date, stockPrice });
            startDateEndFound = true;
          } else {
            allLeadInValues.push({ Ticker: ticker, date, stockPrice });
          }
          startDateStartFound = true;
        }
        if (
          datesAreEqual(
            date,
            performanceAwardsParameters.endingWindow.value[0]
          ) ||
          (endDateStartFound && !endDateEndFound)
        ) {
          if (
            datesAreEqual(
              date,
              performanceAwardsParameters.endingWindow.value[1]
            )
          ) {
            allLeadOutValues.push({ Ticker: ticker, date, stockPrice });
            endDateEndFound = true;
          } else {
            allLeadOutValues.push({ Ticker: ticker, date, stockPrice });
            endDateStartFound = true;
          }
        }
      }

      //TODO: Track this for every company, remap to format into table
      if (ticker === companyInfo.Ticker) {
        setStartingWindowData(allLeadInValues);
        setEndingWindowData(allLeadOutValues);
      }

      const getAverage = (array) =>
        array.reduce((a, b) => a + b, 0) / array.length;
      const leadInAverage = getAverage(
        allLeadInValues.map((item) => parseFloat(item.stockPrice))
      );
      const leadOutAverage = getAverage(
        allLeadOutValues.map((item) => parseFloat(item.stockPrice))
      );
      if (ticker === companyInfo.Ticker) {
        setStartingWindowAverage(leadInAverage);
        setEndingWindowAverage(leadOutAverage);
      }

      const absoluteTSR = (leadOutAverage / leadInAverage) * 100;
      calculatedTSRsforPeerGroup[ticker] = absoluteTSR;
    }
    // }
    return calculatedTSRsforPeerGroup;
  }, [
    revisedCohortTableTickers,
    dailyPrices,
    performanceAwardsParameters,
    companyInfo,
  ]);

  const peerGroupTableColumns = [
    {
      title: "Ticker",
      dataIndex: "Ticker",
      key: "Ticker",
      render: (text, record) =>
        companyInfo.Ticker === record.Ticker ? (
          <b style={{ fontSize: 20 }}>{text}</b>
        ) : (
          <p>{text}</p>
        ),
    },
    {
      title: headingWithHoverText(
        "Absolute TSR",
        OptionsFAQTopics.performance_absolute_tsr.short_desc,
        "",
        12
      ),
      dataIndex: "Absolute TSR",
      key: "Absolute TSR",
      render: (text, record) =>
        companyInfo.Ticker === record.Ticker ? (
          <b style={{ fontSize: 20 }}>{text}</b>
        ) : !record?.["Absolute TSR"] ||
          record?.["Absolute TSR"] === -Infinity ? (
          <p>N/A</p>
        ) : (
          <p>{text}</p>
        ),
    },
    {
      title: "Peer Order",
      dataIndex: "Peer Order",
      key: "Peer Order",
      render: (text, record) =>
        companyInfo.Ticker === record.Ticker ? (
          <b style={{ fontSize: 20 }}>{text}</b>
        ) : !record?.["Absolute TSR"] ||
          record?.["Absolute TSR"] === -Infinity ? (
          <p>{emdash}</p>
        ) : (
          <p>{text}</p>
        ),
    },
    {
      title: headingWithHoverText(
        "rTSR%",
        OptionsFAQTopics.performance_rtsr_percent.short_desc,
        "",
        12
      ),
      dataIndex: "rTSR%",
      key: "rTSR%",
      render: (text, record) =>
        companyInfo.Ticker === record.Ticker ? (
          <b style={{ fontSize: 20 }}>{text}</b>
        ) : !record?.["Absolute TSR"] ||
          record?.["Absolute TSR"] === -Infinity ? (
          <p>{emdash}</p>
        ) : (
          <p>{text}</p>
        ),
    },
  ];

  const abbreviateDateString = (dateString) => {
    try {
      const formattedDateString = new Date(
        dateString.split("T")[0] + "T00:00:00"
      ).toLocaleDateString();
      const baseDate = formattedDateString.split(/\/20..$/)[0];
      const year = formattedDateString.split(/.*\/.*\//)[1];
      const abbreviatedYear = year.substring(2);
      // Return initial input in case of error
      if (!baseDate || abbreviatedYear.length !== 2) {
        return formattedDateString;
      }
      return `${baseDate}/${abbreviatedYear}`;
    } catch {
      return dateString;
    }
  };

  const dailyDataTableColumns = [
    // {
    //   title: "Ticker",
    //   dataIndex: "Ticker",
    //   key: "Ticker",
    //   render: (text, record) =>
    //     companyInfo.Ticker === record.Ticker ? (
    //       <b style={{ fontSize: 13 }}>{text}</b>
    //     ) : (
    //       <p>{text}</p>
    //     ),
    // },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) =>
        companyInfo.Ticker === record.Ticker ? (
          <b style={{ fontSize: 13 }}>{abbreviateDateString(text)}</b>
        ) : (
          <p>{abbreviateDateString(text)}</p>
        ),
      width: 150,
    },
    {
      title: `Daily Price (${companyInfo.Ticker})`,
      dataIndex: "stockPrice",
      key: "stockPrice",
      render: (text, record) =>
        companyInfo.Ticker === record.Ticker ? (
          <b style={{ fontSize: 13 }}>
            {!isNaN(parseFloat(text))
              ? "$" + parseFloat(text).toFixed(2)
              : "N/A"}
          </b>
        ) : (
          <p>
            {" "}
            {!isNaN(parseFloat(text))
              ? "$" + parseFloat(text).toFixed(2)
              : "N/A"}
          </p>
        ),
      width: 200,
    },
  ];

  const peerGroupTSRTableData = useMemo(() => {
    if (!calculatedTSRsforPeerGroup) return;
    const peerGroupTSRTableData = [];
    const allPeerTSRs = Object.values(calculatedTSRsforPeerGroup).sort(
      (a, b) => parseInt(a) - parseInt(b)
    );
    for (const [ticker, TSR] of Object.entries(calculatedTSRsforPeerGroup)) {
      const filteredPeerTSRs = allPeerTSRs.filter(
        (item) => item && !isNaN(item)
      );
      const peerRank = [...filteredPeerTSRs].reverse().indexOf(TSR) + 1;
      const percentile = getPercentileFromValue(filteredPeerTSRs, TSR);
      const newData = {
        Ticker: ticker,
        "Absolute TSR": TSR.toFixed(2),
        "Peer Order": peerRank,
        "rTSR%": percentile.toFixed(2),
      };
      peerGroupTSRTableData.push(newData);
    }
    return peerGroupTSRTableData.sort((a, b) => {
      if (isNaN(a["Absolute TSR"])) {
        a["Absolute TSR"] = -Infinity;
      } else {
        a["Absolute TSR"] = parseFloat(a["Absolute TSR"]);
      }
      if (isNaN(b["Absolute TSR"])) {
        b["Absolute TSR"] = -Infinity;
      } else {
        b["Absolute TSR"] = parseFloat(b["Absolute TSR"]);
      }
      return b["Absolute TSR"] - a["Absolute TSR"];
    });
  }, [calculatedTSRsforPeerGroup]);

  function getCalendarDays(startDate, endDate) {
    function treatAsUTC(date) {
      var result = new Date(date);
      result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
      return result;
    }
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
  }

  const [showEndingWindowData, setShowEndingWindowData] = useState(false);
  const [showStartingWindowData, setShowStartingWindowData] = useState(false);

  return (
    <div>
      <div className="user-options">
        <div
          style={{
            width: "100%",
            display: "flex",
            margin: 15,
          }}
        >
          <CohortSelector
            companyInfo={companyInfo}
            setCohortTableTickers={setCohortTableTickers}
            cohortTableSelector={cohortTableSelector}
            setCohortTableSelector={setCohortTableSelector}
            heading="Comparison Group:"
            removeCustomCohort={true}
            onlyIncludePeerGroups={true}
          />
        </div>
        {Object.entries(performanceAwardsParameters).map(
          ([parameterKey, parameterValues]) => (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              {parameterValues.dataType === "Date" ? (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <CustomDatePicker
                    state={parameterValues.value}
                    setState={(val) =>
                      setPerformanceAwardsParameters((prev) => {
                        const newParams = { ...prev };
                        const prevValue = newParams[parameterKey];
                        newParams[parameterKey] = {
                          ...prevValue,
                          value: val,
                        };
                        return newParams;
                      })
                    }
                    heading={parameterValues.label + ":"}
                    style={{ margin: 15 }}
                    key={parameterKey}
                    isDateRange={true}
                    hoverText={parameterValues.hoverText}
                  />
                  {parameterKey === "startingWindow" && (
                    <div style={{ marginLeft: 50, width: "100%" }}>
                      <h4 style={{ fontSize: 18 }}>
                        Starting Window Trading Days:{" "}
                        <b>{startingWindowData.length}</b>
                      </h4>
                      <h4 style={{ fontSize: 18 }}>
                        Starting Window Calendar Days:{" "}
                        <b>
                          {getCalendarDays(
                            performanceAwardsParameters.startingWindow.value[0],
                            performanceAwardsParameters.startingWindow.value[1]
                          )}
                        </b>
                      </h4>
                      <h4 style={{ fontSize: 18 }}>
                        {headingWithHoverText(
                          `${companyInfo.Ticker} Lead In Average`,
                          OptionsFAQTopics.performance_lead_in_average
                            .short_desc,
                          !isNaN(parseFloat(startingWindowAverage) && !loading)
                            ? "$" + parseFloat(startingWindowAverage).toFixed(2)
                            : "N/A"
                        )}
                      </h4>
                      <Button
                        onClick={() =>
                          setShowStartingWindowData(!showStartingWindowData)
                        }
                        style={{ margin: 10 }}
                      >
                        {showStartingWindowData ? "Hide" : "Show"} Starting
                        Window Daily Prices
                      </Button>
                      {showStartingWindowData && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Table
                            columns={dailyDataTableColumns}
                            dataSource={startingWindowData}
                            pagination={{
                              pageSize: 50,
                              hideOnSinglePage: true,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {parameterKey === "endingWindow" && (
                    <div style={{ marginLeft: 50, width: "100%" }}>
                      <h4 style={{ fontSize: 18 }}>
                        Ending Window Trading Days:{" "}
                        <b>{endingWindowData.length}</b>
                      </h4>
                      <h4 style={{ fontSize: 18 }}>
                        Ending Window Calendar Days:{" "}
                        <b>
                          {getCalendarDays(
                            performanceAwardsParameters.startingWindow.value[0],
                            performanceAwardsParameters.startingWindow.value[1]
                          )}
                        </b>
                      </h4>
                      <h4 style={{ fontSize: 18 }}>
                        {headingWithHoverText(
                          `${companyInfo.Ticker} Lead Out Average`,
                          OptionsFAQTopics.performance_lead_out_average
                            .short_desc,
                          !isNaN(parseFloat(endingWindowAverage) && !loading)
                            ? "$" + parseFloat(endingWindowAverage).toFixed(2)
                            : emdash
                        )}
                      </h4>
                      <Button
                        onClick={() =>
                          setShowEndingWindowData(!showEndingWindowData)
                        }
                        style={{ margin: 10 }}
                      >
                        {showEndingWindowData ? "Hide" : "Show"} Ending Window
                        Daily Prices
                      </Button>
                      {showEndingWindowData && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Table
                            columns={dailyDataTableColumns}
                            dataSource={endingWindowData}
                            pagination={{
                              pageSize: 50,
                              hideOnSinglePage: true,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <CustomInput
                  state={parameterValues.value}
                  setState={(val) =>
                    setPerformanceAwardsParameters((prev) => {
                      val = filterInput(
                        val,
                        parameterValues.min,
                        parameterValues.max
                      );
                      const newParams = { ...prev };
                      const prevValue = newParams[parameterKey];
                      newParams[parameterKey] = { ...prevValue, value: val };
                      return newParams;
                    })
                  }
                  heading={parameterValues.label + ":"}
                  style={{ margin: 15 }}
                  key={parameterKey}
                />
              )}
            </div>
          )
        )}
      </div>
      <Table
        dataSource={peerGroupTSRTableData}
        columns={peerGroupTableColumns}
        pagination={{ pageSize: 50, hideOnSinglePage: true }}
        style={{ margin: 20 }}
        loading={loading}
      />
    </div>
  );
}
