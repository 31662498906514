import {
  RESET_FILTER,
  SET_CSUITE,
  SET_FILTER,
  SET_TABLE_LOAD,
} from "../constants/TableFilterConstant";

export const resetTableFilter = () => ({
  type: RESET_FILTER,
});

export const setTableFilter = (filter) => ({
  type: SET_FILTER,
  filter,
});

export const setFirstTableLoad = (loaded) => ({
  type: SET_TABLE_LOAD,
  loaded,
});

export const setCSuite = (csuite) => ({
  type: SET_CSUITE,
  csuite,
});
