import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "../css/cancel.css";
import moment from "moment";
import { Button } from "../JSS/Button";
import {
  getSubscription,
  renewSubscription,
  getPaymentMethod,
  updateSubscription,
} from "../redux/actions/CustomerAction";
import { ACCESS_TOKEN, STATUS_CODE } from "../utils/Constants/systemSetting";
import { getAllPlans } from "../redux/actions/PlansAction";
import ClipLoader from "react-spinners/ClipLoader";
import PaymentService from "../services/PaymentService";
import toast from "react-hot-toast";
import { HIDE_LOADING, SHOW_LOADING } from "../redux/constants/LoadingConstant";

export default function RenewSubscription() {
  const [cancelationState, setCancelationState] = useState("canceled");
  const dispatch = useDispatch();
  const history = useHistory();

  const { subscription, paymentMethod } = useSelector(
    (state) => state.CustomerReducer
  );

  const user = useSelector((state) => state.UserReducer);

  useEffect(() => {
    dispatch(getSubscription());
    dispatch(getPaymentMethod());
  }, [dispatch]);

  function toTitleCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getPlanInterval = (interval) => {
    if (interval === "year") return "annual";
    if (interval === "month") return "month";
    return "loading...";
  };

  const { plans } = useSelector((state) => state.PlansReducer);
  const [plan, setPlan] = useState("");
  const [sortedPlans, setSortedPlans] = useState([]);
  const [planDetails, setPlanDetails] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [active, setActive] = useState({
    month: "",
    year: "active",
  });

  useEffect(() => {
    setDisabled(!paymentMethod?.brand);
  }, [paymentMethod]);
  useEffect(() => {
    if (!plans.length) {
      dispatch(getAllPlans()); // load plans if they weren't loaded on homepage
    } else {
      if (!sortedPlans.length) {
        setSortedPlans(
          plans
            .filter((plan) => plan.active)
            .filter((plan) => plan.metadata?.displayOnFrontend === "true")
            .sort((a, b) => {
              return a.price - b.price;
            })
        );
      }
    }
  }, [plans, sortedPlans]);

  useEffect(() => {
    if (!plan) {
      setPlan(sortedPlans[sortedPlans.length - 1]?.id);
    }
  }, [plan, sortedPlans]);

  function PaymentPlans() {
    useEffect(() => {
      for (const planDetail of sortedPlans) {
        if (planDetail.id === plan) {
          setPlanDetails(planDetail);
        }
      }
    }, [sortedPlans, plan]);

    const changeActive = (tab) => {
      const values = { ...active };
      for (let key in values) {
        values[key] = "";
      }
      values[tab] = "active";
      setActive({ ...values });
    };

    const renderPayPlan = () => {
      return sortedPlans.map((plan, index) => {
        return (
          <div key={index} className={`col-${12 / Number(sortedPlans.length)}`}>
            <div
              onClick={() => {
                changeActive(plan.interval);
                setPlan(plan.id);
              }}
              className={`${plan.interval} product-item ${
                active[plan.interval]
              }`}
            >
              {plan.interval === "year" && (
                <div className="popular">Best Value</div>
              )}
              <div className="title">
                <h3>
                  {(plan.interval === "month" && "Monthly Plan") ||
                    (plan.interval === "year" && "Annual Plan")}
                </h3>
                <p style={{ marginTop: "-5px" }}>
                  Billed{" "}
                  {(plan.interval === "month" && "monthly") ||
                    (plan.interval === "year" && "yearly")}
                </p>

                <br />

                <p>
                  <span>
                    $
                    {(
                      (plan.interval === "month"
                        ? plan.price
                        : plan.price / 12) / 100
                    ).toLocaleString()}
                  </span>
                  /mo ($
                  {(
                    (plan.interval === "year" ? plan.price : plan.price * 12) /
                    100
                  ).toLocaleString()}
                  /{"yr"})
                </p>
              </div>

              <br />
              <br />

              {/* <img
              src={require("../assets/lock.svg").default}
              style={{ width: "20px", position: "absolute" }}
              alt="lock icon"
            />
            <p style={{ paddingLeft: 30 }}>Secure Transaction</p> */}
              <div className="buttonContainer"></div>
            </div>
          </div>
        );
      });
    };
    return <div className="row">{renderPayPlan()}</div>;
  }

  const handleSubmit = async () => {
    try {
      dispatch({
        type: SHOW_LOADING,
      });
      const token = localStorage.getItem(ACCESS_TOKEN);
      // uid, email, payment_method, plan;
      const uid = user._id;

      const response = await PaymentService.resubscribeCustomer({
        uid,
        email: user.email,
        payment_method: paymentMethod.id,
        plan,
        token,
      });
      if (response.status === STATUS_CODE.SUCCESS) {
        toast.success("Thank you for your subscription!");
        dispatch(updateSubscription({ token, subscription: response.data }));
        history.push("/");
      } else {
        toast.error("Cannot re-subscribe right now. Please try again later.");
      }
    } catch (err) {
      console.log(err?.raw?.code);
      if (err?.response?.data?.raw?.code === "customer_tax_location_invalid") {
        toast.error(
          "Zip code for this payment method is not valid. Please update your payment details on the membership page."
        );
      } else {
        console.log(err?.response?.data || err);
        toast.error("Cannot re-subscribe right now. Please try again later.");
      }
    }
    dispatch({
      type: HIDE_LOADING,
    });
  };

  return (
    <div className="cancel">
      <div className="canceled">
        {" "}
        <h2>Re-Subscribe to C-Suite Comp</h2>
        <div className="change-plan" style={{ padding: "20px" }}>
          {!plan ? (
            <div className="loaderContainer">
              <ClipLoader color={"var(--primary)"} loading={true} size={50} />
            </div>
          ) : (
            <PaymentPlans />
          )}
        </div>
        <br />
        <p style={{ color: "black" }}>
          By clicking "Re&#8209;Subscribe", you agree to be charged{" "}
          {planDetails?.price
            ? `$${(
                planDetails?.price / 100
              ).toLocaleString()} ${getPlanInterval(planDetails?.interval)}ly`
            : "the listed price"}{" "}
          (plus tax) today and that your subscription will automatically renew
          every {planDetails?.interval} until you cancel (price subject to
          change). Cancel anytime via{" "}
          <Link to="/contact" target="_blank" rel="noopener noreferrer">
            Customer Support.
          </Link>{" "}
          You also agree to the{" "}
          <Link to="/privacy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link to="/terms" target="_blank" rel="noopener noreferrer">
            Terms of Use.
          </Link>
        </p>
        <div className="button-group">
          <p style={{ color: "black" }}>
            {!plan
              ? "Loading..."
              : paymentMethod?.brand
              ? `Your ${toTitleCase(paymentMethod?.brand)} (ending in ${
                  paymentMethod.last4
                }) will be charged today.`
              : "You will need to update your payment method before resubscribing."}
          </p>
          <p style={{ paddingTop: 10 }}>
            You can update your payment method on the{" "}
            <Link to="/membership">Membership</Link> page.
          </p>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <Link to="/membership">
                  <Button secondary>Back to subscription</Button>
                </Link>
              </div>
              <div className="col-6">
                <div className="cancel-btn">
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    primary
                    disabled={disabled}
                  >
                    Re-Subscribe
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
