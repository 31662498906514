export const CSuiteVCard = `BEGIN:VCARD
VERSION:3.0
PRODID:-//Apple Inc.//macOS 13.4.1//EN
N:Comp;C-Suite;;;
EMAIL;type=INTERNET;type=WORK;type=pref:noreply@csuitecomp.com
NOTE:C-Suite Comp: Executive Pay Analytics
item1.URL;type=pref:https://csuitecomp.com
item1.X-ABLabel:_$!<HomePage>!$_
X-ABShowAs:COMPANY
END:VCARD`;
