import React, { useState, useEffect } from "react";
import Homepage from "./pages/Homepage";
import "antd/dist/antd.css";
import "./css/styles.css";
import { Switch, useHistory, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import MainTemplate from "./templates/MainTemplate";
import LoginAndSignup from "./pages/LoginAndSignup";
import { useDispatch, useSelector } from "react-redux";
import { SET_HISTORY } from "./redux/constants/HistoryConstant";
import AboutUs from "./pages/AboutUs";
import BoardOfAdvisors from "./pages/BoardOfAdvisors";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import ForgotPassword from "./pages/ForgotPassword";
import Loading from "./components/LoadingScreen/Loading";
import UserSettings from "./pages/UserSettings";
import Product from "./pages/Product";
import Checkout from "./pages/Checkout";
import { GET_USER_IP_SAGA } from "./redux/constants/UserConstant";
import { checkTableUseLimit } from "./redux/actions/UserAction";
import { Toaster } from "react-hot-toast";
import ActivateAccount from "./pages/ActivateAccount";
import ModalHOC from "./HOC/ModalHOC";
import CancelSubscription from "./pages/CancelSubscription";
import RenewSubscription from "./pages/RenewSubscription";
import ReactivateSubscription from "./pages/ReactivateSubscription";
import ChangePlan from "./pages/ChangePlan";
import FAQ from "./pages/FAQ";
import {
  getAllCompanies,
  getAllExecutives,
  getAllMarketCap,
} from "./redux/actions/CompanyAction";
import CompanyOverview from "./pages/CompanyOverview";
import ExecutiveOverview from "./pages/ExecutiveOverview";
import BoardMemberOverview from "./pages/BoardMemberOverview";
import DemoRequest from "./pages/DemoRequest";
import { getSubscription } from "./redux/actions/CustomerAction";
import { store } from "./redux/reducers/configStore";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./pages/notFound";
import { HIDE_LOADING, SHOW_LOADING } from "./redux/constants/LoadingConstant";
import CustomerService from "./services/CustomerService";
import { ACCESS_TOKEN } from "./utils/Constants/systemSetting";
import { getAllPlans } from "./redux/actions/PlansAction";
import Tutorials from "./pages/Tutorials";
import Articles from "./pages/Articles.js";
import ArticlesList from "./pages/ArticlesList.js";

function App() {
  useEffect(() => {
    fetch(`/metadata.json?nocache=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((data) => {
        const version = `${data?.buildMajor}.${data?.buildMinor}.${data?.buildRevision}`;
        // console.log(version, localStorage.getItem("version"));

        if (!localStorage.getItem("version")) {
          localStorage.setItem("version", version);
        }
        // console.log(version, localStorage.getItem("version"));
        if (
          version &&
          localStorage.getItem("version") &&
          localStorage.getItem("version") !== version
        ) {
          localStorage.setItem("version", version);
          window.location.reload();
        }
      });
  }, []);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  // Check if the current URL ends with a slash
  useEffect(() => {
    // Check if the current URL ends with a slash
    if (location.pathname.endsWith("/")) {
      // Remove the trailing slash
      const newUrl = location.pathname.slice(0, -1);
      // Replace the URL without the trailing slash
      history.replace(newUrl);
    }
  }, []); // Run the effect only once, on mount

  let { ip } = useSelector((state) => state.UserReducer);

  const { subscription } = useSelector((state) => state.CustomerReducer);
  const { companies } = useSelector((state) => state.CompaniesReducer);
  const user = useSelector((state) => state.UserReducer);
  const { plans } = useSelector((state) => state.PlansReducer);

  const [subscriptionActive, setSubscriptionActive] = useState(undefined);
  const [subscriptionFetched, setSubscriptionFetched] = useState(false);
  const [subscriptionFetchesAttempted, setSubscriptionFetchesAttempted] =
    useState(0);
  const [redirectAfterLogin, setRedirectAfterLogin] = useState("");
  // const [redirectAfterAdminLogin, setRedirectAfterAdminLogin] = useState("");

  const checkSubscriptionStatus = async () => {
    try {
      const token = localStorage.getItem(ACCESS_TOKEN);
      if (!token) {
        setSubscriptionFetched(true);
        return;
      }
      const { data } = await CustomerService.getSubscription(token);
      //Let throw error to know if fetch attempted, but check for subscription status with useEffect below
    } catch (err) {
      // console.log(err);
      setSubscriptionFetched(true);
      setSubscriptionFetchesAttempted(subscriptionFetchesAttempted + 1);
    }
  };
  useEffect(() => {
    dispatch(getAllPlans()); // load plans if they are not loaded
  }, [dispatch]);

  const [userLoggedIn, setUserLoggedIn] = useState(null);
  useEffect(() => {
    if (user?.email && userLoggedIn === null) {
      setUserLoggedIn(true);
    }
    if (!user?.email && userLoggedIn) {
      setUserLoggedIn(null);
    }
    if (
      userLoggedIn &&
      subscriptionFetched &&
      subscription?.status === undefined &&
      subscriptionFetchesAttempted < 2
    ) {
      setSubscriptionFetched(false);
    }
  }, [
    user,
    userLoggedIn,
    subscriptionFetched,
    subscription,
    subscriptionFetchesAttempted,
  ]);

  useEffect(() => {
    if (!subscriptionFetched) {
      checkSubscriptionStatus();
      dispatch(getSubscription());
    }
    if (subscription?.status !== undefined) {
      setSubscriptionFetched(true);
    }
  }, [subscription, subscriptionFetched, dispatch]);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    dispatch({
      type: SET_HISTORY,
      history,
    });
  }, [history, dispatch]);

  useEffect(() => {
    if (
      ["active", "trialing"].includes(subscription?.status) &&
      !(
        window.navigator.userAgentData?.mobile ||
        window.navigator.userAgent.includes("Mobile")
      )
    ) {
      dispatch({
        type: GET_USER_IP_SAGA,
      });
      /*
      Note that loading depends on companies.length <= 8, as there is placeholder data before companies dispatches.
      TODO: Figure out how to not rely on companies.length. It can only dispatch once, but useEffect also needs to
        keep track of subscriptions. So any subscription updates will cause multiple dispatches which multiply the
        executive count by the number of changes to subscriptions.
      */
      if (Object.keys(companies).length <= 8 && !isFetching) {
        dispatch(getAllCompanies());
        dispatch(getAllExecutives());
        dispatch(getAllMarketCap());
        // dispatch(getAllCSuite());
        setIsFetching(true);
      }
    }
  }, [subscription, companies, dispatch, isFetching]);

  useEffect(() => {
    if (!subscriptionFetched) {
      dispatch({
        type: SHOW_LOADING,
      });
    } else {
      dispatch({
        type: HIDE_LOADING,
      });
    }

    if (["active", "trialing"].includes(subscription?.status)) {
      setSubscriptionActive(true);
    } else {
      setSubscriptionActive(false);
    }
  }, [subscription, subscriptionActive, subscriptionFetched, dispatch]);

  useEffect(() => {
    if (
      user?.email &&
      (location.pathname === "/signup" || location.pathname === "/login")
    ) {
      if (redirectAfterLogin) {
        history.push(redirectAfterLogin);
      } else {
        history.push("/");
      }
      setSubscriptionFetched(false);
      // window.location.reload();
    }
  }, [user, history, redirectAfterLogin, location.pathname]);

  useEffect(() => {
    const redirectIfNotSubscriber = [
      "/profile",
      "/security",
      "/membership",
      "/subscription/renew",
    ];
    const redirectIfNotActiveSubscriber = [
      "/subscription/change-plan",
      "/subscription/cancel",
    ];

    const checkIfRedirectNeeded = (redirectArray) => {
      return redirectArray.some((path) => {
        if (location.pathname.includes(path)) {
          setRedirectAfterLogin(location.pathname);
          return true;
        }
        return false;
      });
    };
    if (subscriptionFetched) {
      if (
        !subscription &&
        !user?.email &&
        checkIfRedirectNeeded(redirectIfNotSubscriber)
      ) {
        history.push("/login");
      }
      if (
        !subscriptionActive &&
        !user?.email &&
        checkIfRedirectNeeded(redirectIfNotActiveSubscriber)
      ) {
        history.push("/login");
      }
      if (
        subscriptionActive &&
        (location.pathname.includes("/subscribe") ||
          location.pathname.includes("/checkout") ||
          location.pathname.includes("/subscription/reactivate"))
      ) {
        history.push("/");
      }
      if (
        user.email &&
        (location.pathname.includes("/subscribe") ||
          location.pathname.includes("/checkout"))
      ) {
        history.push("/subscription/reactivate");
      }
    }
  }, [
    location,
    user,
    subscription,
    subscriptionActive,
    subscriptionFetched,
    redirectAfterLogin,
    history,
  ]);

  const { pathname } = useLocation();

  return (
    <div style={{ userSelect: "none" }}>
      {/* ^^prevent copy paste (copy/paste) on whole site^^ */}
      <ModalHOC />
      <Toaster
        toastOptions={{
          style: { zIndex: 1000 },
        }}
      />

      <>
        <ScrollToTop />
        <Loading />
        {subscriptionFetched && (
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <MainTemplate exact path="/" Component={Homepage} />
            <MainTemplate exact path="/about" Component={AboutUs} />
            <MainTemplate
              exact
              path="/board-of-advisors"
              Component={BoardOfAdvisors}
            />
            <MainTemplate exact path="/contact" Component={ContactUs} />
            {subscription ? (
              <MainTemplate exact path="/profile" Component={UserSettings} />
            ) : (
              <Redirect exact from="/profile" to="/login" />
            )}
            {subscription && (
              <MainTemplate exact path="/security" Component={UserSettings} />
            )}
            {subscription && (
              <MainTemplate exact path="/membership" Component={UserSettings} />
            )}
            <MainTemplate exact path="/subscribe" Component={Product} />
            <MainTemplate exact path="/privacy" Component={PrivacyPolicy} />
            <MainTemplate exact path="/terms" Component={TermsOfUse} />
            <MainTemplate exact path="/faq" Component={FAQ} />
            <MainTemplate exact path="/tutorials" Component={Tutorials} />
            <MainTemplate exact path="/checkout/:id" Component={Checkout} />
            <MainTemplate
              exact
              path="/articles/:year?"
              Component={ArticlesList}
            />

            <MainTemplate
              path="/articles/:year/:articleID/:id?"
              Component={Articles}
            />
            <MainTemplate
              exact
              path="/checkout/:id/:coupon"
              Component={Checkout}
            />
            {subscriptionActive && (
              <MainTemplate
                exact
                path="/company/:id"
                Component={CompanyOverview}
              />
            )}
            {subscriptionActive && (
              <MainTemplate
                exact
                path="/company/:id/:executiveName"
                Component={ExecutiveOverview}
              />
            )}
            {subscriptionActive && (
              <MainTemplate
                exact
                path="/company/:id/board/:boardMemberName"
                Component={BoardMemberOverview}
              />
            )}
            <MainTemplate
              exact
              path="/activate/:id"
              Component={ActivateAccount}
            />
            {subscriptionActive && (
              <MainTemplate
                exact
                path="/subscription/cancel/:id"
                Component={CancelSubscription}
              />
            )}
            {subscriptionActive && (
              <MainTemplate
                exact
                path="/subscription/renew/:id"
                Component={RenewSubscription}
              />
            )}
            {subscriptionActive && (
              <MainTemplate
                exact
                path="/subscription/change-plan/:id"
                Component={ChangePlan}
              />
            )}
            {subscription && !subscriptionActive && (
              <MainTemplate
                exact
                path="/subscription/reactivate"
                Component={ReactivateSubscription}
              />
            )}
            <MainTemplate
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <MainTemplate exact path="/login" component={LoginAndSignup} />
            {/* <MainTemplate exact path="/signup" component={LoginAndSignup} /> */}
            <MainTemplate exact path="/request-demo" component={DemoRequest} />
            <MainTemplate path="*" component={NotFound} />
          </Switch>
        )}
      </>
    </div>
  );
}

export default App;
