import { Link } from "react-router-dom";

export const OptionsFAQTopics = {
  annual_volatility: {
    title: "Annual Volatility",
    short_desc: (
      <p>
        <Link
          to="/faq#annual_volatility_assumptions"
          target="_blank"
          rel="noopener noreferrer"
        >
          This represents the annualized standard deviation of the firm’s stock
          returns, stated as a percentage (e.g., 31.2 and not 0.312). C-Suite
          Comp&reg; is able to estimate historical realized volatility across
          different timeframes as of the Valuation Effective Date. These
          historical values help inform the analyst as to what assumption to use
          for future stock return volatility, a crucial factor in estimating the
          option’s value. Higher volatility assumptions result in higher option
          valuations and vice versa. Click here to see how we estimate return
          volatility for these option pricing models.
        </Link>
      </p>
    ),
  },
  time_to_maturity: {
    title: "Time to maturity",
    short_desc: (
      <p>
        The amount of time (stated in years) the option will be “alive.” Stated
        differently, it is the time between the VED and when the option is
        assumed to be terminated, either by early exercise or arriving at the
        maturity of the option as stated on the option grant. It’s important
        that this maturity assumption not be rounded up or down.
      </p>
    ),
  },
  strike_price: {
    title: "Strike Price",
    short_desc: (
      <p>
        This is the specific price stated in the contract at which the executive
        is entitled to purchase the firm’s stock at some point in the future.
        The Strike Price (sometimes referred to as the Exercise Price) is
        typically set only one time when the option is granted and does not
        change (except for some corporate events, such as a stock split) during
        the option’s life. The strike price is commonly set to the market price
        of the stock upon the date the executive stock option is granted.
      </p>
    ),
  },
  current_price: {
    title: "Current Price",
    short_desc: (
      <p>
        The model assumes the analyst is pricing the option at the close of
        business on a particular day (the Valuation Effective Day, or VED). The
        Current Price should be the closing stock price on that day the
        valuation is assumed to occur. Thus, if one is valuing the option on
        April 9 for a grant which was issued earlier on March 31, and we are
        seeking the value on the grant date, then March 31 is the proper date to
        assume in this exercise. If the valuation is occurring on a weekend, use
        the closing price on the previous business day. However, please seek
        guidance if there is no closing price on the day you are valuing the
        option.
      </p>
    ),
  },
  continuously_compounded_riskfree_rate: {
    title: "Continuously Compounded Risk-free Rate",
    short_desc: (
      <p>
        This is the continuously compounded risk-free rate C-Suite Comp&reg; has
        determined is appropriate for the conditions you have set. We recommend
        you use this assumed rate, However, you may change this rate if you so
        chose.
      </p>
      // <p>
      //   Using the Time to Maturity assumption and the Valuation Effective Date,
      //   C-Suite Comp&reg; automatically locates the appropriate interest rate
      //   (or discount rate) from the yield curve formed from U.S. bills, notes
      //   and bonds yield curve prevailing at that moment in time. That estimate
      //   is the default value observed in this field, however the analyst can
      //   override that value if they so choose. All else the same, higher
      //   discount rates lead to higher option values.
      // </p>
    ),
  },
  valuation_effective_date: {
    title: "Valuation Effective Date",
    short_desc: (
      <p>
        For each option grant, C-Suite Comp&reg; needs to know the date at which
        the valuation is occurring, what we call the Valuation Effective Date
        (or VED). For example, if a grant was issued on September 20, but we
        want to know the valuation of the option on say December 31, then the
        VED would be December 31.
      </p>
    ),
  },
  call_price_estimate: {
    title: "Call Price Estimate",
    short_desc: (
      <p>
        This is the value of the executive stock option as of the effective date
        being assumed for one share of stock. Thus, if the option is priced at
        $37.553 and the grant is for 10,000 shares, then you would multiply
        37.553 by 10,000 for a total grant value of $375,530.
      </p>
    ),
  },
  dividend_paying_stock: {
    title: "Dividend Paying Stock?",
    short_desc: (
      <p>
        Turn this switch ON if the company is currently paying a dividend. You
        will then be asked for the periodicity of the dividend, the dividend per
        share amount, and the ex-dividend date for that dividend payment. If the
        firm has not announced a dividend or has suspended dividend payments,
        leave this switch OFF.
      </p>
    ),
  },
  black_scholes_assumptions: {
    title: "What assumptions underlie the Black-Scholes option pricing model?",
    short_desc: (
      <p>
        <Link
          to="/faq#black_scholes_assumptions"
          target="_blank"
          rel="noopener noreferrer"
        >
          This calculator uses the Black-Scholes pricing formula for
          European-style call options to estimate the value of the executive
          stock option. It relies on five key parameters or assumptions, the
          most important one typically being the return volatility. Click to see
          our FAQs for a more in-depth appreciation of this approach.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        This famous, Nobel-prize-winning model was derived in 1973 for European
        options (options which contractually do not permit early exercise) on
        stocks which do not pay dividends, and which are openly transferable in
        the marketplace. Several of these key characteristics are unfortunately
        violated when applied to executive stock options. Nevertheless, the
        model is a common choice for valuation, as it relies on only five key
        factors. Input those factors and one quickly gets a solution. At the
        time of the model’s introduction, this was revolutionary. These five
        factors are time to maturity, the risk-free discount rate, stock price,
        strike price, and return volatility. The first and last parameters
        require some thoughtful finessing; the middle three are straight
        forward. To handle the possibility of early exercise, firms typically
        assume a term shorter than the contractually stated maturity. An
        estimate of the future volatility is also a critical input to the model.
        Please read the related FAQ on volatility. Dividends also create a minor
        issue and thus force a slight modification to the original Black-Scholes
        model. For dividend paying companies, see the related FAQ on dividends.
      </p>
    ),
  },
  binomial_assumptions: {
    title: "What assumptions underlie the Binomial option pricing model?",
    short_desc: (
      <p>
        <Link
          to="/faq#binomial_assumptions"
          target="_blank"
          rel="noopener noreferrer"
        >
          This tool is also commonly adopted by companies to value executive
          stock options and is an alternative to the Black-Scholes model and
          should produce similar valuations. Click to see our FAQs for a more
          in-depth appreciation of this approach.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        This tool is also commonly adopted by companies to value executive stock
        options and is an alternative to the Black-Scholes model and should
        produce similar valuations. With the Binomial model, one assumes that at
        any moment in time, stock prices can either move “up” or “down” in a
        discrete, binary fashion (thus the title). This process is carried out
        into the future for 300 periods. This creates a complex, expanding
        series of nodes which run for the assumed life of the option. Once fully
        constructed, this approach generates a “sideways” looking tree from
        which we can recursively work backward to determine the value of the
        option grant. The limitations with this approach and its application
        here are similar to those for the Black-Scholes and mimic several of the
        key assumptions underlying that model, particularly that the option is
        European in nature.
      </p>
    ),
  },
  trinomial_assumptions: {
    title: "What assumptions underlie the Trinomial option pricing model?",
    short_desc: (
      <p>
        <Link
          to="/faq#trinomial_assumptions"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Trinomial model, first introduced by Phelim Boyle in 1986, offers
          slightly more appealing flexibility in how the stock price is assumed
          to move over time. Click to see our FAQs for a more in-depth
          appreciation of this approach.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        The Trinomial model, first introduced by Phelim Boyle in 1986, offers
        slightly more appealing flexibility in how the stock price is assumed to
        move over time. Rather than just up or down (as in the binomial), the
        trinomial model allows for sideways price movement, too. This allows for
        better conversion to the Black-Scholes model. What distinguishes
        C-Suite’s application of this model is that it can more realistically
        model key features in option grants such as dynamic early exercise
        patterns, vesting restrictions and black-out dates. These are all key
        corrections to fair value which the Black-Scholes model has difficulty
        dealing with, but which are incorporated into C-Suite’s Trinomial model.
      </p>
    ),
  },
  monte_carlo_assumptions: {
    title: "What assumptions underlie the Monte-Carlo pricing model?",
    short_desc: (
      <p>
        <Link
          to="/faq#monte_carlo_assumptions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Unlike the other models which provide a single, analytical solution,
          the Monte-Carlo approach obtains an estimate of the executive stock
          option’s value via simulation, assuming key assumptions similar to the
          other three models. Click to see our FAQs for a more in-depth
          appreciation of this approach.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        Unlike the other models which provide a single, analytical solution, the
        Monte-Carlo approach obtains an estimate of the executive stock option’s
        value via simulation, assuming key assumptions similar to the other
        three models. Here, C-Suite Comp&reg; executes thousands of trials to
        simulate how the stock price might end up in the future, and how that in
        turn affects the option’s payoff. This approach allows us to control for
        complex, nuanced issues such as early exercise, stochastic volatility
        depending on share price and black-out windows, at a granular level. To
        the extent there is a best tool for obtaining a “fair” value for the
        option, this is perhaps the best. Unfortunately, it may be the most
        difficult approach to explain to your investors in your mandatory
        disclosures. Further, unlike the other three methods which require
        little to only modest computational effort, this method, due to its
        numerous simulations, is computer intensive. Depending on available
        computational horsepower, the analyst may need to be patient while this
        method does its work. Further, because each Monte-Carlos simulation has
        an element of randomness, each execution of this approach (unlike the
        other three methods) will generate a unique solution. As such, the
        analyst should not be surprised to see slight variation from one attempt
        to the next. C-Suite Comp&reg; works to limit this variation such that
        it is de minimis for reporting purposes.
      </p>
    ),
  },
  annual_volatility_assumptions: {
    title:
      "How do we estimate return volatility for these option pricing models?",
    long_desc: (
      <>
        <p>
          Because an executive option has asymmetric payoffs that cannot
          penalize the executive, as assumed volatility increases so will the
          value of the option and thus the valuation which is disclosed in the
          proxy. It is important to understand that even small changes in
          assumed volatility can have material effects on the option’s
          valuation. Most analysts use some measure of historical volatility as
          an estimate for this key parameter. Regrettably though, the “correct”
          value to incorporate is the average future volatility of the stock
          over the option’s assumed life. This is, of course, unobservable
          (unless your firm is fortunate enough to have existing options traded
          in the open-market with maturities similar to the executive stock
          option you are trying to value, something typically not available to
          most analysts).
        </p>
        <p>
          As such, analysts rely on historical realized volatility as an
          estimate of future volatility. As you may see in the plot of your
          company’s trailing past volatility, this parameter is mean reverting
          meaning that in periods of high observed volatility, future volatility
          will revert lower. Conversely, when observed volatility is low, it
          typically reverts to its higher long-run average. We allow the analyst
          to estimate historical volatility over various windows of time in the
          past ranging from six months to ten years. The calculator reports a
          “blended” average volatility as a tool to estimate forward volatility
          by taking the simple average of the various trailing horizons chosen
          by the analyst. This blended-average technique places greater weight
          on volatility observed more recently, while also blending in the
          realized, long-run average. This is helpful for long-lived options
          like executive stock options. Thus, in C-Suite’s volatility
          calculator, if the analyst chooses historical horizons of say
          six-months, one-, three-, five- and ten-years, then this will weigh
          the observed volatility from each respective period at .333, .267,
          .200, .133 and .067. In other words, while the most recent experience
          (the last six-months) is weighted more heavily, other return
          observations from five- and ten-years ago also influence the estimate.
          Changing which retrospective periods are activated will change the
          blended average calculation.
        </p>
        <p>
          Volatility differs across firms for a variety of reasons. For example,
          smaller firms tend to have higher volatility than larger firms.
          Certain industries tend to have comparatively low or high volatility
          compared to firms more generally. If estimating your company’s
          volatility is difficult for some reason, say due to being a young firm
          or being an in-actively traded stock, using the volatility obtained
          from an industry index is not advisable and, all else the same, will
          underestimate the fair value of the executive stock option. By
          construction, these indices act as portfolios which, of course, reduce
          realized risk. Thus, they are a poor representation of the volatility
          of a single stock in that industry. Here, one may need to seek counsel
          on how best to proceed.
        </p>
        <p>
          For further insight on volatility, see our FAQ on{" "}
          <Link to="/faq#weekly_returns_for_volatility">
            "Why does C-Suite Comp&reg; use weekly returns when estimating
            annual volatility?"
          </Link>
        </p>
      </>
    ),
  },
  weekly_returns_for_volatility: {
    title: (
      <h3 style={{ margin: 0 }}>
        Why does C-Suite Comp&reg; use weekly returns when estimating annual
        volatility?
      </h3>
    ),
    short_desc: (
      <p>
        <Link
          to="/faq#weekly_returns_for_volatility"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to see why C-Suite Comp&reg; uses weekly returns when
          estimating annual volatility.
        </Link>
      </p>
    ),
    long_desc: (
      <>
        <p>
          Statisticians often prefer more data points to fewer when estimating
          key parameters. Using annual data to estimate annual return volatility
          sounds logical, but unfortunately leaves the analyst with only a
          handful of observations leading to noisy volatility estimates. At the
          other extreme, daily data provides the analyst with quite a bit of
          data, however daily returns can induce upward bias (thus unnecessarily
          and inappropriately inflating the option’s value). For example,
          suppose during the course of a week a stock were to bounce back and
          forth between say $10 to $11 but at the end of the week settle back at
          $10 where it started. A daily observation window will pick up this
          day-to-day bouncing and infer a meaningful degree of return
          volatility. Yet if this movement is simply mean-reverting noise as we
          see in this case, using weekly time intervals will smooth out this
          upward bias and give us an estimate closer to the true realized,
          historical volatility of the stock. While monthly return data is also
          a possibility, generally speaking we see similar results assuming
          weekly data, with the advantage of more observations with which to do
          our analysis. Weekly data is also beneficial for younger firms who
          have been public for a shorter period of time, and thus would have
          only a limited number of monthly return observations. C-Suite
          Comp&reg; automatically calculates weekly return volatility (inclusive
          of dividends and other corporate actions) as of the Valuation
          Effective Date (VED) so as not to induce look-ahead bias. This weekly
          volatility is then annualized assuming 52 weeks in a year.
        </p>
        <p>
          For further insight on the general nuances of how volatility affects
          option valuation, see our FAQ on{" "}
          <Link to="/faq#annual_volatility_assumptions">
            how to estimate return volatility
          </Link>
          .
        </p>
      </>
    ),
  },
  time_to_maturity_selection: {
    title: "What time to maturity should I use?",
    short_desc: (
      <p>
        <Link
          to="/faq#time_to_maturity_selection"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here for an explanation on how to select time to maturity.
        </Link>
      </p>
    ),
    long_desc: (
      <>
        <p>
          Since executive options typically aren't transferable but do allow for
          early exercise within certain constraints, many analysts infer a
          shorter maturity period compared to the stated life of the grant, such
          as say 8.6 years instead of 10 years. As time passes, the time to
          maturity for a given contract will shrink. It’s important that this
          maturity assumption not be rounded up or down. E.g., if the
          anticipated termination date is 46 months away, one should enter 3.833
          years and not 4.0. Some analysts count the total number of (calendar)
          days to (assumed) maturity and divide by 365.
        </p>
        <p>
          This shorter life has the effect of decreasing the option’s value. As
          time progresses, the analyst may choose not to shorten the time to
          maturity in a linear fashion. For example, suppose an option is
          granted with a three-year vesting period and a contractual expiration
          of ten years. For valuation purposes, the analyst might choose 8.6
          years if past experience indicates executives in the firm tend to
          exercise early. However, if we assume that time has passed and we are
          at year 5, the analyst may choose to extend the remaining life from
          3.6 years (8.5 – 5) to say 4 years given that the option is vested,
          but unexercised. Thus, as the option ages, the time to maturity
          assumed is not necessarily fixed on a particular date; instead to
          mimic reality better, the analyst may model the assumed maturity of a
          particular grant to gradually converge on the contractual expiration
          date over time.
        </p>
        <p>
          Further, over the life of the option, if the stock price falls below
          the strike price as time progresses, the likelihood of early exercise
          also decreases thus suggesting that the implied life of the option
          will move out closer to the contract’s stated maturity.
        </p>
      </>
    ),
  },
  valuation_effective_date_selection: {
    title: "Why do we need to know the Valuation Effective Date (VED)?",
    short_desc: (
      <p>
        <Link
          to="/faq#valuation_effective_date_selection"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to see why Valuation Effective Date (VED) is required.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        In order to obtain the “fair” value of the option on a particular date,
        we need to know what that particular day is. Moreover, our analyst needs
        to only use information up to but not after that point in time. Not
        doing so creates what is known as “look-ahead” bias. Once we know the
        precise VED, we can calculate historical volatilities as of that point
        in time and retrieve prevailing risk-free rates of interest without fear
        of look-ahead bias. These key parameters along with the stock price on
        the VED allows us to accurately measure the value of the option.
      </p>
    ),
  },
  expected_future_dividends_selection: {
    title: "Why do we need to enter expected future dividends?",
    short_desc: (
      <p>
        <Link
          to="/faq#expected_future_dividends_selection"
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here to see why expected future dividends are required if using
          Dividend Adjusted Stock Price.
        </Link>
      </p>
    ),
    long_desc: (
      <>
        <p>
          An executive stock option gives the employee the privilege (but not
          the requirement) of purchasing the firm’s stock at a specific price
          (the strike or exercise price) at some point in the future. However,
          prior to exercising that contract, the employee will not receive any
          dividends which might be paid between the VED and the exercise date.
          This is not the case for shareholders. If they were to purchase the
          stock today, they would receive those future payments which, in turn,
          are thus reflected in the Current Stock Price. This effectively means
          that the share price that shareholders “feel” is above that of what
          the executive economically perceives.
        </p>
        <p>
          Some option calculators correct for dividends by asking the analyst to
          provide the stock’s dividend yield. While plausible in some
          applications, this is unappealing when valuing executive stock options
          as stocks pay discrete dividends. Correcting for dividends via a
          dividend yield assumes that the company’s future dividend payments
          will increase or decrease as the firm’s stock price increases or
          decreases. This is highly unrealistic; cash dividends tend to be
          sticky and are rarely cut, making this assumption unappealing. As
          such, C-Suite Comp&reg; uses a more appealing approach which adjusts
          the current stock price on the VED by deducting the present value of
          these future dividend payments using prevailing interest rates at the
          time. If your company has a regular practice of increasing its
          dividend on a predictable pattern (say every x years), you may wish to
          use that assumption as you fill in the future dividend per share
          amounts. Provide the dividend payment amounts and the ex-dividend
          dates for these future payments. These dividend adjustments are
          important, particularly for long-lived options, as dividends reduce
          the value of the option.
        </p>
      </>
    ),
  },
  current_stock_price: {
    title: "How should we think of the Current Stock Price?",
    short_desc: (
      <p>
        <Link
          to="/faq#current_stock_price"
          target="_blank"
          rel="noopener noreferrer"
        >
          The model assumes the analyst is pricing the option at the close of
          business on a particular day (the Valuation Effective Day, or VED).
          The Current Price should be the closing stock price on that day the
          valuation is assumed to occur. Thus, if one is valuing the option on
          April 9 for a grant which was issued earlier on March 31, and we are
          seeking the value on the grant date, then March 31 is the proper date
          to assume in this exercise. If the valuation is occurring on a
          weekend, use the closing price on the previous business day. However,
          please seek guidance if there is no closing price on the day you are
          valuing the option. Click here to see how Current Stock Price is
          determined, and how to adjust it if your company is affected by
          corporate actions such as stock splits or spin-offs.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        Typically, this is straight forward; you simply take the closing stock
        price on the day of the valuation (the VED). If you are valuing the
        option on a non-business day, use the previous trading day’s closing
        stock price. There should be no adjustments for past or future dividend
        payments; simply take the closing price as published (C-Suite Comp&reg;
        will automatically make these adjustments for you). C-Suite Comp&reg;
        will automatically populate the Current Stock Price for you given the
        VED you supplied. However you can alter that price if, for some reason,
        it is not appropriate. If your stock has experienced a corporate action
        such as a split or spin-off, please read{" "}
        <Link to="faq#corperate_actions">
          “How do I handle Corporate Actions such as stock splits or spin-offs”
        </Link>{" "}
        in the FAQ.
      </p>
    ),
  },
  corperate_actions: {
    title:
      "How do I handle Corporate Actions such as stock splits or spin-offs?",
    long_desc: (
      <p>
        Typically, we never change the exercise price once the option is
        granted. Yet one time when you may need to make an adjustment relates to
        corporate actions which occur after the option was granted, such as a
        stock split, a stock-dividend or a spin-off. You will need to seek
        clarity from the contractual language in the option grant, but most
        executive option grants are adjusted for certain corporate actions.
        Thus, if the company goes through a two-for-one stock split, the
        exercise price will be cut in half. This allows for a fair comparison
        with the prevailing current stock price. For dividend paying stocks, you
        will need to adjust the future dividend payments by half, as well.
      </p>
    ),
  },
  historical_prices_difference: {
    title:
      "Why is that the historical prices we see in the Option Valuation calculators sometimes differ from the historical prices we see in the Performance Share Award calculator?",
    long_desc: (
      <p>
        The option calculators depend on the actual share price we observe at a
        given point in time. Occasionally, we need to alter those prices, but
        generally we don’t. Moreover, the option valuations are “forward”
        looking in nature based on a particular point in time. This contrasts
        with the backward-looking information we need to accurately assess the
        performance share award for our executive. Performance share awards are
        based on past stock performance by attempting to replicate the return an
        investor would have earned if they had invested $100 at the average
        stock price at the beginning of the performance window and sold at the
        average stock price at the end of the window. For firms which do not pay
        dividends and who have no corporate actions, then these prices will be
        the same as what you would see for the option pricing calculators. Of
        course, many firms do pay dividends and occasionally will split their
        shares. Correcting for stock splits and properly incorporating cash
        dividend payments is often times accomplished by retroactively adjusting
        downward the initial stock price. As such, these earlier prices will
        differ from what was actually published, say if you were to look up an
        old copy of the Wall Street Journal.
      </p>
    ),
  },
  ex_ante_rtsr_psa_calculator_assumptions: {
    title: "What assumptions underlie the Ex-Ante rTSR PSA calculator?",
    short_desc: (
      <p>
        <Link
          to="/faq#ex_ante_rtsr_psa_calculator_assumptions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Use this model to provide a “forward-looking” valuation estimate of a
          relative performance share award which is ASC-718 compliant using
          parameters specifically tailored to your firm’s unique situation. This
          can be used to provide a fair value for an rPSA under consideration,
          at grant date or mid-stream while the measurement period is underway.
          Use the other calculator to value an rPSA which has terminated.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        Use this model to provide a “forward-looking” valuation estimate of a
        relative performance share award which is ASC-718 compliant using
        parameters specifically tailored to your firm’s unique situation. This
        can be used to provide a fair value for an rPSA under consideration, at
        grant date or mid-stream while the measurement period is underway. Use
        the other calculator to value an rPSA which has terminated.
      </p>
    ),
  },
  select_performance_mode: {
    title: "How do I choose the performance mode?",
    short_desc: (
      <p>
        <Link
          to="/faq#select_performance_mode"
          target="_blank"
          rel="noopener noreferrer"
        >
          This rTSR PSA uses a complex Monte Carlo technique which can be
          computationally intensive. Therefore, when first starting this task,
          use the "high speed" function to assess validity and reasonableness of
          your parameter choices. Once you are confident that the model is
          correctly specified, switch to the "high precision" approach, but use
          it sparingly. Keep in mind that the complexity of your simulation will
          affect the wait time needed using high precision. For longer-duration
          awards with a large number of peers, the processing time may take
          anywhere from several minutes to several hours to complete. Click to
          learn more.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        This rTSR PSA uses a complex Monte Carlo technique which can be
        computationally intensive. Therefore, when first starting this task, use
        the "high speed" function to assess validity and reasonableness of your
        parameter choices. Once you are confident that the model is correctly
        specified, switch to the "high precision" approach, but use it
        sparingly. Keep in mind that the complexity of your simulation will
        affect the wait time needed using high precision. For longer-duration
        awards with a large number of peers, the processing time may take
        anywhere from several minutes to several hours to complete. Important
        note: This model is driven using the same statistical technology used to
        estimate several problems in physics and in the sciences. It relies on a
        random number generator and replicates thousands of outcomes. As such,
        it is highly unlikely one will obtain the same exact valuation estimate,
        even if the calculator is re-executed with the same parameters due to
        this built-in random feature. In high precision mode, the valuation
        deviations however should be small.
      </p>
    ),
  },
  annual_drift: {
    title: "What is the annual drift?",
    short_desc: (
      <p>
        <Link to="/faq#annual_drift" target="_blank" rel="noopener noreferrer">
          The annual rate of return in the future we will assume your company’s
          stock will earn (i.e., the midpoint of all the randomized
          simulations). The default value in this cell has been tailored to your
          company based on its market cap. Click here to see the related FAQ for
          important details.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        The model needs to know what average growth rate your stock will take in
        the future. This is unknowable, but we call this the annual drift the
        model will assume. Setting this value is important. Setting the drift
        too high will overvalue the contact. Setting it too low will undervalue
        it and will also hypothetically activate the negative payout cap more
        frequently. C-Suite Comp has inserted a default drift rate we feel is
        appropriate for you given your market cap and historical returns over
        the last century, however you can alter it if you so choose. Note that
        the drift we will use for your peer group will be the default values we
        see. Please contact us if you need this customized.
      </p>
    ),
  },
  simulated_ranking_shape: {
    title:
      'Why does the graph of simulated outcomes appear “u"-shaped, “n"-shaped, or perhaps flat?',
    short_desc: (
      <p>
        <Link
          to="/faq#simulated_ranking_shape"
          target="_blank"
          rel="noopener noreferrer"
        >
          Why does the graph of simulated outcomes appear “u"-shaped,
          “n"-shaped, or perhaps flat? Click here to see our related FAQ for
          important details.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        These variations are entirely normal and depend on the firms selected
        for your peer group. To ensure realism, we use the trailing three-year
        statistical return properties realized by your company and its peers.
        For instance, if your firm's volatility is high compared to the peer
        group, it will increase the likelihood of experiencing very high or very
        low relative Total Shareholder Returns (rTSRs). This can cause the graph
        to appear "U"-shaped. Further, if your firm’s volatility is similar to
        its peers and the returns among this grouping are highly correlated,
        this will tend to flatten out the picture you see. These features are
        important to consider so that your valuation is ASC-718 compliant.
      </p>
    ),
  },
  negative_payout_structure: {
    title: "What does the negative payout structure toggle do?",
    short_desc: (
      <p>
        <Link
          to="/faq#negative_payout_structure"
          target="_blank"
          rel="noopener noreferrer"
        >
          Turning this switch activates a share payout cap if the TSR for the
          company is negative. In these negative TSR cases, the share payout is
          limited to the lower of the award schedule (if the firm’s return is
          below Target) or the Target amount even if its TSR relative to its
          peer group is above Target. Turning this switch on lowers the value of
          the contract.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        Turning this switch activates a share payout cap if the TSR for the
        company is negative. In these negative TSR cases, the share payout is
        limited to the lower of the award schedule (if the firm’s return is
        below Target) or the Target amount even if its TSR relative to its peer
        group is above Target. Turning this switch on lowers the value of the
        contract.
      </p>
    ),
  },
  ex_post_rtsr_psa_calculator_assumptions: {
    title: "What assumptions underlie the Ex-Post rTSR PSA calculator?",
    short_desc: (
      <p>
        <Link
          to="/faq#ex_post_rtsr_psa_calculator_assumptions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Use this model to assess how a previous rPSA paid off or, if the
          measurement window is still open, to calculate the firm’s rTSR and how
          it compares to its peers. For mid-stream valuation purposes, the
          returns from this step are inputs into the “forward-looking” valuation
          model needed to value an existing rPSA.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        This module estimates the value of a Performance Stock Unit (PSU) for a
        given company at inception, expiration, or any point in between. Valuing
        these awards is somewhat analogous to a horse race. The value of a PSU
        is driven by the company's total shareholder return (TSR) compared to
        the TSRs of a peer group over a specific time period. The value at
        expiration is determined by the company’s TSR percentile ranking
        relative to its peer group at the end of the grant period, similar to
        how a horse's payoff depends on its finishing position in a race. The
        model uses a Monte Carlo statistical routine with 10,000 replications
        and the variance-covariance matrix of returns estimated from weekly data
        over a trailing three-year period. The analyst can also input implied
        forward-looking volatilities if they prefer and have access to that
        information. Additionally, this model can account for PSUs with Negative
        TSR Cap restrictions, which limit share awards to target levels even if
        performance exceeds the target when total returns over the grant period
        are negative. The model can handle award payments that occur in discrete
        blocks or are linearly adjusted between breakpoints.
      </p>
    ),
  },
  performance_beginning_window: {
    title:
      "What happens if my stock is not traded each day during the beginning or ending window?",
    short_desc: (
      <p>
        <Link
          to="/faq#performance_beginning_window"
          target="_blank"
          rel="noopener noreferrer"
        >
          Choose the beginning dates for which share prices will be averaged.
          These dates are inclusive of the first and last day of the window.
          Given a particular window of days, you will be able to confirm whether
          the resulting number of business or calendar days is appropriate for
          your application. Click to see our FAQs for a more in-depth
          appreciation of this approach.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        For any missing day, C-Suite takes the closing price from the last
        previous, valid date where trading occurred, thus including that value
        twice (or perhaps more) in the average beginning or ending price
        calculation. Click on the “Show Starting Window Daily Prices” to see the
        closing prices being used in this calculation (or do the same for the
        Ending Window). For more information on how C-Suite handles business
        days where the stock did not trade, also read the FAQ related to
        interpreting the rTSR%.
      </p>
    ),
  },

  performance_ending_window: {
    title:
      "What happens if my stock is not traded each day during the beginning or ending window? ",
    short_desc: (
      <p>
        <Link
          to="/faq#performance_beginning_window"
          target="_blank"
          rel="noopener noreferrer"
        >
          The logic here is consistent with the beginning window. The last day
          of the window is typically the last day of the performance award
          period. Click to see our FAQs for a more in-depth appreciation of this
          approach.
        </Link>
      </p>
    ),
  },
  performance_lead_in_average: {
    title:
      "Why does the Lead-in Average Price not exactly match the mean historical closing prices one might have seen in the stock market during that window of time?",
    short_desc: (
      <p>
        <Link
          to="/faq#performance_lead_in_average"
          target="_blank"
          rel="noopener noreferrer"
        >
          Given the window specified above, this is the average “adjusted”
          closing share price for each of the days in your beginning window, a
          value necessary to initiate the total return calculation. Click to see
          our FAQs for a more in-depth appreciation of this approach.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        The mean adjusted price reported here is altered to reflect the returns
        that investors would have earned, adjusted to reflect stock splits,
        stock and cash dividends, and other corporate events. This allows for
        calculation of the total return (assuming the events are implemented on
        their respective ex-dates). As such, it is possible or even likely that
        the lead-in average price will not match the actual historic closing
        prices during the beginning window. The replicating assumption in this
        exercise is that the investors are “purchasing” one share of stock each
        day during the beginning window. Thus if there are 20 days in the
        window, the investor is purchasing 20 shares, one share each day. The
        other possibility for a price deviation is that even though markets were
        open, the stock in question did not trade. Here, C-Suite replaces the
        missing value with the last previous closing price where trading did
        occur. Click on the “Show Beginning Window Daily Prices” to see the
        adjusted closing prices C-Suite is using in this calculation.
      </p>
    ),
  },

  performance_lead_out_average: {
    title:
      "Why does the lead-out average price not exactly match the mean historical closing prices we see in the stock market during that window? ",
    short_desc: (
      <p>
        <Link
          to="/faq#performance_lead_out_average"
          target="_blank"
          rel="noopener noreferrer"
        >
          Given the window specified above, this is the “adjusted” closing share
          price for each of the days in your ending window, a value necessary to
          calculate the absolute TSR. Click to see our FAQs for a more in-depth
          appreciation of this approach.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        The mean adjusted price reported here is again altered to accurately
        reflect the returns that investors would have earned, accounting for
        stock splits, stock and cash dividends, and other corporate events. If
        no such event occurred during this Lead-Out window, the mean of the
        actual, historical closing prices you would see in the stock market
        should match the Lead-Out Average price being report here by C-Suite.
        The replicating assumption in this exercise is that the investors are
        “selling” one share of stock each day (assuming an equal number of
        business days if the beginning and ending windows) during the ending
        window. Click on the “Show Ending Window Daily Prices” to see the
        closing prices C-Suite is using in this calculation.
      </p>
    ),
  },
  performance_absolute_tsr: {
    title: "How do we interpret the Absolute TSR? ",
    short_desc: (
      <p>
        <Link
          to="/faq#performance_absolute_tsr"
          target="_blank"
          rel="noopener noreferrer"
        >
          This is the total return of the stock inclusive of all corporate
          events (such as splits and dividends) assuming the investor bought
          shares at the mean Lead-In Price and sold at the Lead-Out Price for
          the time period of the performance award.Click to see our FAQs for a
          more in-depth appreciation of this approach.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        The Absolute TSR (or Total Shareholder Return) is the total holding
        period return (not adjusted for length), stated in dollars assuming an
        initial $100 investment. Thus a TSR of -20% would be reported as 80. The
        calculation method for each of the peer firms matches that of the target
        firm. Thus, their starting and ending windows match that of the target.
        This approach also assumes dividends and other corporate events are
        “reinvested” on the respective ex-dates, and thus are fairly reflected
        in the Absolute TSR value. For a target firm which might have a missing
        closing price in the beginning or ending window which is then
        substituted with its last valid trading value, this practice of price
        substitution is not repeated for each firm in the Peer Group. As such,
        we do not eliminate the actual closing price of a peer firm just because
        the target firm is missing a price on that particular day. Should a peer
        group firm experience a non-trading day, the same approach applied to
        the target of using its last previous valid closing price is used in the
        calculation.
      </p>
    ),
  },
  performance_rtsr_percent: {
    title: "How do we interpret the rTSR%",
    short_desc: (
      <p>
        <Link
          to="/faq#performance_rtsr_percent"
          target="_blank"
          rel="noopener noreferrer"
        >
          The relative TSR is obtained by sorting the Absolute TSR for the
          target firm and its Peer Group in rank order from highest to lowest.
          These ordinal ranks are converted into a percentile rank, thus the
          label rTSR%. 100 is the firm with the highest Absolute TSR and 0 is
          the lowest.Click to see our FAQs for a more in-depth appreciation of
          this approach.
        </Link>
      </p>
    ),
    long_desc: (
      <p>
        This percentile rank can be interpreted as the percentage of firms in
        the total grouping of stocks (peers and target combined) whose Absolute
        TSR is lower than the value in question. The calculation method for
        determining Absolute TSR for each firm in the grouping is treated the
        same. For a target firm which might have a missing closing price in the
        beginning or ending window which is then substituted with its last valid
        trading value, this practice of price substitution is not repeated for
        each firm in the Peer Group. As such, we do not eliminate the actual
        closing price of a peer firm just because the target firm is missing a
        price on that particular day. Should a peer group firm experience a
        non-trading day, the same approach applied to the target of using its
        last previous valid closing price is used in the calculation. A rating
        of 100 means all other firms are below that particular case. An rTSR of
        0 means all other companies in this grouping have an Absolute TSR great
        than the company in question.
      </p>
    ),
  },
};
