import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NotFound from "./notFound";
import allArticles from "../assets/articles/allArticles.json";
import PuffLoader from "react-spinners/PuffLoader";

const Article = () => {
  const { year, articleID, id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ComponentModule, setComponentModule] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        // Import the JSON data dynamically based on year and id
        const articleData = allArticles[year].find(
          (item) => item.id === articleID
        );

        const { default: DynamicComponent } = await import(
          `../components/Articles/${articleData.content}`
        );
        setComponentModule(() => DynamicComponent); // Set the component state
        setLoading(false);
        setArticle(articleData);
      } catch (error) {
        console.error(error);
        setArticle(null); // Handle error state
        setComponentModule(null);
        setLoading(false);
      }
    };
    fetchArticle();
  }, [year, articleID, id]);

  if (loading) {
    return (
      <div>
        <center
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          <PuffLoader color={"var(--loader)"} loading={loading} size={100} />
        </center>
      </div>
    );
  }

  if (!article) {
    return (
      <div>
        <NotFound />
      </div>
    );
  }

  return (
    <div className="overview container mb-3" style={{ minHeight: "65vh" }}>
      <div
        className="container"
        style={{
          marginTop: "100px",
        }}
      >
        {!id && <h1>{article.title}</h1>}
        <ComponentModule />
      </div>
    </div>
  );
};

export default Article;
