import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Select, Popover, Tooltip, Button } from "antd";
import {
  getBoardPay,
  getBoardPayProfiles,
} from "../redux/actions/CompanyAction";
import {
  boardPayMap,
  formatMeasure,
  emdash,
} from "../utils/Constants/systemSetting";
import BarGraphOverview from "../components/Graphs/BarGraphOverview";
import LineGraph from "../components/Graphs/LineGraph";
import { Link } from "react-router-dom";
import slugifyName from "../utils/slugifyName";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";

export default function BoardPay({ companyInfo, PDFprocessing }) {
  const version = localStorage.getItem("version") || "1.0.0";
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);
  const { boardPay } = useSelector((state) => state.CompanyReducer);
  const { boardPayProfiles } = useSelector((state) => state.CompanyReducer);

  const { companies } = useSelector((state) => state.CompaniesReducer);

  const [yearSelected, setYearSelected] = useState("");

  const [dataPeekLinks, setDataPeekLinks] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker) {
      dispatch(getBoardPay(ticker, token));
    }
  }, [companyInfo, dispatch]);

  const yearsAvailable = useMemo(() => {
    if (!boardPay?.BoardPay) return [];
    setYearSelected("");
    const ticker = companyInfo?.Ticker;
    const token = localStorage.getItem("ACCESS_TOKEN");
    dispatch(getBoardPayProfiles(ticker, token));
    return Object.keys(boardPay?.BoardPay)
      .map((item, index) => ({ value: item, label: item, key: index }))
      .sort((a, b) => parseInt(b.value) - parseInt(a.value));
  }, [boardPay, companyInfo?.Ticker, dispatch]);

  useEffect(() => {
    if (yearsAvailable && yearsAvailable.length && yearsAvailable?.[0]?.value) {
      setYearSelected(yearsAvailable[0].value);
    }
  }, [yearsAvailable]);

  useEffect(() => {
    const dataPeekKeys = companyInfo?.BoardPayDataPeek
      ? Object.keys(companyInfo?.BoardPayDataPeek).reverse()
      : [];

    let reportYearEqualsDataYear = true;
    if (
      dataPeekKeys.length &&
      yearsAvailable.length &&
      yearsAvailable[0] !== dataPeekKeys[0] &&
      yearsAvailable.length > dataPeekKeys.length // means there is missing data, so just display the report years instead of guessing
    ) {
      reportYearEqualsDataYear = false;
    }
    setDataPeekLinks(
      dataPeekKeys.map((year) => {
        const content = (
          <div key={year}>
            {companyInfo.BoardPayDataPeek[year]?.url ? (
              <a
                href={companyInfo.BoardPayDataPeek[year].url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`/boardpay_peek/${companyInfo.BoardPayDataPeek[year].file}?ver=${version}`}
                  style={{ width: "750px" }}
                  alt={`BoardPayDataPeek data for ${companyInfo?.Ticker} ${
                    reportYearEqualsDataYear ? year : year - 1
                  }`}
                />
              </a>
            ) : (
              <img
                src={`/boardpay_peek/${companyInfo.BoardPayDataPeek[year].file}?ver=${version}`}
                style={{ width: "750px" }}
                alt={`BoardPayDataPeek data for ${companyInfo?.Ticker} ${
                  reportYearEqualsDataYear ? year : year - 1
                }`}
              />
            )}
          </div>
        );
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
            key={year}
          >
            <Popover
              // style={{ width: 100 }}
              content={content}
              placement="bottomRight"
              title={
                <>
                  <p>
                    Board Pay DataPeek - {companyInfo?.Ticker}{" "}
                    {reportYearEqualsDataYear ? year : year - 1}
                  </p>
                  {companyInfo.BoardPayDataPeek[year]?.conversion && (
                    <p>
                      Data in this table was converted at a rate of{" "}
                      {companyInfo.BoardPayDataPeek[year].conversion.amount}{" "}
                      {companyInfo.BoardPayDataPeek[year].conversion.type} based
                      on report date{" "}
                      {new Date(
                        companyInfo.BoardPayDataPeek[year].conversion?.date
                      ).toLocaleDateString()}
                    </p>
                  )}
                </>
              }
            >
              <div
                style={{
                  width: 200,
                  cursor: "pointer",
                  paddingRight: "2px",
                  paddingTop: "0px",
                  paddingBottom: "4px",
                  marginTop: "-6px",
                }}
              >
                <p
                  style={{
                    textAlign: "right",
                    color: "var(--blue)",
                  }}
                >
                  DataPeek - {companyInfo?.Ticker}{" "}
                  {reportYearEqualsDataYear ? year : year - 1}
                </p>
              </div>
            </Popover>
          </div>
        );
      })
    );
  }, [companyInfo, version, yearsAvailable]);

  const boardPayData = useMemo(() => {
    if (!boardPay?.BoardPay) return {};
    const filteredBoardPay = {};
    // console.log(boardPay);
    for (const [boardPayYear, boardPayData] of Object.entries(
      boardPay.BoardPay
    )) {
      // console.log(boardPayData);
      const filteredBoardPayData = boardPayData.filter(
        (item) => item?.total >= 0
      );
      filteredBoardPay[boardPayYear] = filteredBoardPayData;
    }
    return filteredBoardPay;
  }, [boardPay]);

  const dataSource = useMemo(() => {
    if (!boardPayData?.[yearSelected]) return [];
    return boardPayData[yearSelected]
      .filter((item) => item?.total >= 0)
      .map((item, index) => {
        const boardMemberData = { key: index };
        Object.keys(boardPayMap).forEach((key) => {
          // Ensure its own ticker/alternate tickers don't appear here (also done in formatter, this is a backup)
          const appearsInTickers =
            typeof item?.appearsIn === "object" &&
            !Array.isArray(item?.appearsIn)
              ? Object.keys(item.appearsIn)
              : item?.appearsIn?.length
              ? item?.appearsIn
              : [];
          const formattedKey =
            key === "name" ? (
              boardPayProfiles?.BoardMembers?.some(
                (member) => slugifyName(member.Name) === slugifyName(item[key])
              ) ? (
                <Link
                  to={`/company/${companyInfo.Ticker}/board/${slugifyName(
                    item[key]
                  )}`}
                >
                  {item[key]}
                </Link>
              ) : (
                item[key]
              )
            ) : key === "count" ? (
              appearsInTickers
            ) : (
              formatMeasure(item[key], "dollar")
            );
          boardMemberData[key] = formattedKey || emdash;
        });
        return boardMemberData;
      });
  }, [boardPayData, boardPayProfiles, companyInfo, yearSelected]);

  const columns = Object.entries(boardPayMap).map(([key, titles]) => ({
    title:
      key === "name" || key === "total" ? (
        <b key={key}>{titles[0]}</b>
      ) : (
        <span key={key}>{titles[0]}</span>
      ),
    dataIndex: key,
    key: key,
    width: key === "count" ? 10 : "",
    render: (item) =>
      key === "count" ? (
        <>
          {item.length ? (
            <Tooltip
              title={
                <div style={{ textAlign: "center" }}>
                  <p>Board Appearances Ticker{item.length > 1 ? "s" : ""}:</p>
                  {item.map((item, index) =>
                    companies[item] ? (
                      <p key={index}>
                        <Link
                          to={`/company/${item}`}
                          style={{ color: "var(--light-blue" }}
                        >
                          {item}
                        </Link>
                      </p>
                    ) : (
                      <p>{item}</p>
                    )
                  )}
                </div>
              }
              overlayClassName="white-link"
            >
              <span style={{ cursor: "help" }}>{item.length}</span>
            </Tooltip>
          ) : (
            <span>{emdash}</span>
          )}
        </>
      ) : key === "name" || key === "total" ? (
        <b>{item}</b>
      ) : (
        <span>{item}</span>
      ),
  }));

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => {
      return dataSource.some((item) => item[col.key] !== emdash);
    });
  }, [columns, dataSource]);

  const yearsOptionsForBarGraph = useMemo(() => {
    return yearsAvailable
      .map((item) => item.value)
      .map((year, index) => (
        <option value={year} key={index}>
          {year}
        </option>
      ));
  }, [yearsAvailable]);

  const hasBoardPayData = useMemo(() => {
    return yearsAvailable
      .map((item) => item.value)
      .some((year) => boardPayData[year].some((member) => member?.name));
  }, [boardPayData, yearsAvailable]);

  const formattedDataForLineGraph = useMemo(() => {
    if (!hasBoardPayData) return {};
    const lineGraphData = {};
    const formattedBoardPayMembers = {};
    for (const [year, boardPayMembers] of Object.entries(boardPayData)) {
      for (const boardPayMember of boardPayMembers) {
        if (!formattedBoardPayMembers?.[boardPayMember?.name]) {
          formattedBoardPayMembers[boardPayMember?.name] = {};
          formattedBoardPayMembers[boardPayMember?.name][year] = boardPayMember;
        } else {
          formattedBoardPayMembers[boardPayMember?.name][year] = boardPayMember;
        }
      }
    }
    const maxYear = Math.max(
      ...yearsAvailable.map((item) => parseInt(item.value))
    );
    const minYear = Math.min(
      ...yearsAvailable.map((item) => parseInt(item.value))
    );
    for (let year = minYear; year <= maxYear; year++) {
      lineGraphData[year] = [];
      for (const [name, data] of Object.entries(formattedBoardPayMembers)) {
        if (data?.[year]) {
          lineGraphData[year].push({ name, compensation: data });
        }
      }
    }
    return lineGraphData;
  }, [boardPayData, yearsAvailable, hasBoardPayData]);

  const boardPayForCSVExport = useCallback(
    (boardPayData) => {
      if (!boardPayData) return;
      const allBoardPayData = [];
      for (const yearObject of yearsAvailable) {
        const year = yearObject.value;
        if (boardPayData?.[year]?.length) {
          allBoardPayData.push(
            ...boardPayData?.[year].map((item) => {
              delete item?.appearsIn;
              delete item?.[year];
              delete item?.[""];
              return { year, ...item };
            })
          );
        }
      }
      const allBoardPayHeaderKeys = [];
      allBoardPayData.forEach((item) => {
        for (const key of Object.keys(item)) {
          if (!allBoardPayHeaderKeys.includes(key)) {
            allBoardPayHeaderKeys.push(key);
          }
        }
      });
      const sortedBoardPayHeaderKeys = allBoardPayHeaderKeys.sort(
        (a, b) =>
          Object.keys(boardPayMap).indexOf(a) -
          Object.keys(boardPayMap).indexOf(b)
      );
      const formattedBoardPayData = allBoardPayData.map((item) => {
        const formattedItem = {};
        sortedBoardPayHeaderKeys.forEach((key) => {
          if (key && key !== "DataPeek") {
            formattedItem[boardPayMap[key][0]] =
              item?.[key] || item?.[key] >= 0 ? item[key] : "";
          }
        });
        return formattedItem;
      });
      return formattedBoardPayData;
    },
    [yearsAvailable]
  );

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>
              Contact your account manager to access Board Pay data and analysis
              on how board members across the nation are being compensated and
              intelligence on interlocking board positions.
            </p>
          </h3>
        </div>
      ) : (
        <>
          <div className="company-calculator-section col-lg-9 executives p-0">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                marginLeft: "20px",
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <h3 style={{ textAlign: "center" }}>Board Pay:</h3>
              </div>
              {!hasBoardPayData ? (
                <p style={{ textAlign: "center" }}>
                  Board Pay is not currently available for{" "}
                  {companyInfo?.Company
                    ? `${companyInfo.Company}${
                        companyInfo.Company.endsWith(".") ? "" : "."
                      }`
                    : "this company."}
                </p>
              ) : (
                <>
                  <div style={{ width: 350, display: "flex" }}>
                    <h3
                      style={{
                        fontSize: 20,
                        marginRight: 10,
                        marginTop: 4,
                        textAlign: "bottom",
                      }}
                    >
                      Select Report Year:{" "}
                    </h3>
                    <Select
                      defaultValue={
                        yearsAvailable?.length ? yearsAvailable?.[0]?.value : ""
                      }
                      style={{ width: 120, marginBottom: 20 }}
                      options={yearsAvailable}
                      onChange={(value) => setYearSelected(value)}
                      value={yearSelected}
                    />
                  </div>
                  {!PDFprocessing && (
                    <div style={{ width: "100%" }}>
                      <Button
                        style={{ marginBottom: 10 }}
                        onClick={() =>
                          exportToCSV(
                            boardPayForCSVExport(boardPayData),
                            `${companyInfo?.Ticker} Board Pay ${
                              new Date().toString().split("GMT-")[0]
                            }.csv`
                          )
                        }
                      >
                        Export All Board Pay Data as CSV
                      </Button>
                    </div>
                  )}
                  <div className="col-md">{dataPeekLinks}</div>
                  <Table
                    dataSource={dataSource}
                    columns={filteredColumns}
                    pagination={{
                      hideOnSinglePage: true,
                      pageSize: 100,
                    }}
                    style={{ width: "100%" }}
                  />
                  <div className="c-table">
                    <div
                      className="graphs"
                      style={{
                        width: "100%",
                        margin: "20px 0 0 0 ",
                        height: 425,
                      }}
                    >
                      <BarGraphOverview
                        data={boardPayData?.[yearSelected]}
                        year={yearSelected}
                        setYear={setYearSelected}
                        yearsOptions={yearsOptionsForBarGraph}
                        company={companyInfo?.Company}
                        isBoardPay={true}
                        PDFprocessing={PDFprocessing}
                      />
                    </div>
                    <div
                      className="graphs"
                      style={{ width: "100%", margin: 0 }}
                    >
                      <LineGraph
                        data={formattedDataForLineGraph}
                        currentYear={yearSelected}
                        company={companyInfo?.Company}
                        isBoardPay={true}
                        PDFprocessing={PDFprocessing}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
