import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Switch } from "antd";

import VolatilityCalculator from "./OptionsCalculators/VolatilityCalculator";
import BlackScholesCalculator from "./OptionsCalculators/BlackScholesCalculator";
import PerformanceShareAwardsCalculator from "./OptionsCalculators/PerformanceShareAwardsCalculator";
import SimulatePeerRankings from "./OptionsCalculators/SimulatePeerRankings";

import { RadioSelector } from "../utils/Constants/systemSetting";
import FinalValue from "./OptionsCalculators/utils/FinalValue";

import { OptionsFAQTopics } from "../assets/faqTopicsOptions";

export default function OptionsCalculators({ companyInfo }) {
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  const [optionsCalculatorOptions, setOptionsCalculatorOptions] = useState([
    "Black-Scholes",
    // "Binomial",
    // "Trinomial",
    "Monte Carlo",
  ]);
  const [optionsCalculatorSelected, setOptionsCalculatorSelected] = useState(
    optionsCalculatorOptions[0] // Set to [2] for Black-Scholes
  );

  const optionsCalculatorOptionsHoverTextMap = {
    "Black-Scholes": OptionsFAQTopics.black_scholes_assumptions.short_desc,
    // Binomial: OptionsFAQTopics.binomial_assumptions.short_desc,
    // Trinomial: OptionsFAQTopics.trinomial_assumptions.short_desc,
    "Monte Carlo": "",
  };

  // Volatility Calculator States
  const [
    lookbackVolatilityBlendedAverage,
    setLookbackVolatilityBlendedAverage,
  ] = useState(null);
  const [calculatingVolatility, setCalculatingVolatility] = useState(true);

  // Black-Scholes Calculator States
  const [showVolatilityEditView, setShowVolatilityEditView] = useState(false);
  const VolatilitySwitch = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <h3 style={{ fontSize: 20 }}>Use Volatility Calculator: </h3>
        <div style={{ height: 38, marginLeft: 10 }}>
          <Switch
            checked={showVolatilityEditView}
            onChange={() => setShowVolatilityEditView((prev) => !prev)}
          />
        </div>
      </div>
    );
  };

  useMemo(() => {
    if (optionsCalculatorSelected !== "Black-Scholes") {
      setShowVolatilityEditView(false);
    }
  }, [optionsCalculatorSelected]);

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>
              Contact your account manager to access the Options Awards
              Calculators which allow you to estimate the value of your
              executive’s stock options. Also access the Performance Share
              Awards calculator which allows you to estimate the value of a
              Performance Stock Unit (PSU) at inception, expiration, or any
              point in between and generate relative TSR percentiles.
            </p>
          </h3>
        </div>
      ) : (
        <>
          <div className="company-calculator-section col-lg-9 executives p-0">
            <RadioSelector
              options={optionsCalculatorOptions}
              state={optionsCalculatorSelected}
              setState={setOptionsCalculatorSelected}
              heading={"Select Options Calculator:"}
              style={{ justifyContent: "center" }}
              optionsHoverTextMap={optionsCalculatorOptionsHoverTextMap}
            />

            {optionsCalculatorSelected === "Black-Scholes" && (
              <BlackScholesCalculator
                companyInfo={companyInfo}
                lookbackVolatilityBlendedAverage={
                  lookbackVolatilityBlendedAverage
                }
                VolatilitySwitch={VolatilitySwitch}
                showVolatilityEditView={showVolatilityEditView}
              />
            )}

            {/* Add calculators to this list as they are added, otherwise display "Coming Soon" message */}
            {![
              "Black-Scholes",
              "Performance Share Awards",
              "Peer Ranking Simulator (beta)",
            ].includes(optionsCalculatorSelected) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 150,
                }}
              >
                <h3>
                  {optionsCalculatorSelected} Options Calculator Coming Soon
                </h3>
              </div>
            )}
          </div>
          <VolatilityCalculator
            companyInfo={companyInfo}
            showVolatilityEditView={showVolatilityEditView}
            lookbackVolatilityBlendedAverage={lookbackVolatilityBlendedAverage}
            setLookbackVolatilityBlendedAverage={
              setLookbackVolatilityBlendedAverage
            }
            calculatingVolatility={calculatingVolatility}
            setCalculatingVolatility={setCalculatingVolatility}
          />
        </>
      )}
    </>
  );
}
