import { HIDE_MODAL, SHOW_MODAL } from "../constants/ModalConstant";

export const showModal = (title, Component) => ({
    type: SHOW_MODAL,
    Component,
    title
})

export const hideModal = () => ({
    type: HIDE_MODAL,
})

