import React from "react";
import { Tabs } from "antd";
import "../css/user-settings.css";
import ProfileInfo from "../components/UserSettings/Profile/ProfileInfo";
import Security from "../components/UserSettings/Security/Security";
import Membership from "../components/UserSettings/Membership/Membership";
import { useLocation, useHistory } from "react-router-dom";

const { TabPane } = Tabs;

export default function UserSettings() {
  const location = useLocation();
  const history = useHistory();

  const defaultLocation = {
    "/profile": "1",
    "/security": "2",
    "/membership": "3",
  };

  const profile = (
    <div className="tab-menu" onClick={() => history.push("/profile")}>
      <i className="fa fa-user-alt"></i>
      <p>Profile Info</p>
    </div>
  );

  const security = (
    <div className="tab-menu" onClick={() => history.push("/security")}>
      <i className="fa fa-lock"></i>
      <p>Security</p>
    </div>
  );

  const membership = (
    <div className="tab-menu" onClick={() => history.push("/membership")}>
      <i className="fa fa-address-card"></i>
      <p>Membership</p>
    </div>
  );

  return (
    <div className="user-settings">
      <Tabs centered activeKey={defaultLocation?.[location.pathname] || "1"}>
        <TabPane tab={profile} key="1">
          <ProfileInfo />
        </TabPane>
        <TabPane tab={security} key="2">
          <Security />
        </TabPane>
        <TabPane tab={membership} key="3">
          <Membership />
        </TabPane>
      </Tabs>
    </div>
  );
}
