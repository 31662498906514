/* eslint-disable import/no-anonymous-default-export */
import EnterEmail from "../../components/ForgotPassword/EnterEmail";
import { SET_FORGOT_PASSWORD_COMPONENT } from "../constants/ForgotPasswordConstant";

const initialState = {
  Component: <EnterEmail />,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FORGOT_PASSWORD_COMPONENT: {
      return { ...state, Component: action.Component };
    }

    default:
      return state;
  }
};
