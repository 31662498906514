import React, { useEffect, useState } from "react";
import "./purchaseHistory.css";
import moment from "moment";
import {
  ACCESS_TOKEN,
  PLAN_NAME,
} from "../../../utils/Constants/systemSetting";
import CustomerService from "../../../services/CustomerService";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

export default function PurchaseHistory({ invoice }) {
  const [expand, setExpand] = useState(false);
  const [state, setState] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const getPlanName = (interval) => {
    // if (interval === "year") return "- Annual Subscription";
    if (interval === "month") return "- Monthly Subscription";
    return "- Annual Subscription"; //default for one-time payment subscribers
  };
  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    CustomerService.getSubscriptionHistory(token, invoice?.subscription)
      .then((res) => {
        setState(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
    CustomerService.getPaymentIntent(token, invoice.payment_intent)
      .then((res) => {
        setPaymentIntent(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [invoice]);

  useEffect(() => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    if (paymentIntent) {
      CustomerService.getPaymentMethodID(token, paymentIntent?.payment_method)
        .then((res) => {
          setPaymentMethod(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  }, [paymentIntent]);

  return (
    <div className="purchase-history">
      <div className="card">
        <div className="detail">
          <i className="d-none d-sm-inline fa fa-receipt"></i>
          <div className="time">
            <p>Ordered on</p>
            <p>{moment(invoice?.period_start * 1000).format("ll")}</p>
          </div>
        </div>
        <div className="order method d-none d-md-block">
          <p>Paid with </p>
          {invoice?.total === 0 && invoice?.discount?.coupon?.id ? (
            <p>Coupon&nbsp;code</p>
          ) : (
            <p>
              {paymentMethod ? (
                <>
                  <Icon icon={`logos:${paymentMethod.brand}`} width="30" />{" "}
                  {"  "}
                  ****
                  {paymentMethod?.last4}
                </>
              ) : (
                ""
              )}
            </p>
          )}
        </div>
        <div className="price">
          <p>Amount</p>
          <p>
            {(invoice?.total / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </p>
        </div>
        <div className="order">
          <p>Order #</p>
          <p>{invoice?.number}</p>
        </div>
        <div
          className="arrow"
          onClick={() => {
            setExpand(!expand);
          }}
        >
          {expand ? (
            <img
              src={require("../../../assets/collapse-arrow.png").default}
              alt="collapse"
            />
          ) : (
            <img
              src={require("../../../assets/expand-arrow.png").default}
              alt="expand"
            />
          )}
        </div>
      </div>

      <div className={`card-expand ${expand ? "d-block" : "d-none"}`}>
        <div className="plan">
          <i className="fa fa-money-check"></i>
          <div className="plan-detail">
            <h3>{`${PLAN_NAME} ${getPlanName(state?.plan?.interval)}`}</h3>
            {invoice.collection_method === "send_invoice" ? (
              <p>
                This subscription was paid through an invoice.{" "}
                <a href={invoice.invoice_pdf}>Receipt (PDF)</a>
              </p>
            ) : invoice?.total === 0 && invoice?.discount?.coupon?.id ? (
              <p>
                This subscription was paid with a coupon code.{" "}
                <a href={invoice.invoice_pdf}>Receipt (PDF)</a>
              </p>
            ) : (
              <p>
                {(state?.plan?.amount / 100).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
                /{state?.plan?.interval} plus applicable taxes
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
