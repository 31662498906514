import Axios from "axios";
import { GEO_DOMAIN } from "../utils/Constants/systemSetting";
import { CSuiteBaseService } from "./CSuiteBaseServices";

class UserService extends CSuiteBaseService {
  //very slow, disabled now.
  // getUserIP = () => {
  //   return null;
  //   return Axios({
  //     url: GEO_DOMAIN,
  //     method: "GET",
  //   });
  // };

  login = (user) => {
    return this.post("user/login", user);
  };

  createNewIP = (ip) => {
    return this.post("ip/create-new-ip", ip);
  };

  checkTableUseLimit = (ip, token) => {
    if (token) {
      return this.get(`ip/check-table-use-limit?ip=${ip}&token=${token}`);
    } else {
      return this.get(`ip/check-table-use-limit?ip=${ip}`);
    }
  };

  setTableUseLimit = (ip) => {
    return this.put("ip/set-table-use-limit", ip);
  };

  signUp = (newUser) => {
    return this.post("user/sign-up", newUser);
  };

  signIn = (user) => {
    return this.post("user/sign-in", user);
  };

  getUserInfo = (token) => {
    return this.get(`user/get-user-info?token=${token}`);
  };

  checkExistingEmail = (email) => {
    return this.get(`user/check-existing-email?email=${email}`);
  };

  resetPasswordRequest = (email) => {
    return this.post(`user/reset-password`, email);
  };

  verifySecretCode = (code) => {
    return this.post("user/verify-secret-code", code);
  };

  resetNewPassword = (newPassword) => {
    return this.post(`user/reset-new-password`, newPassword);
  };

  updateUser = (newUserDetails) => {
    return this.put("user/update-user", newUserDetails);
  };

  updateUserPassword = (newUserDetails) => {
    return this.post("user/update-user-password", newUserDetails);
  };

  activateAccount = (id) => {
    return this.get(`user/activate-account?id=${id}`);
  };

  resendActivationLink = (email) => {
    return this.get(`user/send-activation-link?email=${email}`);
  };

  sendContactForm = (contactForm) => {
    return this.post("contact", contactForm);
  };
  sendDemoRequestForm = (demoRequestForm) => {
    return this.post("requestdemo", demoRequestForm);
  };
}

export default new UserService();
