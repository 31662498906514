import { Radio, Tooltip } from "antd";
import { measureOptions as allMeasureOptions } from "./executiveMeasure";
import { emdash } from "../Constants/systemSetting";

export default function MeasureSelector({
  measure,
  setMeasure,
  setCustomMeasureSelected = () => null,
  heading = "",
  useCustomHeading = false,
  measureOptions = allMeasureOptions,
  tableKey = null,
  setTableKey = () => null,
  showIfDataAvailable = false,
  sortAndColorCodeMeasures = false,
  measureOptionsWithCorrelation = null,
}) {
  const onMeasureChange = (e) => {
    setMeasure(e.target.value);
    setCustomMeasureSelected(e.target.value);
    if (tableKey && setTableKey) {
      setTableKey(tableKey + 1);
    }
  };

  const getCorrelation = (matchItem) => {
    if (!measureOptionsWithCorrelation) {
      return emdash;
    }
    const correlation = parseFloat(
      measureOptionsWithCorrelation.find(
        (item) => matchItem?.title === item?.title
      )?.average
    );
    if (!isNaN(correlation)) {
      return correlation;
    }
    return emdash;
  };

  // For sorting measures, when using correlations for PvP table are avalable
  const newMeasureOptions = [];
  if (sortAndColorCodeMeasures && measureOptionsWithCorrelation?.length) {
    measureOptions.forEach((item) => newMeasureOptions.push(item));
    newMeasureOptions.sort((itemA, itemB) => {
      const correlationA = getCorrelation(itemA);
      const correlationB = getCorrelation(itemB);
      return correlationB - correlationA;
    });
  }

  return (
    <>
      <div
        className="measure-selector"
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        {useCustomHeading && heading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
              {heading.split(" ").map((head, index) => (
                <span key={index}>{head}&nbsp;</span>
              ))}
            </h3>
          </div>
        ) : heading ? (
          <div className="title" style={{ width: 380 }}>
            <h5 style={{ marginBottom: 0, marginRight: 5 }}>
              {heading.split(" ").map((head, index) => (
                <span key={index}>{head}&nbsp;</span>
              ))}
            </h5>
          </div>
        ) : null}
        <div
          className="radio-buttons-group"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Radio.Group
            defaultValue={
              Object.values(
                newMeasureOptions.length ? newMeasureOptions[0] : measureOptions
              )[0]
            }
            size="medium"
            onChange={onMeasureChange}
            value={measure}
          >
            {Object.keys(
              newMeasureOptions.length ? newMeasureOptions : measureOptions
            ).map((key) => {
              return (
                <Tooltip
                  title={
                    newMeasureOptions.length
                      ? newMeasureOptions[key].description
                      : measureOptions[key].description
                  }
                  key={key}
                >
                  <Radio.Button
                    value={
                      newMeasureOptions.length
                        ? newMeasureOptions[key]
                        : measureOptions[key]
                    }
                    key={key}
                    className="custom-radio-button"
                    style={{
                      backgroundColor:
                        !sortAndColorCodeMeasures ||
                        isNaN(
                          parseFloat(getCorrelation(newMeasureOptions[key]))
                        )
                          ? "white"
                          : getCorrelation(newMeasureOptions[key]) > 0
                          ? `rgba(0,255,174,${Math.abs(
                              getCorrelation(newMeasureOptions[key])
                            )})`
                          : `rgba(255,80,90,${Math.abs(
                              getCorrelation(newMeasureOptions[key])
                            )})`,
                    }}
                  >
                    {newMeasureOptions.length
                      ? newMeasureOptions[key]?.displayTitle ||
                        newMeasureOptions[key].title
                      : measureOptions[key]?.displayTitle ||
                        measureOptions[key].title}
                    {showIfDataAvailable &&
                      (newMeasureOptions.length
                        ? newMeasureOptions[key]?.dataAvailable === "all"
                        : measureOptions[key]?.dataAvailable === "all") && (
                        <sup>&#9650;</sup>
                      )}
                    {showIfDataAvailable &&
                      (newMeasureOptions.length
                        ? newMeasureOptions[key]?.dataAvailable === "some"
                        : measureOptions[key]?.dataAvailable === "some") && (
                        <sup>&#9651;</sup>
                      )}
                  </Radio.Button>
                </Tooltip>
              );
            })}
          </Radio.Group>
        </div>
      </div>
      {showIfDataAvailable && (
        <div style={{ marginLeft: 265, marginTop: 10 }}>
          <div>
            <sup>&#9650;</sup> All values can be prefilled for the current year
            range selected.
          </div>
          <div>
            <sup>&#9651;</sup> Some values can be prefilled for the current year
            range selected.
          </div>
        </div>
      )}
    </>
  );
}
