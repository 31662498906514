import React, { useState, useEffect, useMemo } from "react";
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { Button } from "antd";
import exportAsImage from "../../utils/exportAsImage";

const pickShapeAndColor = (symbolNumber) => {
  // Picks a new, consistant shape/color combo based on a symbolNumber input (index within the graph)
  // GraphShapes.length cannot === graphColors.length, or it will repeat colors/symbol combos
  const graphShapes = [
    "circle",
    "diamond",
    "square",
    "star",
    "triangle",
    "cross",
    "wye",
  ];
  const graphColors = ["black", "red", "blue", "green", "purple"];

  const shape = graphShapes[symbolNumber % graphShapes.length];
  const color = graphColors[symbolNumber % graphColors.length];

  return { shape, color };
};

export default function TSRLineGraph({
  companyInfo,
  cohortTableSelector,
  weightingsCheckList,
  TSRtableData,
  indexesSelected,
  addedComparisonCompanies = [],
  currentOptions = {},
}) {
  const generateCurrentLegend = (weightingsCheckList) => {
    const defaultLegend = [
      {
        id: "calculatedTSR",
        value: companyInfo.Company + " TSR",
        type: pickShapeAndColor(0).shape,
        color: pickShapeAndColor(0).color,
      },
    ];
    if (weightingsCheckList.includes("Equal-Weighted")) {
      defaultLegend.push({
        id: "peerGroupTSR",
        value: `${
          cohortTableSelector.includes("Peer Group") ? "Peer Group" : "Cohort"
        } TSR (Equal-Weighted)`,
        type: pickShapeAndColor(1).shape,
        color: pickShapeAndColor(1).color,
      });
    }
    if (weightingsCheckList.includes("Value-Weighted")) {
      defaultLegend.push({
        id: "peerGroupValueWeighted",
        value: `${
          cohortTableSelector.includes("Peer Group") ? "Peer Group" : "Cohort"
        } TSR (Value-Weighted)`,
        type: pickShapeAndColor(2).shape,
        color: pickShapeAndColor(2).color,
      });
    }
    if (weightingsCheckList.includes("Median Composite")) {
      defaultLegend.push({
        id: "peerGroupMedianWeighted",
        value: `${
          cohortTableSelector.includes("Peer Group") ? "Peer Group" : "Cohort"
        } TSR (Median Composite)`,
        type: pickShapeAndColor(3).shape,
        color: pickShapeAndColor(3).color,
      });
    }
    if (weightingsCheckList.includes("Value-Weighted (Item 201 Compliant)")) {
      defaultLegend.push({
        id: "peerGroup201Compliant",
        value: `${
          cohortTableSelector.includes("Peer Group") ? "Peer Group" : "Cohort"
        } TSR (201 Compliant)`,
        type: pickShapeAndColor(4).shape,
        color: pickShapeAndColor(4).color,
      });
    }
    if (indexesSelected.length) {
      indexesSelected.forEach((index, indexOfIndex) => {
        defaultLegend.push({
          id: index,
          value: index,
          color: pickShapeAndColor(indexOfIndex + 5).color,
          type: pickShapeAndColor(indexOfIndex + 5).shape,
        });
      });
    }
    if (addedComparisonCompanies.length) {
      addedComparisonCompanies.forEach((company, indexOfIndex) => {
        defaultLegend.push({
          id: company,
          value: company,
          color: pickShapeAndColor(indexesSelected.length + indexOfIndex + 5)
            .color,
          type: pickShapeAndColor(indexesSelected.length + indexOfIndex + 5)
            .shape,
        });
      });
    }
    return defaultLegend;
  };

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height={400}
        className="TSR-line-graph"
        id="TSR-line-graph"
      >
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          data={TSRtableData}
        >
          <CartesianGrid />
          <XAxis
            type="category"
            dataKey="TSRperiod"
            name="TSR Date"
            domain={["auto", "auto"]}
            interval={
              currentOptions?.TSRperiod === "1yr" &&
              currentOptions?.periodicitySelected === "Monthly"
                ? 0
                : ""
            }
            fontSize={11}
            tickFormatter={(tick) => tick.split("(")[0]}
          />
          <YAxis
            type="number"
            name="calculatedTSR"
            scale="auto"
            domain={["dataMin - 10", "auto"]}
            tickFormatter={(value) => "$" + value.toLocaleString()}
            label={{
              value: "TSR",
              angle: -90,
              position: "insideLeft",
              // offset: 0,
              style: { textAnchor: "middle" },
            }}
          />
          <Tooltip
            formatter={(value, name, props) =>
              !isNaN(parseFloat(value))
                ? "$" + parseFloat(value).toFixed(2)
                : value
            }
            cursor={{ strokeDasharray: "3 3" }}
          />
          <Legend payload={generateCurrentLegend(weightingsCheckList)} />
          <Scatter
            name={companyInfo.Company + " TSR"}
            dataKey="calculatedTSR"
            line
            fill={pickShapeAndColor(0).color}
            shape={pickShapeAndColor(0).shape}
          />
          {weightingsCheckList.includes("Equal-Weighted") && (
            <Scatter
              name={`${
                cohortTableSelector.includes("Peer Group")
                  ? "Peer Group"
                  : "Cohort"
              } TSR (Equal-Weighted)`}
              dataKey="peerGroupTSR"
              line
              fill={pickShapeAndColor(1).color}
              shape={pickShapeAndColor(1).shape}
            />
          )}
          {weightingsCheckList.includes("Value-Weighted") && (
            <Scatter
              name={`${
                cohortTableSelector.includes("Peer Group")
                  ? "Peer Group"
                  : "Cohort"
              } TSR (Value-Weighted)`}
              dataKey="peerGroupValueWeighted"
              line
              fill={pickShapeAndColor(2).color}
              shape={pickShapeAndColor(2).shape}
            />
          )}
          {weightingsCheckList.includes("Median Composite") && (
            <Scatter
              name={`${
                cohortTableSelector.includes("Peer Group")
                  ? "Peer Group"
                  : "Cohort"
              } TSR (Median Composite)`}
              dataKey="peerGroupMedianWeighted"
              line
              fill={pickShapeAndColor(3).color}
              shape={pickShapeAndColor(3).shape}
            />
          )}
          {weightingsCheckList.includes(
            "Value-Weighted (Item 201 Compliant)"
          ) && (
            <Scatter
              name={`${
                cohortTableSelector.includes("Peer Group")
                  ? "Peer Group"
                  : "Cohort"
              } TSR (201 Compliant)`}
              dataKey="peerGroup201Compliant"
              line
              fill={pickShapeAndColor(4).color}
              shape={pickShapeAndColor(4).shape}
            />
          )}
          {indexesSelected.length &&
            indexesSelected.map((index, indexOfIndex) => {
              return (
                <Scatter
                  name={index}
                  dataKey={index}
                  key={index}
                  line
                  fill={pickShapeAndColor(indexOfIndex + 5).color}
                  shape={pickShapeAndColor(indexOfIndex + 5).shape}
                />
              );
            })}
          {addedComparisonCompanies.length &&
            addedComparisonCompanies.map((company, index) => {
              return (
                <Scatter
                  name={company}
                  dataKey={company}
                  key={company}
                  line
                  fill={
                    pickShapeAndColor(indexesSelected.length + index + 5).color
                  }
                  shape={
                    pickShapeAndColor(indexesSelected.length + index + 5).shape
                  }
                />
              );
            })}
        </ScatterChart>
      </ResponsiveContainer>
      <Button
        style={{ marginLeft: "77.5%" }}
        onClick={() =>
          exportAsImage(
            "TSR-line-graph",
            `${companyInfo?.Ticker} TSR Line Graph  ${
              new Date().toString().split("GMT-")[0]
            }`
          )
        }
      >
        Export Graph as Image
      </Button>
    </>
  );
}
