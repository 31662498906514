import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "../css/cancel.css";
import "../css/change-plan.css";
import moment from "moment";
import { Button } from "../JSS/Button";
import {
  getSubscription,
  getPaymentMethod,
  updateSubscription,
} from "../redux/actions/CustomerAction";
import { getAllPlans } from "../redux/actions/PlansAction";
import { Tooltip } from "antd";
import { ACCESS_TOKEN, STATUS_CODE } from "../utils/Constants/systemSetting";
import CustomerService from "../services/CustomerService";
import toast from "react-hot-toast";
import { HIDE_LOADING, SHOW_LOADING } from "../redux/constants/LoadingConstant";
import ClipLoader from "react-spinners/ClipLoader";

export default function RenewSubscription() {
  const [cancelationState, setCancelationState] = useState("canceled");
  const dispatch = useDispatch();
  const history = useHistory();
  const { subscription, paymentMethod } = useSelector(
    (state) => state.CustomerReducer
  );
  const { id } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    dispatch(getSubscription());
    dispatch(getPaymentMethod());
  }, [dispatch]);

  // console.log(subscription);

  function toTitleCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getPlanInterval = (interval) => {
    if (interval === "year") return "annual";
    if (interval === "month") return "month";
    return "loading...";
  };

  const { plans } = useSelector((state) => state.PlansReducer);
  const [sortedPlans, setSortedPlans] = useState([]);
  const [activePlan, setActivePlan] = useState("");
  const [planID, setPlanID] = useState("");
  const [planDetails, setPlanDetails] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [active, setActive] = useState({
    month: "",
    year: "active",
  });

  function PaymentPlans() {
    useEffect(() => {
      if (!plans.length) {
        dispatch(getAllPlans()); // load plans if they weren't loaded on homepage
      } else {
        if (!sortedPlans.length) {
          setSortedPlans(
            plans
              // .filter((plan) => plan.active)
              .filter((plan) => plan.metadata?.displayOnFrontend === "true")
              .sort((a, b) => {
                return a.price - b.price;
              })
          );
        }
      }
    }, [plans]);

    useEffect(() => {
      if (!activePlan) {
        setActivePlan(sortedPlans[sortedPlans.length - 1]?.interval);
        setPlanID(sortedPlans[sortedPlans.length - 1]?.id);
      }
    }, [activePlan, sortedPlans]);

    useEffect(() => {
      if (
        subscription?.plan?.interval &&
        activePlan &&
        subscription?.plan?.interval === activePlan
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }, [subscription, activePlan]);

    useEffect(() => {
      for (const planDetail of sortedPlans) {
        if (planDetail.interval === activePlan) {
          setPlanDetails(planDetail);
        }
      }
    }, [activePlan, sortedPlans]);

    const changeActive = (tab) => {
      const values = { ...active };
      for (let key in values) {
        values[key] = "";
      }
      values[tab] = "active";
      setActive({ ...values });
    };

    const renderPayPlan = () => {
      return sortedPlans.map((plan, index) => {
        return (
          <div key={index} className={`col-${12 / Number(sortedPlans.length)}`}>
            <div
              onClick={() => {
                changeActive(plan.interval);
                setActivePlan(plan.interval);
                setPlanID(plan.id);
              }}
              className={`${plan.interval} product-item ${
                active[plan.interval]
              }`}
            >
              {plan.interval === "year" && (
                <div className="popular">Best Value</div>
              )}
              <div className="title">
                <h3>
                  {(plan.interval === "month" && "Monthly Plan") ||
                    (plan.interval === "year" && "Annual Plan")}
                </h3>
                <p style={{ marginTop: "-5px" }}>
                  Billed{" "}
                  {(plan.interval === "month" && "monthly") ||
                    (plan.interval === "year" && "yearly")}
                </p>

                <br />

                <p>
                  <span>
                    $
                    {(
                      (plan.interval === "month"
                        ? plan.price
                        : plan.price / 12) / 100
                    ).toLocaleString()}
                  </span>
                  /mo ($
                  {(
                    (plan.interval === "year" ? plan.price : plan.price * 12) /
                    100
                  ).toLocaleString()}
                  /{"yr"})
                </p>
              </div>

              <br />
              <br />

              {/* <img
              src={require("../assets/lock.svg").default}
              style={{ width: "20px", position: "absolute" }}
              alt="lock icon"
            />
            <p style={{ paddingLeft: 30 }}>Secure Transaction</p> */}
              <div className="buttonContainer">
                {subscription?.plan?.interval === plan.interval ? (
                  <p className="current-plan">Current plan</p>
                ) : null}
              </div>
            </div>
          </div>
        );
      });
    };

    return <div className="row">{renderPayPlan()}</div>;
  }

  const handleSubmit = async () => {
    try {
      dispatch({
        type: SHOW_LOADING,
      });
      const token = localStorage.getItem(ACCESS_TOKEN);
      const response = await CustomerService.updatePaymentPlan({
        token,
        planID,
      });
      if (response.status === STATUS_CODE.SUCCESS) {
        toast.success("Payment plan successfully updated!");
        dispatch(updateSubscription({ token, subscription: response.data }));
        history.push("/membership");
      } else {
        toast.error(
          "Cannot update payment plan right now. Please try again later."
        );
      }
    } catch (err) {
      console.log(err?.raw?.code);
      if (err?.response?.data?.raw?.code === "customer_tax_location_invalid") {
        toast.error(
          "Zip code is not valid. Please update your payment details on the membership page."
        );
      } else {
        console.log(err?.response?.data || err);
        toast.error(
          "Cannot update payment plan right now. Please try again later."
        );
      }
    }
    dispatch({
      type: HIDE_LOADING,
    });
  };

  return (
    <>
      <div style={{ paddingTop: 200 }}></div>
      <div className="change-plan">
        <h1>Update your Payment Plan</h1>

        {/* <div className="container">
          <div className="row">{renderPayPlan()}</div>
        </div> */}
        <div className="container">
          <PaymentPlans />
        </div>
      </div>

      <div className="cancel" style={{ paddingTop: 50 }}>
        {cancelationState === "canceled" ? (
          <div className="canceled">
            <p>
              {!activePlan ? (
                <div className="loaderContainer">
                  <ClipLoader
                    color={"var(--primary)"}
                    loading={true}
                    size={50}
                  />
                </div>
              ) : // "Loading..."
              disabled ? (
                "Plan selected is your currently active plan."
              ) : (
                <>
                  Starting on{" "}
                  {moment(subscription?.current_period_end * 1000).format(
                    "MMMM DD, YYYY"
                  )}
                  , your plan will update to{" "}
                  <b>renew {getPlanInterval(planDetails?.interval)}ly</b> and
                  we'll charge you
                  {paymentMethod?.brand &&
                    `r ${toTitleCase(paymentMethod?.brand)} (ending in ${
                      paymentMethod.last4
                    })`}{" "}
                  ${(planDetails?.price / 100).toLocaleString()} plus applicable
                  taxes every {planDetails?.interval}.
                </>
              )}
            </p>

            <div className="button-group">
              <p style={{}}>
                You can update your payment method or cancel anytime on the{" "}
                <Link to="/membership">Membership</Link> page.
              </p>
              <br />
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-6">
                    <Link to="/membership">
                      <Button secondary>Back to subscription</Button>
                    </Link>
                  </div>
                  <div className="col-6">
                    <div className="cancel-btn">
                      {/* <Tooltip
                        overlayStyle={{ maxWidth: "345px" }}
                        placement="top"
                        overlayInnerStyle={{
                          borderRadius: "5px",
                          color: "var(--white)",
                          backgroundColor: "var(--dark-red)",
                        }}
                        title={
                          disabled
                            ? "The plan selected is already your current plan."
                            : ""
                        }
                      >
                        <div> */}
                      <Button
                        onClick={() => {
                          handleSubmit();
                        }}
                        primary
                        disabled={disabled}
                      >
                        Update Plan
                      </Button>
                      {/* </div>
                      </Tooltip> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="renewed">
            {" "}
            <h2>Welcome back!</h2>
            <div
              className="button-group"
              style={{ marginTop: 50, color: "black" }}
            >
              <p style={{ color: "black" }}>
                You will not be charged today. Your first recurring charge will
                be on{" "}
                {moment(subscription?.current_period_end * 1000).format(
                  "MMMM DD, YYYY"
                )}
                .
              </p>
              <div style={{ paddingTop: 25 }}></div>
              <p style={{ color: "black" }}>
                {" "}
                To stop future charges, turn off recurring billing on the
                membership page before your next billing date.
              </p>
              <br />
              <Link to="/membership">
                <Button primary style={{ width: "50%" }}>
                  Back to subscription
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
