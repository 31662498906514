import React, { useMemo, useCallback } from "react";
import {
  BarChart,
  Bar,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CorrelationGraph({
  formattedTableData,
  correlationType,
  PvPTableColumns,
  measureOptions,
  measuresInGraph,
}) {
  const getPvPColumnTitle = useCallback(
    (PvPColumnKey) => {
      const PvPColumn = PvPTableColumns.find(
        (item) => item.key === PvPColumnKey
      );
      const PvPColumnTitle = (
        PvPColumn?.displayTitle || PvPColumn?.title
      )?.replace(" ($)", "");
      return PvPColumnTitle;
    },
    [PvPTableColumns]
  );

  const allMeasures = Object.values(measureOptions);

  const data = useMemo(() => {
    const newData = [];
    for (const d of formattedTableData) {
      if (
        !measuresInGraph.includes(allMeasures[d.measure]?.title) ||
        isNaN(parseFloat(d.correlation[correlationType])) ||
        (Math.round(Math.abs(d.correlation[correlationType].toPrecision(4))) ===
          1 &&
          d.correlation.datapoints === 2)
      ) {
        continue;
      }
      if (
        !newData.some((item) => item.name === allMeasures[d.measure]?.title)
      ) {
        const datapoint = {
          name: allMeasures[d.measure]?.title,
          key: d.compensationType,
        };
        datapoint[`${getPvPColumnTitle(d.compensationType)}`] =
          d.correlation[correlationType].toPrecision(4);
        newData.push(datapoint);
      } else {
        const dataIndex = newData.findIndex(
          (item) => item.name === allMeasures[d.measure]?.title
        );
        const newDatapoint = { ...newData[dataIndex] };
        newDatapoint[`${getPvPColumnTitle(d.compensationType)}`] =
          d.correlation[correlationType].toPrecision(4);
        newData[dataIndex] = newDatapoint;
      }
    }
    return newData;
  }, [
    formattedTableData,
    allMeasures,
    correlationType,
    measuresInGraph,
    getPvPColumnTitle,
  ]);

  const compensationBars = [
    { key: "PeoTotalCompAmt", color: "var(--graph-salary)" },
    { key: "PeoTotalCompAmt2", color: "var(--graph-rsus)" },
    { key: "PeoTotalCompAmt3", color: "var(--graph-psus)" },
    { key: "PeoTotalCompAmt4", color: "var(--graph-psus)" },
    { key: "PeoTotalCompAmt5", color: "var(--graph-psus)" },
    { key: "PeoActuallyPaidCompAmt", color: "var(--graph-bonus)" },
    { key: "PeoActuallyPaidCompAmt2", color: "var(--graph-sar)" },
    { key: "PeoActuallyPaidCompAmt3", color: "var(--graph-secondary)" },
    { key: "PeoActuallyPaidCompAmt4", color: "var(--graph-secondary)" },
    { key: "PeoActuallyPaidCompAmt5", color: "var(--graph-secondary)" },
    { key: "NonPeoNeoAvgTotalCompAmt", color: "var(--graph-stock-awards)" },
    {
      key: "NonPeoNeoAvgCompActuallyPaidAmt",
      color: "var(--graph-option-awards)",
    },
    { key: "TotalShareholderRtnAmt", color: "var(--graph-non-equity)" },
    { key: "PeerGroupTotalShareholderRtnAmt", color: "var(--graph-pension)" },
    { key: "NetIncomeLoss", color: "var(--graph-other)" },
    // { key: "PSUs", color: "var(--graph-psus)" },
    // { key: "total", color: "var(--secondary)" },
  ];
  const filteredCompensationBars = compensationBars.filter((bar) =>
    data.some((item) => {
      return Object.keys(item).includes(getPvPColumnTitle(bar.key));
    })
  );

  return (
    <ResponsiveContainer width={"100%"} height={400}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
        barGap={1}
        barCategoryGap={5}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis
          dataKey="name"
          fontSize={8}
          interval={0}
          minTickGap={10}
          tickFormatter={(tick) =>
            tick.length > 12 ? tick.slice(0, 10) + "..." : tick
          }
        />
        <YAxis domain={correlationType === "correlation" ? [-1, 1] : [0, 1]} />
        <Tooltip />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "30px" }} />
        <Brush
          dataKey="name"
          height={30}
          stroke="#8884d8"
          travellerWidth={10}
        />
        {filteredCompensationBars.map((bar) => (
          <Bar dataKey={getPvPColumnTitle(bar.key)} fill={bar.color} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
