import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { getRiskFactors } from "../redux/actions/CompanyAction";

export default function RiskModule({ companyInfo, PDFprocessing }) {
  const version = localStorage.getItem("version") || "1.0.0";
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);
  const { riskFactors, companies } = useSelector(
    (state) => state.CompanyReducer
  );

  const [yearSelected, setYearSelected] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker) {
      dispatch(getRiskFactors([ticker], token));
    }
  }, [companyInfo, dispatch]);

  // console.log(companyInfo.Ticker, riskFactors);

  const riskFactorsForCompany = riskFactors?.[companyInfo.Ticker];
  const riskFactorsData = riskFactorsForCompany?.RiskFactors;

  const sortedRiskFactorData = useMemo(() => {
    if (!riskFactorsData || !riskFactorsData?.length) {
      return [];
    }
    const sortedData = riskFactorsData.sort(
      (a, b) => b.isNewPercent - a.isNewPercent
    );
    return sortedData;
  }, [riskFactorsData]);

  //   {
  //     "Ticker": "AOS",
  //     "CompanyName": "A. O. Smith Corporation",
  //     "reportDate": "2023-12-31",
  //     "OverallSummaryPreviousYear": "The company faces risks related to global economic conditions, extraordinary events, foreign currency fluctuations, cybersecurity threats, product defects, acquisitions, and other operational and strategic factors.",
  //     "OverallSummaryCurrentYear": "The company faces risks related to global economic conditions, extraordinary events, foreign currency fluctuations, cybersecurity threats, product defects, acquisitions, and other operational and strategic factors.",
  //     "OverallPercentChange": 0,
  //     "OverallSummaryOfChange": "There were no significant changes to the overall risk factors between the 2022 and 2023 annual reports.",
  //     "RiskFactors": [
  //       {
  //         "Category": "Economic and Industry Risks",
  //         "isNewPercent": 3.57,
  //         "whatChanged": "The company added 'further' to describe potential adverse effects of global and regional economic conditions. The phrase 'such as a recession or economic downturn' was added to provide examples of economic decline.",
  //         "Description": "The effects of global and regional economic conditions could have a material adverse effect on our business",
  //         "Details": "A decline in economic activity, such as a recession or economic downturn, in the U.S. and other regions in which the company does business could adversely affect consumer confidence and spending patterns, resulting in decreased demand for products, delayed purchases, increased price competition, and slower adoption of energy-efficient products.",
  //         "CompanyWeight": 5
  //       }
  //     ]
  // }

  const awardsColumns = useMemo(
    () => [
      {
        title: "Category",
        dataIndex: "Category",
        key: "Category",
        align: "center",
      },
      {
        title: "Amount changed (%)",
        dataIndex: "isNewPercent",
        key: "isNewPercent",
        align: "center",
      },
      {
        title: "What Changed",
        dataIndex: "whatChanged",
        key: "whatChanged",
        align: "center",
      },
      {
        title: "Description",
        dataIndex: "Description",
        key: "Description",
        align: "center",
      },
      {
        title: "Details",
        dataIndex: "Details",
        key: "Details",
        align: "center",
      },
      {
        title: "Risk Importance (1-5)",
        dataIndex: "CompanyWeight",
        key: "CompanyWeight",
        align: "center",
      },
    ],
    []
  );

  // const LTIcolumns = useMemo(() => {
  //   if (!awardsDataLTI) return [];
  //   const columns = JSON.parse(JSON.stringify(awardsColumns)).filter(
  //     (item) =>
  //       Object.keys(awardsDataLTI).includes(item.dataIndex) ||
  //       awardsDataLTI.some((award) => award[item.dataIndex] !== "")
  //   );
  //   return columns;
  // }, [awardsColumns, awardsDataLTI]);

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>Contact your account manager to access Company Risk data.</p>
          </h3>
        </div>
      ) : (
        <>
          {!sortedRiskFactorData.length ? (
            <div
              className="company-calculator-section col-lg-9 executives p-0"
              style={{ padding: 50, textAlign: "center", marginTop: 50 }}
            >
              <h2>Company Risks for {companyInfo?.Ticker} Coming Soon</h2>
            </div>
          ) : (
            <>
              <div className="company-calculator-section col-lg-9 executives p-0">
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "20px",
                    marginLeft: "20px",
                    width: "100%",
                  }}
                >
                  <h2 style={{ marginTop: 25 }}>Overall Risk Summary</h2>
                  <p style={{ fontSize: "1.3em", textAlign: "center" }}>
                    {riskFactorsForCompany?.OverallSummaryCurrentYear}
                  </p>
                  <h2 style={{ marginTop: 50 }}>
                    Overall Change From Last Year
                  </h2>
                  <p style={{ fontSize: "1.3em", textAlign: "center" }}>
                    {riskFactorsForCompany?.OverallSummaryOfChange}
                  </p>
                </div>
              </div>
              <h2 style={{ textAlign: "center" }}>Summary of Risks</h2>
              <Table
                dataSource={sortedRiskFactorData}
                columns={awardsColumns}
                rowKey="Metric"
                pagination={{ hideOnSinglePage: true }}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
