/* eslint-disable import/no-anonymous-default-export */
import { SET_CUSTOMER_SUBSCRIPTION, SET_PAYMENT_METHOD } from "../constants/CustomerConstant"

const initialState = {
    
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CUSTOMER_SUBSCRIPTION: {
            return {...state, ...action.subscription}
        }
        case SET_PAYMENT_METHOD: {
            return {...state, paymentMethod: action.paymentMethod}
        }
        
    default:
        return state
    }
}
