import {
  CANCEL_SUBSCRIPTION,
  GET_PAYMENT_METHOD_SAGA,
  GET_SUBSCRIPTION_SAGA,
  RENEW_SUBSCRIPTION,
  UPDATE_PAYMENT_PLAN,
  SET_CUSTOMER_SUBSCRIPTION,
} from "../constants/CustomerConstant";

export const getSubscription = () => ({
  type: GET_SUBSCRIPTION_SAGA,
});

export const updateSubscription = (token) => ({
  type: SET_CUSTOMER_SUBSCRIPTION,
  token,
});

export const getPaymentMethod = () => ({
  type: GET_PAYMENT_METHOD_SAGA,
});

export const cancelSubscription = (cancellation) => ({
  type: CANCEL_SUBSCRIPTION,
  cancellation,
});

export const renewSubscription = (token) => ({
  type: RENEW_SUBSCRIPTION,
  token,
});

export const updatePaymentPlan = (token) => ({
  type: UPDATE_PAYMENT_PLAN,
  token,
});
