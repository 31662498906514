import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { usePDF } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  Font,
  Image,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import Arimo from "../../assets/Arimo/static/Arimo-Regular.ttf";
import ArimoBold from "../../assets/Arimo/static/Arimo-Bold.ttf";
import { maxExecutiveRanks } from "../../utils/Constants/executiveMaxRank";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    display: "flex",
    flexWrap: "wrap",
    fontSize: 14,
    // textAlign: "center",
  },
  section: {
    margin: 15,
    marginTop: 0,
    padding: 15,
    alignItems: "center",
    flexGrow: 1,
  },
  logoSection: {
    alignItems: "left",
    flexGrow: 1,
    width: "100vw",
    marginTop: 15,
    marginLeft: 30,
  },
  logo: {
    width: 90,
  },
  image: {
    marginBottom: 25,
  },
  filterSection: {
    display: "flex",
    // flexWrap: "wrap",
    alignItems: "flex-start",
    width: 450,
    lineHeight: 1.25,
  },
  filterTitle: {
    fontFamily: "ArimoBold",
    fontSize: 18,
    margin: 10,
  },
  filterKey: {
    fontFamily: "ArimoBold",
  },
  filterValue: {
    fontFamily: "Arimo",
  },
  footer: {
    fontSize: 8,
    fontFamily: "Arimo",
    position: "absolute",
    // distance of bottom:0 does not work, so this is the bottom of an A4 page in mm
    top: "255mm",
    left: 0,
    right: 0,
    textAlign: "center",
    // Full width, minus the padding of "section", and text and/or graphs don't show up unless part of "section"
    width: "94.5vw",
  },
});

export default function ExportHomepagePDFButton({
  needsFilters,
  setPDFprocessing,
  setPDFprocessingForAllUsers = () => null,
  allowExecutiveTableExport = false,
}) {
  const { filter } = useSelector((state) => state.TableFilterReducer);
  const user = useSelector((state) => state.UserReducer);

  const [image, setImage] = useState();
  const [table, setTable] = useState();
  const [executiveTable, setExecutiveTable] = useState();
  const [processing, setProcessing] = useState(false);
  const [formattedFilters, setFormattedFilters] = useState([]);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [updatePDF, setUpdatePDF] = useState(false);
  Font.register({
    family: "Arimo",
    src: Arimo,
    format: "truetype",
  });
  Font.register({
    family: "ArimoBold",
    src: ArimoBold,
    format: "truetype",
  });

  const camelToTitleCase = (string) => {
    string = string.replace(/([A-Z])/g, " $1");
    string = string.charAt(0).toUpperCase() + string.slice(1);
    return string;
  };
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const pluralize = (filter) => {
    if (filter.endsWith("y")) {
      return `${filter.replace(/y$/, "ies")}`;
    } else {
      return `${filter}s`;
    }
  };

  useEffect(() => {
    const formattedFiltersResult = [];
    for (let [filterKey, filterValue] of Object.entries(filter)) {
      if (Array.isArray(filterValue) && filterKey !== "index") {
        // Format Array filters (min, max)
        if (filterValue.length > 0) {
          let newFilter = {};
          let newFilterKey =
            filterValue.length === 1 ? filterKey : pluralize(filterKey);
          filterValue = filterValue.join(", ");
          newFilter[newFilterKey] = filterValue;
          formattedFiltersResult.push(newFilter);
        }
      } else if (typeof filterValue == "object") {
        // Format Object filters (min, max)
        let newFilter = {};
        let newKey = "";
        let minValue = "";
        let maxValue = "";
        for (const [key, value] of Object.entries(filterValue)) {
          if (!key.toLowerCase().includes("digit")) {
            if (key.toLowerCase().includes("min")) {
              newKey = key.replace("Min", "");
              if (newKey === "salary") {
                newKey = "annualCompensation";
              }
              if (newKey !== "executiveRank" || value !== 1) {
                minValue = value;
              }
              if (
                minValue &&
                newKey !== "employees" &&
                newKey !== "companyRank" &&
                newKey !== "executiveRank" &&
                newKey !== "percentile"
              ) {
                minValue = "$" + minValue;
              }
            }
            if (value && key.toLowerCase().includes("max")) {
              if (
                (newKey !== "payRatio" ||
                  parseFloat(value.replace(",", "")) < 1e5) &&
                (newKey !== "percentile" || parseFloat(value) < 100) &&
                (newKey !== "executiveRank" ||
                  parseFloat(value) < maxExecutiveRanks?.[filter.currentYear])
              ) {
                maxValue = value;
              }
              if (
                maxValue &&
                newKey !== "employees" &&
                newKey !== "companyRank" &&
                newKey !== "executiveRank" &&
                newKey !== "percentile"
              ) {
                maxValue = "$" + maxValue;
              }
            }
          }
        }
        if (minValue && maxValue) {
          newFilter[newKey] = `${minValue} - ${maxValue}`;
        } else if (minValue && !maxValue) {
          newFilter[newKey] = `≥ ${minValue}`;
        } else if (!minValue && maxValue) {
          newFilter[newKey] = `≤ ${maxValue}`;
        } else {
          newFilter = {}; // no values for either
        }
        formattedFiltersResult.push(newFilter);
      } else {
        // Format Sting Filters
        if (
          filterValue !== "All" &&
          filterValue !== "all" &&
          filterKey !== "locationFilter" &&
          (filterKey !== "cohortFilter" || filter.cohort.length) &&
          (filterKey !== "diffPercentAllowed" || filterValue !== 0)
        ) {
          let newFilter = {};
          filterValue =
            filterKey === "cohortFilter"
              ? camelToTitleCase(filterValue)
              : filterValue;
          filterKey = filterKey.replace("current", "");
          filterKey = filterKey.replace("cohortFilter", "cohortFilterType");
          newFilter[filterKey] = filterValue;
          formattedFiltersResult.push(newFilter);
        }
      }
    }
    setFormattedFilters(formattedFiltersResult);
  }, [filter]);

  const PDF = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.logoSection}>
            <Image
              style={styles.logo}
              src="/logos/CSuiteLogo(R)_NoSlogan.png"
            />
            <Text style={{ ...styles.filterKey, fontSize: 11, marginLeft: 6 }}>
              https://csuitecomp.com
            </Text>
          </View>

          <View style={styles.section}>
            {table ? <Image src={table} style={styles.image} /> : null}

            {image ? <Image src={image} style={styles.image} /> : null}
            <Text style={styles.filterTitle}>Active Filters:</Text>
            <View style={styles.filterSection}>
              {formattedFilters.map((filter) => {
                for (const [key, value] of Object.entries(filter)) {
                  return (
                    <Text style={styles.filterKey}>
                      {camelToTitleCase(key)}:{" "}
                      <Text style={styles.filterValue}>{value}</Text>
                    </Text>
                  );
                }
              })}
            </View>
            <Text style={styles.footer}>
              {`Downloaded by ${user.fname} ${
                user.lname
              } on ${new Date().toLocaleDateString()} for internal use only${
                user?.companyName ? ` by ${user.companyName}.` : "."
              }`}{" "}
              {`\n`}All Rights Reserved. C-Suite Comp<sup>&reg;</sup>
            </Text>
          </View>
        </Page>
        {allowExecutiveTableExport && (
          <Page size="A4" style={styles.page}>
            <View style={styles.logoSection}>
              <Image
                style={styles.logo}
                src={"/logos/CSuiteLogo(R)_NoSlogan.png"}
              />
              <Text
                style={{ ...styles.filterKey, fontSize: 11, marginLeft: 6 }}
              >
                https://csuitecomp.com
              </Text>
            </View>

            <View style={styles.section}>
              {executiveTable ? (
                <Image src={executiveTable} style={styles.image} />
              ) : null}
              {/* {elements.map((element) => (
            <Image key={element.id} src={element.src} style={element.style} />
          ))} */}
              <Text style={styles.footer}>
                {`Downloaded by ${user.fname} ${
                  user.lname
                } on ${new Date().toLocaleDateString()} for internal use only${
                  user?.companyName ? ` by ${user.companyName}.` : "."
                }`}{" "}
                {`\n`}All Rights Reserved. C-Suite Comp<sup>&reg;</sup>
              </Text>
            </View>
          </Page>
        )}
      </Document>
    );
  };

  const [instance, update] = usePDF({ document: <PDF /> });
  useEffect(() => {
    if (updatePDF && !instance.loading) {
      document.body.style.width = "100%";

      update(); // updates "instance"
      setUpdatePDF(false);
      setTriggerDownload(true);
    }
  }, [updatePDF, update, instance]);

  useEffect(() => {
    if (triggerDownload && !instance.loading) {
      const a = document.createElement("a");
      a.href = instance.url;
      a.download = instance.url.split("/").pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTriggerDownload(false);
      setProcessing(false);
      setPDFprocessing(false);
      setPDFprocessingForAllUsers(false);
    }
  }, [
    triggerDownload,
    instance,
    setPDFprocessing,
    setPDFprocessingForAllUsers,
  ]);

  const handleDownloadPDF = async () => {
    document.body.style.width = "1800px";
    document.getElementById("barChart").style.width = "45%";
    document.getElementById("lineGraph").style.width = "45%";
    await sleep(1000); // Time to re-render page for formatting, may have to adjust
    setPDFprocessingForAllUsers(true);
    setPDFprocessing(true);
    setProcessing(true);

    const input = document.getElementById("graph");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      setImage(imgData);
      html2canvas(document.getElementById("table")).then((canvas2) => {
        const tableImg = canvas2.toDataURL("image/png");
        setTable(tableImg);
        html2canvas(document.getElementById("executive-table")).then(
          (canvas2) => {
            const exectutiveTableImg = canvas2.toDataURL("image/png");
            setExecutiveTable(exectutiveTableImg);
            setUpdatePDF(true);
          }
        );
      });
    });
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (instance.loading || updatePDF || triggerDownload || processing)
    return <div>Processing PDF...</div>;
  if (instance.error) return <div>Something went wrong: {"error"}</div>;

  return (
    <button
      onClick={() => {
        handleDownloadPDF();
      }}
    >
      {screenWidth > 1400 ? "Export Data to PDF" : <div>Export&nbsp;PDF</div>}
    </button>
  );
}
