import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../css/footer.css";
const url = window.location.href;

export default function Footer() {
  const location = useLocation();
  const [isHomepage, setIsHomepage] = useState(true);
  useEffect(() => {
    setIsHomepage(location.pathname === "/");
  }, [location]);
  return (
    <footer
      className="footer"
      style={{
        minWidth: "100%",
        display: "flex",
        justifyContent: "start",
      }}
    >
      <div className="container" style={{ margin: !isHomepage ? "auto" : "" }}>
        <div className="left">
          <span className="p-3" style={{ color: "white", textAlign: "center" }}>
            {" "}
            Copyright &copy; {new Date().getFullYear()}{" "}
            C&#8209;Suite&nbsp;Comp&reg;{" "}
          </span>
          <div className="footer-links">
            <Link to="/privacy/" className="footer p-3">
              Privacy Policy
            </Link>
            <Link to="/terms/" className="footer p-3">
              Terms of Use
            </Link>
          </div>
        </div>
        <div className={`footer-social ${isHomepage ? "on-homepage" : ""}`}>
          <div className="ms-2">
            <FacebookShareButton url={url}>
              <FacebookIcon size={45} round={true} />
            </FacebookShareButton>
          </div>

          <div className="ms-2">
            <WhatsappShareButton url={url}>
              <WhatsappIcon size={45} round={true} />
            </WhatsappShareButton>
          </div>
          <div className="ms-2">
            <TwitterShareButton url={url}>
              <TwitterIcon size={45} round={true} />
            </TwitterShareButton>
          </div>
          <div className="ms-2">
            <TelegramShareButton url={url}>
              <TelegramIcon size={45} round={true} />
            </TelegramShareButton>
          </div>
        </div>
      </div>
    </footer>
  );
}
