import { SET_ALL_CSUITE, SET_EXECUTIVES } from "../constants/CompanyConstant";
import { SET_ALL_EXECUTIVES } from "../constants/ExecutivesConstant";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  executives: [],
  csuite: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_EXECUTIVES: {
      return { ...state, executives: action.executives };
    }
    // case SET_ALL_CSUITE: {
    //   const csuite = state.csuite;
    //   return { ...state, csuite: [...csuite, ...action.csuite] };
    // }
    case SET_EXECUTIVES: {
      const csuite = state.csuite;
      return { ...state, csuite: [...csuite, ...action.csuite] };
    }
    default:
      return { ...state };
  }
};
