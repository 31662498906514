import React from "react";
import { Redirect, Route } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import Authentication from "../utils/Authentication/Authentication";
import Footer from "../components/Footer";

export default function MainTemplate(props) {
  let { Component, ...restRoute } = props;

  return (
    <>
      <NavigationBar />
      <Route
        {...restRoute}
        render={(propsRoute) => {
          const { pathname } = props.location;
          if (pathname === "/profile") {
            if (Authentication.isAuthenticated()) {
              return <Component {...propsRoute} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          } else if (pathname === "/checkout") {
            if (
              Authentication.isAuthenticated() &&
              Authentication.isActivated()
            ) {
              return <Component {...propsRoute} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          } else {
            return <Component {...propsRoute} />;
          }
        }}
      />
      <Footer />
    </>
  );
}
