import React, { useState, useEffect, useMemo } from "react";
import { Input, Button, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  disclosureSearch,
  clearDisclosureResults,
} from "../redux/actions/CompanyAction";
import { DISCLOSURE_REGEX } from "../utils/Constants/systemSetting";
import toast from "react-hot-toast";
const { Search } = Input;

export default function DisclosureSearchBar({ companyInfo }) {
  const dispatch = useDispatch();
  const { disclosureResults } = useSelector((state) => state.CompanyReducer);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchAllCompanies, setSearchAllCompanies] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [searchErrorMessage, setSearchErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [noResultsForSearchQuery, setNoResultsForSearchQuery] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    setSearchAllCompanies(false);
  }, [companyInfo]);

  useEffect(() => {
    setSearchQuery("");
    setSearchResults(null);
    setSearchErrorMessage(null);
    dispatch(clearDisclosureResults());
    setPage(1);
    if (!companyInfo?.CIK && !searchAllCompanies) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [companyInfo, searchAllCompanies, dispatch]);

  useMemo(() => {
    setLoading(false);
    if (disclosureResults?.data) {
      setSearchResults(disclosureResults.data);
    }
    if (disclosureResults?.error) {
      toast.error(disclosureResults.error);
      setSearchErrorMessage(disclosureResults.error);
    }
  }, [disclosureResults]);

  useMemo(() => {
    if (searchResults?.length === 0 && !noResultsForSearchQuery) {
      setNoResultsForSearchQuery(searchQuery);
    }
  }, [noResultsForSearchQuery, searchResults, searchQuery]);

  const handleChange = (value) => {
    //If changing value make sure to change it in
    value = value.replace(DISCLOSURE_REGEX, "").slice(0, 50);
    setSearchQuery(value);
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    setNoResultsForSearchQuery("");
    setSearchResults(null);
    setSearchErrorMessage(null);
    setLoading(true);
    setPage(1);
    dispatch(
      disclosureSearch({
        cik: !searchAllCompanies ? companyInfo.CIK : "allCompanies",
        searchQuery,
        token,
      })
    );
  };

  const createLinkToHighlight = (string, reportURL) => {
    //Use https://onlinetextcompare.com/ to compare URLs
    //Use https://www.w3schools.com/tags/ref_urlencode.asp for URI encoding characters

    // Add this to document URI to use Chrome's "higlight to section" feature (does not work in all cases yet)
    if (!string || !reportURL) return;

    //remove any characters not supported by feature:
    string = string.replace(/[^a-zA-Z0-9 -.,$:<>/‘’“”]/gm, "");

    //replace actual commas with URI %2C
    string = string.replaceAll(",", "%2C");

    //replace hyphen with %2D
    string = string.replaceAll("-", "%2D");

    //replace : with %3A URI
    string = string.replaceAll(":", "%3A");

    //replace multiple spaces in a row with commas:
    string = string.replace(/\s{2,}/g, ",");

    return `${reportURL}#:~:text=${string
      .replaceAll("<b>", "")
      .replaceAll("</b>", "")}`;
  };

  const formatString = (string, reportURL) => {
    const highlightedTextURL = createLinkToHighlight(string, reportURL);
    // Converts string with bold tags into actual bold and span tags:
    const splitString = string
      .split(/(<b>|<\/b>)/g)
      .filter((str) => str !== "<b>" && str !== "</b>");
    let bolded = false;
    const htmlElements = [];
    for (const subString of splitString) {
      if (bolded) {
        htmlElements.push(<b key={htmlElements.length}>{subString}</b>);
      } else {
        htmlElements.push(<span key={htmlElements.length}>{subString}</span>);
      }
      bolded = !bolded;
    }

    return (
      <span>
        {highlightedTextURL ? (
          <a
            href={highlightedTextURL}
            style={{ color: "black" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {htmlElements}
          </a>
        ) : (
          htmlElements
        )}
      </span>
    );
  };

  const splitTextExerpts = (reportText) => {
    // Splits text at "...", each one is it's own excerpt from the report.
    return reportText.split("...").map((str) => str.trim());
  };

  const formatTextExcerpt = (reportURL, reportText, reportDate, entityName) => (
    <span>
      <a
        href={reportURL}
        style={{ color: "black" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <b>{new Date(reportDate).toLocaleDateString()}:</b>
        {searchAllCompanies && entityName && <b> {entityName}:</b>}
      </a>
      <span>
        {splitTextExerpts(reportText)
          .filter((txt) => txt.trim() !== "")
          .map((txt, idx) => (
            <span key={idx}>
              {idx !== 0 && <span> | </span>} "{formatString(txt, reportURL)}"
            </span>
          ))}
      </span>
    </span>
  );

  return (
    <div className="container px-2">
      <h2>
        Disclosure Search for{" "}
        {!searchAllCompanies ? companyInfo?.Company : "All Companies"}:
      </h2>
      <div className="row">
        <div className="col-2">
          <Button
            type="primary"
            className="w-100"
            onClick={() => setSearchAllCompanies(!searchAllCompanies)}
          >
            {searchAllCompanies
              ? "Search within company"
              : "Search all companies"}
          </Button>
        </div>
        <div className="col">
          {disabled && companyInfo?.Company ? (
            <Tooltip title="Disclosure search is not available for this company.">
              <div>
                <Search
                  placeholder="Search keywords… (e.g. 10-K, covid impact, CEO, bonus pay, etc.)"
                  value={searchQuery}
                  onChange={(e) => handleChange(e.target.value)}
                  disabled={disabled}
                  onSearch={() => handleSubmit()}
                  loading={loading}
                  enterButton={"Search Disclosures"}
                />
              </div>
            </Tooltip>
          ) : (
            <div>
              <Search
                placeholder="Search keywords… (e.g. 10-K, covid impact, CEO, bonus pay, etc.)"
                value={searchQuery}
                onChange={(e) => handleChange(e.target.value)}
                disabled={disabled}
                onSearch={() => handleSubmit()}
                loading={loading}
                enterButton={"Search Disclosures"}
              />
            </div>
          )}
        </div>
      </div>
      <div className="disclosure-results pt-2">
        {loading && <div>Searching...</div>}
        {searchResults?.length === 0 && !loading && (
          <div>
            No results for search "{noResultsForSearchQuery}"{" "}
            {!searchAllCompanies && `for ${companyInfo.Company}`}
          </div>
        )}
        {searchResults?.length > 10 && (
          <div className="row py-2">
            {page > 1 ? (
              <div className="col-6" onClick={() => setPage(page - 1)}>
                <p style={{ cursor: "pointer", color: "var(--blue)" }}>
                  Previous Page
                </p>
              </div>
            ) : (
              <div className="col-6"></div>
            )}
            {searchResults?.length > page * 10 ? (
              <div
                className="col-6 text-right"
                onClick={() => setPage(page + 1)}
              >
                <p
                  style={{
                    cursor: "pointer",
                    textAlign: "right",
                    color: "var(--blue)",
                  }}
                >
                  Next Page
                </p>
              </div>
            ) : (
              <div className="col-6"></div>
            )}
          </div>
        )}
        <ul>
          {searchErrorMessage && !loading ? (
            <div>{searchErrorMessage}</div>
          ) : (
            searchResults?.length > 0 &&
            !loading &&
            searchResults
              .slice((page - 1) * 10, page * 10)
              .map((result, index) => (
                <li key={index} className="pt-1">
                  {formatTextExcerpt(
                    result["document.uri"],
                    result["document.example"],
                    result["report.filing-date"],
                    result["entity.name"]
                  )}
                </li>
              ))
          )}
        </ul>
      </div>
    </div>
  );
}
