import {
  SET_USER_INFO,
  SET_USER_IP,
  SIGN_OUT
} from "../constants/UserConstant";

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  ip: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_IP: {
      return { ...state, ip: action.ip };
    }
    case SET_USER_INFO: {
      return { ...state, ...action.user };
    }

    case SIGN_OUT: {
      const { ip } = state.ip;
      return {
        ip: ip
      };
    }
    default:
      return state;
  }
};
