import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Switch } from "antd";

import VolatilityCalculator from "./OptionsCalculators/VolatilityCalculator";
import BlackScholesCalculator from "./OptionsCalculators/BlackScholesCalculator";
import PerformanceShareAwardsCalculator from "./OptionsCalculators/PerformanceShareAwardsCalculator";
import SimulatePeerRankings from "./OptionsCalculators/SimulatePeerRankings";

import { RadioSelector } from "../utils/Constants/systemSetting";
import FinalValue from "./OptionsCalculators/utils/FinalValue";

import { OptionsFAQTopics } from "../assets/faqTopicsOptions";

export default function RTSRCalculators({ companyInfo }) {
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  const [optionsCalculatorOptions, setOptionsCalculatorOptions] = useState([
    "Ex-Ante rTSR PSA Calculator",
    "Ex-Post rTSR PSA Calculator",
  ]);
  const [optionsCalculatorSelected, setOptionsCalculatorSelected] = useState(
    optionsCalculatorOptions[0]
  );

  const optionsCalculatorOptionsHoverTextMap = {
    "Ex-Ante rTSR PSA Calculator":
      OptionsFAQTopics.ex_ante_rtsr_psa_calculator_assumptions.short_desc,
    "Ex-Post rTSR PSA Calculator":
      OptionsFAQTopics.ex_post_rtsr_psa_calculator_assumptions.short_desc,
  };

  // Volatility Calculator States
  const [
    lookbackVolatilityBlendedAverage,
    setLookbackVolatilityBlendedAverage,
  ] = useState(null);
  const [calculatingVolatility, setCalculatingVolatility] = useState(true);

  // Black-Scholes Calculator States
  const [showVolatilityEditView, setShowVolatilityEditView] = useState(false);
  const VolatilitySwitch = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <h3 style={{ fontSize: 20 }}>Use Volatility Calculator: </h3>
        <div style={{ height: 38, marginLeft: 10 }}>
          <Switch
            checked={showVolatilityEditView}
            onChange={() => setShowVolatilityEditView((prev) => !prev)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>
              Contact your account manager to access the Options Awards
              Calculators which allow you to estimate the value of your
              executive’s stock options. Also access the Performance Share
              Awards calculator which allows you to estimate the value of a
              Performance Stock Unit (PSU) at inception, expiration, or any
              point in between and generate relative TSR percentiles.
            </p>
          </h3>
        </div>
      ) : (
        <>
          <div className="company-calculator-section col-lg-9 executives p-0">
            <RadioSelector
              options={optionsCalculatorOptions}
              state={optionsCalculatorSelected}
              setState={setOptionsCalculatorSelected}
              heading={"Select rTSR Calculator:"}
              style={{ justifyContent: "center" }}
              optionsHoverTextMap={optionsCalculatorOptionsHoverTextMap}
            />

            {optionsCalculatorSelected === "Ex-Ante rTSR PSA Calculator" && (
              <SimulatePeerRankings companyInfo={companyInfo} />
            )}

            {optionsCalculatorSelected === "Ex-Post rTSR PSA Calculator" && (
              <PerformanceShareAwardsCalculator companyInfo={companyInfo} />
            )}
          </div>

          <VolatilityCalculator
            companyInfo={companyInfo}
            showVolatilityEditView={showVolatilityEditView}
            lookbackVolatilityBlendedAverage={lookbackVolatilityBlendedAverage}
            setLookbackVolatilityBlendedAverage={
              setLookbackVolatilityBlendedAverage
            }
            calculatingVolatility={calculatingVolatility}
            setCalculatingVolatility={setCalculatingVolatility}
          />
        </>
      )}
    </>
  );
}
