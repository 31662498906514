/* eslint-disable import/no-anonymous-default-export */
import { HIDE_MODAL, SHOW_MODAL } from "../constants/ModalConstant"

const initialState = {
    visible: false,
    title: '', 
    Component: <></>
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL: {
            return {visible: true, Component: action.Component, title: action.title}
        }
        case HIDE_MODAL: {
            return {...state, visible: false}
        }
    

    default:
        return state
    }
}
