import { SET_PLANS } from "../constants/PlansConstant"

const initialState = {
    plans: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PLANS: {
            return {...state, plans: action.plans}
        }
   
    default:
        return state
    }
}
