import React from "react";
import "../css/homepage.css";
import WSJLogo from "../assets/featuredBy/wsjLogo.svg";
import CNBCLogo from "../assets/featuredBy/cnbcLogo.svg";
import MintLogo from "../assets/featuredBy/mintLogo.svg";
import TheTimesLogo from "../assets/featuredBy/thetimesLogo.png";
import CFOJournalLogo from "../assets/featuredBy/cfojournalLogo.png";

const allArticlesFeaturedIn = [
  {
    name: "WSJ",
    logo: WSJLogo,
    url: "https://www.wsj.com/articles/meet-the-ceos-who-pull-in-more-than-100-million-a-year-8409afe0",
  },
  {
    name: "CNBC",
    logo: CNBCLogo,
    url: "https://www.cnbc.com/2023/07/05/heres-how-much-the-10-highest-paid-us-ceos-earn.html",
  },
  {
    name: "Mint",
    logo: MintLogo,
    url: "https://www.livemint.com/specials/meet-the-ceos-who-pull-in-more-than-100-million-a-year-11688474554941.html",
  },
  {
    name: "The Times",
    logo: TheTimesLogo,
    url: "https://www.thetimes.co.uk/article/highest-paid-ceo-usa-peloton-pinterest-79xhbxnfh",
  },
  {
    name: "CFO Journal",
    logo: CFOJournalLogo,
    url: "https://www.wsj.com/articles/meet-the-highest-paid-cfos-of-2023-1b8e7748",
    customWidthPercent: "150%",
  },
];

export default function FeaturedIn() {
  const FeaturedInArticle = ({
    articleName,
    articleLogo,
    articleURL,
    customWidthPercent = null,
  }) => {
    return (
      <a
        href={articleURL}
        target="_blank"
        rel="noopener noreferrer"
        style={{ verticalAlign: "bottom", textAlign: "center" }}
      >
        <div className="centered-flex logo-container with-link">
          <img
            className="article-logo"
            src={articleLogo}
            style={{
              width: customWidthPercent ? customWidthPercent : "100%",
              verticalAlign: "bottom",
              objectFit: "contain",
            }}
            alt={articleName + "-logo"}
          />
        </div>
      </a>
    );
  };

  return (
    <div className="featured-in">
      <hr className="divider-line" />
      <div className="section">
        <div className="container mw-2000 centered-flex">
          <div className="inner-box">
            <h2 className="hero-text">AS FEATURED IN</h2>
            <div className="centered-flex w-100">
              {allArticlesFeaturedIn.map((article) => {
                return (
                  <FeaturedInArticle
                    articleName={article.name}
                    articleLogo={article.logo}
                    articleURL={article.url}
                    customWidthPercent={article.customWidthPercent}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <hr className="divider-line" />
    </div>
  );
}
