import React, { useEffect } from "react";
import "./paywall.css";
import { Button } from "../../JSS/Button";
import { useDispatch, useSelector } from "react-redux";
import { hidePaywall } from "../../redux/actions/PaywallAction";
import { LinkButton } from "../../JSS/LinkButton";
import { Link } from "react-router-dom";
import { getSubscription } from "../../redux/actions/CustomerAction";

export default function Paywall() {
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state.CustomerReducer);
  useEffect(() => {
    dispatch(getSubscription());
  }, []);
  useEffect(() => {
    if (["active", "trialing"].includes(subscription?.status)) {
      dispatch(hidePaywall());
    }
  }, [subscription]);

  return (
    <div className="paywall">
      <div className="paywall-content">
        <button
          onClick={() => {
            dispatch(hidePaywall());
          }}
          className="close"
        >
          <i className="fa fa-times"></i> Close
        </button>
        <div className="paywall-text">
          <center>
            <h1>Your C-Suite Comp Membership has expired!</h1>
            <p style={{ fontSize: "1.2em" }}>
              Re&#8209;Subscribe now to access our live and
              up&#8209;to&#8209;date C&#8209;Suite compensation analytics &#38;
              visualization platform to make informed decisions.
            </p>
          </center>

          <div className="btn d-flex justify-content-center">
            <Link to="/subscription/reactivate">
              <LinkButton style={{ marginTop: 25 }} primary>
                Re-Subscribe Now
              </LinkButton>
            </Link>
          </div>
        </div>

        <div className="overlay-1"></div>
        <div className="overlay-2"></div>
      </div>
    </div>
  );
}
