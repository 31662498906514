import React from "react";
import LoginAndSignup from "../components/Login/LoginAndSignup";

export default function LoginAndSignupPage() {
  return (
    <div className="loginSignupContainer" style={{ display: "inline-flex" }}>
      <LoginAndSignup />
    </div>
  );
}
