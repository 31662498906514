import React, { useState, useMemo } from "react";
import { Table, Tooltip } from "antd";
import {
  customParseFloat,
  fromDollar,
  getPercentile,
  formatMeasure,
  hasHoverText,
} from "../../../utils/Constants/systemSetting";

export default function MeasurePercentileTable({
  cohortTableSelector,
  measure,
  sortedTableKeys,
}) {
  const [customPercentile, setCustomPercentile] = useState(0);

  const cohortPercentileColumns = [
    {
      title: `${cohortTableSelector} ${measure.title}`,
      dataIndex: "type",
      key: "type",
      width: "50%",
      editable: true,
      render: (text, record, index) => {
        const editable = record.type.includes("Custom Percentile");

        return editable ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={
                "Enter your desired percentile. The output indicates the measure’s value at that percentile level. For e.g. if you enter 90 and have the Revenue measure selected above, the outputted value indicates the Revenue level below which 90% of the cohort companies’ Revenue levels fall."
              }
            >
              {hasHoverText(
                <div style={{}}>
                  <p style={{ whiteSpace: "nowrap", margin: 0 }}>{text}</p>
                </div>
              )}
            </Tooltip>
            <input
              className="custom"
              type="number"
              name="custom"
              value={customPercentile}
              step="0.01"
              onChange={(e) => {
                let value = customParseFloat(e.target.value);
                if (value < 0) {
                  value = 0;
                } else if (value > 100) {
                  value = 100;
                }
                setCustomPercentile(value.toLocaleString());
              }}
              onBlur={() =>
                setCustomPercentile(
                  customPercentile === "" ? 0 : customPercentile
                )
              }
              style={{ width: 70, borderRadius: 5 }}
            />
          </div>
        ) : (
          text
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "50%",
    },
  ];

  const cohortPercentileData = useMemo(() => {
    const values = sortedTableKeys
      .filter((key) => {
        return (
          key !== null &&
          !["N/A", "N", "P"].some((excludeTerm) =>
            key.measureValue.toString().includes(excludeTerm)
          )
        );
      })
      .map((key) =>
        key.toString().includes("%")
          ? parseFloat(key.measureValue)
          : fromDollar(key.measureValue)
      );
    const sorted = values.sort((a, b) => a - b);
    const len = sorted.length;
    const total = values.reduce((total, acc) => total + acc, 0);
    const dataType = measure.dataType;
    return [
      {
        key: "median",
        type: `Median`,
        amount: formatMeasure(getPercentile(sorted, 0.5), dataType),
      },
      {
        key: "average",
        type: `Average`,
        amount: formatMeasure(Math.round(total / len), dataType),
      },
      {
        key: "max",
        type: `Maximum`,
        amount: formatMeasure(sorted[len - 1], dataType),
      },
      {
        key: "min",
        type: `Minimum`,
        amount: formatMeasure(sorted[0], dataType),
      },
      {
        key: "custom",
        type: `Custom Percentile`,
        amount: formatMeasure(
          getPercentile(sorted, customPercentile / 100),
          dataType
        ),
      },
    ];
  }, [customPercentile, measure, sortedTableKeys]);

  return (
    <div
      className="cohort-percentile-table"
      style={{
        marginBottom: 25,
        marginTop: 0,
        flex: 1,
      }}
    >
      <Table
        columns={cohortPercentileColumns}
        dataSource={cohortPercentileData}
        pagination={{ hideOnSinglePage: true }}
        bordered={true}
      />
    </div>
  );
}
