import { toDollar } from "../Constants/systemSetting";
import { Link } from "react-router-dom";

export const createColumn = (
  title,
  handleCheck,
  handleResetCheck,
  currentYear,
  handleSort
) => {
  return [
    {
      title: "Company",
      dataIndex: "company",
      sorter: {
        compare: (a, b) => {
          return a.company.trim().toLowerCase() > b.company.trim().toLowerCase()
            ? 1
            : -1;
        },
      },
      defaultSortOrder: "ascend",
      width: 250,
      fixed: "left",
      render: (text, record) => {
        return (
          <div className="table-header d-flex align-items-center">
            <input
              onChange={(e) => {
                handleCheck(e, record);
              }}
              type="checkbox"
              className="company-checkbox"
            />
            <Link to={`/company/${record.ticker}`} className="ms-2">
              {text}
            </Link>
          </div>
        );
      },
    },
    {
      title: "Industry",
      dataIndex: "industry",
      width: 100,
      sorter: {
        compare: (a, b) => {
          return a.industry.trim().toLowerCase() >
            b.industry.trim().toLowerCase()
            ? 1
            : -1;
        },
      },
      fixed: "left",
      align: "center",
    },
    {
      title: title.toUpperCase(),
      dataIndex: "name",
      fixed: "left",
      sorter: {
        compare: (a, b) => {
          return a.name.trim().toLowerCase() > b.name.trim().toLowerCase()
            ? 1
            : -1;
        },
      },
      align: "center",
      width: 150,
    },
    {
      title: "Base Salary",
      dataIndex: "compensation",
      key: "salary",
      width: 50,
      sorter: {
        compare: (a, b) => {
          return handleSort(
            parseInt(a.compensation?.[currentYear]?.salary) || "N/A",
            parseInt(b.compensation?.[currentYear]?.salary) || "N/A"
          );
        },
      },
      align: "center",
      render: (text, record) => {
        return toDollar(text?.[currentYear]?.salary) || "–";
      },
    },
    {
      title: "Bonus Payout",
      dataIndex: "compensation",
      key: "bonus",
      width: 50,
      sorter: {
        compare: (a, b) => {
          return handleSort(
            parseInt(a.compensation?.[currentYear]?.bonus) || "N/A",
            parseInt(b.compensation?.[currentYear]?.bonus) || "N/A"
          );
        },
      },
      align: "center",
      render: (text, record) => {
        return toDollar(text?.[currentYear]?.bonus) || "–";
      },
    },
    {
      title: "Stock Awards",
      dataIndex: "compensation",
      key: "stockAward",
      width: 50,
      sorter: {
        compare: (a, b) => {
          return handleSort(
            parseInt(a.compensation?.[currentYear]?.stockAward) || "N/A",
            parseInt(b.compensation?.[currentYear]?.stockAward) || "N/A"
          );
        },
      },
      align: "center",
      render: (text, record) => {
        return toDollar(text?.[currentYear]?.stockAward) || "–";
      },
    },
    {
      title: "Option Awards",
      dataIndex: "compensation",
      key: "optionAward",
      width: 50,
      sorter: {
        compare: (a, b) => {
          return handleSort(
            parseInt(a.compensation?.[currentYear]?.optionAward) || "N/A",
            parseInt(b.compensation?.[currentYear]?.optionAward) || "N/A"
          );
        },
      },
      align: "center",
      render: (text, record) => {
        return toDollar(text?.[currentYear]?.optionAward) || "–";
      },
    },
    {
      title: "Non-Equity Incentive Plan Compensation",
      dataIndex: "compensation",
      key: "nonEquity",
      width: 50,
      sorter: {
        compare: (a, b) => {
          return handleSort(
            parseInt(a.compensation?.[currentYear]?.nonEquity) || "N/A",
            parseInt(b.compensation?.[currentYear]?.nonEquity) || "N/A"
          );
        },
      },
      align: "center",
      render: (text, record) => {
        return toDollar(text?.[currentYear]?.nonEquity) || "–";
      },
    },
    {
      title:
        "Change in Pension Value & Nonqualified Deferred Compensation Earnings",
      dataIndex: "compensation",
      key: "pension",
      width: 50,
      sorter: {
        compare: (a, b) => {
          return handleSort(
            parseInt(a.compensation?.[currentYear]?.pension) || "N/A",
            parseInt(b.compensation?.[currentYear]?.pension) || "N/A"
          );
        },
      },
      align: "center",
      render: (text, record) => {
        return toDollar(text?.[currentYear]?.pension) || "–";
      },
    },
    {
      title: "All Other Compensation",
      dataIndex: "compensation",
      key: "other",
      width: 50,
      sorter: {
        compare: (a, b) => {
          return handleSort(
            parseInt(a.compensation?.[currentYear]?.other) || "N/A",
            parseInt(b.compensation?.[currentYear]?.other) || "N/A"
          );
        },
      },
      align: "center",
      render: (text, record) => {
        return toDollar(text?.[currentYear]?.other) || "–";
      },
    },
    {
      title: <b>Total Compensation</b>,
      dataIndex: "compensation",
      key: "total",
      width: 50,
      sorter: {
        compare: (a, b) => {
          return handleSort(
            parseInt(a.compensation?.[currentYear]?.total) || "N/A",
            parseInt(b.compensation?.[currentYear]?.total) || "N/A"
          );
        },
      },
      align: "center",
      render: (text, record) => {
        return <b>{toDollar(text?.[currentYear]?.total) || "–"}</b>;
      },
    },
  ];
};

export const createAllExecutivesColumn = (
  title,
  handleCheck,
  handleResetCheck
) => {
  console.log(title);

  return [
    {
      title: "Company",
      dataIndex: "company",
      width: 200,
      fixed: "left",
      render: (text, record) => {
        return (
          <div className="table-header d-flex align-items-center">
            <input
              onChange={(e) => {
                handleCheck(e, record);
              }}
              type="checkbox"
              className="company-checkbox"
            />
            <Link to={`/company/${record.ticker}`} className="ms-2">
              {text}
            </Link>
          </div>
        );
      },
    },
    {
      title: "Industry",
      // dataIndex: "industry",
      // width: 350,
      // fixed: "left",
      // render: (text, record) => (
      //     <span className="industry">{text.toLowerCase()}</span>
      // ),
    },
    {
      title: title.toUpperCase(),
      dataIndex: title,
      fixed: "left",
      width: 150,
      render: (text, record) => text?.name || "N/A",
    },
    {
      title: "Base Salary",
      dataIndex: title,
      width: 50,
      align: "center",
      render: (text, record) => {
        return text ? toDollar(text.salary) : "N/A";
      },
    },
    {
      title: "Bonus Payout",
      dataIndex: title,
      width: 50,
      align: "center",
      render: (text, record) => {
        return text ? toDollar(text.bonus) : "N/A";
      },
    },
    {
      title: "Stock Awards",
      dataIndex: title,
      width: 50,
      align: "center",
      render: (text, record) => {
        return text ? toDollar(text.stockAward) : "N/A";
      },
    },
    {
      title: "Option Awards",
      dataIndex: title,
      width: 50,
      align: "center",
      render: (text, record) => {
        return text ? toDollar(text.optionAward) : "N/A";
      },
    },
    {
      title: "Non-Equity Incentive Plan Compensation",
      dataIndex: title,
      width: 50,
      align: "center",
      render: (text, record) => {
        return text ? toDollar(text.nonEquity) : "N/A";
      },
    },
    {
      title:
        "Change in Pension Value & Nonqualified Deferred Compensation Earnings",
      dataIndex: title,
      width: 50,
      align: "center",
      render: (text, record) => {
        return text ? toDollar(text.pension) : "N/A";
      },
    },
    {
      title: "All Other Compensation",
      dataIndex: title,
      width: 50,
      align: "center",
      render: (text, record) => {
        return text ? toDollar(text.other) : "N/A";
      },
    },
    {
      title: "TOTAL COMPENSATION",
      dataIndex: title,
      fixed: "center",
      width: 50,
      align: "center",
      render: (text, record) => {
        return text ? toDollar(text.total) : "N/A";
      },
    },
  ];
};
