import { SET_HISTORY } from "../constants/HistoryConstant"

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    history: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_HISTORY: {
            return {...state, history: action.history}
        }
   
    default:
        return state
    }
}
