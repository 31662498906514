export default function slugifyName(name) {
  // Remove any stray characters and normalize whitespace
  name = name.replace(/[^\w\s]+/g, "").trim();
  // Split name into array of words
  const words = name.split(" ");
  const excludeTitles = [
    "phd",
    "md",
    "dds",
    "do",
    "dvm",
    "dmd",
    "jd",
    "esq",
    "pharmd",
    "rn",
    "msn",
    "bsn",
    "np",
    "pa",
    "lcsw",
    "lmft",
    "lpcc",
    "mb",
    "chb",
  ];
  for (let i = 1; i < words.length; i++) {
    // Remove any excluded titles
    for (const word of words) {
      if (excludeTitles.includes(word.toLowerCase())) {
        words.splice(words.indexOf(word), 1);
      }
    }
  }
  // If there are more than 2 words, assume the second word is a middle initial and exclude any trailing degrees or titles
  if (words.length > 2) {
    words[1] = words[1].substring(0, 1); // Keep only the first letter of the middle initial
    // Exclude any trailing degrees or titles
  }
  // Join the words with hyphens and return the slugified name
  return words.join("-").toLowerCase();
}
