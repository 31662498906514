/* eslint-disable import/no-anonymous-default-export */
import companyList from "../../assets/sampleData.json";
import {
  SET_ALL_COMPANIES,
  SET_ALL_MARKET_CAP,
  SET_EXECUTIVES,
} from "../constants/CompanyConstant";

const initialState = {
  companies: companyList,
  marketCap: [],
  executives: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_COMPANIES: {
      return { ...state, companies: action.companies };
    }
    case SET_ALL_MARKET_CAP: {
      return { ...state, marketCap: action.marketCap };
    }
    case SET_EXECUTIVES: {
      return { ...state, executives: action.executives };
    }
    default:
      return state;
  }
};
