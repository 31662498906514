import { combineReducers, applyMiddleware, createStore } from "redux";
import createMiddlewareSaga from "redux-saga";
import { rootSaga } from "../saga/rootSaga.js";
import ForgotPasswordReducer from "./ForgotPasswordReducer.js";
import HistoryReducer from "./HistoryReducer.js";
import LoadingReducer from "./LoadingReducer.js";
import UserReducer from "./UserReducer.js";
import ExecutivesReducer from "./ExecutivesReducer.js";
import PaywallReducer from "./PaywallReducer.js";
import CompaniesReducer from "./CompaniesReducer.js";
import ComparisonTableReducer from "./ComparisonTableReducer.js";
import TableFilterReducer from "./TableFilterReducer.js";
import ModalReducer from "./ModalReducer.js";
import PlansReducer from "./PlansReducer.js";
import CustomerReducer from "./CustomerReducer.js";
import CompanyReducer from "./CompanyReducer.js";

import { persistStore } from "redux-persist";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const middlewareSaga = createMiddlewareSaga();

const rootReducer = combineReducers({
  HistoryReducer,
  ForgotPasswordReducer,
  LoadingReducer,
  UserReducer,
  ExecutivesReducer,
  PaywallReducer,
  CompaniesReducer,
  ComparisonTableReducer,
  TableFilterReducer,
  ModalReducer,
  PlansReducer,
  CustomerReducer,
  CompanyReducer,
});

// export const store = createStore(rootReducer, applyMiddleware(middlewareSaga));
// export const persistor = persistStore(store);

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(middlewareSaga));
const persistor = persistStore(store);
middlewareSaga.run(rootSaga);

export { store, persistor };
