import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../redux/actions/ModalAction';
import { Button } from '../JSS/Button';

export default function ModalHOC() {

    const {visible, Component, title} = useSelector(state => state.ModalReducer);
    const dispatch = useDispatch();

    const handleCancel = () => {
        dispatch(hideModal());
    }

    const renderFooter = () => {
        return <div className="text-center">
            <Button onClick={handleCancel} primary>Close</Button>
        </div>
    }

    return (
        <Modal title={title} width={1000} footer={renderFooter()} className="custom-modal" visible={visible} onCancel={handleCancel}>
            {Component}
        </Modal>
    )
}
