import { deserialize } from "bson";
import crypto from "crypto";
import toast from "react-hot-toast";
import { Redirect } from "react-router-dom";

const algorithm = "aes-256-ctr";
const encryptionKey = localStorage.getItem("KEY");
// const encryptionKey = "invalid"; // test expired key
// console.log(encryptionKey);
const key = crypto
  .createHash("sha256")
  .update(String(encryptionKey))
  .digest("base64")
  .substring(0, 32);

export function parseJSON(encryptedData) {
  // encryptedData = Buffer.from(encryptedData.toString(), "binary");

  try {
    const decrypt = (encrypted) => {
      // Get the iv: the first 16 bytes
      const iv = encrypted.slice(0, 16);
      // Get the rest
      encrypted = encrypted.slice(16);
      // Create a decipher
      const decipher = crypto.createDecipheriv(algorithm, key, iv);
      // Actually decrypt it
      const result = Buffer.concat([
        decipher.update(encrypted),
        decipher.final(),
      ]);
      return result;
    };

    const decrypted = JSON.parse(decrypt(encryptedData));
    // console.log("Decrypted:", JSON.stringify(decrypted));

    // De serialize it again
    // const JSONdata = deserialize(decrypted);
    // console.log("JSONdata:", JSONdata);
    // console.log(decrypted);
    return decrypted;
  } catch (error) {
    // console.log(`parseJSON Error: ${error}`);
    return "key_not_valid";
  }
}

// export default async function parseJSON(encryptedData) {
//   try {
//     const decrypt = (encrypted) => {
//       const iv = encrypted.slice(0, 16);
//       encrypted = encrypted.slice(16);
//       const decipher = crypto.createDecipheriv(algorithm, key, iv);
//       const result = Buffer.concat([
//         decipher.update(encrypted),
//         decipher.final(),
//       ]);
//       return result;
//     };
//     const decrypted = decrypt(encryptedData);
//     console.log(decrypted.toString());
//     const JSONdata = deserialize(decrypted.toString());
//     console.log(JSONdata);
//     return JSON.parse(JSONdata);
//   } catch (error) {
//     console.log(`parseJSON Error: ${error}`);
//   }
// }
