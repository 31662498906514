import React, { useRef, useState, useEffect } from "react";
import { Button } from "../../JSS/Button";
import Input from "../Input/Input";
import { Tooltip } from "antd";
import {
  CSUITE_DOMAIN,
  EMAIL_REGEX,
  PASSWORD_REGEX,
  STATUS_CODE,
  PUBLIC_RECAPTCHA_SITE_KEY,
} from "../../utils/Constants/systemSetting";
import { useDispatch } from "react-redux";
import { signUp } from "../../redux/actions/UserAction";
import { Select } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import UserService from "../../services/UserService";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
// import GoogleLoginButton from "./oAuthButtons/GoogleLoginButton";
// import FacebookLoginButton from "./oAuthButtons/FBloginButton";

const { Option } = Select;

export default function SignUpForm(props) {
  const dispatch = useDispatch();

  const [selectKey, setSelectKey] = useState(0);
  const [showPhone, setShowPhone] = useState(false);
  const [invalid, setInvalid] = useState(true);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [passwordInvalid, setPasswordInvalid] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(
    invalid || captchaRef === null
  );

  useEffect(() => {
    setButtonDisabled(invalid || captchaRef === null);
  }, [captchaRef, invalid]);
  const emailRef = useRef(null);
  const reRef = useRef(null);

  const [status, setStatus] = useState("");

  const [state, setState] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    companyName: "",
    companyWebsite: "",
    employees: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const values = { ...state };
    values[name] = value;

    // Adjust autofill for phone, assume US phone (+1-...)
    if (name == "phone" && value.length === 10) {
      values[name] = "1" + value;
    }

    let valid = true;

    if (!EMAIL_REGEX.test(values.email)) {
      valid = false;
    }

    if (!PASSWORD_REGEX.test(values.password)) {
      valid = false;
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }

    for (let key in values) {
      if (values[key].trim() === "") {
        valid = false;
      }
    }

    setInvalid(!valid);
    setState({ ...values });
  };

  const handleSelectChange = (value) => {
    const values = { ...state };
    values["employees"] = value;
    let valid = true;

    for (let key in values) {
      if (values[key].trim() === "") {
        valid = false;
      }
    }
    setInvalid(!valid);
    setState({ ...values });
  };

  const handleChangePhone = (phone) => {
    const values = { ...state };
    values["phone"] = phone;
    let valid = true;

    for (let key in values) {
      if (values[key].trim() === "") {
        valid = false;
      }
    }
    setInvalid(!valid);
    setState({ ...values });
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const token = captchaRef;

      const newUser = {
        ...state,
        token,
      };

      dispatch(signUp(newUser));
      reRef.current.reset();
      setCaptchaRef(null);

      // setState({
      //   fname: "",
      //   lname: "",
      //   email: "",
      //   phone: "",
      //   companyName: "",
      //   companyWebsite: "",
      //   employees: "",
      //   password: "",
      // });
      // setSelectKey(selectKey + 1);
      // setShowPhone(false);
      // setInvalid(true);
    } catch (err) {
      // console.log(err);
      // console.log(err?.response);
      toast.error(
        "Cannot create new account right now. Please try again later."
      );
    }
  };

  const checkExistingEmail = async (e) => {
    try {
      const { status, data } = await UserService.checkExistingEmail(
        e.target.value
      );
      if (status === STATUS_CODE.SUCCESS) {
        if (EMAIL_REGEX.test(state.email)) {
          setStatus(data.valid);
        }
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="sign-up-form">
      <h1>Create Account</h1>
      {/* <div className="social-container">
        {props.active ? (
          <FacebookLoginButton setUser={props.setUser} />
        ) : (
          <div className="social">
            <i className="fab fa-facebook-f"></i>
          </div>
        )}
        {props.active ? (
          <GoogleLoginButton setUser={props.setUser} />
        ) : (
          <div className="social">
            <i className="fab fa-google"></i>
          </div>
        )}
      </div>
      <span>or use your email for registration</span> */}
      <div className="row">
        <div className="col-6">
          <Input
            name="fname"
            value={state.fname}
            onChange={handleChange}
            required
            type="text"
            label="First Name"
          />
        </div>
        <div className="col-6">
          <Input
            name="lname"
            value={state.lname}
            onChange={handleChange}
            required
            type="text"
            label="Last Name"
          />
        </div>
      </div>

      <Input
        name="companyName"
        onChange={handleChange}
        value={state.companyName}
        required
        label="Company Name"
      />

      <Input
        name="companyWebsite"
        onChange={handleChange}
        value={state.companyWebsite}
        required
        label="Company Website"
      />

      <div className="select-container" style={{ borderColor: "var(--black)" }}>
        <Select
          name="employees"
          className="antd-select"
          size="large"
          key={selectKey}
          placeholder="Number of Employees"
          onChange={handleSelectChange}
          style={{ width: "100%", marginTop: 12 }}
          bordered={false}
          // dropdownStyle={{ padding: "-50px" }}
        >
          <Option key="1" value="1-10">
            1-10 employees
          </Option>
          <Option key="2" value="10-100">
            10-100 employees
          </Option>
          <Option key="3" value="100-1000">
            100-1000 employees
          </Option>
          <Option key="4" value="1000+">
            1000+ employees
          </Option>
        </Select>
      </div>

      {showPhone ? (
        <div style={{ width: "100%" }}>
          <PhoneInput
            name="phone"
            placeholder="Phone Number"
            country="us"
            preferredCountries={["us"]}
            // onlyCountries={["us"]}
            onChange={(phone) => handleChangePhone(phone)}
            onBlur={() => (!state.phone ? setShowPhone(false) : "")}
            value={state.phone}
            inputProps={{
              autoFocus: true,
            }}
            inputStyle={{
              borderRadius: "0.5rem",
              // border: `1px solid ${state.errors.phone}`,
              // borderColor: "var(--secondary)",
              width: "100%",
              padding: "0px 12px 0px 48px",
              fontSize: "1.2em",
              height: "47px",
              autoFocus: true,
            }}
            containerStyle={{ marginTop: "15px" }}
          />
        </div>
      ) : (
        <Input
          name="phone"
          type="text"
          onChange={handleChange}
          value={state.phone}
          required
          label="Phone Number"
          onFocus={() => {
            setShowPhone(true);
          }}
        />
      )}

      <div className={`checking-email w-100 ${status}`}>
        <Input
          name="email"
          value={state.email}
          onChange={(e) => {
            handleChange(e);
            if (emailRef.current) {
              setStatus("validating");
              clearTimeout(emailRef.current);
            }
            emailRef.current = setTimeout(() => {
              checkExistingEmail(e);
            }, 300);
          }}
          onBlur={checkExistingEmail}
          required
          label="Email"
        />

        <div className="valid status">
          <i className="fa fa-check"></i>
        </div>
        <Tooltip
          overlayInnerStyle={{
            backgroundColor: "var(--dark-red)",
            color: "var(--white)",
            borderRadius: "0.5rem",
            padding: "10px",
          }}
          title="Email is already exists. Please Log in or try another email."
        >
          <div className="invalid status">
            <i className="fa fa-exclamation"></i>
          </div>
        </Tooltip>
        <div className="validating status">
          <img
            src={require("../../assets/loading-2.svg").default}
            alt="loading"
          />
        </div>
      </div>

      <Tooltip
        overlayStyle={{ maxWidth: "345px" }}
        trigger="focus"
        overlayInnerStyle={{
          borderRadius: "5px",
          color: "var(--white)",
          backgroundColor: "var(--dark-red)",
        }}
        title={
          passwordInvalid
            ? "Please enter a combination of 6-20 characters. Must include at least one letter, number, and special character (e.g. '!', '&')."
            : ""
        }
        placement="right"
      >
        <Input
          name="password"
          value={state.password}
          onChange={handleChange}
          required
          type="password"
          label="Password"
        />
      </Tooltip>

      <div
        className="captchaContainer"
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "12px 0 6px",
        }}
      >
        <ReCAPTCHA
          sitekey={PUBLIC_RECAPTCHA_SITE_KEY}
          size="normal"
          onChange={(e) => setCaptchaRef(e)}
          ref={reRef}
        />
      </div>

      <p
        className="pb-2"
        style={{ textAlign: "center", fontSize: "0.85em", margin: 0 }}
      >
        By joining, you are agreeing to our{" "}
        <a href="/privacy">Privacy Policy</a>, <a href="/terms">Terms of Use</a>
        , and to recieve occasional emails from our team.
      </p>

      <Tooltip
        overlayStyle={{ maxWidth: "345px" }}
        placement="right"
        overlayInnerStyle={{
          borderRadius: "5px",
          color: "var(--white)",
          backgroundColor: "var(--dark-red)",
        }}
        title={
          invalid
            ? "Please fill out all fields."
            : captchaRef === null
            ? "Please complete the reCAPTCHA above."
            : ""
        }
      >
        <div
          style={{
            cursor: buttonDisabled ? "not-allowed" : "auto",
            visibility: buttonDisabled ? "visible" : "hidden",
          }}
        >
          <Button
            type="submit"
            disabled={buttonDisabled}
            style={{
              pointerEvents: buttonDisabled ? "none" : "auto",
              visibility: "visible",
            }}
            primary
          >
            Join
          </Button>
        </div>
      </Tooltip>
    </form>
  );
}
