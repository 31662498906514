export default function FinalValue({
  heading,
  value,
  decimalPlaces = 5,
  headingWidth = undefined,
  isPercent = false,
  isDollar = false,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
      }}
    >
      <h3
        style={{
          width: headingWidth ? headingWidth : "",
          marginRight: !headingWidth ? 10 : "",
        }}
      >
        {heading}:
      </h3>
      <h2
        style={{
          fontWeight: "bolder",
          textDecoration: "underline",
          textUnderlineOffset: 5,
        }}
      >
        {!isNaN(parseFloat(value))
          ? (isDollar ? "$" : "") +
            parseFloat(value).toLocaleString(undefined, {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            }) +
            (isPercent ? "%" : "")
          : "N/A"}
      </h2>
    </div>
  );
}
