import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../JSS/Button";
import { signIn } from "../../redux/actions/UserAction";
import { EMAIL_REGEX } from "../../utils/Constants/systemSetting";
import Input from "../Input/Input";
import { Link } from "react-router-dom";
// import GoogleLoginButton from "./oAuthButtons/GoogleLoginButton";
// import FacebookLoginButton from "./oAuthButtons/FBloginButton";

export default function SignInForm(props) {
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(true);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const values = { ...state };
    values[name] = value;

    let valid = true;

    if (!EMAIL_REGEX.test(values.email)) {
      valid = false;
    }
    for (let key in values) {
      if (values[key].trim() === "") {
        valid = false;
      }
    }
    setDisabled(!valid);
    setState({ ...values });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      ...state,
    };
    dispatch(signIn(user));

    setState({
      email: "",
      password: "",
    });
    setDisabled(true);
  };

  return (
    <form onSubmit={handleSubmit} className="sign-in-form">
      <h1>Log in</h1>
      <br />
      {/* <div className="social-container">
        {props.active ? (
          <FacebookLoginButton setUser={props.setUser} />
        ) : (
          <div className="social">
            <i className="fab fa-facebook-f"></i>
          </div>
        )}

        {props.active ? (
          <GoogleLoginButton setUser={props.setUser} />
        ) : (
          <div className="social">
            <i className="fab fa-google"></i>
          </div>
        )}
      </div>
      <span>or use your account</span> */}
      <Input
        value={state.email}
        name="email"
        onChange={handleChange}
        required
        label="Email"
      />
      <Input
        value={state.password}
        name="password"
        onChange={handleChange}
        required
        label="Password"
        type="password"
      />
      <Link to="/subscribe">Don't have an account?</Link>
      <Link to="/forgot-password" style={{ marginTop: -10 }}>
        Forgot your password?
      </Link>
      <Button disabled={disabled} primary>
        Log in
      </Button>
    </form>
  );
}
