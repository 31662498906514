import React from "react";
import { Steps } from "antd";

const { Step } = Steps;

export default function CheckoutSteps({ step }) {
  return (
    <div
      className="stepsContainer"
      style={{ display: "flex", justifyContent: "center", marginBottom: 35 }}
    >
      <Steps
        size="med"
        current={step}
        style={{
          width: "30%",
          lineHeight: "32px",
          minWidth: 300,
          marginTop: -30,
        }}
      >
        <Step title="Payment" />
        <Step title="Create Account" />
      </Steps>
    </div>
  );
}
