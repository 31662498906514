import React, { useState, useMemo, useCallback } from "react";
import { Table, Checkbox, Input } from "antd";
import { RadioSelector, emdash } from "../../../utils/Constants/systemSetting";
import { getExecPosition } from "../../../utils/ExecutiveData/getExecPosition.mjs";
import EditableTableCell from "./EditableTableCell";

export default function PEOsAndNEOsTable({
  companyNEOs,
  setCompanyNEOs,
  NEOyearsAvailable,
  showPEOsAndNEOsTable,
  setShowPEOsAndNEOsTable,
  updateCount,
  setUpdateCount,
  PDFprocessing = false,
}) {
  const { Search } = Input;
  const [yearSelected, setYearSelected] = useState(NEOyearsAvailable[0]);
  const [alternateYear, setAlternateYear] = useState(null);
  const [customExec, setCustomExec] = useState("");
  const [customizedNEOs, setCustomizedNEOs] = useState([]);

  const allCompanyNEOs = useMemo(() => {
    let newAltYear = null;
    if (
      !companyNEOs?.[yearSelected] ||
      !Object.values(companyNEOs[yearSelected]) ||
      !Object.values(companyNEOs?.[yearSelected])?.some(
        (execArray) =>
          execArray &&
          execArray?.some((exec) => exec?.compensation?.[yearSelected]?.total)
      )
    ) {
      for (const prevYear of NEOyearsAvailable) {
        if (
          companyNEOs?.[prevYear] &&
          Object.values(companyNEOs?.[prevYear]) &&
          Object.values(companyNEOs?.[prevYear])?.some(
            (execArray) =>
              execArray &&
              execArray?.some((exec) => exec?.compensation?.[prevYear]?.total)
          )
        ) {
          newAltYear = prevYear;
          setAlternateYear(newAltYear);
          break;
        }
      }
      if (!newAltYear) {
        return [];
      }
    }
    const allNEOs = [];
    companyNEOs?.[yearSelected] &&
      Object.entries(companyNEOs?.[yearSelected])?.forEach(
        ([NEOtype, NEOdata]) => {
          NEOdata.forEach((NEO) => {
            allNEOs.push({
              ...NEO,
              isPEO: NEOtype.startsWith("PEO"),
              isNonPEO: NEOtype.startsWith("nonPEO"),
            });
          });
        }
      );
    return allNEOs;
  }, [companyNEOs, yearSelected, NEOyearsAvailable]);

  const handlePEOchange = useCallback(
    (NEO, setAsPEO, setAsNonPEO) => {
      const currentPEOs = companyNEOs[yearSelected].PEOexecs;
      const currentNonPEOs = companyNEOs[yearSelected].nonPEOexecs;
      const currentOtherNEOs = companyNEOs[yearSelected]?.otherNEOexecs || [];
      const newCompanyNEOs = { ...companyNEOs };

      if (NEO && setAsPEO) {
        const PEOexecs = [...currentPEOs, NEO];
        const nonPEOexecs = currentNonPEOs.filter(
          (exec) => exec.key !== NEO.key
        );
        const otherNEOexecs = currentOtherNEOs.filter(
          (exec) => exec.key !== NEO.key
        );
        newCompanyNEOs[yearSelected] = {
          PEOexecs,
          nonPEOexecs,
          otherNEOexecs,
        };
      }
      if (NEO && setAsNonPEO) {
        const nonPEOexecs = [...currentNonPEOs, NEO];
        const PEOexecs = currentPEOs.filter((exec) => exec.key !== NEO.key);
        const otherNEOexecs = currentOtherNEOs.filter(
          (exec) => exec.key !== NEO.key
        );
        newCompanyNEOs[yearSelected] = {
          PEOexecs,
          nonPEOexecs,
          otherNEOexecs,
        };
      }
      if (NEO && !setAsPEO && !setAsNonPEO) {
        const otherNEOexecs = [...currentOtherNEOs, NEO];
        const PEOexecs = currentPEOs.filter((exec) => exec.key !== NEO.key);
        const nonPEOexecs = currentNonPEOs.filter(
          (exec) => exec.key !== NEO.key
        );
        newCompanyNEOs[yearSelected] = { PEOexecs, nonPEOexecs, otherNEOexecs };
      }

      const NEOtypesAvailable = Object.keys(newCompanyNEOs[yearSelected]);
      for (const NEOtype of NEOtypesAvailable) {
        const newCompanyNEOsForType = newCompanyNEOs[yearSelected][NEOtype].map(
          (exec) => {
            if (
              customizedNEOs.find((customExec) => customExec.key === exec.key)
            ) {
              return customizedNEOs.find(
                (customExec) => customExec.key === exec.key
              );
            }
            return exec;
          }
        );
        newCompanyNEOs[yearSelected][NEOtype] = newCompanyNEOsForType;
      }
      setCompanyNEOs(newCompanyNEOs);
      setUpdateCount(updateCount + 1);
    },
    [
      companyNEOs,
      yearSelected,
      setCompanyNEOs,
      customizedNEOs,
      updateCount,
      setUpdateCount,
    ]
  );

  useMemo(() => {
    customizedNEOs.forEach((NEO) => {
      handlePEOchange();
      setCustomizedNEOs([]);
      setUpdateCount(updateCount + 1);
    });
  }, [customizedNEOs, handlePEOchange, updateCount, setUpdateCount]);

  const dataSource = useMemo(() => {
    const newAllCompanyNEOs = allCompanyNEOs
      .filter(
        (NEO) =>
          NEO?.compensation?.[yearSelected]?.total ||
          NEO?.compensation?.[alternateYear]?.total ||
          NEO?.isCustomAdded
      )
      .sort((a, b) => a.key - b.key);

    return newAllCompanyNEOs.map((NEO) => {
      const NEOTotalComp = NEO?.compensation?.[yearSelected]?.total;
      const NEOTotalCompOriginalValue =
        NEO?.compensation?.[yearSelected]?.originalTotal;

      return {
        key: NEO.key,
        isPEO: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Checkbox
              checked={NEO?.isPEO}
              onChange={() => handlePEOchange(NEO, !NEO.isPEO, false)}
              // disabled={companyNEOs?.[yearSelected]?.PEOexecs?.length >= 2}
            />
          </div>
        ),
        isNonPEO: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 5,
            }}
          >
            <Checkbox
              checked={NEO?.isNonPEO}
              onChange={() => handlePEOchange(NEO, false, !NEO.isNonPEO)}
            />
          </div>
        ),
        neoName: NEO?.name,
        neoPosition: getExecPosition(NEO, yearSelected).join(", "),
        totalComp: (
          <EditableTableCell
            cellData={NEOTotalComp ? NEOTotalComp : emdash}
            dataType={"dollar"}
            key={yearSelected}
            updateItem={NEO}
            updateKey={["compensation", yearSelected, "total"]}
            setUpdate={setCustomizedNEOs}
            originalValue={NEOTotalCompOriginalValue}
          />
        ),
      };
    });
  }, [allCompanyNEOs, yearSelected, alternateYear, handlePEOchange]);

  // console.log(allCompanyNEOs);

  const columns = [
    {
      title: `PEO in ${yearSelected}`,
      dataIndex: "isPEO",
      key: "isPEO",
      width: "125px",
      align: "center",
    },
    {
      title: `Non-PEO NEO in ${yearSelected}`,
      dataIndex: "isNonPEO",
      key: "isNonPEO",
      width: "125px",
      align: "center",
    },
    {
      title: "NEO Name",
      dataIndex: "neoName",
      key: "neoName",
      align: "center",
    },
    {
      title: `NEO Position in ${
        alternateYear && yearSelected > alternateYear
          ? alternateYear
          : yearSelected
      }`,
      dataIndex: "neoPosition",
      key: "neoPosition",
      align: "center",
    },
    {
      title: `Total Compensation in ${yearSelected}`,
      dataIndex: "totalComp",
      key: "totalComp",
      align: "center",
    },
  ];

  const [customExecKey, setCustomExecKey] = useState(1);
  const handleCustomNEOSubmit = () => {
    const newCompanyNEOs = { ...companyNEOs };
    const compensationData = {};
    NEOyearsAvailable.forEach((year) => {
      compensationData[year] = { total: null };
    });
    newCompanyNEOs[yearSelected].nonPEOexecs = [
      ...newCompanyNEOs[yearSelected].nonPEOexecs,
      {
        key: customExec + "_" + customExecKey,
        isPEO: false,
        isNonPEO: false,
        name: customExec,
        neoPosition: {},
        compensation: compensationData,
        isCustomAdded: true,
      },
    ];
    setCompanyNEOs(newCompanyNEOs);
    setCustomExecKey(customExecKey + 1);
    // setCompanyNEOs((prevExecNames) => {
    //   prevExecNames,
    //     prevExecNames[yearSelected].push({
    //       key: customExec + prevExecNames.length,
    //       isPEO: false,
    //       isNonPEO: false,
    //       name: customExec,
    //       neoPosition: emdash,
    //       totalComp: emdash,
    //       isCustomAdded: true,
    //     });
    // });
    setCustomExec("");
  };
  return (
    <div style={{ marginTop: 20 }}>
      <div
        style={{
          height: PDFprocessing ? "1px" : "",
          display: PDFprocessing ? "none" : "",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Edit PEOs and Non-PEO NEOs:</h3>
        <p
          className="link"
          style={{ textAlign: "center" }}
          onClick={() => {
            setShowPEOsAndNEOsTable(!showPEOsAndNEOsTable);
          }}
        >
          Done Editing
        </p>
        <RadioSelector
          options={NEOyearsAvailable}
          state={yearSelected}
          setState={setYearSelected}
          heading="Year of NEO data:"
          hoverText="Edit PEOs/Non-PEOs for each year in the PvP table above"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
            {"Add an Executive:".split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
          <Search
            placeholder="Add Executive To List"
            value={customExec}
            onChange={(e) => setCustomExec(e.target.value)}
            onSearch={() => handleCustomNEOSubmit()}
            enterButton={"Add Executive"}
          />
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ hideOnSinglePage: true }}
        />
        <p>* These values were manually edited.</p>
      </div>
    </div>
  );
}
