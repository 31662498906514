import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  ReferenceDot,
  ReferenceLine,
} from "recharts";
import { Link } from "react-router-dom";
import { measureOptions as allMeasureOptions } from "../../utils/ExecutiveData/executiveMeasure";
import {
  Tooltip as AntdTooltip,
  Radio,
  Select,
  Table,
  Button,
  Checkbox,
} from "antd";
import slugifyName from "../../utils/slugifyName";
import {
  getExecPosition,
  posGroupMatch,
} from "../../utils/ExecutiveData/getExecPosition";
import { useSelector } from "react-redux";
import {
  abbreviateAndFormatMeasure,
  dataKeyMap,
  formatMeasure,
  getAllDefinedValues,
  MIN_YEAR,
  payRatioCalculatedMessage,
} from "../../utils/Constants/systemSetting";
import CohortSelector from "./CohortSection/CohortSelector";
import CorrelationGraph from "./CohortSection/CorrelationGraph.js";
import { getLinearCorrelationData } from "../../utils/ExecutiveData/getLinearCorrelationData";
import "../../css/overview.css";
import MeasureSelector from "../../utils/ExecutiveData/MeasureSelector";

export default function MeasureScatterplotAndTable({
  executive,
  companyInfo,
  execPositionGroup,
  year,
  PDFprocessing,
  setCaptureCorrelationTableInPDF,
}) {
  // Only does linear coorelation. May be able to use more correlation types from here:
  // https://github.com/Tom-Alexander/regression-js/blob/master/src/regression.js

  const { Option } = Select;
  const { companies } = useSelector((state) => state.CompaniesReducer);
  const { csuite: allExecutives } = useSelector(
    (state) => state.ExecutivesReducer
  );

  const [cohortTableTickers, setCohortTableTickers] = useState([]);
  const [cohortTableSelector, setCohortTableSelector] =
    useState("Analyst Cohort");

  const [compensationType, setCompensationType] = useState("total");
  const onCompensationTypeChange = (e) => {
    setCompensationType(e.target.value);
  };

  const [selectedExecutive, setSelectedExecutive] = useState("allExecutives");
  const handleExecutiveChange = (value) => {
    if (value === "allExecutives") {
      setSelectedExecutive("allExecutives");
      return;
    }
    for (const exec of cohortExecutives) {
      if (exec.key === value) {
        setSelectedExecutive(exec);
        return;
      }
    }
  };
  const [showRsquaredMessage, setShowRsquaredMessage] = useState(false);
  const [showCorrelationColors, setShowCorrelationColors] = useState(true);

  // Get array of years that contain data for the selected executive
  const yearsWithData = useMemo(() => {
    const newYears = [];
    if (selectedExecutive === "allExecutives") {
      const currentYear = new Date().getFullYear();
      for (let year = MIN_YEAR; year < currentYear; year++) {
        newYears.push(year);
      }
    } else if (selectedExecutive?.compensation) {
      for (const year of Object.keys(selectedExecutive?.compensation)) {
        if (!newYears.includes(year)) {
          newYears.push(year);
        }
      }
    }
    return newYears.sort((a, b) => parseInt(b) - parseInt(a));
  }, [selectedExecutive]);

  const cohortExecutives = useMemo(() => {
    if (allExecutives.length < 50) return [];
    const cohortTableKeys = [executive];

    for (const exec of allExecutives) {
      if (
        cohortTableTickers?.includes(exec.Ticker) &&
        ////If positions need to match:
        posGroupMatch(year, exec, execPositionGroup) &&
        // yearsWithData.some((year) =>
        //   getExecPosition(exec, year).some((pos) =>
        //     execPositionGroup.some((posGroup) =>
        //       pos.toLowerCase().includes(posGroup?.term)
        //     )))
        !cohortTableKeys.includes(exec)
      ) {
        cohortTableKeys.push(exec);
      }
    }
    return cohortTableKeys;
  }, [executive, year, allExecutives, cohortTableTickers, execPositionGroup]);

  const measureOptions = useMemo(() => {
    return Object.values(allMeasureOptions).filter((measure) =>
      (selectedExecutive === "allExecutives"
        ? cohortExecutives
        : [selectedExecutive]
      ).some((exec) =>
        yearsWithData.some((year) =>
          measure.getValue(companies[exec.Ticker], year)
        )
      )
    );
  }, [cohortExecutives, companies, selectedExecutive, yearsWithData]);
  const [measure, setMeasure] = useState(Object.values(measureOptions)[0]);
  const onMeasureChange = (e) => {
    setMeasure(e.target.value);
  };

  // Returns array of all the executives within the selector as an Option for the Select dropdown
  const executiveOptions = useMemo(() => {
    if (!executive) return [];
    const allExecutivesOption = [
      <Option key="allExecutives" value="allExecutives">
        All Executives
      </Option>,
    ];
    const sectorExecutivesOptions = cohortExecutives.map((exec, index) => {
      return (
        <Option key={exec?.name + index} value={exec.key}>
          {`${exec?.name} ${[exec?.ticker]}`}
        </Option>
      );
    });
    return allExecutivesOption.concat(sectorExecutivesOptions);
  }, [cohortExecutives, executive]);

  // Returns array of the compensation types with available data
  const compensationOptions = useMemo(() => {
    const execTableKeys = [];
    const execs =
      selectedExecutive === "allExecutives"
        ? cohortExecutives
        : [selectedExecutive];
    for (const exec of execs) {
      for (const year of yearsWithData) {
        if (!exec?.compensation?.[year]) continue;
        for (const key of Object.keys(exec.compensation[year])) {
          if (
            !execTableKeys.includes(dataKeyMap[key]) &&
            key !== "rank" &&
            key !== "undefined" &&
            key !== "originalTotal" &&
            key !== "manuallyEdited" &&
            key !== "sumOfCompensationComponents" &&
            exec.compensation[year][key] > 0
          ) {
            execTableKeys.push(dataKeyMap[key]);
          }
        }
      }
    }
    return execTableKeys.sort((a, b) => a.order - b.order);
  }, [selectedExecutive, yearsWithData, cohortExecutives]);

  const [dataExceedsMax, setDataExceedsMax] = useState(false);

  // Returns the scatterplot data with measure value and compensation for x and y, respectivly
  const getScatterPlotData = useCallback(
    (measure, compensationType, selectedExecutive) => {
      const getExecutiveData = (executive) => {
        const executiveData = {};
        for (const year of yearsWithData) {
          const measureValue = measure.getValue(
            companies[executive.Ticker],
            year
          );
          const compensationValue =
            executive?.compensation?.[year]?.[compensationType];
          executiveData[year] = {};
          if (
            measureValue &&
            compensationValue &&
            compensationValue !== "–" &&
            !["N/A", "N", "P"].some(
              (excludeValue) => measureValue === excludeValue
            )
          ) {
            executiveData[year]["measure"] = measureValue;
            executiveData[year]["compensation"] = compensationValue;
            executiveData[year]["name"] = executive?.name;
            executiveData[year]["ticker"] = executive?.Ticker;
            executiveData[year]["position"] = getExecPosition(
              executive,
              year
            )?.join(", ");
            executiveData[year]["year"] = year;
            executiveData[year]["key"] = executive?.name + year;
          }
        }
        return executiveData;
      };

      if (selectedExecutive !== "allExecutives") {
        const data = [];
        const allData = getExecutiveData(selectedExecutive);
        data.push(Object.values(allData));
        return data.flat();
      } else {
        const data = [];
        for (const exec of cohortExecutives) {
          const allData = getExecutiveData(exec);
          data.push(Object.values(allData));
          if (cohortExecutives.indexOf(exec) > 50) {
            setDataExceedsMax(true);
            return data.flat();
          }
        }
        setDataExceedsMax(false);
        return data.flat();
      }
    },
    [companies, cohortExecutives, yearsWithData]
  );

  const scatterPlotData = useMemo(
    () => getScatterPlotData(measure, compensationType, selectedExecutive),
    [measure, compensationType, selectedExecutive, getScatterPlotData]
  );

  // For ReferenceLine for showing horizontal pos of datapoint hovered:
  const [activeX, setActiveX] = useState(null);
  const [activeY, setActiveY] = useState(null);

  // Custom Tooltip to display formatted details about the datapoint
  const CustomTooltip = ({ active, payload, callback }) => {
    // Set position of active dot for reference lines
    useEffect(() => {
      if (active && payload && payload.length) {
        setActiveX(payload[0].payload.measure);
        setActiveY(payload[0].payload.compensation);
      }
    });

    if (active && payload && payload.length) {
      //Only want to show info about the scatterplot dots, not anything to do with line:
      if (!payload[0].payload?.name) return null;

      return (
        <div
          className="custom-tooltip"
          style={{
            textAlign: "center",
            background: "rgba(255, 255, 255, 0.9)",
          }}
        >
          <b>{`${payload[0].payload.name} [${payload[0].payload.ticker}]`}</b>
          <p>{`${payload[0].payload.position}`}</p>
          <div>
            <b>{`${payload[0].payload.year} ${measure.title}: `}</b>
            {measure?.title !== "CEO Pay Ratio" ||
            payload[0].payload.measure === "N/A" ? (
              `${formatMeasure(payload[0].payload.measure, measure.dataType)}`
            ) : (
              <span>
                {formatMeasure(payload[0].payload.measure, measure.dataType)} :
                1
                {measure?.isCalculated &&
                measure?.isCalculated(
                  companies[payload[0].payload.ticker],
                  payload[0].payload.year
                ) ? (
                  <sup> †</sup>
                ) : (
                  <sup></sup>
                )}
              </span>
            )}
          </div>
          <div>
            <b>{`${payload[0].payload.year}  ${dataKeyMap[compensationType].title}:`}</b>{" "}
            {`${formatMeasure(payload[0].payload.compensation, "dollar")}`}
          </div>
          {measure?.isCalculated &&
            measure?.isCalculated(
              companies[payload[0].payload.ticker],
              payload[0].payload.year
            ) && (
              <p>
                <sup>† </sup>This CEO Pay ratio is a calculated based on CEO pay
                and sector median.
              </p>
              // <p>
              //   {payRatioCalculatedMessage(
              //     companies[payload[0].payload.ticker],
              //     payload[0].payload.year
              //   )}
              // </p>
            )}
        </div>
      );
    }

    return null;
  };

  const linearCorrelationData = useMemo(
    () => getLinearCorrelationData(scatterPlotData),
    [scatterPlotData]
  );

  const allCorrelationsForExecutive = useMemo(() => {
    const correlations = {};
    // if (selectedExecutive === "allExecutives") return correlations;

    Object.entries(measureOptions).forEach(([measureKey, measure]) => {
      correlations[measureKey] = {};

      compensationOptions.forEach((comp) => {
        const execScatterPlotData = getScatterPlotData(
          measure,
          comp.key,
          selectedExecutive
        );
        const linearCorrelationData =
          getLinearCorrelationData(execScatterPlotData);
        correlations[measureKey][comp.key] = linearCorrelationData;
      });
    });

    return correlations;
  }, [
    compensationOptions,
    selectedExecutive,
    measureOptions,
    getScatterPlotData,
  ]);
  const [showCorrelation, setShowCorrelation] = useState(true);

  const transformCorrelationDataForTable = (correlations) => {
    const tableData = [];

    Object.keys(correlations).forEach((measure, index) => {
      Object.keys(correlations[measure]).forEach((compensationType) => {
        // console.log(
        //   measure,
        //   compensationType,
        //   correlations[measure][compensationType]
        // );
        tableData.push({
          measure,
          compensationType,
          correlation: correlations[measure][compensationType],
          datapoints: correlations[measure][compensationType],
          key: `${measure}+${compensationType}+${index}`,
        });
      });
    });

    return tableData;
  };

  const formattedTableData = transformCorrelationDataForTable(
    allCorrelationsForExecutive
  );

  const CorrelationTable = ({ formattedTableData, setMeasuresInGraph }) => {
    const uniqueCompensationTypes = [
      ...new Set(formattedTableData.map((row) => row.compensationType)),
    ];

    const isValidCorrelation = (correlation) =>
      correlation.value && !isNaN(correlation.value);
    const notEnoughDataPoints = (correlation) =>
      correlation.datapoints === 2 &&
      Math.abs(correlation.value.toPrecision(4)) === 1;

    const compensationColumns = uniqueCompensationTypes.map(
      (compensationKey) => {
        return {
          title: (
            <span
              className={
                compensationKey === compensationType ? "bold-cell" : ""
              }
            >
              {dataKeyMap[compensationKey].title}
            </span>
          ),
          dataIndex: compensationKey,
          key: compensationKey,
          align: "center",
          render: (correlation, rowData) => {
            if (notEnoughDataPoints(correlation)) setShowRsquaredMessage(true);
            return (
              <span
                className={
                  "correlation " + measure.title === rowData.measureType &&
                  compensationType === compensationKey
                    ? "bold-cell"
                    : ""
                }
                style={{
                  display: "block",
                  padding: "5px 10px",
                  backgroundColor:
                    !showCorrelationColors ||
                    !isValidCorrelation(correlation) ||
                    notEnoughDataPoints(correlation)
                      ? "white"
                      : correlation.value > 0
                      ? `rgba(0,255,174,${Math.abs(correlation.value)})`
                      : `rgba(255,80,90,${Math.abs(correlation.value)})`,
                  borderRadius: 4,
                }}
              >
                {!correlation.value || isNaN(correlation.value)
                  ? "–"
                  : correlation.value.toPrecision(4) +
                    (notEnoughDataPoints(correlation) ? "*" : "")}
              </span>
            );
          },
          onCell: () => {
            <span style={{ backgroundColor: "red" }}></span>;
          },
        };
      }
    );

    const columns = [
      {
        title: () => (
          <span>
            Show&nbsp;in Graph?{" "}
            <Checkbox
              checked={measuresInGraph.length === measureOptions.length}
              onChange={() =>
                measuresInGraph.length === measureOptions.length
                  ? setMeasuresInGraph([])
                  : setMeasuresInGraph(
                      Object.values(measureOptions).map((meas) => meas.title)
                    )
              }
            />
          </span>
        ),
        dataIndex: "measureType",
        key: "showInGraph",
        width: "10px",
        align: "center",
        render: (cellData, rowData) => {
          return (
            <Checkbox
              checked={measuresInGraph.includes(rowData.measureType)}
              onChange={() =>
                measuresInGraph.includes(cellData)
                  ? setMeasuresInGraph(
                      measuresInGraph.filter((key) => key !== cellData)
                    )
                  : setMeasuresInGraph([...measuresInGraph, cellData])
              }
            />
          );
        },
      },
      {
        title: "Measure Type",
        dataIndex: "measureType",
        key: "measureType",
        render: (cellData, rowData) => {
          return (
            <span
              className={
                measure.title === rowData.measureType ? "bold-cell" : ""
              }
            >
              {cellData}
            </span>
          );
        },
      },
      ...compensationColumns,
    ];

    const formattedDataSource = Object.entries(measureOptions).map(
      ([measureKey, measureDetails]) => {
        const rowData = { measureType: measureDetails.title };

        formattedTableData.forEach((row, index) => {
          if (row.measure === measureKey) {
            rowData[row.compensationType] = showCorrelation
              ? {
                  value: row.correlation.correlation,
                  datapoints: row.correlation.datapoints,
                }
              : {
                  value: row.correlation.RSquared,
                  datapoints: row.correlation.datapoints,
                };
            rowData["key"] = row.compensationType + index;
          }
        });

        return rowData;
      }
    );

    return (
      <Table
        key={`correlation-table-measures`}
        dataSource={formattedDataSource}
        columns={columns}
        pagination={false}
        bordered
        style={{ marginBottom: "1rem" }}
        size={PDFprocessing ? "small" : "large"}
      />
    );
  };

  // Combines all the scatterplot data with the correlation line to add both in one graph
  const [allData, setAllData] = useState([]);
  useEffect(() => {
    setAllData([...scatterPlotData, ...linearCorrelationData.linePlotData]);
  }, [scatterPlotData, linearCorrelationData]);

  // Reset page when going to a new executive page
  useEffect(() => {
    // setSelectedExecutive("allExecutives"); //TODO: RE-ENABLE AFTER GRAPH ADDED
    setCompensationType("total");
    setMeasure(Object.values(measureOptions)[0]);
  }, [executive]);

  // Ensure graph and Rsquared message are up-to-date with new executive selection
  useEffect(() => {
    setShowRsquaredMessage(false);
    if (!compensationOptions.some((comp) => comp.key === compensationType)) {
      setCompensationType("total");
    }
    if (!measureOptions.some((meas) => meas.title === measure.title)) {
      setMeasure(Object.values(measureOptions)[0]);
    }
  }, [
    selectedExecutive,
    compensationOptions,
    compensationType,
    measureOptions,
    measure,
  ]);

  useMemo(() => {
    if (selectedExecutive !== "allExecutives") {
      setCaptureCorrelationTableInPDF(true);
    } else {
      setCaptureCorrelationTableInPDF(false);
    }
  }, [selectedExecutive, setCaptureCorrelationTableInPDF]);

  const [measuresInGraph, setMeasuresInGraph] = useState([]);

  useMemo(() => {
    setMeasuresInGraph(Object.values(measureOptions).map((meas) => meas.title));
  }, [measureOptions]);

  return (
    <div className="measure-scatterplot-section">
      <div id="measure-correlation-graph">
        <h3>
          Measure Regression Plot for{" "}
          {selectedExecutive === "allExecutives" ? (
            `All Executives in ${cohortTableSelector}` +
            (dataExceedsMax ? " (Limited to 50 Results)" : "")
          ) : (
            <Link
              to={`/company/${selectedExecutive?.Ticker}/${slugifyName(
                selectedExecutive?.name
              )}`}
            >
              {selectedExecutive?.name}
            </Link>
          )}
        </h3>
        <div
          className="measure-correlation-section"
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              flexWrap: "wrap-reverse",
            }}
          >
            <CohortSelector
              heading="Select Cohort:"
              companyInfo={companyInfo}
              setCohortTableTickers={setCohortTableTickers}
              cohortTableSelector={cohortTableSelector}
              setCohortTableSelector={setCohortTableSelector}
              removeCustomCohort={true}
            />
            <div
              style={{
                flex: 1,
                paddingTop: 5,
                paddingRight: 80,
                textAlign: "right",
              }}
            >
              {getAllDefinedValues(scatterPlotData, "measure").length > 1 && (
                <>
                  <p style={{ fontSize: "1.1rem" }}>
                    {/* Option exists to show actual values in calculation (too long to display as is) */}
                    {/* {dataKeyMap[compensationType].title} ={" "} */}y ={" "}
                    {parseFloat(linearCorrelationData.slope).toPrecision(3)}{" "}
                    {/* {measure.title} +{" "} */}x{" "}
                    {linearCorrelationData.yIntercept >= 0 ? "+" : "–"}{" "}
                    {parseFloat(
                      Math.abs(linearCorrelationData.yIntercept)
                    ).toPrecision(3)}
                  </p>
                  {/* {getAllDefinedValues(scatterPlotData, "measure").length > 2 && ( */}
                  <div style={{ fontSize: "1.5rem" }}>
                    R<sup>2</sup>:{" "}
                    {/* RSquared can be N/A when all y values are the same (causes divide by 0) */}
                    {isNaN(linearCorrelationData.RSquared)
                      ? "N/A"
                      : parseFloat(linearCorrelationData.RSquared).toPrecision(
                          4
                        )}
                    {getAllDefinedValues(scatterPlotData, "measure").length ===
                      2 &&
                      linearCorrelationData.RSquared === 1 && (
                        <>
                          *
                          <p style={{ fontSize: "0.8rem" }}>
                            *Only two datapoints are available with this
                            selection
                          </p>
                        </>
                      )}
                  </div>
                  {/* )} */}
                </>
              )}
            </div>
          </div>

          <div
            className="measure-selector"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 10,
              width: "100%",
            }}
          >
            <div className="title" style={{ width: 160 }}>
              <h5 style={{ marginBottom: 0 }}>Select Executive:</h5>
            </div>
            <Select
              defaultValue="allExecutives"
              style={{ width: 300 }}
              onChange={handleExecutiveChange}
              value={selectedExecutive?.key || "allExecutives"}
            >
              {executiveOptions}
            </Select>
          </div>
          <MeasureSelector
            heading="Select Measure:"
            measureOptions={measureOptions}
            measure={measure}
            setMeasure={setMeasure}
          />
          <div
            className="measure-selector"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 10,
              width: "100%",
            }}
          >
            <div className="title" style={{ width: 240 }}>
              <h5 style={{ marginBottom: 0, marginRight: 5 }}>
                Select Compensation:
              </h5>
            </div>
            <div
              className="radio-buttons-group"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Radio.Group
                defaultValue={0}
                size="medium"
                onChange={onCompensationTypeChange}
                value={compensationType}
              >
                {compensationOptions.map((option) => {
                  return (
                    <Radio.Button
                      value={option.key}
                      key={option.key}
                      className="custom-radio-button"
                    >
                      {option.title}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </div>
          </div>
          <div style={{ width: "100%", height: 500 }}>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={400}
                data={allData}
                margin={{
                  top: 20,
                  right: 80,
                  bottom: 20,
                  left: 20,
                }}
                onMouseLeave={() => {
                  setActiveX(null);
                  setActiveY(null);
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <Tooltip
                  cursor={{
                    stroke: "var(--primary)",
                    strokeDasharray: "3 3",
                    strokeWidth: "1",
                  }}
                  content={CustomTooltip}
                  contentStyle={{ border: "none", background: "none" }}
                  // wrapperStyle={{ backgroundColor: "#fff" }}
                />
                <Legend />
                <XAxis
                  dataKey="measure"
                  type="number"
                  label={{
                    value: measure.title,
                    position: "insideBottom",
                    offset: -10,
                  }}
                  tickFormatter={(value) =>
                    abbreviateAndFormatMeasure(value, measure.dataType)
                  }
                />
                <YAxis
                  dataKey="compensation"
                  type="number"
                  tickFormatter={(value) =>
                    abbreviateAndFormatMeasure(value, "dollar")
                  }
                  label={{
                    className: "yaxis",
                    value: dataKeyMap[compensationType].title,
                    position: "insideLeft",
                    angle: 270,
                    offset: -15,
                    style: { textAnchor: "middle" },
                  }}
                />
                <ReferenceLine
                  y={activeY}
                  stroke="var(--primary)"
                  strokeDasharray="5 2"
                  strokeWidth="1"
                />
                <ReferenceLine
                  x={activeX}
                  stroke="var(--primary)"
                  strokeDasharray="3 3"
                  strokeWidth="1"
                />

                <Scatter
                  name={compensationType}
                  dataKey="compensation"
                  fill="var(--secondary)"
                  legendType="none"
                />
                {activeX && activeY && (
                  <ReferenceDot
                    x={activeX}
                    y={activeY}
                    r={10}
                    fill="var(--primary-1)"
                    stroke="none"
                  />
                )}

                <Line
                  dataKey="correlation"
                  stroke="var(--secondary)"
                  dot={false}
                  legendType="none"
                  label={false}
                  type="none"
                  activeDot={false}
                  strokeWidth={2}
                  animationDuration={100} //ms
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div
        className="correlation-table-section"
        style={{
          paddingTop: 30,
        }}
        id="measure-correlation-table"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>
            Measure {showCorrelation ? "Correlation" : "Regression"} Graph/Table{" "}
            {selectedExecutive !== "allExecutives" && (
              <>
                for{" "}
                <Link
                  to={`/company/${selectedExecutive?.Ticker}/${slugifyName(
                    selectedExecutive?.name
                  )}`}
                >
                  {selectedExecutive?.name}
                </Link>
              </>
            )}
          </h3>
          <div>
            <Checkbox
              checked={showCorrelationColors}
              onChange={() => setShowCorrelationColors(!showCorrelationColors)}
            >
              Show Correlation Colors
            </Checkbox>
            <Button onClick={() => setShowCorrelation(!showCorrelation)}>
              Show {showCorrelation ? "Regression" : "Correlation"}
            </Button>
          </div>
        </div>

        {selectedExecutive === "allExecutives" ? (
          <div style={{ fontSize: "1rem" }}>
            Use the <b>Select Executive</b> option above to view the selected
            executive's {showCorrelation ? "correlation" : "regression"} table.
          </div>
        ) : (
          <>
            <div style={{ width: "100%" }}>
              <CorrelationGraph
                formattedTableData={formattedTableData}
                correlationType={showCorrelation ? "correlation" : "RSquared"}
                measureOptions={measureOptions}
                measuresInGraph={measuresInGraph}
              />
            </div>
            <div>
              <CorrelationTable
                formattedTableData={formattedTableData}
                measuresInGraph={measuresInGraph}
                setMeasuresInGraph={setMeasuresInGraph}
              />
            </div>
            {showRsquaredMessage && (
              <p>*Only two datapoints are available for these selections</p>
            )}
          </>
        )}
      </div>
    </div>
  );
}
