import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
  ReferenceDot,
  ReferenceLine,
} from "recharts";
import { Link } from "react-router-dom";
import { measureOptions as allMeasureOptions } from "../../../utils/ExecutiveData/executiveMeasure";
import { Table, Button, Checkbox } from "antd";
import slugifyName from "../../../utils/slugifyName";
import { useSelector } from "react-redux";
import {
  abbreviateAndFormatMeasure,
  formatMeasure,
  getAllDefinedValues,
  emdash,
  fromDollar,
  MIN_YEAR,
} from "../../../utils/Constants/systemSetting";
import PvPCorrelationGraph from "./PvPCorrelationGraph.js";
import { getLinearCorrelationData } from "../../../utils/ExecutiveData/getLinearCorrelationData";
import "../../../css/overview.css";
import MeasureSelector from "../../../utils/ExecutiveData/MeasureSelector";

export default function PvPScatterplotAndTable({
  companyInfo,
  PvPTableData,
  PvPTableColumns,
  PDFprocessing = false,
  yearsToDisplay,
  customCorrelationMeasureSelected,
  setCustomCorrelationMeasureSelected,
  setCaptureCorrelationTableInPDF = () => null,
}) {
  // Only does linear coorelation. May be able to use more correlation types from here:
  // https://github.com/Tom-Alexander/regression-js/blob/master/src/regression.js

  const { companies } = useSelector((state) => state.CompaniesReducer);

  const [compensationType, setCompensationType] = useState("total");
  const [selectedExecutive, setSelectedExecutive] = useState("allExecutives");

  const [showCorrelationTable, setShowCorrelationTable] = useState(true);
  const [showRsquaredMessage, setShowRsquaredMessage] = useState(false);
  const [showCorrelationColors, setShowCorrelationColors] = useState(true);
  const [tableDataWithCorrelations, setTableDataWithCorrelations] = useState();

  const yearsAvailable = useMemo(() => {
    const newYearsAvailable = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= MIN_YEAR; year--) {
      newYearsAvailable.push(year);
    }
    return newYearsAvailable;
  }, []);

  const getPvPColumnTitle = useCallback(
    (PvPTableKey) => {
      const PvPColumn = PvPTableColumns.find(
        (item) => item.key === PvPTableKey
      );
      const PvPColumnTitle = (
        PvPColumn?.displayTitle || PvPColumn?.title
      )?.replace(" ($)", "");
      return PvPColumnTitle;
    },
    [PvPTableColumns]
  );

  const formattedPvPTableData = useMemo(() => {
    if (!PvPTableData) return {};
    const formattedData = {};
    for (const year of yearsAvailable) {
      const PvPTableDataForYear = PvPTableData?.find(
        (data) => data.year === year
      );
      if (!PvPTableDataForYear) continue;
      for (const [PvPColumnKey, PvPColumnData] of Object.entries(
        PvPTableDataForYear
      )) {
        if (
          PvPColumnKey === "year" ||
          PvPColumnKey === "key" ||
          PvPColumnKey === "CoSelectedMeasureAmt"
        ) {
          continue;
        }
        if (!formattedData?.[PvPColumnKey]) {
          formattedData[PvPColumnKey] = {};
        }
        formattedData[PvPColumnKey][year] = !isNaN(
          parseFloat(PvPColumnData?.props?.cellData)
        )
          ? parseFloat(PvPColumnData?.props?.cellData)
          : emdash;
      }
    }
    return formattedData;
  }, [PvPTableData, yearsAvailable]);

  const PvPColumnOptions = useMemo(() => {
    if (PvPTableColumns) {
      return PvPTableColumns.filter(
        (col) =>
          col.key !== "year" &&
          col.key !== "CoSelectedMeasureAmt" &&
          formattedPvPTableData?.[col.key] &&
          Object.values(formattedPvPTableData[col.key]).some(
            (data) => data && data !== emdash
          )
      ).map((item) => ({ ...item, displayTitle: getPvPColumnTitle(item.key) }));
    } else {
      return [];
    }
  }, [PvPTableColumns, formattedPvPTableData, getPvPColumnTitle]);

  const [PvPColumnSelected, setPvPColumnSelected] = useState();
  const [PvPIndexOfColumnSelected, setPvPIndexOfColumnSelected] = useState(0);

  useMemo(() => {
    if (!PvPColumnOptions.some((item) => item === PvPColumnSelected)) {
      setPvPColumnSelected(
        PvPColumnOptions[
          PvPIndexOfColumnSelected >= 0 ? PvPIndexOfColumnSelected : 0
        ]
      );
    }
  }, [PvPColumnOptions, PvPColumnSelected, PvPIndexOfColumnSelected]);

  useEffect(() => {
    const PvPOptionIndex = PvPColumnOptions.indexOf(
      PvPColumnOptions.find((item) => {
        return item.key === PvPColumnSelected.key;
      })
    );

    if (PvPOptionIndex >= 0) {
      setPvPIndexOfColumnSelected(PvPOptionIndex);
    }
  }, [PvPColumnOptions, PvPColumnSelected, PvPIndexOfColumnSelected]);

  const measureOptions = useMemo(() => {
    return Object.values(allMeasureOptions)
      .filter(
        (meas) =>
          yearsAvailable &&
          yearsAvailable.some(
            (year) => meas && meas.getValue(companyInfo, year)
          ) &&
          // To allow for alwaysSelectable, replace yearsAvailable.some line above:
          // (yearsAvailable.some(
          //   (year) => meas && meas.getValue(companyInfo, year)
          // ) ||
          //   meas?.isAlwaysSelectable) &&
          !["CAP", "TSR"].some((excludeTerm) =>
            meas.title.includes(excludeTerm)
          )
      )
      .map((meas) => {
        return {
          ...meas,
          dataAvailable: yearsToDisplay.every(
            (year) =>
              meas &&
              meas.getValue(companyInfo, year) &&
              meas.getValue(companyInfo, year) !== "N/A"
          )
            ? "all"
            : yearsToDisplay.some(
                (year) =>
                  meas &&
                  meas.getValue(companyInfo, year) &&
                  meas.getValue(companyInfo, year) !== "N/A"
              )
            ? "some"
            : "none",
        };
      });
  }, [companyInfo, yearsAvailable, yearsToDisplay]);
  const [measure, setMeasure] = useState(Object.values(measureOptions)[0]);

  useEffect(() => {
    if (!customCorrelationMeasureSelected?.title) {
      setCustomCorrelationMeasureSelected(measureOptions[0]);
    }
  });

  // Returns the scatterplot data with measure value and compensation for x and y, respectivly
  const getScatterPlotData = useCallback(
    (measure, PvPColumnSelected, selectedExecutive) => {
      const getExecutiveData = (executive) => {
        const executiveData = {};
        for (const year of yearsAvailable) {
          // "formattedCurrentMeasureValue" allows for data to stay up to date with manual edits to PvP table
          let formattedCurrentMeasureValue = null;
          if (measure?.title === customCorrelationMeasureSelected?.title) {
            const currentMeasureValue =
              PvPTableData.find((item) => item.year === year)?.[
                "CoSelectedMeasureAmt"
              ]?.props?.cellData || null;

            formattedCurrentMeasureValue =
              !currentMeasureValue || currentMeasureValue === emdash
                ? null
                : currentMeasureValue.toString().includes("%")
                ? parseFloat(currentMeasureValue)
                : fromDollar(currentMeasureValue);
          }

          const measureValue = !isNaN(parseFloat(formattedCurrentMeasureValue))
            ? parseFloat(formattedCurrentMeasureValue)
            : measure.getValue(companyInfo, year);
          const PvPTableValue =
            formattedPvPTableData?.[`${PvPColumnSelected?.key}`]?.[year];

          executiveData[year] = {};
          if (measureValue && PvPTableValue && PvPTableValue !== "–") {
            executiveData[year]["measure"] = measureValue;
            executiveData[year]["compensation"] = PvPTableValue;
            executiveData[year]["name"] = executive?.name;
            executiveData[year]["ticker"] = executive?.Ticker;
            executiveData[year]["year"] = year;
            executiveData[year]["key"] = executive?.name + year;
          }
        }
        return executiveData;
      };

      if (selectedExecutive !== "allExecutives") {
        const data = [];
        const allData = getExecutiveData(selectedExecutive);
        data.push(Object.values(allData));
        return data.flat();
      } else {
        const data = [];
        for (const exec of [selectedExecutive]) {
          const allData = getExecutiveData(exec);
          data.push(Object.values(allData));
        }
        return data.flat();
      }
    },
    [
      companyInfo,
      yearsAvailable,
      formattedPvPTableData,
      PvPTableData,
      customCorrelationMeasureSelected,
    ]
  );

  const scatterPlotData = useMemo(() => {
    return getScatterPlotData(measure, PvPColumnSelected, selectedExecutive);
  }, [measure, PvPColumnSelected, selectedExecutive, getScatterPlotData]);

  // For ReferenceLine for showing horizontal pos of datapoint hovered:
  const [activeX, setActiveX] = useState(null);
  const [activeY, setActiveY] = useState(null);

  // Custom Tooltip to display formatted details about the datapoint
  const CustomTooltip = ({ active, payload, callback }) => {
    // Set position of active dot for reference lines
    useEffect(() => {
      if (active && payload && payload.length) {
        setActiveX(payload[0].payload.measure);
        setActiveY(payload[0].payload.compensation);
      }
    });

    if (active && payload && payload.length) {
      //Only want to show info about the scatterplot dots, not anything to do with line:
      if (!payload[0].payload.compensation) return null;

      return (
        <div
          className="custom-tooltip"
          style={{
            textAlign: "center",
            background: "rgba(255, 255, 255, 0.9)",
          }}
        >
          <b>{`${payload[0].payload.year} ${companyInfo.Company} [${companyInfo.Ticker}]`}</b>
          <div>
            <b>{`${payload[0].payload.year} ${measure.title}: `}</b>
            {measure?.title !== "CEO Pay Ratio" ||
            payload[0].payload.measure === "N/A" ? (
              `${formatMeasure(payload[0].payload.measure, measure.dataType)}`
            ) : (
              <span>
                {formatMeasure(payload[0].payload.measure, measure.dataType)} :
                1
                {measure?.isCalculated &&
                measure?.isCalculated(companyInfo, payload[0].payload.year) ? (
                  <sup> †</sup>
                ) : (
                  <sup></sup>
                )}
              </span>
            )}
          </div>
          <div>
            <b>{`${payload[0].payload.year}  ${
              PvPColumnSelected?.displayTitle || PvPColumnSelected?.title
            }:`}</b>{" "}
            {`${formatMeasure(payload[0].payload.compensation, "dollar")}`}
          </div>
          {measure?.isCalculated &&
            measure?.isCalculated(companyInfo, payload[0].payload.year) && (
              <p>
                <sup>† </sup>This CEO Pay ratio is a calculated based on CEO pay
                and sector median.
              </p>
            )}
        </div>
      );
    }
    return null;
  };

  const linearCorrelationData = useMemo(
    () => getLinearCorrelationData(scatterPlotData),
    [scatterPlotData]
  );

  const allCorrelationsForExecutive = useMemo(() => {
    const correlations = {};
    // if (selectedExecutive === "allExecutives") return correlations;

    Object.entries(measureOptions).forEach(([measureKey, measure]) => {
      correlations[measureKey] = {};

      PvPColumnOptions.forEach((option) => {
        const execScatterPlotData = getScatterPlotData(
          measure,
          option,
          selectedExecutive
        );
        const linearCorrelationData =
          getLinearCorrelationData(execScatterPlotData);
        correlations[measureKey][option.key] = linearCorrelationData;
      });
    });

    return correlations;
  }, [PvPColumnOptions, selectedExecutive, measureOptions, getScatterPlotData]);
  const [showCorrelation, setShowCorrelation] = useState(true);

  const transformCorrelationDataForTable = (correlations) => {
    const tableData = [];

    Object.keys(correlations).forEach((measure, index) => {
      Object.keys(correlations[measure]).forEach((compensationType) => {
        tableData.push({
          measure,
          compensationType,
          correlation: correlations[measure][compensationType],
          datapoints: correlations[measure][compensationType],
          key: `${measure}+${compensationType}+${index}`,
        });
      });
    });

    return tableData;
  };

  const formattedTableData = transformCorrelationDataForTable(
    allCorrelationsForExecutive
  );

  const CorrelationTable = ({
    formattedTableData,
    measuresInGraph,
    setMeasuresInGraph,
    tableDataWithCorrelations,
    setTableDataWithCorrelations,
  }) => {
    const uniqueCompensationTypes = [
      ...new Set(formattedTableData.map((row) => row.compensationType)),
    ];

    const isValidCorrelation = (correlation) =>
      correlation.value && !isNaN(correlation.value);
    const notEnoughDataPoints = (correlation) =>
      correlation.datapoints === 2 &&
      Math.abs(correlation.value.toPrecision(4)) === 1;

    const compensationColumns = uniqueCompensationTypes.map((PvPTableKey) => {
      return {
        title: (
          <span className={PvPTableKey === compensationType ? "bold-cell" : ""}>
            {getPvPColumnTitle(PvPTableKey)}
          </span>
        ),
        dataIndex: PvPTableKey,
        key: PvPTableKey,
        align: "center",
        sorter: {
          compare: (a, b) => {
            return a?.[PvPTableKey]?.value - b?.[PvPTableKey].value;
          },
        },
        render: (correlation, rowData) => {
          if (notEnoughDataPoints(correlation)) setShowRsquaredMessage(true);
          return (
            <span
              className={
                "correlation " + measure.title === rowData.measureType &&
                compensationType === PvPTableKey
                  ? "bold-cell"
                  : ""
              }
              style={{
                display: "block",
                padding: "5px 10px",
                backgroundColor:
                  !showCorrelationColors ||
                  !isValidCorrelation(correlation) ||
                  notEnoughDataPoints(correlation)
                    ? "white"
                    : correlation.value > 0
                    ? `rgba(0,255,174,${Math.abs(correlation.value)})`
                    : `rgba(255,80,90,${Math.abs(correlation.value)})`,
                borderRadius: 4,
              }}
            >
              {!correlation.value || isNaN(correlation.value)
                ? "–"
                : correlation.value.toPrecision(4) +
                  (notEnoughDataPoints(correlation) ? "*" : "")}
            </span>
          );
        },
        onCell: () => {
          <span style={{ backgroundColor: "red" }}></span>;
        },
      };
    });

    const excludeKeysFromAverage = [
      "TotalShareholderRtnAmt",
      "PeerGroupTotalShareholderRtnAmt",
      "NetIncomeLoss",
    ];
    const columns = [
      {
        title: () => (
          <span>
            Show&nbsp;in Graph?{" "}
            <Checkbox
              checked={measuresInGraph.length === measureOptions.length}
              onChange={() =>
                measuresInGraph.length === measureOptions.length
                  ? setMeasuresInGraph([])
                  : setMeasuresInGraph(
                      Object.values(measureOptions).map((meas) => meas.title)
                    )
              }
            />
          </span>
        ),
        dataIndex: "measureType",
        key: "showInGraph",
        width: "10px",
        align: "center",
        render: (cellData, rowData) => {
          return (
            <Checkbox
              checked={measuresInGraph.includes(rowData.measureType)}
              onChange={() =>
                measuresInGraph.includes(cellData)
                  ? setMeasuresInGraph(
                      measuresInGraph.filter((key) => key !== cellData)
                    )
                  : setMeasuresInGraph([...measuresInGraph, cellData])
              }
            />
          );
        },
      },
      {
        title: "Measure Type",
        dataIndex: "measureType",
        key: "measureType",
        sorter: {
          compare: (a, b) => {
            if (a.measureType < b.measureType) {
              return -1;
            }
            if (a.measureType > b.measureType) {
              return 1;
            }
            return 0;
          },
        },
        render: (cellData, rowData) => {
          return (
            <span
              className={
                measure.title === rowData.measureType ? "bold-cell" : ""
              }
            >
              {cellData}
            </span>
          );
        },
      },
      ...compensationColumns.filter(
        (item) => !excludeKeysFromAverage.includes(item.key)
      ),
      {
        title: <b>Average of Compensation of PEO(s) and non-PEO NEOs</b>,
        dataIndex: "average",
        key: "measureType",
        align: "center",
        sorter: {
          compare: (a, b) => {
            return a?.average - b?.average;
          },
        },
        render: (correlation, rowData) => {
          return (
            <span
              className={
                "correlation " + measure.title === rowData.measureType &&
                measure.title === rowData.measureType
                  ? "bold-cell"
                  : ""
              }
              style={{
                display: "block",
                padding: "5px 10px",
                backgroundColor:
                  !showCorrelationColors && !isNaN(parseFloat(correlation))
                    ? "white"
                    : correlation > 0
                    ? `rgba(0,255,174,${Math.abs(correlation)})`
                    : `rgba(255,80,90,${Math.abs(correlation)})`,
                borderRadius: 4,
                fontWeight: "bold",
              }}
            >
              {!correlation || isNaN(parseFloat(correlation))
                ? "–"
                : correlation + (notEnoughDataPoints(correlation) ? "*" : "")}
            </span>
          );
        },
      },
      ...compensationColumns.filter((item) =>
        excludeKeysFromAverage.includes(item.key)
      ),
    ];

    const formattedDataSource = Object.entries(measureOptions).map(
      ([measureKey, measureDetails]) => {
        const rowData = { measureType: measureDetails.title };

        formattedTableData.forEach((row, index) => {
          if (row.measure === measureKey) {
            rowData[row.compensationType] = showCorrelation
              ? {
                  value: row.correlation.correlation,
                  datapoints: row.correlation.datapoints,
                }
              : {
                  value: row.correlation.RSquared,
                  datapoints: row.correlation.datapoints,
                };
            rowData["key"] = row.compensationType + index;
            const excludeKeysFromAverage = [
              "TotalShareholderRtnAmt",
              "PeerGroupTotalShareholderRtnAmt",
              "NetIncomeLoss",
            ];
            const total = Object.entries(rowData).reduce((acc, [key, curr]) => {
              if (!curr?.value || excludeKeysFromAverage.includes(key)) {
                return acc + 0;
              } else {
                return (
                  acc +
                  (!isNaN(parseFloat(curr.value)) && curr?.datapoints > 2
                    ? parseFloat(curr.value)
                    : 0)
                );
              }
            }, 0);
            let dataCount = 0;
            Object.entries(rowData).forEach(([key, item]) => {
              if (
                !isNaN(parseFloat(item?.value)) &&
                item?.datapoints > 2 &&
                !excludeKeysFromAverage.includes(key)
              ) {
                dataCount += 1;
              }
            });

            rowData["average"] =
              parseFloat(total / dataCount) !== 0
                ? parseFloat(total / dataCount).toFixed(4)
                : emdash;
          }
        });

        return rowData;
      }
    );

    if (
      !tableDataWithCorrelations ||
      tableDataWithCorrelations.some((item) =>
        formattedDataSource.every((item2) => item2?.average !== item?.average)
      )
    ) {
      setTableDataWithCorrelations(formattedDataSource);
    }

    return (
      <Table
        key={`correlation-table-measures`}
        dataSource={
          PDFprocessing
            ? formattedDataSource.filter((item) =>
                measuresInGraph.includes(item.measureType)
              )
            : formattedDataSource
        }
        columns={columns}
        pagination={false}
        bordered
        style={{ marginBottom: "1rem" }}
        size={PDFprocessing ? "small" : "large"}
      />
    );
  };

  // Combines all the scatterplot data with the correlation line to add both in one graph
  const [allData, setAllData] = useState([]);
  useEffect(() => {
    setAllData([...scatterPlotData, ...linearCorrelationData.linePlotData]);
  }, [scatterPlotData, linearCorrelationData]);

  // Ensure graph and Rsquared message are up-to-date with new executive selection
  useEffect(() => {
    setShowRsquaredMessage(false);
    if (!PvPColumnOptions.some((option) => option.key === compensationType)) {
      setCompensationType("total");
    }
    if (!measureOptions.some((meas) => meas.title === measure.title)) {
      setMeasure(Object.values(measureOptions)[0]);
    }
  }, [
    selectedExecutive,
    PvPColumnOptions,
    compensationType,
    measureOptions,
    measure,
  ]);

  useMemo(() => {
    if (selectedExecutive !== "allExecutives") {
      setCaptureCorrelationTableInPDF(true);
    } else {
      setCaptureCorrelationTableInPDF(false);
    }
  }, [selectedExecutive, setCaptureCorrelationTableInPDF]);

  const [measuresInGraph, setMeasuresInGraph] = useState([]);

  useMemo(() => {
    setMeasuresInGraph(Object.values(measureOptions).map((meas) => meas.title));
  }, [measureOptions]);

  const getMeasureOptionsWithCorrelation = (
    measureOptions,
    tableDataWithCorrelations
  ) => {
    if (tableDataWithCorrelations) {
      return measureOptions.map((item) => ({
        ...item,
        average: tableDataWithCorrelations.find(
          (tableCol) => tableCol.measureType === item.title
        )?.average,
      }));
    }
  };

  const measureOptionsWithCorrelation = getMeasureOptionsWithCorrelation(
    measureOptions,
    tableDataWithCorrelations
  );

  return (
    <div className="measure-scatterplot-section">
      <div id="measure-correlation-graph">
        <div
          className="measure-correlation-section"
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              flexWrap: "wrap-reverse",
            }}
          >
            <h3>Measure Correlation Plot</h3>
            <div
              style={{
                flex: 1,
                paddingTop: 5,
                paddingRight: 30,
                textAlign: "right",
              }}
            >
              {getAllDefinedValues(scatterPlotData, "measure").length > 1 && (
                <div>
                  <p style={{ fontSize: "1.1rem" }}>
                    {/* Option exists to show actual values in calculation (too long to display as is) */}
                    {/* {dataKeyMap[compensationType].title} ={" "} */}y ={" "}
                    {parseFloat(linearCorrelationData.slope).toPrecision(3)}{" "}
                    {/* {measure.title} +{" "} */}x{" "}
                    {linearCorrelationData.yIntercept >= 0 ? "+" : "–"}{" "}
                    {parseFloat(
                      Math.abs(linearCorrelationData.yIntercept)
                    ).toPrecision(3)}
                  </p>
                  {/* {getAllDefinedValues(scatterPlotData, "measure").length > 2 && ( */}
                  <div style={{ fontSize: "1.5rem" }}>
                    R<sup>2</sup>:{" "}
                    {/* RSquared can be N/A when all y values are the same (causes divide by 0) */}
                    {isNaN(linearCorrelationData.RSquared)
                      ? "N/A"
                      : parseFloat(linearCorrelationData.RSquared).toPrecision(
                          4
                        )}
                    {getAllDefinedValues(scatterPlotData, "measure").length ===
                      2 &&
                      linearCorrelationData.RSquared === 1 && (
                        <>
                          *
                          <p style={{ fontSize: "0.8rem" }}>
                            *Only two datapoints are available with this
                            selection
                          </p>
                        </>
                      )}
                  </div>
                  {/* )} */}
                </div>
              )}
            </div>
          </div>

          <MeasureSelector
            measure={measure}
            setMeasure={setMeasure}
            setCustomMeasureSelected={setCustomCorrelationMeasureSelected}
            measureOptions={measureOptions}
            heading="Company Selected Measure:"
            useCustomHeading={true}
            showIfDataAvailable={true}
            sortAndColorCodeMeasures={true}
            measureOptionsWithCorrelation={measureOptionsWithCorrelation}
          />
          <MeasureSelector
            measure={PvPColumnSelected}
            setMeasure={setPvPColumnSelected}
            measureOptions={PvPColumnOptions}
            heading="PvP Table Column:"
            useCustomHeading={true}
          />
          <div style={{ width: "100%", height: 500 }}>
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                width={500}
                height={400}
                data={allData}
                margin={{
                  top: 20,
                  right: 80,
                  bottom: 20,
                  left: 20,
                }}
                onMouseLeave={() => {
                  setActiveX(null);
                  setActiveY(null);
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <Tooltip
                  cursor={{
                    stroke: "var(--primary)",
                    strokeDasharray: "3 3",
                    strokeWidth: "1",
                  }}
                  content={CustomTooltip}
                  contentStyle={{ border: "none", background: "none" }}
                  // wrapperStyle={{ backgroundColor: "#fff" }}
                />
                <Legend />
                <XAxis
                  dataKey="measure"
                  type="number"
                  label={{
                    value: measure.title,
                    position: "insideBottom",
                    offset: -10,
                  }}
                  tickFormatter={(value) =>
                    abbreviateAndFormatMeasure(value, measure.dataType)
                  }
                />
                <YAxis
                  dataKey="compensation"
                  type="number"
                  tickFormatter={(value) =>
                    abbreviateAndFormatMeasure(value, "dollar")
                  }
                  label={{
                    className: "yaxis",
                    value:
                      PvPColumnSelected?.displayTitle ||
                      PvPColumnSelected?.title,
                    position: "insideLeft",
                    angle: 270,
                    offset: -15,
                    style: { textAnchor: "middle" },
                  }}
                />
                <ReferenceLine
                  y={activeY}
                  stroke="var(--primary)"
                  strokeDasharray="5 2"
                  strokeWidth="1"
                />
                <ReferenceLine
                  x={activeX}
                  stroke="var(--primary)"
                  strokeDasharray="3 3"
                  strokeWidth="1"
                />

                <Scatter
                  name={compensationType}
                  dataKey="compensation"
                  fill="var(--secondary)"
                  legendType="none"
                />
                {activeX && activeY && (
                  <ReferenceDot
                    x={activeX}
                    y={activeY}
                    r={10}
                    fill="var(--primary-1)"
                    stroke="none"
                  />
                )}

                <Line
                  dataKey="correlation"
                  stroke="var(--secondary)"
                  dot={false}
                  legendType="none"
                  label={false}
                  type="none"
                  activeDot={false}
                  strokeWidth={2}
                  animationDuration={100} //ms
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => setShowCorrelationTable(!showCorrelationTable)}
          style={{ marginBottom: 20, fontWeight: "bold" }}
        >
          {!showCorrelationTable ? "Show" : "Hide"}{" "}
          {!showCorrelation ? "Regression" : "Correlation"}{" "}
          {"Table" + (showCorrelationTable ? "" : "?")}
        </Button>
      </div>
      {showCorrelationTable && (
        <div
          className="correlation-table-section"
          style={{
            paddingTop: 30,
          }}
          id="measure-correlation-table"
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>
              Measure {showCorrelation ? "Correlation" : "Regression"}{" "}
              Graph/Table{" "}
              {selectedExecutive !== "allExecutives" && (
                <>
                  for{" "}
                  <Link
                    to={`/company/${selectedExecutive?.Ticker}/${slugifyName(
                      selectedExecutive?.name
                    )}`}
                  >
                    {selectedExecutive?.name}
                  </Link>
                </>
              )}
            </h3>
            <div>
              <Checkbox
                checked={showCorrelationColors}
                onChange={() =>
                  setShowCorrelationColors(!showCorrelationColors)
                }
              >
                Show Correlation Colors
              </Checkbox>
              <Button onClick={() => setShowCorrelation(!showCorrelation)}>
                Show {showCorrelation ? "Regression" : "Correlation"}
              </Button>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <PvPCorrelationGraph
              formattedTableData={formattedTableData}
              correlationType={showCorrelation ? "correlation" : "RSquared"}
              PvPTableColumns={PvPTableColumns}
              measureOptions={measureOptions}
              measuresInGraph={measuresInGraph}
            />
          </div>
          <div>
            <CorrelationTable
              formattedTableData={formattedTableData}
              measuresInGraph={measuresInGraph}
              setMeasuresInGraph={setMeasuresInGraph}
              tableDataWithCorrelations={tableDataWithCorrelations}
              setTableDataWithCorrelations={setTableDataWithCorrelations}
            />
          </div>

          {showRsquaredMessage && (
            <p>*Only two datapoints are available for these selections</p>
          )}
        </div>
      )}
    </div>
  );
}
