import { CSuiteBaseService } from "./CSuiteBaseServices";

class PlansService extends CSuiteBaseService {
    getAllPlan = () => {
        return this.get('payment/get-all-plans');
    }

    getPlan = (id) => {
        return this.get(`payment/get-plan?id=${id}`)
    }
}

export default new PlansService();