import React, { useState, useEffect } from "react";
import "../css/tutorials.css";
import { useSelector } from "react-redux";
import NotFound from "./notFound";

export default function Tutorials() {
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);

  const [isActiveSubscriber, setIsActiveSubscriber] = useState(
    ["active", "trialing"].includes(subscription?.status)
  );
  useEffect(() => {
    setIsActiveSubscriber(
      ["active", "trialing"].includes(subscription?.status)
    );
  }, [subscription]);

  const vimeoTutorials = [
    { title: "1. Main Page Intro & Aggregate Analysis", vimeoID: "930806213" },
    { title: "2. Company and Executive View ", vimeoID: "930806370" },
    { title: "3. Company Calculators", vimeoID: "930806571" },
    { title: "4. Peer Group Analysis", vimeoID: "930806678" },
    { title: "5. Downloading Compensation Data", vimeoID: "930806760" },
    { title: "6. Board Pay", vimeoID: "930806809" },
    { title: "7. Options Calculator", vimeoID: "930806852" },
  ];

  return (
    <div className="tutorials">
      {!isActiveSubscriber ? (
        <NotFound />
      ) : (
        <>
          <h1 style={{ textAlign: "center", fontSize: "4rem" }}>
            Tutorial Videos
          </h1>
          <div style={{ margin: "0px 25px" }}>
            <p style={{ fontSize: 18 }}>
              The following tutorial videos are best watched in sequence and
              will maximize your utilization of our platform.
            </p>
          </div>
          {vimeoTutorials.map((vimeo) => (
            <div style={{ margin: "25px 25px" }}>
              <h2>{vimeo.title}</h2>{" "}
              <div className="tutorial_wrapper">
                <iframe
                  src={`https://player.vimeo.com/video/${vimeo.vimeoID}?badge=0amp;byline=falseamp;portrait=falseamp;title=falseamp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                  width="973"
                  height="500"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  title={vimeo.title}
                ></iframe>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}
