export const exportToCSV = (JSONdata, fileName) => {
  const array = [Object.keys(JSONdata[0])].concat(JSONdata);
  const csvdata = array
    .map((val) => {
      return Object.values(val).toString();
    })
    .join("\n");

  const csvContent = `data:text/csv;charset=utf-8,${"\uFEFF" + csvdata}`;

  const encodedURI = encodeURI(csvContent);
  const a = document.createElement("a");
  a.setAttribute("href", encodedURI);
  a.setAttribute("download", fileName);
  a.click();
  a.remove();
  // window.open(encodedURI);
};
