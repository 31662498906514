export const sortClosestCompany = (a, b, searchVal) => {
  if (searchVal) {
    if (
      a.Ticker.toLowerCase() === searchVal.toLowerCase() &&
      b.Ticker.toLowerCase() !== searchVal.toLowerCase()
    ) {
      return -1;
    } else if (
      b.Ticker.toLowerCase() === searchVal.toLowerCase() &&
      a.Ticker.toLowerCase() !== searchVal.toLowerCase()
    ) {
      return 1;
    } else if (
      a?.AlternateTickers?.some((ticker) =>
        ticker.toLowerCase().includes(searchVal.toLowerCase())
      ) &&
      !b?.AlternateTickers?.some((ticker) =>
        ticker.toLowerCase().includes(searchVal.toLowerCase())
      )
    ) {
      return -1;
    } else if (
      b?.AlternateTickers?.some((ticker) =>
        ticker.toLowerCase().includes(searchVal.toLowerCase())
      ) &&
      !a?.AlternateTickers?.some((ticker) =>
        ticker.toLowerCase().includes(searchVal.toLowerCase())
      )
    ) {
      return 1;
    } else if (a.Company && b.Company) {
      return (
        b.Company.toLowerCase().startsWith(searchVal.toLowerCase()) -
        a.Company.toLowerCase().startsWith(searchVal.toLowerCase())
      );
    }
  } else {
    return a.Company?.localeCompare(b?.Company);
  }
};

export const sortClosestExecutive = (a, b, searchVal) => {
  const searchValArray = searchVal.toLowerCase().split(" ");

  // Sort most relevent results first
  if (!!searchVal) {
    // const searchValArray = searchVal.toLowerCase().split(" ");
    const score = (item) => {
      const name = item.name.toLowerCase();
      const ticker = item.Ticker.toLowerCase();
      const company = item.company.toLowerCase();
      const splitName = name.split(" ");
      let score = 0;
      for (const val of searchValArray) {
        if (splitName.some((name) => name.startsWith(val))) {
          score += 3;
        }
        if (ticker.includes(val)) {
          score += 2;
        }
        if (company.includes(val)) {
          score += 1;
        }
      }
      return score;
    };
    if (a?.name && b?.name) {
      return score(b) - score(a);
    } else {
      return 0;
    }
  } else {
    if (a?.company && b?.company) {
      const nameA = a.company.toLowerCase();
      const nameB = b.company.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }
  }
};
