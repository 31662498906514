import React, { useState, useEffect, useMemo } from "react";
import SyncLoader from "react-spinners/SyncLoader";

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "../../css/graphs.css";
import { Tooltip as AntdTooltip } from "antd";
import { DEFAULT_YEAR, MIN_YEAR } from "../../utils/Constants/systemSetting";

export default function LineGraph({
  data,
  currentYear,
  delay,
  update,
  company = "",
  executive = "",
  isBoardPay = false,
  PDFprocessing = false,
  MAX_YEAR = DEFAULT_YEAR,
  isHomePage = false,
}) {
  const [rawData, setRawData] = useState();
  const [dataset, setDataset] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [years, setYears] = useState([]);
  const [executiveNames, setExecutiveNames] = useState([]);
  const [currentExecutive, setCurrentExecutive] = useState(
    "All Executives (average)"
  );

  useEffect(() => {
    setCurrentExecutive("All Executives (average)");
  }, [company]);

  useEffect(() => {
    const getYearsArray = () => {
      const years = [];
      let year = new Date().getFullYear();
      // If on homepage, max year should be the default year for the site:
      if (!company && !executive) {
        year = Number(MAX_YEAR);
      }
      for (year; year >= MIN_YEAR; year--) {
        years.push(year);
      }
      return years;
    };
    setYears(getYearsArray());
  }, [company, executive, MAX_YEAR]);

  // Load executives data //

  useEffect(() => {
    setRawData(data);
  }, [data]);

  useEffect(() => {
    if (!delay && !update) {
      const yearsData = {};
      let allExecutiveNames = [];

      if (years.some((yr) => rawData?.[yr]?.length > 0)) {
        // console.log(rawData);
        setLoaded(true);
        setDataset([]);
        // generate list of all compenstaions
        for (let y in years) {
          let allCompensation = [];

          for (let d in rawData?.[years[y].toString()]) {
            allCompensation.push(rawData?.[years[y].toString()][d]);
            if (company) {
              let executiveName = rawData?.[years[y].toString()][d]?.name;
              if (executiveName && !allExecutiveNames.includes(executiveName)) {
                allExecutiveNames.push(executiveName);
              }
            }
          }
          if (
            allExecutiveNames.length > 1 &&
            !allExecutiveNames.includes("All Executives (average)")
          ) {
            allExecutiveNames.unshift("All Executives (average)");
          }
          setExecutiveNames(allExecutiveNames);

          yearsData[years[y]] = {};
          let totalCount = 0;
          let salaryCount = 0;
          let bonusCount = 0;
          let stockCount = 0;
          let optionCount = 0;
          let nonEquityCount = 0;
          let pensionCount = 0;
          let otherCount = 0;
          //---------------------//
          let currentTotal = 0;
          let currentSalary = 0;
          let currentBonus = 0;
          let currentStockAward = 0;
          let currentOptionAward = 0;
          let currentNonEquity = 0;
          let currentPension = 0;
          let currentOther = 0;

          if (currentExecutive !== "All Executives (average)") {
            allCompensation = allCompensation.filter((compensationData) => {
              return compensationData?.name === currentExecutive;
            });
          }

          for (let c in allCompensation) {
            let currentCompensation = allCompensation[c]?.compensation;
            let totalComp =
              parseInt(currentCompensation?.[years[y]]?.total) || 0;
            if (totalComp > 0) totalCount += 1;
            let salary =
              (!isBoardPay || isHomePage
                ? currentCompensation?.[years[y]]?.salary
                : currentCompensation?.[years[y]]?.feesEarnedOrPaid) || 0;
            if (salary > 0) salaryCount += 1;
            let bonus = currentCompensation?.[years[y]]?.bonus || 0;
            if (bonus > 0) bonusCount += 1;
            let stockAward =
              currentCompensation?.[years[y]]?.[
                "stockAward" + (isBoardPay ? "s" : "")
              ] || 0;
            if (stockAward > 0) stockCount += 1;
            let optionAward =
              currentCompensation?.[years[y]]?.[
                "optionAward" + (isBoardPay ? "s" : "")
              ] || 0;
            if (optionAward > 0) optionCount += 1;
            let nonEquity = currentCompensation?.[years[y]]?.nonEquity || 0;
            if (nonEquity > 0) nonEquityCount += 1;
            let pension = currentCompensation?.[years[y]]?.pension || 0;
            if (pension > 0) pensionCount += 1;
            let other = currentCompensation?.[years[y]]?.other || 0;
            if (other > 0) otherCount += 1;
            currentTotal += totalComp;
            currentSalary += salary;
            currentBonus += bonus;
            currentStockAward += stockAward;
            currentOptionAward += optionAward;
            currentNonEquity += nonEquity;
            currentPension += pension;
            currentOther += other;

            if (c == allCompensation.length - 1) {
              // if (years[y] == "2021") {
              // for debugging:
              // console.log(currentTotal, totalCount);
              // console.log(allCompensation);
              // console.log(rawData);
              // }
              yearsData[years[y]]["Total"] = Math.round(
                currentTotal / totalCount
              );
              yearsData[years[y]][
                `${!isBoardPay ? "Salary" : "Fees Earned or Paid in Cash"}`
              ] = Math.round(currentSalary / salaryCount);

              yearsData[years[y]]["Bonus"] = Math.round(
                currentBonus / bonusCount
              );
              yearsData[years[y]]["Stock Awards"] = Math.round(
                currentStockAward / stockCount
              );
              yearsData[years[y]]["Option Awards"] = Math.round(
                currentOptionAward / optionCount
              );
              yearsData[years[y]]["Non-Equity Incentive"] = Math.round(
                currentNonEquity / nonEquityCount
              );
              yearsData[years[y]]["Change in Pension"] = Math.round(
                currentPension / pensionCount
              );
              yearsData[years[y]]["Other"] = Math.round(
                currentOther / otherCount
              );
            }
          }
          if (y == years.length - 1) {
            setDataset(yearsData);
          }
        }
      } else setDataset([]);
    }
  }, [rawData, years, currentExecutive, company, delay, update, isBoardPay]);

  const generateData = (years, dataset, datapoint) => {
    const data = [];
    for (let year of years) {
      if (dataset?.[year]?.[datapoint]) {
        data.push({
          x: year,
          y: dataset?.[year]?.[datapoint],
          type: datapoint === "Total" ? "Total Compensation" : datapoint,
        });
      }
    }
    return data;
  };

  const dataTotal = generateData(years, dataset, "Total");
  const dataSalary = generateData(
    years,
    dataset,
    `${!isBoardPay ? "Salary" : "Fees Earned or Paid in Cash"}`
  );
  const dataBonus = generateData(years, dataset, "Bonus");
  const dataStock = generateData(years, dataset, "Stock Awards");
  const dataOptions = generateData(years, dataset, "Option Awards");
  const dataNonEquity = generateData(years, dataset, "Non-Equity Incentive");
  const dataPension = generateData(years, dataset, "Change in Pension");
  const dataOther = generateData(years, dataset, "Other");

  const [yearsWithData, setYearsWithData] = useState([]);
  useEffect(() => {
    const newYears = [];
    for (let data of dataTotal) {
      if (data["y"] && !newYears.includes(data["x"])) {
        newYears.push(data["x"]);
      }
    }
    setYearsWithData(newYears);
  }, [dataset]);

  const [active, setActive] = useState(["Total"]);
  const handleClick = (e) => {
    if (!active.includes(e.target.value)) {
      setActive(active.concat(e.target.value));
    } else {
      let index = active.indexOf(e.target.value);
      setActive(active.filter((i) => i != e.target.value));
    }
  };
  function CustomTooltip({ payload, label, active }) {
    if (active) {
      let label = "";
      try {
        label = `${payload[1].payload?.type} (${payload[0].value})`;
      } catch {
        label = "Value";
      }
      if (company || executive) {
        return (
          <div>
            <p style={{ textAlign: "center" }}>
              {!executive && currentExecutive === "All Executives (average)"
                ? `Average of All Executives at ${company}:`
                : (executive || currentExecutive) + ":"}
            </p>
            <p style={{ textAlign: "center" }}>
              {label}
              <b>{": $" + payload[1].value.toLocaleString()}</b>
            </p>
          </div>
        );
      } else {
        return (
          <div>
            <p style={{ textAlign: "center" }}>
              Average of All Executives Selected:
            </p>
            <p style={{ textAlign: "center" }}>
              {label} <b>{": $" + payload[1].value.toLocaleString()}</b>
            </p>
          </div>
        );
      }
    }

    return null;
  }

  const formatLegend = (value, entry, index) => {
    const { color } = entry;

    return (
      <span style={{ color, fontSize: "0.82em", marginLeft: 0 }}>{value}</span>
    );
  };

  const [renderLine, setRenderLine] = useState();
  useEffect(() => {
    if (!delay && !update) {
      setRenderLine(
        <ResponsiveContainer width="100%" height={325}>
          <ScatterChart
            margin={{ top: 20, bottom: 40, left: 55, right: 0 }}
            padding={{ left: 25, right: 25 }}
            isAnimationActive={!PDFprocessing}
          >
            <CartesianGrid />
            {PDFprocessing && (
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="center"
                iconType="square"
                formatter={formatLegend}
              />
            )}
            <XAxis
              type="number"
              dataKey="x"
              name="Year"
              domain={["dataMin - 0.5", "dataMax + 0.5"]}
              ticks={yearsWithData}
            >
              <Label value="Year" offset={-10} position="insideBottom" />
            </XAxis>

            <YAxis
              type="number"
              dataKey="y"
              name="Value"
              domain={["auto", "auto"]}
              tickFormatter={(value) => "$" + value.toLocaleString()}
            >
              <Label
                className="yaxis"
                value="Compensation"
                offset={-35}
                angle={270}
                position="insideLeft"
              />
            </YAxis>
            <ZAxis dataKey="z" range={[100]} />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              content={CustomTooltip}
              wrapperStyle={{ backgroundColor: "#fff" }}
            />
            {active.includes("Total") ? (
              <Scatter
                name="Total Compensation"
                data={dataTotal}
                fill="black"
                line={{ strokeWidth: 3 }}
                shape="dot"
                isAnimationActive={!PDFprocessing}
              />
            ) : null}

            {active.includes(
              `${!isBoardPay ? "Salary" : "Fees Earned or Paid in Cash"}`
            ) ? (
              <Scatter
                name={`${
                  !isBoardPay ? "Salary" : "Fees Earned or Paid in Cash"
                }`}
                data={dataSalary}
                fill={"var(--graph-salary)"}
                line={{ strokeWidth: 3 }}
                shape="dot"
                isAnimationActive={!PDFprocessing}
              />
            ) : null}
            {active.includes("Bonus") ? (
              <Scatter
                name="Bonus"
                data={dataBonus}
                fill={"var(--graph-bonus)"}
                line={{ strokeWidth: 3 }}
                shape="dot"
                isAnimationActive={!PDFprocessing}
              />
            ) : null}
            {active.includes("Stock Awards") ? (
              <Scatter
                name="Stock Awards"
                data={dataStock}
                fill={"var(--graph-stock-awards)"}
                line={{ strokeWidth: 3 }}
                shape="dot"
                isAnimationActive={!PDFprocessing}
              />
            ) : null}
            {active.includes("Option Awards") ? (
              <Scatter
                name="Option Awards"
                data={dataOptions}
                fill={"var(--graph-option-awards)"}
                line={{ strokeWidth: 3 }}
                shape="dot"
                isAnimationActive={!PDFprocessing}
              />
            ) : null}
            {active.includes("Non-Equity Incentive") ? (
              <Scatter
                name="Non-Equity Incentive"
                data={dataNonEquity}
                fill={"var(--graph-non-equity)"}
                line={{ strokeWidth: 3 }}
                shape="dot"
                isAnimationActive={!PDFprocessing}
              />
            ) : null}
            {active.includes("Change in Pension") ? (
              <Scatter
                name="Change in Pension"
                data={dataPension}
                fill={"var(--graph-pension)"}
                line={{ strokeWidth: 3 }}
                shape="dot"
                isAnimationActive={!PDFprocessing}
              />
            ) : null}
            {active.includes("Other") ? (
              <Scatter
                name="Other"
                data={dataOther}
                fill={"var(--graph-other)"}
                line={{ strokeWidth: 3 }}
                shape="dot"
                isAnimationActive={!PDFprocessing}
              />
            ) : null}
          </ScatterChart>
        </ResponsiveContainer>
      );
    }
  }, [
    dataset,
    yearsWithData,
    delay,
    active,
    update,
    isBoardPay,
    PDFprocessing,
  ]);

  const dataTypes = [
    ["Total", "black"],
    [
      `${!isBoardPay ? "Salary" : "Fees Earned or Paid in Cash"}`,
      "var(--graph-salary)",
    ],
    ["Bonus", "var(--graph-bonus)"],
    ["Stock Awards", "var(--graph-stock-awards)"],
    ["Option Awards", "var(--graph-option-awards)"],
    ["Non-Equity Incentive", "var(--graph-non-equity)"],
    ["Change in Pension", "var(--graph-pension)"],
    ["Other", "var(--graph-other)"],
  ];

  const handleExecutiveChange = (e) => {
    setCurrentExecutive(e.target.value);
  };

  const executiveOptions = useMemo(() => {
    return executiveNames.map((name, index) => (
      <option value={name} key={index}>
        {name}
      </option>
    ));
  }, [executiveNames]);

  const showItem = (item) => {
    const dataValues = Object.values(dataset);
    for (const dataValue of Object.values(dataValues)) {
      if (!isNaN(dataValue[item])) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="lineGraph" id="lineGraph">
      {dataset?.length > 1 || loaded ? (
        <>
          {currentExecutive === "All Executives (average)" &&
          yearsWithData.length <= 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="title" style={{ marginTop: 0 }}>
                {" "}
                Trend data is not yet available for{" "}
                {company || "current selection"}.
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <div
                  className="year-filter geography"
                  style={{ width: "275px", marginBottom: "5px" }}
                >
                  {company && (
                    <>
                      {executiveOptions.length > 1 && (
                        <>
                          <div className="title" style={{ height: "60px" }}>
                            <h5 style={{ lineHeight: "32px" }}>Executive:</h5>
                          </div>
                          {!PDFprocessing ? (
                            <select
                              name="year"
                              onChange={handleExecutiveChange}
                              value={currentExecutive}
                              style={{ width: "200px", textAlign: "center" }}
                            >
                              {executiveOptions}
                            </select>
                          ) : (
                            <span>{currentExecutive}</span>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>

                <div className="above buttons">
                  <AntdTooltip
                    overlayInnerStyle={{
                      padding: "10px",
                      color: "var(--secondary)",
                    }}
                    color="white"
                    title="Click on a compensation type below to include it in the graph."
                    placement="top"
                  >
                    <div
                      className="title above"
                      style={{ cursor: "help", marginTop: company ? 0 : 0 }}
                    >
                      Trend in Executive Compensation
                      {yearsWithData.length > 1
                        ? ` ${yearsWithData[yearsWithData.length - 1]} -
                      ${yearsWithData[0]}`
                        : ""}
                      &nbsp;
                      {executive
                        ? `for ${executive}`
                        : company
                        ? `for ${company}`
                        : ""}
                    </div>
                  </AntdTooltip>
                </div>
                {yearsWithData.length <= 1 ? (
                  <div>
                    <div
                      className="title"
                      style={{ marginTop: 20, width: 550, height: 400 }}
                    >
                      {" "}
                      Trend data is not yet available for this executive.
                      <br />
                      {currentExecutive} only has data available for{" "}
                      {yearsWithData.toString()} (see table above).
                    </div>
                  </div>
                ) : (
                  <>
                    {!PDFprocessing && (
                      <div
                        className="buttons"
                        style={{
                          margin: company
                            ? "65px 0 65px 55px"
                            : "15px 0 15px 55px",
                        }}
                      >
                        {dataTypes.map((item, i) => {
                          return (
                            <button
                              className={
                                active.includes(item[0])
                                  ? item[0] !== "Total"
                                    ? "active"
                                    : "active2"
                                  : ""
                              }
                              style={{
                                backgroundColor: item[1],
                                display: showItem(item[0]) ? "" : "None",
                              }}
                              key={item[0]}
                              value={item[0]}
                              onClick={(e) => {
                                handleClick(e);
                              }}
                            >
                              {item[0]}
                            </button>
                          );
                        })}
                      </div>
                    )}

                    {renderLine}
                  </>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="loaderContainer">
          <SyncLoader color={"var(--loader)"} loading={true} size={10} />
        </div>
      )}
    </div>
  );
}
