import styled from "styled-components";
//   height: 10px;

export const Button = styled.button`
  font-size: calc(0.8rem + 0.15vw);
  color: var(--secondary);
  background-color: ${(props) =>
    props.primary
      ? "var(--primary)"
      : props?.color
      ? props.color
      : "transparent"};
  border-radius: 5px;
  border: ${(props) =>
    props.primary ? "2px solid var(--primary)" : "2px solid var(--secondary)"};
  padding: 5px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.5s;
  text-align: center;
  justify-content: center;

  &:hover {
    color: ${(props) =>
      props.primary ? "var(--secondary)" : "var(--white) !important"};
    transform: translateY(-3px);
    background-color: ${(props) =>
      props.primary ? "var(--primary-1)" : "var(--black)"};
    border: ${(props) =>
      props.primary ? "2px solid var(--primary-1)" : "2px solid var(--black)"};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--primary-disabled);
    border-color: var(--primary-disabled);
  }

  &:disabled:hover {
    transform: translateY(0);
  }
`;
